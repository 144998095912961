
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>ACCOUNTING AND FINANCE</h1>
			<p>
				The International financial and quantitative LCCI 
				qualification cover a wide range of essential quantitative 
				topics to prepare students for professional qualifications, or 
				a direct employment within the financial sector; as it is 
				comprised of four levels according to the individuals’ 
				preferences and skills set
			</p>
		</div>
		<div class="img desktop">
			<img src="../../assets/imgs/business-administration/sect1.png" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Sub Title</h1>
			<p>
				The globally LCCI recognized financial and quantitative 
				certificate covers a wide spectrum of the financial and 
				accounting essentials of computerised, financial, and 
				management. As well as the key financial and quantitative 
				topics such as: bookkeeping, business statistics and 
				organisational behaviour and performance; thus, allowing 
				individuals development within the financial sector. 
				Exemptions and accreditations are available from the 
				following professional bodies
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/business-administration/sect1.png" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info" id="info1">
			<div>
				<p id="title">ACCA</p>
				<p>
					The Association of 
					Chartered Certified 
					Accountants
				</p>
			</div>
		</div>
		<div class="info" id="info2">
			<div>
				<p id="title">ICAEW</p>
				<p>
					Chartered Accountants
				</p>
			</div>
		</div>
		<div class="info" id="info3">
			<div>
				<p id="title">CIMA</p>
				<p>
					The Chartered Institute of 
					Management Accountants
				</p>
			</div>
		</div>
		<div class="info" id="info4">
			<div>
				<p id="title">AIA</p>
				<p>
					The Association of 
					International Accountants
				</p>
			</div>
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<p id="title">The available LCCI ACCOUNTING AND FINANCE  diplomas include:</p>
		<div class="cards" id="card1">
			<h1>Level 1</h1>
			<p>BOOK KEPING</p>
		</div>
		<div class="cards" id="card2">
			<h1>Level 2</h1>
			<p>BOOK KEPING AND ACCOUNTING</p>
			<p>BUSINESS CALCULATIONS</p>
			<p>BUSINESS STATISTICS</p>
			<p>COMPUTERIZED BOOK KEEPING</p>
			<p>COST ACCOUNTING</p>
		</div>
		<div class="cards" id="card3">
			<h1>Level 3</h1>
			<p>ACCOUNTING</p>
			<p>ADVANCED BUSINESS CALCULATIONS</p>
			<p>BUSINESS STATISTICS</p>
			<p>COMPUTERIZED ACCOUNTING (VQR)</p>
			<p>COMPUTRISED ACCOUNTING SKILLS</p>
			<p>COST AND MANAGEMENT ACCOUNTING</p>
			<p>FINANCIAL ACCOUNTING</p>
		</div>
		<div class="cards" id="card4">
			<h1>Level 4</h1>
			<p>FINANCIAL ACCOUNTING</p>
			<p>MANAGAMENT ACCOUNTING</p>
			<p>ORGANIZATIONAL BEHAVIOUR AND PERFORMANCE</p>
		</div>
	</div>
</section>