import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounting-and-financing',
  templateUrl: './accounting-and-financing.component.html',
  styleUrls: ['./accounting-and-financing.component.css']
})
export class AccountingAndFinancingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

}
