<section class="sect7_wrapper">
	<div class="sect7_content">
		<h1>Gallery</h1>
		<div class="cards_parent">
			<div class="cards" id="card1_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img42.jpg" alt="">
			</div>
			<div class="cards" id="card2_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img32.jpg" alt="">
			</div>
			<div class="cards" id="card3_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img33.jpg" alt="">
			</div>
			<div class="cards" id="card4_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img34.jpg" alt="">
			</div>
			<div class="cards" id="card5_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img35.jpg" alt="">
			</div>
			<div class="cards" id="card6_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img34.jpg" alt="">
			</div>
			<div class="cards" id="card7_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img37.jpg" alt="">
			</div>
			<div class="cards" id="card8_gallery">
				<div id="opacity"></div>
				<img src="../../assets/imgs/gallery/img38.jpg" alt="">
			</div>
		</div>
	</div>
</section>