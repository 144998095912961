import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard


@Component({
  selector: 'app-student-assignment',
  templateUrl: './student-assignment.component.html',
  styleUrls: ['./student-assignment.component.css']
})
export class StudentAssignmentComponent implements OnInit {

  
param:string;

 constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.param = params.courseName;			
			});
	}

	student_pk:string = this.apiService.loggedIn_user()["pk"];

  courses:any;
  course:any;

	assignments:any;

		turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void {
  	 window.scrollTo(0, 0);  	 
	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		this.courses = response.data;
		for (var i in this.courses) {	
			if(this.courses[i].courseName == this.param){				
				this.course = this.courses[i];
			}else{}
		}
														
	});

	 this.apiService.getAssignments_byStudentId(this.student_pk).subscribe(response=>{		
		this.assignments = response.data;
		// console.log(1);
		// console.log(response);

		
		this.assignments = this.turn2dArrayToAssoc2dArray(this.assignments, 'courseName')[this.param];
		// console.log(this.assignments);
		
	});

  }

}
