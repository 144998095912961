import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
  	if(value.length>21) {
  		value = value.substr(0,21)+"...";
  	}
    return value;
  }

}
