import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-admin-testimonials',
  templateUrl: './admin-testimonials.component.html',
  styleUrls: ['./admin-testimonials.component.css']
})
export class AdminTestimonialsComponent implements OnInit {

  constructor(private apiService: ApiService) { }



  testimonials:any;

  searchText:string;

  	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}



  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getTestimonials().subscribe(response=>{	
		this.testimonials = response;		
	});
  }


    deleteTestimonial(pk):void{      	
	  	this.apiService.deleteTestimonial(pk).subscribe(response=>{		  			  				  		
	  		console.log(response);  		
		});
	}

}
