
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Conferences</h1>
			<p>
				Industry conferences are the perfect opportunity for companies and startups to gain more experience in the field they are in. No matter how much experience a company has, holding or attending conferences are one of the crucial points for the growth of businesses and startups as there is so much to be learnt about the current business climate. Being in a small business reduces the exposure to not only a variety of points of views, but also new vendors and suppliers. Hence, creating a network and being connected to other people is a power point for companies
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/conferences/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Our event coordinators will help ensure that the 
				conferences are successfully and smoothly run from start 
				to finish. Communicating with you to generate ideas and 
				create a plan that fully meets your needs and satisfaction 
				is our top priority. Cadres will partner with the needed 
				organizations to ensure quality services that are also on 
				the specified budget. That is through managing vendors 
				and venues, onsite management, and post even 
				reporting. 
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/conferences/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="bg">
			<img src="../../assets/backgrounds/conferences/sect3.svg">
	</div>
	<div class="sect3_content">
		<div class="left">
			<div>	
				<p>
					<span></span> Production companies
				</p>
			</div>
			<div>	
				<p>
					<span></span> Decorations and room layouts 
				</p>
			</div>
			<div>	
				<p>
					<span></span> Offsite venues
				</p>
			</div>
			<div>	
				<p>
					<span></span> Menu
				</p>
			</div>
			<div>	
				<p>
					<span></span> Transportation
				</p>
			</div>
			<div>	
				<p>
					<span></span> Detailed financial records
				</p>
			</div>
		</div>
		<div class="right">
			<div>	
				<p>
					<span></span> Manage audio visual equipment
				</p>
			</div>
			<div>	
				<p>
					<span></span> Entertainment booking
				</p>
			</div>
			<div>	
				<p>
					<span></span> Banquet and catering
				</p>
			</div>
			<div>	
				<p>
					<span></span> Housekeeping
				</p>
			</div>
			<div>	
				<p>
					<span></span> Audit coordination
				</p>
			</div>
		</div>
	</div>
</section>