
<lcci-sign-in></lcci-sign-in>
<payment-gateway></payment-gateway>
<product-plan></product-plan>
 
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="types_container">
			<div class="culomn1"><button>Type:</button></div>
			<div class="culomn2" [style.background]="diplomas_bg" (click)="switch('diplomas')"><button>Diplomas</button></div>
			<div class="culomn3" [style.background]="courses_bg" (click)="switch('courses')"><button>Courses</button></div>
		</div>
		<p id="title">Filter By:</p>
		<div class="filters_container">
			<div class="level">
				<p>Level</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="category">
				<p>Category</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="learningHours">
				<p>Learning Hours</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="price">
				<p>Price</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
		</div>
	</div>
</section>

<section class="diplomas_wrapper" [style.display]="diplomas_display">
	<div class="diplomas_content">
		<div class="cards" id="card1" *ngFor="let diploma of diplomasRounds; index as i" id="card{{i+1}}">
			<div class="img" routerLink="./diploma-details/{{diploma.diplomaName | removeHtmlTags}}/{{diploma.round_pk}}">
				<img src="../../assets/imgs/imgPlaceHolder.svg" alt="">
			</div>
			<div class="info" routerLink="./diploma-details/{{diploma.diplomaName | removeHtmlTags}}/{{diploma.round_pk}}">
				<p id="title">
					{{diploma.diplomaName | removeHtmlTags | titlecase}}
				</p>
				<p id="description">
					{{diplomasAssoc[diploma.diplomaName][0].description | removeHtmlTags | slice:0:310}}<span *ngIf="diplomasAssoc[diploma.diplomaName][0].description.length > 320">...</span>
				</p>
				<div class="extras" routerLink="./diploma-details/{{diploma.diplomaName | removeHtmlTags}}/{{diploma.round_pk}}">
					<div>
						<p id="price">£{{diplomasAssoc[diploma.diplomaName][0].price}}</p>
						<p>Level: {{diplomasAssoc[diploma.diplomaName][0].level}}</p>
						<p>Learning Hours: {{diplomasAssoc[diploma.diplomaName][0].learningHours}}</p>
						<p>Suite: {{diplomasAssoc[diploma.diplomaName][0].suite}}</p>
						<div class="logos">
							<div class="logo1">
								<img src="../../assets/icons/pearson.svg" alt="">
							</div>
							<div class="logo2">
								<img src="../../assets/icons/lcci.svg" alt="">
							</div>	
						</div>
					</div>
					<div class="enroll">
						<div class="capacity">
				            <svg viewBox="0 0 36 36" class="circular-chart">

				                <path class="that-circle" stroke="#707070" stroke-dasharray="100 50" d="M18 2.0845
				                                a 15.9155 15.9155 0 0 1 0 31.831
				                                a 15.9155 15.9155 0 0 1 0 -31.831" />

				                <path class="that-circle" stroke="#3FA500" attr.stroke-dasharray="{{diploma.numberOfEnrollments*10}}, {{100-(diploma.numberOfEnrollments*10)}}" d="M18 2.0845
				                                a 15.9155 15.9155 0 0 1 0 31.831
				                                a 15.9155 15.9155 0 0 1 0 -31.831" />
				            </svg>
				            <div class="percentage">
					            <p id="percentage">
					            	{{(diploma.numberOfEnrollments*100)/(diploma.maxCapacity) | number: '1.0-0'}}%
					            </p>
				                <p>booked</p>
				            </div>
				        </div>
						<button>
							<p>Enroll</p>
							<br>
							<p id="startDate">Starts {{diploma.startDate | date: 'LLL'}} {{diploma.startDate | date: 'd'}}</p>
						</button>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</section>

<section class="courses_wrapper" [style.display]="courses_display">
	<div class="courses_content">
		<div class="cards" *ngFor="let course of coursesRounds; index as i" id="card{{i+1}}" >
			<div class="img" routerLink="./course-details/{{course.courseName | removeHtmlTags}}/{{course.round_pk}}">
				<img src="../../assets/imgs/imgPlaceHolder2.svg" alt="">
			</div>
			<div class="info">
				<p routerLink="./course-details/{{course.courseName | removeHtmlTags}}/{{course.round_pk}}" id="title">{{course.courseName | removeHtmlTags | titlecase}}</p>
				<p routerLink="./course-details/{{course.courseName | removeHtmlTags}}/{{course.round_pk}}" id="description">
					{{coursesAssoc[course.courseName | removeHtmlTags][0].description | slice:0:350}}...
				</p>
				<div class="extras" routerLink="./course-details/{{course.courseName | removeHtmlTags}}">
					<p id="price">£{{coursesAssoc[course.courseName | removeHtmlTags][0].price}}</p>
					<p>Level: {{course.level}}</p>
					<p>Learning Hours: {{coursesAssoc[course.courseName | removeHtmlTags][0].learningHours}}</p>
					<p>Suite: {{coursesAssoc[course.courseName | removeHtmlTags][0].suite}}</p>
					<div class="logos">
						<div class="logo1">
							<img src="../../assets/icons/pearson.svg" alt="">
						</div>
						<div class="logo2">
							<img src="../../assets/icons/lcci.svg" alt="">
						</div>	
					</div>
				</div>
				<div class="enroll">
					<div class="capacity">
			            <svg viewBox="0 0 36 36" class="circular-chart">

			                <path class="that-circle" stroke="#707070" stroke-dasharray="100 50" d="M18 2.0845
			                                a 15.9155 15.9155 0 0 1 0 31.831
			                                a 15.9155 15.9155 0 0 1 0 -31.831" />

			                <path class="that-circle" stroke="#3FA500" attr.stroke-dasharray="{{course.numberOfEnrollments*10}}, {{100-(course.numberOfEnrollments*10)}}" d="M18 2.0845
			                                a 15.9155 15.9155 0 0 1 0 31.831
			                                a 15.9155 15.9155 0 0 1 0 -31.831" />
			            </svg>
			            <div class="percentage">
				            <p id="percentage">
					            	{{(course.numberOfEnrollments*100)/(course.maxCapacity) | number: '1.0-0'}}%
					            </p>
			                <p>booked</p>
			            </div>
			        </div>
					<button (click)="lcciPlan('flex', course.courseName, course.round_pk)">
						<p>Enroll</p>
						<br>
						<p id="startDate">Starts {{course.startDate | date: 'LLL'}} {{course.startDate | date: 'd'}}</p>
					</button>
				</div>
			</div>
		</div>
		
	</div>
</section>


<student-and-instructor-signup-cards></student-and-instructor-signup-cards>
