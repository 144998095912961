<get-a-quote [style.display] = "globals.getQuoteWrapper_display"></get-a-quote>


<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Cadres provide skilled expertise to gather information about your business potential buyers’ persona, target audience, competitor analysis, and customers’ needs to determine how feasible and successful your product or service are. As we conduct competent marketing and management analysis, to ensure the buying behaviour in addition to customer influences regarding their decisions to accomplish higher business conversions…
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/market-research/sect1.png" alt="">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sections_wrapper">
	<div class="sections_content">
		<p [style.box-shadow]="boxShadow_marketResearch" (click)='changeBody("Market Research")' id="marketResearch">Market Research</p>
		<p [style.box-shadow]="boxShadow_strageticPlanning" (click)='changeBody("Strategic Planning")' id="strageticPlanning">Strategic Planning</p>
		<p [style.box-shadow]="boxShadow_measurementsAndAnalytics" (click)='changeBody("Measurements and Analytics")' id="measurementsAndAnalytics">Measurements and Analytics</p>
	</div>
</section>


<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>{{sect2_title}}</h1>
			<p>{{sect2_para1}}</p>
			<p>{{sect2_para2}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/market-research/sect1.png" alt="">
		</div>
	</div>
</section>

<section [style.display]="display1" class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<p>{{sect3_para1}}</p>
		</div>
	</div>
</section>

<section [style.display]="display2" class="sect3-2_wrapper">
	<div class="sect3-2_content">
		<div class="info">
			<p>
				Cadres value measurements and analytics by not only demonstrating 
				and understand social media and social conversions, but also by 
				leveraging a primary research, advanced analytics, and data 
				modeling for continuous improvement and optimization
			</p>
		</div>
		<div class="img"><img src="../../assets/imgs/market-research/sect3-2.png" alt=""></div>
	</div>
</section>

<section [style.display]="display1" class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<div class="numbers" id="number1">
				<p>1</p>
			</div>
			<p>{{sect4_para1}}</p>
		</div>
		<div class="info">
			<div class="numbers" id="number2">
				<p>2</p>
			</div>
			<p>{{sect4_para2}}</p>
		</div>
		<div class="info">
			<div class="numbers" id="number3">
				<p>3</p>
			</div>
			<p>{{sect4_para3}}</p>
		</div>
		<div class="info">
			<div class="numbers" id="number4">
				<p>4</p>
			</div>
			<p>{{sect4_para4}}</p>
		</div>
	</div>
</section>

<section [style.display]="display2" class="sect4-2_wrapper">
	<div class="sect4-2_content">
		<div class="info">
			<p>
				As per our research and analytics team is comprised of professional analysts, consultants, data scientists, and content creators to develop measurement frameworks to fully understand our impact on our communication efforts to carry the business audience, brand image, and add integrity to the business.
			</p>
		</div>
		<div class="img"><img src="../../assets/imgs/market-research/sect4-2.png" alt=""></div>
	</div>
</section>


<section [style.display]="display2" class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<h1>Our approach</h1>
			<p>
				is competitive and unique that is based on ensuring and organizing our data streams in 
				a consistent, scalable, and operative manner. In Cadres, we assure businesses of our 
				marketing and communication disciplines by our advanced analytical techniques to 
				increase businesses performance and its’ return on investment (ROI).
			</p>
		</div>
	</div>
</section>

<button (click)="show('show')">
		Get a Quote
</button>

<section [style.display]="display3" class="sect5-2_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/market-research/sect5.svg">
	</div>
	<div class="sect5-2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Cadres provide skilled expertise to gather information about your business potential buyers’ persona, target audience, competitor analysis, and customers’ needs to determine how feasible and successful your product or service are. As we conduct competent marketing and management analysis, to ensure the buying behaviour in addition to customer influences regarding their decisions to accomplish higher business conversions…
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/market-research/sect5.png" alt="">
		</div>
	</div>
</section>