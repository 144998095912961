
<apply-as-instructor></apply-as-instructor>

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Why Choose CADRES</h1>
			<p [innerHTML]="globals.sections['home'][0]['text']"></p>
			<button routerLink="/about">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/home/sect1.svg">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/>
</svg>

<section class="sect2_wrapper">
	<div class="bg" id="bg_desktop"><img src="../../assets/backgrounds/index/sect2.svg"></div>
	<div class="sect2_content">	
		<div class="info">
			<h1>Cadres School of Business</h1>
			<p [innerHTML]="globals.sections['home'][1]['text']"></p>
			<button routerLink="/school-of-business">
				Explore More
				<img src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img"><img src="../../assets/imgs/home/sect2.png"></div>
	</div>	
</section>






<section class="sect3_wrapper">
	<div class="sect3_content">
		<div routerLink="./cadres-english" class="cards" id="card1">
			<div class="cardsIllustraion" id="illustration_englishCourses">
				<img src="../../assets/icons/categories/englishCourses.svg" alt="">
			</div>
			<p>English <br> Courses</p>
		</div>
		<div routerLink="./lcci" class="cards" id="card2">
			<div class="cardsIllustraion" id="illustration_lcciDiplomas">
				<img src="../../assets/icons/categories/lcciDiplomas.svg" alt="">
			</div>
			<p>LCCI <br> Diplomas</p>
		</div>
		<div routerLink="./business" class="cards" id="card3">
			<div class="cardsIllustraion" id="illustration_mbas">
				<img src="../../assets/icons/categories/mbas.svg" alt="">
			</div>
			<p>MBAs</p>
		</div>
	</div>
</section>



<section class="sect4_wrapper sect4_wrapper_desktop">
	<div class="bg"><img src="../../assets/backgrounds/index/sect4.svg"></div>
	<div class="sect4_content">
		<div class="info">
			<h1>Business Solutions</h1>
			<p [innerHTML]="globals.sections['home'][2]['text']"></p>
			<button routerLink="/business-solutions">
				Explore More
				<img src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/home/sect4.png">
		</div>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="sect5_content">
		<div routerLink="/business-solutions/business-consultation" class="cards" id="card1">
			<div class="cardsIllustraion" id="illustration_businessConsultation">
				<img src="../../assets/illustrations/categories/businessConsultation.svg" alt="">
			</div>
			<p>Business <br> Consultation</p>
		</div>
		<div routerLink="/business-solutions/recruitment" class="cards" id="card2">
			<div class="cardsIllustraion" id="illustration_recruiting">
				<img src="../../assets/illustrations/categories/recruiting.svg" alt="">
			</div>
			<p>Recruiting</p>
		</div>
		<div routerLink="/business-solutions/corprate-training" class="cards" id="card3">
			<div class="cardsIllustraion" id="illustration_corprateTraining">
				<img src="../../assets/illustrations/categories/corprateTraining.svg" alt="">
			</div>
			<p>Corporate <br> Training</p>
		</div>
		<div routerLink="/business-solutions/marketing-and-advertisement" class="cards" id="card4">
			<div class="cardsIllustraion" id="illustration_marketingAndAdvertising">
				<img src="../../assets/illustrations/categories/marketingAndAdvertising.svg" alt="">
			</div>
			<p>Marketing <br> & <br> Advertising</p>
		</div>
		<div routerLink="/business-solutions/event-management" class="cards" id="card5">
			<div class="cardsIllustraion" id="illustration_eventManagement">
				<img src="../../assets/illustrations/categories/eventManagement.svg" alt="">
			</div>
			<p>Event <br> Management</p>
		</div>
	</div>
</section>

<student-and-instructor-signup-cards></student-and-instructor-signup-cards>

<section class="sect7_wrapper">
	<div class="sect7_content">
		<h2>Latest New and Events</h2>
		<div class="cards" id="card1">
			<div class="opacity" id="opacity_card2"></div>
			<div class="img">
				<img src="../../assets/imgs/news/news.svg">
			</div>	
			<img id="leftArrow_icon"  class="arrows" src="../../assets/icons/chevronLeft_white.svg">
			<img id="rightArrow_icon" class="arrows"  src="../../assets/icons/chevronRight_white.svg">
			<p id="date">12 <br> May</p>
			<div class="info">
				<p id="category">World News</p>
				<p id="info">Ipsum <br> Dolor Lorem ipsum dolor sit amet</p>
			</div>
			<div class="pages">
				<div id="page1"></div>
				<div id="page2"></div>
				<div id="page3"></div>
			</div>
		</div>
		<div class="cards" id="card2">
			<div class="opacity" id="opacity_card2"></div>
			<div class="img">
				<img src="../../assets/imgs/news/news10.svg">
			</div>
			<p id="date">May 16</p>
			<div class="info">
				<p id="category">Marketing</p>
				<p id="info">Ipsum <br> Dolor Lorem ipsum dolor sit amet</p>
			</div>
		</div>
		<div class="cards" id="card3">
			<div class="opacity" id="opacity_card3"></div>
			<div class="img">
				<img src="../../assets/imgs/news/news11.svg">
			</div>
			<p id="date">May 04</p>
			<div class="info">
				<p id="category">Design</p>
				<p id="info">Ipsum <br> Dolor Lorem ipsum dolor sit amet</p>
			</div>
		</div>
	</div>
</section>

<hr id="hr">


<!-- <section class="sect8_wrapper">
	<div class="sect8_content">
		<h2>Testimonials</h2>
		<div class="arrowLeft"><img src="../../assets/" alt=""></div>
		<div class="cards" id="card1">
			<img src="../../assets/" alt="">
			<p id="title"></p>
			<p id="info"></p>
		</div>
		<div class="cards" id="card1">
			<img src="../../assets/" alt="">
			<p id="title"></p>
			<p id="info"></p>
		</div>
		<div class="arrowRight"><img src="../../assets/" alt=""></div>
		<div class="pages">
			<div id="page"></div>
			<div id="page"></div>
			<div id="page"></div>
		</div>
	</div>
</section>
 -->




