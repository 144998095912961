import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';


@Component({
  selector: 'get-a-quote',
  templateUrl: './get-a-quote.component.html',
  styleUrls: ['./get-a-quote.component.css']
})
export class GetAQuoteComponent implements OnInit {

  constructor(private api:ApiService, public globals: Globals) { }

  getQuote_display:string;
  extraRadios_display:string;

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  	var currentDate:string = '2017-08-15';
    this.extraRadios_display = "none";

  }


  getQuote=new FormGroup({
  fullName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  title:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.email]),
  companyName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  functions:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  industry:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  dateOfBirth:new FormControl('',[Validators.required ,Validators.pattern(/[\s\S]*/)]),
  type:new FormControl('', [Validators.required]),
  businessType:new FormControl('', [Validators.required]),
  businessSize:new FormControl('', [Validators.required]),
  revenueSize:new FormControl('', [Validators.required]),


});


  fromData(){
    var serviceRequired:any = window.location.href.split("/");
    serviceRequired = serviceRequired[serviceRequired.length-1];
    
    console.log(this.getQuote.value);
    this.api.requestQuote(this.getQuote.value, serviceRequired).subscribe(response=>{
      console.log(response);
    });
  }

    show(hideORshow):void {
      if(hideORshow=="hide") {
        this.globals.getQuoteWrapper_display = "none";
      }else if(hideORshow=="show") {
        this.globals.getQuoteWrapper_display = "block";
      }
    }

    extraRadios(display){
      this.extraRadios_display = display;
    }

}
