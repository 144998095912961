import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'instructor-dashboard-banner',
  templateUrl: './instructor-dashboard-banner.component.html',
  styleUrls: ['./instructor-dashboard-banner.component.css']
})
export class InstructorDashboardBannerComponent implements OnInit {

  constructor(private apiService: ApiService) {}

	menuWrapper_display:string = "none";


  ngOnInit(): void {
    // this.apiService.setLogged(true, "3", "instructor");
  }


  showMenu(){
      
    if (this.menuWrapper_display == "block"){
      this.menuWrapper_display = "none";
    }else if (this.menuWrapper_display == "none"){
       this.menuWrapper_display = "block";
    }
      console.log(this.menuWrapper_display);
  }
  
}
