

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="filters_container">
			<div class="addStudent">
				<img src="../../assets/icons/add_white.svg">
				<p>Add Student</p>
			</div>
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="students_wrapper">
	<div class="students_content">
		<div class="student"  *ngFor="let student of students | filter:searchText" routerLink="/admin-dashboard/student/{{student.student_pk}}">
			<p id="studentName">{{student.firstName}} {{student.middleName}} {{student.lastName}}</p>
			<p id="delete" (click)="deleteStudent(student.student_pk)">Remove</p>
		</div>
	</div>
</section>