



	
	<h1 id="h1">Our Terms and Conditions</h1>
	
	<div class="catogeries">
		<p (click) = "changeBody('Registration and Administration')">Registration and Administration</p>
		<p (click) = "changeBody('Students Resposibility')">Students Resposibility</p>
		<p (click) = "changeBody('Cancellation fees')">Cancellation fees</p>
	</div>


	<div class="pages" id="page1">
		<div *ngFor="let subTitle of subTitles; index as i">
			<h3 >{{subTitle}}</h3>
			<p *ngFor="let subTitle_body of subTitle_bodies[i];">{{subTitle_body}}</p>
		</div>
		
	</div>

