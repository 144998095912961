
<div class="sign_wrapper" id="sign_wrapper">
	<!-- <img id="cross_icon" src="../../assets/icons/cross_white.svg"> -->
	<div class="signCard_wrapper" id="signInCard_wrapper">
		<div class="img">
			<img src="../../assets/icons/logo_orange.svg">
		</div>
		<!-- <h1>Sign In</h1> -->
		<form action="/action_page.php" [formGroup]="signIn" (ngSubmit)="log()">
			<input type="email" placeholder="john.doe@email.com" formControlName="email">
			<input type="password" placeholder="**********" formControlName="password">
			<div *ngIf="!signInStatus" class=""
				role="alert">
				<p [style.color]="'#A94483'">Invalid username or password. Please try again.</p>
			</div>
			<input type="submit" value="Sign In">
		</form>
	</div>
</div>
