import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-in-progress-products',
  templateUrl: './admin-in-progress-products.component.html',
  styleUrls: ['./admin-in-progress-products.component.css']
})
export class AdminInProgressProductsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

	courses_display:string;
	diplomas_display:string;
	courses_bg:string;
	diplomas_bg:string;

  courses:any = [];	
  coursesRounds:any;

  diplomas:any = [];	
  diplomasRounds:any;


  diplomaClasses:any;

  searchText:string;

  
  products:any;

  addType:string = "course";

	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}


  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.switch("courses");
  	this.apiService.getCoursesRounds().subscribe(response=>{  			
		this.coursesRounds = response;
		this.coursesRounds = this.turn2dArrayToAssoc2dArray(this.coursesRounds, 'courseName');
		for (let key in this.coursesRounds) {
		    this.courses.push(key);
		}
	});

	 this.apiService.getDiplomasRounds().subscribe(response=>{
  		console.log(response);
		this.diplomasRounds = response;
		this.diplomasRounds = this.turn2dArrayToAssoc2dArray(this.diplomasRounds, 'diplomaName');
		for (let key in this.diplomasRounds) {
		    this.diplomas.push(key);
		}
		console.log(this.diplomas);
	});
  }


  switch(filter):void{
  	if(filter == "courses") {
  		this.addType = "course";
		this.courses_display = "block";
		this.diplomas_display = "none";
		this.courses_bg = "#C5C5C5";
		this.diplomas_bg = "#f1f1f1";
  	}else if(filter == "diplomas") {
  		this.addType = "diploma";
		this.courses_display = "none";
		this.diplomas_display = "block";
		this.courses_bg = "#f1f1f1";
		this.diplomas_bg = "#C5C5C5";
  	}
  }

}
