import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corprate-training',
  templateUrl: './corprate-training.component.html',
  styleUrls: ['./corprate-training.component.css']
})
export class CorprateTrainingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

}
