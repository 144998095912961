
<div class="testimonial_wrapper">
	<div class="testimonial_content">
		<p id="title">Add Testimonial</p>
		<form [formGroup]="getAddTestimonial" (ngSubmit)="addTestimonial()">
			<div class="inputs" id="input1">
				<legend>Name</legend>
				<input rows="1"  type="text" formControlName="name">
			</div>
			<div class="inputs" id="input2">
				<legend>Information</legend>
				<textarea rows="1" type="text" formControlName="text"> </textarea>
			</div>
			<div class="inputs" id="input3">
				<legend>Upload Image</legend>
				<div>
					<label for="upload_inputMaterial">
					<div class="file_img"> 
						<img src="../../assets/icons/add_black.svg"/>
					</div>
					</label>
					<input (change)="onFileSelect($event)" id="upload_inputMaterial" type="file" name="avatar"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>
			<div class="inputs" id="input4">
				<input type="submit">
			</div>
		</form>
	</div>
</div>