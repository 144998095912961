
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="productType" id="courses" [style.background]="courses_bg" (click)="switch('courses')" >
			<p>Courses</p>
		</div>
		<div class="productType" id="diplomas" [style.background]="diplomas_bg" (click)="switch('diplomas')" >
			<p>Diplomas</p>
		</div>
	</div>
</section>



<section class="forms_wrapper" id="courses" [style.display]="courseForm_display">
	<div class="forms_content">
		<div class="form">
			<div class="editor_parent" id="name">
				<label for="editor">
					<p id="title">Name</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '5vw'}" [(ngModel)]="getAddCourse.value.name"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Description:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddCourse.value.description"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="inputs_parent">
				<div class="inputs_child" id="price">
					<label for="inputs">
						<p id="title">Price:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddCourse.value.price">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>

				<div class="inputs_child" id="learningHours">
					<label for="inputs">
						<p id="title">Learning Hours:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddCourse.value.learningHours">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>

			<div class="inputs_parent">
				<div class="dropDowns_parent" id="suite">
					<mat-form-field>
					  <mat-label>Suite</mat-label>
					  <mat-select [(ngModel)]="getAddCourse.value.suite">
					    <mat-option *ngFor="let suite of suites" [value]="suite">
					      {{suite}}
					    </mat-option>
					  </mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="suite">
					<mat-form-field>
					  <mat-label>Level</mat-label>
					  <mat-select [(ngModel)]="getAddCourse.value.level">
					    <mat-option *ngFor="let level of levels" [value]="level">
					      {{level}}
					    </mat-option>
					  </mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Who Should Attend:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddCourse.value.whoShouldAttend"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Requirements:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddCourse.value.requirements"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>
			<div class="inputs_parent">
				<div class="dropDowns_parent" id="suite">
				   <mat-form-field>
						<mat-label>Related Courses</mat-label>
						<mat-select [(ngModel)]="getAddCourse.value.relatedCourses"  multiple>
							<mat-option *ngFor="let course of courses" [value]="course.course_pk">{{course.courseName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="level">
					 <mat-form-field>
						<mat-label>Instructors</mat-label>
						<mat-select [(ngModel)]="getAddCourse.value.relatedInstructors"  multiple>
							<mat-option *ngFor="let instructor of instructors" [value]="instructor.instructor_pk">{{instructor.firstName | removeHtmlTags}} {{instructor.lastName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="level">
					 <mat-form-field>
						<mat-label>Testimonials</mat-label>
						<mat-select [(ngModel)]="getAddCourse.value.relatedTestimonials"  multiple>
							<mat-option *ngFor="let testimonial of testimonials" [value]="testimonial.pk">{{testimonial.name | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>
			<div class="inputs_parent">
				<div class="inputs_child" id="price">
					<label for="inputs">
						<p id="title">Promo Code:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddCourse.value.promoCode">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="inputs_child" id="learningHours">
					<label for="inputs">
						<p id="title">Discount Percentage:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddCourse.value.discountPercentage">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="inputs_child" id="learningHours">
					<label for="inputs">
						<p id="title">Expiration Date:</p>
					</label>	
					<div class="inputs" id="input4">
						<input type="date" [(ngModel)]="getAddCourse.value.promoExpirationDate">
					</div>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>
			<div class="inputs_parent">
				<div class="inputs input_file">
					<legend>Specification</legend>
					<div>
						<label for="upload_specification">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'specification')" id="upload_specification" type="file" name="specification[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
				<div class="inputs input_file">
					<legend>Sample assessment</legend>
					<div>
						<label for="upload_sampleAssesment">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'sampleAssesment')" id="upload_sampleAssesment" type="file" name="sampleAssesment[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
				<div class="inputs input_file">
					<legend>Exam Materials</legend>
					<div>
						<label for="upload_examMaterials">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'examMaterials')" id="upload_examMaterials" type="file" name="examMaterials[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
			</div>
			<div class="inputs_parent">
				<div class="inputs input_file">
					<legend>Past training content</legend>
					<div>
						<label for="upload_pastTrainingContent">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'pastTrainingContent')" id="upload_pastTrainingContent" type="file" name="pastTrainingContent[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
				<div class="inputs input_file">
					<legend>Student Support</legend>
					<div>
						<label for="upload_studentSupport">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'studentSupport')" id="upload_studentSupport" type="file" name="studentSupport[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
				<div class="inputs input_file">
					<legend>Teacher Guide</legend>
					<div>
						<label for="upload_teacherGuide">
						<div class="file_img"> 
							<img src="../../assets/icons/add_black.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'teacherGuide')" id="upload_teacherGuide" type="file" name="teacherGuide[]" multiple/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
			</div>
			<button (click)="addProduct('course')">Add</button>
		</div>
	</div>
</section>
















































<section class="forms_wrapper" id="diplomas" [style.display]="diplomaForm_display">
	<div class="forms_content">
		<div class="form">
			<div class="editor_parent" id="name">
				<label for="editor">
					<p id="title">Name</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '5vw'}" [(ngModel)]="getAddDiploma.value.name"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Description:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddDiploma.value.description"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="inputs_parent">
				<div class="inputs_child" id="price">
					<label for="inputs">
						<p id="title">Price:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddDiploma.value.price">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>

				<div class="inputs_child" id="learningHours">
					<label for="inputs">
						<p id="title">Learning Hours:</p>
					</label>	
					<div class="inputs">	
						<input type="text" name="" [(ngModel)]="getAddDiploma.value.learningHours">
					</div>	
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>

			<div class="inputs_parent">
				<div class="dropDowns_parent" id="suite">
				   <mat-form-field>
						<mat-label>Core Courses</mat-label>
						<mat-select [(ngModel)]="getAddDiploma.value.coreCourses"  multiple>
							<mat-option *ngFor="let course of courses" [value]="course.course_pk">{{course.courseName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="level">
					 <mat-form-field>
						<mat-label>Optional Courses</mat-label>
						<mat-select [(ngModel)]="getAddDiploma.value.optionalCourses"  multiple>
							<mat-option *ngFor="let course of courses" [value]="course.course_pk">{{course.courseName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>

			<div class="inputs_parent">
				<div class="dropDowns_parent" id="suite">
					<mat-form-field>
					  <mat-label>Suite</mat-label>
					  <mat-select [(ngModel)]="getAddDiploma.value.suite">
					    <mat-option *ngFor="let suite of suites" [value]="suite">
					      {{suite}}
					    </mat-option>
					  </mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="suite">
					<mat-form-field>
					  <mat-label>Level</mat-label>
					  <mat-select [(ngModel)]="getAddDiploma.value.level">
					    <mat-option *ngFor="let level of levels" [value]="level">
					      {{level}}
					    </mat-option>
					  </mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Who Should Attend:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddDiploma.value.whoShouldAttend"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Requirements:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getAddDiploma.value.requirements"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				<button (click)="addProduct('diploma')">Add</button>
			</div>
			
		</div>
	</div>
</section>
