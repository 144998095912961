import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'app-admin-add-diploma-round',
  templateUrl: './admin-add-diploma-round.component.html',
  styleUrls: ['./admin-add-diploma-round.component.css']
})
export class AdminAddDiplomaRoundComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	diploma_fk:any;
	diplomas:any;
	courses:any;
	instructors:any;


  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getDiplomas().subscribe(response=>{	
		this.diplomas = response.data;
	});
	this.apiService.getDiplomaCourses_byDiplomaId(this.diploma_fk).subscribe(response=>{	
		this.courses = response.data;
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
	});
  }


}