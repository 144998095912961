
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="messageRecipients" id="students" [style.background]="students_bg" (click)="switch('students')" >
			<p>Students</p>
		</div>
		<div class="messageRecipients" id="instructors" [style.background]="instructors_bg" (click)="switch('instructors')" >
			<p>Instructors</p>
		</div>
	</div>
</section>

<section class="newMessage_wrapper" [style.display]="studentNewMessage_display">
	<div class="newMessage_content">
		<div class="message">
			<label for="editor">
				<p id="title">Message by Cadres</p>
			</label>
			<div class="recipients">
				
			</div>
			<div class="editor">	
				<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getNewMessage.value.content"></quill-editor> <!-- [(ngModel)]="section.text" -->
			</div>	
			<!-- <span *ngIf="textLength > 0"> {{textLength}}</span> -->
			<!-- <span *ngIf="textLength == 0"> {{section.text.length}}</span> -->
			<button (click)="sendMessage('students')">Send</button>
		</div>
		<div class="recipients">
			<div class="dropDowns_parent" id="level">
				<mat-form-field>
					<mat-label>Students</mat-label>
					<mat-select [(ngModel)]="getNewMessage.value.recipientStudents"  multiple>
						<mat-option *ngFor="let student of students" [value]="student.student_pk">{{student.firstName | removeHtmlTags}} {{student.lastName | removeHtmlTags}}</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>
		</div>
	</div>
</section>

<section class="newMessage_wrapper" [style.display]="instructorNewMessage_display">
	<div class="newMessage_content">
		<div class="message">
			<label for="editor">
				<p id="title">Message by Cadres</p>
			</label>
			<div class="recipients">
				
			</div>
			<div class="editor">	
				<quill-editor [styles]="{height: '20vw'}" [(ngModel)]="getNewMessage.value.content"></quill-editor> <!-- [(ngModel)]="section.text" -->
			</div>	
			<!-- <span *ngIf="textLength > 0"> {{textLength}}</span> -->
			<!-- <span *ngIf="textLength == 0"> {{section.text.length}}</span> -->
			<button (click)="sendMessage('instructors')">Send</button>
		</div>
		<div class="recipients">
			<div class="dropDowns_parent" id="level">
				<mat-form-field>
					<mat-label>Instructors</mat-label>
					<mat-select [(ngModel)]="getNewMessage.value.recipientInstructors"  multiple>
						<mat-option *ngFor="let instructor of instructors" [value]="instructor.instructor_pk">{{instructor.firstName | removeHtmlTags}} {{instructor.lastName | removeHtmlTags}}</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>
		</div>
	</div>
</section>
