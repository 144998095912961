import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { FormBuilder, FormGroup } from  '@angular/forms';
import {Globals} from '../globals';

@Component({
  selector: 'payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})
export class PaymentGatewayComponent implements OnInit {

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, public globals: Globals) {}

	payButton_display:string = "none";

    invoiceid:string = '';
  form: FormGroup;
  uploadResponse:any;

  coursePrice:number = parseInt(this.globals.productChosen_price);
  discount:number = 1;
  discountCode:string = '';

  isChecked:boolean = false;
    
    // purchase_units[0].amount = {};
	
	@ViewChild('paypalRef', {static: true}) private paypalRef: ElementRef;
  
  

  ngOnInit(): void {    


  
    this.form = this.formBuilder.group({
      file: ['']
    });

  
  
  }

  onFileSelect(event) {    
    
    if (event.target.files.length > 0) {      
      
      const file = event.target.files[0];
      this.form.get('file').setValue(file);      
      
    }
  }



 
  @ViewChild('signatureCanvas') public canvas: ElementRef;

 

  
  @Input() public width = 300;
  @Input() public height = 150;

  private cx: CanvasRenderingContext2D;  
  
  public ngAfterViewInit() {
    
   
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

   
    canvasEl.width = this.width;
    canvasEl.height = this.height;

   
    this.cx.lineWidth = 3;
    this.cx.lineJoin = 'round';
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';
    
   
    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
   
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
         
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
             
             
              takeUntil(fromEvent(canvasEl, 'mouseup')),
             
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
             
             
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

       
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

       
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number }, 
    currentPos: { x: number, y: number }
  ) {
   
    if (!this.cx) { return; }

   
    this.cx.beginPath();

   
    if (prevPos) {
     
      this.cx.moveTo(prevPos.x, prevPos.y);

     
      this.cx.lineTo(currentPos.x, currentPos.y);

     
      this.cx.stroke();
    }
  }

  clearCanvas(){
    this.cx.clearRect(0, 0, this.width, this.height);
  }

  convertCanvasToImage() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    var image = document.createElement('img');
    image.src = canvasEl.toDataURL("image/png");
    return image;
  }

  gateWay(display):void {
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
    this.payButton_display = "none";
    this.isChecked = false;
  }

    redeemCode():void{
      if(this.discountCode=="redeem20") {
        this.discount = 0.8;
      }
  }

  buttonState() {    
    
    if(this.payButton_display == "block") {
      this.payButton_display = "none";
      this.isChecked = false;
    }else if(this.payButton_display == "none") {      
      this.payButton_display = "block";
      this.isChecked = true;
      var description:string = 'course';
      var amount:string = this.globals.productChosen_price;
      var invoiceid:string = this.invoiceid;
      // var invoiceidDiv_display:string = this.invoiceidDiv_display;
      var tax:string = '0.14';
      var taxNumber:number = +tax;
      var amountNumber:number = +amount*this.discount;      
      var finalAmount:string[] = [''];
      var purchase_units:any = [{}];
      purchase_units[0]['description'] = '';
      purchase_units[0]['amount'] = {'value': '', 'currency_code':"USD", "breakdown":{
                        "item_total":{"currency_code":"USD","value":''},
                        "shipping":{"currency_code":"USD","value":0},
                        "tax_total":{"currency_code":"USD","value":''}
                      }};

       window.paypal.Buttons({
        style: {
          layout: 'horizontal',
          color: 'blue',
          shape: 'rect',
          label: 'pay'
        },
        onClick: function () {        
          purchase_units[0].description = description;
          finalAmount = (""+(amountNumber * (1+taxNumber))).split(".");  
          console.log(  finalAmount);
                          
          purchase_units[0].amount.value = finalAmount[0];"."+finalAmount[1].slice(0, 2);
          purchase_units[0].amount.breakdown.item_total.value = ""+amountNumber;
          finalAmount = (""+(amountNumber * taxNumber)).split(".");
          purchase_units[0].amount.breakdown.tax_total.value = finalAmount[0]+"."+finalAmount[1].slice(0, 2);        
          if(invoiceid !== '') {
            purchase_units[0].invoice_id = invoiceid;
          }
        },

        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: purchase_units
          });
        },
        onApprove: (data, actions) => {        
          return actions.order.capture().then(details => {
            var signature = this.convertCanvasToImage();
            var signatureSrc = signature.src;                                        
            this.apiService.regiserStudentToCourse(this.apiService.loggedIn_user()["pk"][0], this.globals.productChosen_round_pk, signatureSrc).subscribe(response=>{            
                console.log(response);
                
            });          
            
            alert("done");
            
          });
        },
        onError: error => {
          //inser error
        }
      })
      .render(this.paypalRef.nativeElement);
    }
    
  }



}
