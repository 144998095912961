import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';


@Component({
  selector: 'app-lcci',
  templateUrl: './lcci.component.html',
  styleUrls: ['./lcci.component.css', './sect3.component.css']
})
export class LcciComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }


  showHideSignWrapper(display, signCard){
    if(signCard == "signIn") {
      this.globals.signInCard_display = "block";
      this.globals.signUpCard_display = "none";
    }else if(signCard == "signUp") {
      this.globals.signUpCard_display = "block";
      this.globals.signInCard_display = "none";
    }
    this.globals.signWrapper_display = display;
  }

  showApplyInstructorWrapper(display){
    this.globals.applyInstructor_display = display;
  }

}
