
<section class="sect1_wrapper wrapper">
	<div class="sect1_content content">
		<p id="title">Job Title</p>
		<quill-view-html [content]="title"></quill-view-html>
	</div>
</section>

<section class="sect2_wrapper wrapper">
	<div class="sect2_content content">
		<p id="title">Job Description</p>
		<quill-view-html [content]="description"></quill-view-html>
	</div>
</section>

<section class="sect3_wrapper wrapper">
	<div class="sect3_content content">
		<p id="title">Job Summary</p>
		<quill-view-html [content]="summary"></quill-view-html>
	</div>
</section>

<section class="sect4_wrapper wrapper">
	<div class="sect4_content content">
		<p id="title">Job Specifications</p>
		<quill-view-html [content]="specifications"></quill-view-html>
	</div>
</section>

<section class="sect5_wrapper wrapper">
	<div class="sect5_content content">
		<p id="title">Interpersonal Skills</p>
		<quill-view-html [content]="interpersonalSkills"></quill-view-html>
	</div>
</section>

<section class="sect6_wrapper wrapper">
	<div class="sect6_content content">
		<p id="title">Benefits</p>
		<quill-view-html [content]="benefits"></quill-view-html>
	</div>
</section>