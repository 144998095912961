
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Webinars and E-conferences</h1>
			<p>
				Cadres hosts business webinars to elevate Egypt’s economy. We stretch a helping hand for Egyptian Start-ups to collaborate for mutually beneficial relations. We invite the most qualified calibers in the market to benefit our attendees from their expertise from different business perspectives aka Finance and accounting, marketing, and Strategic Management. Moreover, the ever-increasing advance in technology has been in our benefit to conquer the stay-at-home protocols taken due to the COVID-19 pandemic. Cadres is making it possible for companies and businesses to continue holding conferences but with a digital twist. Electronic conferences are popular worldwide method to easily connect with people with just a click
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/webinars-and-econferences/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				A stable internet connection is all it needs for members to participate in the E-conferences cadres holds that would match your needs. Whether you are a startup company hosting a small meeting or an organization holding a big conference, we will handle all the technicalities required to hold a successful meeting without any inconveniences. Not only is this method cost-effective, it also effectively saves time and energy.  Cadres will take responsibility for every aspect of the online call, leaving you free to handle the content of the meeting. We will notify your clients, manage any change done to the schedule, and monitor the meeting in the case of complications.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/webinars-and-econferences/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Technology advancing has transformed our society in more ways than we could have ever imagined. Events, tools and concepts that we never would have thought to become accessible, are spreading widely in our age. In Cadres we can make your virtual needs possible, ranging from E-conferences, webinars and more. 
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/webinars-and-econferences/sect3.png">
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<h1>Webinars</h1>
			<p>
				Webinar, a word that is a blend of “<strong>web</strong>” and “<strong>seminars</strong>”, is an online event held
			</p>
			<p>
				Virtually that is exclusive to online attendees only.  It is becoming more and more popular each 
				year due to the convenience it provides both presenters and participants. They can be held at 
				any time and anywhere, and people anywhere in the world could attend them. In cadres we will 
				coordinate every aspect of your webinar and handle all the technicalities that involve it.

			</p>
		</div>
	</div>
</section>