

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="filters_container">
			<div class="addMessage" routerLink="/admin-dashboard/messages/new-message">
				<img src="../../assets/icons/messaging.svg">
				<p>New Message</p>
			</div>
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="messages_wrapper">
	<div class="messages_content">
		<div class="message"  *ngFor="let message of messages | filter:searchText" routerLink="/admin-dashboard/messages/{{message.message_pk}}">
			<div class="img"><img src="../../assets/icons/logo_orange.svg"></div>
			<p id="message"><span id="sender">From Cadres</span> <br> {{message.content | removeHtmlTags | slice:0:170}}<span *ngIf="message.content.length > 170">...</span></p>
			<div class="delete" (click)="deleteMessage(message.pk)">
				<img src="../../assets/icons/trash_black.png">
			</div>
		</div>
	</div>
</section>
