
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Watson-glaser <br>
			critical thinking appraisal</h1>
			<p>
				A measure that businesses use to examine job 
				applicants’ critical thinking and verbal critical 
				reasoning skills. The test examines hard-skills 
				appraisal and is for recruitment to measure 
				critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/watson-glaser/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Watson-Glaser Critical Thinking Appraisal III</h1>
			<p>
				The Watson-Glaser Critical Thinking Appraisal is considered 
				the leading and most famous measure of critical thinking 
				ability. For almost a 100 years now companies have been 
				dependent on this critical thinking measure to create data-
				driven recruiting and development processes. Organizations 
				and educational entities use this tool to examine managers, 
				employees and even students through placing them in 
				challenging situations to judge their understanding from 
				diverse perspectives.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/watson-glaser/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="left">
			<p id="title">What is Watson-Glaser III?</p>
			<p>- Online and paper based test</p>
			<p>- 30-minute time limit </p>
			<p>- US English</p>
			<p>- Item-banked format that allows unsupervised screening of job
   applicants</p>
   			<p>- Suitable for international use</p>
   			<p>- Tests an individual’s Argumentative, Logical and Assumptive
   abilities </p>
		   <p>- Questions are selected in random to make it unlikely for two
   individuals to have the same exam</p>

		</div>
		<div class="right">
			<p id="title">Why rely on Watson-Glaser Critical Thinking Appraisal III?</p>
			<div>	
				<p>
					<span><p>1</p></span> Less time and money spent in the hiring process by 
eliminating candidates who do not fit the criteria
				</p>
			</div>
			<div>	
				<p>
					<span><p>2</p></span> Create a culture of making data-driven decisions on 
staffing and development
				</p>
			</div>
			<div>	
				<p>
					<span><p>3</p></span> Invest in your staff to develop future leaders and key 
decision makers in your company 
				</p>
			</div>
		</div>
	</div>
</section>