
<div class="newRound_wrapper">
	<div class="newRound_content">
		<p id="title">New Round</p>
		<form [formGroup]="getAddRound" (ngSubmit)="addRound()">
			<div class="inputs" id="input1">
				<legend>Round name:</legend>
				<input rows="1"  type="text" formControlName="roundName">
			</div>
			<div class="inputs" id="input2">
				<div class="dropDowns_parent" id="suite">
				   <mat-form-field>
						<mat-label>Courses</mat-label>
						<mat-select formControlName="course_fk">
							<mat-option *ngFor="let course of courses" [value]="course.course_pk">{{course.courseName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
				<div class="dropDowns_parent" id="level">
					 <mat-form-field>
						<mat-label>Instructors</mat-label>
						<mat-select formControlName="instructor_fk">
							<mat-option *ngFor="let instructor of instructors" [value]="instructor.instructor_pk">{{instructor.firstName | removeHtmlTags}} {{instructor.lastName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
					<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				</div>
			</div>
			<div class="inputs" id="input3">
				<div>
					<legend>Seats Available</legend>
					<input rows="1"  type="text" formControlName="maxCapacity">
				</div>
				<div>
					<legend>Start Date:</legend>
					<input type="date" formControlName="startDate">
				</div>
			</div>
			<div class="inputs" id="input6">
				<input type="submit">
			</div>
		</form>
	</div>
</div>
