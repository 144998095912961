import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-surviving-covid19',
  templateUrl: './surviving-covid19.component.html',
  styleUrls: ['./surviving-covid19.component.css']
})
export class SurvivingCovid19Component implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void { 
    window.scrollTo(0, 0);
  }


   show(hideORshow):void {
    if(hideORshow=="hide") {
      this.globals.bookAppointmentWrapper_display = "none";
    }else if(hideORshow=="show") {
      this.globals.bookAppointmentWrapper_display = "block";
    }
  }

}
