import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-edit-event',
  templateUrl: './admin-edit-event.component.html',
  styleUrls: ['./admin-edit-event.component.css']
})
export class AdminEditEventComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
		this.route.params.subscribe(params =>{
			this.event_pk = params.id;			
		});
	}

	event_pk:number;
	event:any;

	form: FormGroup;
	uploadResponse:any;
		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	
    this.form = this.formBuilder.group({
      event: ['']
    });
    
    this.apiService.getEvent_byEventId(this.event_pk).subscribe(response=>{	
		this.event = response[0];	
	});
  }



getEditEvent=new FormGroup({
  title:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  description:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  info:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  date:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  editEvent(){
    const eventImg = new FormData();
    eventImg.append('event', this.form.get('event').value);
    this.apiService.editEvent(this.event_pk, this.getEditEvent.value, eventImg).subscribe(response=>{
    	console.log(response);
    });
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('event').setValue(file);
    }
  }
}
