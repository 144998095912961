import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private apiService:ApiService) { }

  getContact_display:string;

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }


  getContact=new FormGroup({
  fullName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  phoneNumber:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.email]),
  companyName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  message:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});


  fromData(){
    var serviceRequired:any = window.location.href.split("/");
    serviceRequired = serviceRequired[serviceRequired.length-1];
    
    console.log(this.getContact.value);
    this.apiService.requestContact(this.getContact.value).subscribe(response=>{
      console.log(response);
    });
  }

}
