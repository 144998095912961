
<div class="addExam_wrapper">
	<img (click)="hideAddExamPopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="addExam_content">
		<form [formGroup]="getAddExam" (ngSubmit)="onSubmit()">
			<p id="title">Add Exam</p>
			<div class="inputs" id="input1">
				<legend>Exam Name</legend>
				<input rows="1"  type="text" formControlName="examName">
			</div>
			<div class="inputs" id="input6">
				<legend>Date:</legend>
				<input type="date" formControlName="date">
				<!-- <div *ngIf="ge.controls.date.errors && ge.controls.date.touched" class="alert "
					role="alert">
				Date is Required
			</div> -->
			</div>
			<div class="inputs" id="input7">
				<legend>Time:</legend>
				<input type="time" step="60" formControlName="time">
				<!-- <div *ngIf="getAppointment.controls.time.errors && getAppointment.controls.time.touched" class="alert "
					role="alert">
				Time is Required
			</div> -->
			</div>
			<div class="inputs" id="input1">
				<legend>Exam Location</legend>
				<input rows="1"  type="text" formControlName="location" >
			</div>
			<div class="inputs" id="input8">
				<input type="submit">
			</div>
		</form>
	</div>
</div>




