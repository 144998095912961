
<div class="editMaterial_wrapper">
	<img (click)="hideEditMaterialPopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="editMaterial_content">
		<form [formGroup]="getEditMaterial" (ngSubmit)="onSubmit()">
			<p id="title">Edit Material</p>
			<div class="inputs" id="input1">
				<legend>Material Name</legend>
				<input rows="1"  type="text" formControlName="materialName"  [(ngModel)]="globals.editMaterialName_value">
			</div>
			<div class="inputs" id="input5">
				<legend>Upload Material</legend>
				<div>
					<label for="dtfyguhijo">
					<div class="file_img"> 
						<img src="../../assets/icons/uploadFile.svg"/>
					</div>
					</label>
					<input (change)="onFileSelect($event, 'material')" id="dtfyguhijo" type="file" name="material"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>

			<div class="inputs" id="input8">
				<input type="submit">
			</div>
		</form>
	</div>
</div>
