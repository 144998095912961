import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-edit-course',
  templateUrl: './admin-edit-course.component.html',
  styleUrls: ['./admin-edit-course.component.css']
})
export class AdminEditCourseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
