
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
				<h1>Why Choose Cadres?</h1>
				<p>
					In cadres we have reached the
					middle ground between academic
					knowledge and practical
					experience for delivering worldclass educational services in its
					simplest form. On the other hand,
					we aim to support Egyptian
					Businesses along their journey of
					success in order to push the whole
					economy forward.	
				</p>
		</div>

		<div id="img_bg"></div>
		<div class="img">
			<img src="../../assets/imgs/about/img1.jpg">
		</div>

	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>



<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Our Story</h1>
			<p>
				Cadres is a well-established business development
				educational company that offers academic as well as
				business services. We are partnering with Pearson to
				deliver a better quality of business education in
				Egypt. Cadres is an approved centre to offer LCCI
				international qualifications from Pearson. Pearson is
				the world’s leading learning company, with 40,000
				employees in more than 70 countries working to
				help people of all ages to make measurable progress
				in their lives through learning. Pearson provides
				learning materials, world class qualifications,
				assessments and services to teachers and students in
				order to help people everywhere aim higher and
				fulfil their true potential. 
			</p>
		</div>
		<div class="iframe"><iframe src="https://www.youtube.com/embed/RMEIXmmjFCo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
	</div>
</section>


<section class="sect3_wrapper">
	<img class="bg" id="bg_desktop" src="../../assets/backgrounds/about/sect3.png">
	<img class="bg" id="bg_mobile" src="../../assets/backgrounds/about/mobile/sect3.svg">
	<div class="sect3_content">
		<div class="info">
			<h1 id="title">Our Mission</h1>
			<p class="para">
				We guide individuals and corporations throughout their path of success. Our job is to get MENA cadres equipped with knowledge and excellence toolkit in order to unlock their potentials and lead tomorrow&apos;s transformation. With the help of business acumen, educational experts, and partners, coupled with passion and creativity, we are embarking on a journey towards a better society.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/about/img2.jpeg">
		</div>
	</div>
</section>

<section class="sect4_wrapper">

	<div class="sect4_content">
		<div class="info">
			<h1 id="title">Our Vission</h1>
			<p class="para">
				We are helping today&apos;s cadres to bring <br> in a better tomorrow.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/about/img3.jpeg">
		</div>
	</div>
</section>

<section class="sect5_wrapper">
	<img class="bg" id="bg_desktop" src="../../assets/backgrounds/about/sect5.svg">
	<img class="bg" id="bg_mobile" src="../../assets/backgrounds/about/mobile/sect5.svg">

	<div class="sect5_content">
		<h1>Our Values</h1>
		<div class="cards_parent">
			<div class="cards" id="card1">
				<img src="../../assets/icons/inclusion.svg" alt="">
				<p id="title">Inclusion</p>
			</div>
			<div class="cards" id="card2">
				<img src="../../assets/icons/integrity.svg" alt="">
				<p id="title">Integrity</p>
			</div>
			<div class="cards" id="card3">
				<img src="../../assets/icons/innovation.svg" alt="">
				<p id="title">Innovation</p>
			</div>
			<div class="cards" id="card4">
				<img src="../../assets/icons/accountability.svg" alt="">
				<p id="title">Sccountability</p>
			</div>
			<div class="cards" id="card5">
				<img src="../../assets/icons/professionalism.svg" alt="">
				<p id="title">Professionalism</p>
			</div>
			<div class="cards" id="card6">
				<img src="../../assets/icons/promptness.svg" alt="">
				<p id="title">Promptness</p>
			</div>
		</div>
	</div>
</section>

<section class="sect6_wrapper">
	<div class="sect6_content">
		<div class="info">
			<h1>CEO Message</h1>
			<p>
				Due volatility of today’s market, agility of
				the market dynamics are devastating for
				businesses survival especially after
				COVID-19 strike. We started Cadres in
				Egypt to utilize youth qualifications and
				how their potential used to meet the
				market unlimited demands. We stretch a
				helping hand to those who want to make
				their future brighter. We believe in the
				change, hope and dedication. We just
				wanted to establish an organization who
				introduces real values		
			</p>
		</div>
		<div class="img">
			<img id="img_sect5" src="../../assets/imgs/about/img4.jpeg">
		</div>
	</div>
</section>

<gallery></gallery>

