import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-add-vacancy',
  templateUrl: './admin-add-vacancy.component.html',
  styleUrls: ['./admin-add-vacancy.component.css']
})
export class AdminAddVacancyComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  	window.scrollTo(0, 0);
  }

    getAddVacancy=new FormGroup({
	'title': new FormControl(null),
	'description': new FormControl(null),
	'summary': new FormControl(null),
	'specification': new FormControl(null),
	'interpersonalSkills': new FormControl(null),
	'benefits': new FormControl(null),

	});

  addVacancy(){
  	console.log(this.getAddVacancy.value);
  	
    this.apiService.addVacancy(this.getAddVacancy.value).subscribe(response=>{
    	console.log(response);
    });
  }

}
