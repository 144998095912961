
<div class="sect1_wrapper desktop">
	<div class="sect1_content">
		<div class="news_container">
			<p id="title">News</p>
			<div *ngFor="let singleNews of news">
				<div class="cards" id="card1"> <!--routerLink="./news/{{singleNews.pk}}"-->
					<div class="opacity"></div>
					<div class="img">
						<img src="../../assets/imgs/news/news1.png">
					</div>	
					<p id="date">{{singleNews.date | slice:8:19}} <br> {{singleNews.date | slice:5:7 | date: 'MMMM' | slice:0:3}}</p>
					<div class="info">
						<p id="category">{{singleNews.title}}</p>
						<p id="info">{{singleNews.info | slice:0:50 | removeHtmlTags}}..</p>
					</div>
				</div>
			</div>

			<h2 *ngIf="news['message']">No News Yet</h2>
		</div> 
		<div class="middle">
			<hr>
		</div>
		<div class="events_container">
			<p id="title">Events</p>
			<div *ngFor="let event of events">
				<div class="cards">
					<div class="opacity" id="opacity_card2"></div>
					<div class="img">
						<img src="{{event.imgPath}}">
					</div>
					<p id="date">{{event.date | slice:5:7 | date: 'MMMM' | slice:0:3}} {{event.date | slice:8:19}}</p>
					<div class="info">
						<p id="category">{{event.title}}</p>
						<p id="info">{{event.info | slice:0:50 | removeHtmlTags}}..</p>
					</div>
				</div>
			</div>
			<h2 *ngIf="events['message']">No Events Yet</h2>
		</div>
	</div>
</div>
