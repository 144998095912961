

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="filters_container">
			<div class="addVacancy" routerLink="./add-vacancy">
				<img src="../../assets/icons/add_white.svg">
				<p>Add Vacancy</p>
			</div>
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="vacancies_wrapper">
	<div class="vacancies_content">
		<div class="cards" id="card1" *ngFor="let vacancy of vacancies | filter:searchText">
			<p id="title">{{vacancy.title | removeHtmlTags}}</p>
			<p id="info">
				{{vacancy.summary | removeHtmlTags | slice:1:150}} ...
			</p>
			<p id="date">End Date 20/8/2020</p>
			<p id="edit" routerLink="./edit-vacancy/{{vacancy.vacancy_pk}}">Edit</p>
			<p id="delete" (click)="deleteVacancy(vacancy.vacancy_pk)">Delete</p>
		</div>
	</div>
</section>
