import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'app-instructor-course',
  templateUrl: './instructor-course.component.html',
  styleUrls: ['./instructor-course.component.css']
})
export class InstructorCourseComponent implements OnInit {

	courseName:string;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router,  public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.courseName = params.courseName;
				this.round_fk = params.round_fk;						
			});
	}

	instructor_pk:string = this.apiService.loggedIn_user()["pk"];

	round_fk:any;

 	courses:any;

	classes:any;
	class:any;

	students:any;

	exams:any;

	quizzes:any;

	materials:any;

	assignments:any;

	url:string = "https://www.cadreseg.com/coursePublicMaterials/lcci-(9).xd";

	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}


		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
	this.apiService.getCourses_byInstructortId(this.instructor_pk).subscribe(response=>{	
		this.courses = response.data;
		this.courses = this.turn2dArrayToAssoc2dArray(this.courses)[this.courseName];
		
	});

  	this.apiService.getClasses_byCourseRoundId(this.round_fk).subscribe(response=>{		
		this.classes = response.data;		
				
	});

	this.apiService.getStudents_byCourseRoundId(this.round_fk).subscribe(response=>{	
		this.students = response.data;
	});

  	this.apiService.getExams_byInstructortId(this.instructor_pk).subscribe(response=>{		
		this.exams = response;
		this.exams = this.turn2dArrayToAssoc2dArray(this.exams)[this.courseName];
	});

  	this.apiService.getQuizes_byInstructorId(this.instructor_pk).subscribe(response=>{		
		this.quizzes = response.data;
		this.quizzes = this.turn2dArrayToAssoc2dArray(this.quizzes)[this.courseName];
	});

	this.apiService.getCourseMaterials_byInstructorId(this.instructor_pk).subscribe(response=>{		
		this.materials = response;
		this.materials = this.turn2dArrayToAssoc2dArray(this.materials)[this.courseName];		
	});

	this.apiService.getAssignments_byInstructorId(this.instructor_pk).subscribe(response=>{		
		this.assignments = response.data;
		this.assignments = this.turn2dArrayToAssoc2dArray(this.assignments)[this.courseName];
				
		
	});
	
  }

  showPopUp(index, popup){
  	if(popup == "editClass") {  		
  		if(this.globals.editClassWrapper_display == "none") {
  			
  		this.class = this.classes[index];
  		this.globals.editClassFk_value = this.class.class_pk;
  		// console.log(this.globals.editClassFk_value);
  				
  		// this.globals.editClassWrapper_display = "block";
  		this.globals.editClassName_value = this.class.name;
		this.globals.editClassZoomLink_value = this.class.zoomLink;
		this.globals.editClassDescription_value = this.class.description;
		this.globals.editClassDate_value = this.class.date;
		this.globals.editClassTime_value = this.class.time;
  		this.globals.editClassWrapper_display = "flex";
  		// console.log(this.globals.editClassWrapper_display);
  		

	  	}else if(this.globals.editClassWrapper_display = "block") {
	  		this.globals.editClassWrapper_display = "none";
	  	}
  	}else if(popup == "createClass"){
	  	if(this.globals.createClassWrapper_display == "none") {
	  		this.globals.createClassRoundFk_value = this.courses[0]["round_pk"];
	  		this.globals.createClassRoundType_value = 'diploma';
	  		this.globals.createClassWrapper_display = "flex";
	  		// console.log(this.globals.createClassWrapper_display);
	  	}else if(this.globals.createClassWrapper_display = "block") {
	  		this.globals.createClassWrapper_display = "none";
	  	}
	}else if(popup == "addMaterial"){
	  	if(this.globals.addMaterialWrapper_display == "none") {
	  		this.globals.addMaterialWrapper_display = "flex";
	  		this.globals.addMaterialCourseFk_value = this.courses[0]["course_pk"];
	  		// console.log(this.globals.addMaterialWrapper_display);
	  	}else if(this.globals.addMaterialWrapper_display = "block") {
	  		this.globals.addMaterialWrapper_display = "none";
	  	}
	}else if(popup == "editMaterial"){
	  	if(this.globals.editMaterialWrapper_display == "none") {	  		
	  		this.globals.editMaterialWrapper_display = "flex";
	  		this.globals.editMaterialCourseFk_value = this.courses[0]["course_pk"];
			this.globals.editMaterialPk_value = this.materials[index].material_pk;
			this.globals.editClassFk_value = this.materials[index].class_fk;
			this.globals.editMaterialName_value = this.materials[index].materialName;
	  	}else if(this.globals.editMaterialWrapper_display = "block") {
	  		this.globals.editMaterialWrapper_display = "none";
	  	}
	}else if(popup == "addQuiz"){
	  	if(this.globals.addQuizWrapper_display == "none") {
	  		this.globals.addQuizWrapper_display = "flex";
	  		this.globals.addQuizCourseFk_value = this.courses[0]["course_pk"];
	  		// console.log(this.globals.addQuizWrapper_display);
	  	}else if(this.globals.addQuizWrapper_display = "block") {
	  		this.globals.addQuizWrapper_display = "none";
	  	}
	}else if(popup == "editQuiz"){
	  	if(this.globals.editQuizWrapper_display == "none") {
	  		
	  		this.globals.editQuizWrapper_display = "flex";
	  		this.globals.editQuizCourseFk_value = this.courses[0]["course_pk"];
			this.globals.editQuizPk_value = this.quizzes[index].quiz_pk;
			this.globals.editQuizName_value = this.quizzes[index].quizName;
			this.globals.editQuizDateDue_value = this.quizzes[index].dateDue;
			this.globals.editQuizTimeDue_value = this.quizzes[index].timeDue;
			
	  	}else if(this.globals.editQuizWrapper_display = "block") {
	  		this.globals.editQuizWrapper_display = "none";
	  	}
	}else if(popup == "addAssignment"){
	  	if(this.globals.addAssignmentWrapper_display == "none") {
	  		this.globals.addAssignmentWrapper_display = "flex";
	  		this.globals.addAssignmentCourseFk_value = this.courses[0]["course_pk"];
	  		// console.log(this.globals.addAssignmentWrapper_display);
	  	}else if(this.globals.addAssignmentWrapper_display = "block") {
	  		this.globals.addAssignmentWrapper_display = "none";
	  	}
	}else if(popup == "editAssignment"){
	  	if(this.globals.editAssignmentWrapper_display == "none") {
	  		
	  		this.globals.editAssignmentWrapper_display = "flex";
	  		this.globals.editAssignmentCourseFk_value = this.courses[0]["course_pk"];
			this.globals.editAssignmentPk_value = this.assignments[index].pk;
			this.globals.editAssignmentName_value = this.assignments[index].assignmentName;
			this.globals.editAssignmentDateDue_value = this.assignments[index].dateDue;
			
	  	}else if(this.globals.editAssignmentWrapper_display = "block") {
	  		this.globals.editAssignmentWrapper_display = "none";
	  	}
	}
  	
  }

}
