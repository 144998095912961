
<div id="body_bg"></div>

<section class="sect1_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/recruitment/sect1.svg">
	</div>
	<div class="sect1_content">
		<div class="info">
			<h1>Recruitment</h1>
			<p>
				Cadres Recruitment Solutions (CRS) is a user-
				friendly platform that matches entrepreneurs 
				with authentic qualifications of applicants. Our 
				Recruitment aid include 4 different types of 
				tests all have been developed by our British 
				partner aka Pearson: Watson critical thinking, 
				Iris situational judgmental test, differential 
				attitude test DAT and Sosie 2nd generation 
				test. 
			</p>
		</div>
		<div class="illustration">
			<img src="../../assets/imgs/recruitment/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Watson-glaser <br>
				critical thinking appraisal</h1>
			<p>
				A measure that businesses use to examine job 
				applicants’ critical thinking and verbal critical 
				reasoning skills. The test examines hard-skills 
				appraisal and is for recruitment to measure 
				critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
			<button routerLink="./watson-glaser">
				Learn More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/recruitment/sect2.svg">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<h1>Iris Situational Judgmental Test</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
			<button routerLink="./iris-test">
				Learn More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/recruitment/sect3.svg">
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<h1>Differential aptitude test DAT</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
			<button routerLink="./differential-test">
				Learn More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/recruitment/sect4.svg">
		</div>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<h1>Sosie 2nd Generation</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
			<button routerLink="./sosie-generation">
				Learn More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/recruitment/sect5.svg">
		</div>
	</div>
</section>

<section class="sect6_wrapper">
	<div class="bg"></div>
	<div class="sect6_content">
		<div class="info">
			<h1>Versant English Placement Test<br>
				for Hiring and Selection</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
			<button routerLink="./versent-test">
				Learn More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/recruitment/sect6.svg">
		</div>
	</div>
</section>