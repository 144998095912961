
<instructor-create-class [style.display]="globals.createClassWrapper_display"></instructor-create-class>
<instructor-edit-class [style.display]="globals.editClassWrapper_display"></instructor-edit-class>
<instructor-add-material [style.display]="globals.addMaterialWrapper_display"></instructor-add-material>
<instructor-edit-material [style.display]="globals.editMaterialWrapper_display"></instructor-edit-material>
<instructor-add-quiz [style.display]="globals.addQuizWrapper_display"></instructor-add-quiz>
<instructor-edit-quiz [style.display]="globals.editQuizWrapper_display"></instructor-edit-quiz>
<instructor-add-assignment [style.display]="globals.addAssignmentWrapper_display"></instructor-add-assignment>
<instructor-edit-assignment [style.display]="globals.editAssignmentWrapper_display"></instructor-edit-assignment>

<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro">
			<div class="cards_content">
				<p>{{courseName | titlecase}} </p>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="nextClass">
			<p id="title">Next Class</p>
			<div class="cards_content">
			<!-- 	<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration1.svg" alt="">
				</div>
				<p>No Registered Courses</p> -->
				<div class="data">	
					<p id="data1">{{classes[0].courseName}}</p>
					<p id="data2">{{classes[0].name}}</p>
					<p id="data3">{{classes[0].time}}</p>
					<p id="data4">{{classes[0].date}}</p>
					<a id="data5" href="{{classes[0].zoomLink}}">link</a>
				</div>
				<hr>
			</div>
			
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="myStudents">
			<p id="title">My Students</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration2.svg" alt="">
				</div>
				<p>No Courses In Progress</p> -->
				<div class="data" *ngFor="let student of students; index as i">	
					<p id="data1" *ngIf="student.student_pk is null">{{i+1}}. {{student.firstName + " " + student.lastName | titlecase}}</p>
				</div>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="Materials">
			<p id="title">Materials</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p>No New Messages</p> -->
				<div class="data" *ngFor="let material of materials; index as i">	
					<a id="data1" [href]="material.path" target="_blank" download="{{material.materialName}}.{{material.fileType}}">{{material.materialName}}</a>
					<a (click)="showPopUp(i, 'editMaterial')">edit</a>
				</div>
				<p (click)="showPopUp('', 'addMaterial')">Add New +</p>
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="classes">
			<p id="title">Classes</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Classes In Progress</p> -->
				<div class="headers">
					<p id="header1">Course name:</p>
					<p id="header2">Class:</p>
					<p id="header3">Date:</p>
					<p id="header4">Time:</p>
				</div>
				<div class="data" *ngFor="let class of classes; index as i">	
					<p>{{i+1}}. {{class.courseName | truncateText}}</p>
					<p>{{class.name}}</p>
					<p>{{class.date}}</p>
					<p>{{class.time}}</p>
					<a (click)="showPopUp(i, 'editClass')">edit</a>
				</div>
				<p (click)="showPopUp('', 'createClass')">Add New +</p>
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="assignments">
			<p id="title">Assignments</p>
			<div class="cards_content">
			<!-- 	<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration5.svg" alt="">
				</div>
				<p>No Assignments Appointed</p> -->
				<div class="headers">
					<p id="header1">Course Name:</p>
					<p id="header2">Assignment:</p>
					<p id="header3">Due Date:</p>
				</div>
				<div class="data" *ngFor="let assignment of assignments; index as i">	
					<p>{{i+1}}. {{assignment.courseName | truncateText}}</p>
					<p>{{assignment.assignmentName}}</p>
					<p>{{assignment.dateDue}}</p>
					<a (click)="showPopUp(i, 'editAssignment')">edit</a>
				</div>
				<p (click)="showPopUp('', 'addAssignment')">Add New +</p>
				
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="quizess">
			<p id="title">Exams</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration6.svg" alt="">
				</div>
				<p>No Exams</p> -->
				<div class="headers">
					<p id="header1">Course:</p>
					<p id="header2">Name:</p>
					<p id="header3">Date:</p>
					<p id="header3">Location:</p>
				</div>
				<div class="data" *ngFor="let exam of exams; index as i">	
					<p>{{i+1}}. {{exam.courseName | truncateText}}</p>
					<p>{{exam.examName}}</p>
					<p>{{exam.date}}</p>
					<p>{{exam.location}}</p>

				</div>

			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="quizes">
			<p id="title">Quizes</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Grades</p> -->

				<div class="headers">
					<p id="header1">Quiz Name:</p>
					<p id="header2">Date:</p>
				</div>
				<div class="data" *ngFor="let quiz of quizzes; index as i">
					<p id="data1">{{quiz.quizName}}</p>
					<p id="data2">{{quiz.dateDue}}</p>
					<a (click)="showPopUp(i, 'editQuiz')">edit</a>
				</div>
				<p (click)="showPopUp('', 'addQuiz')">Add New +</p>
			</div>
		</div>
<!-- 		<div class="cards_wrapper cards_thirdWidth" id="messages">
			<p id="title">Messages</p>
			<div class="cards_content">
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p>No New Messages</p>
			</div>
		</div> -->
		<!-- <div class="cards_wrapper cards_thirdWidth" id="certificates">
			<p id="title">Certificates</p>
			<div class="cards_content">
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration8.svg" alt="">
				</div>
				<p>No Certificates</p>
			</div>
		</div> -->
	
		<!-- <div class="cards_wrapper cards_thirdWidth" id="materials">
			<p id="title">Materials</p>
			<div class="cards_content">
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration3.svg" alt="">
				</div>
				<p>No Materials</p>
			</div>
		</div> -->
		<!-- <div class="cards_wrapper cards_thirdWidth">
			<p id="title"></p>
			<div class="cards_content">
				<img src="../../assets/illustrations/student-dashboard/illustration.svg" alt="">
				<p></p>
			</div>
		</div> -->
	</div>
</div>