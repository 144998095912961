import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.css']
})
export class PrivacyAndPolicyComponent implements OnInit {

  constructor() { }

	title:string; 
	subTitles:any;
	subTitle_bodies:any;

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  	this.title= "Appeals and Complaints";
  	this.subTitles = ["Introduction", "Appeals Procedure", "Complaints Procedure"];
  	this.subTitle_bodies = [
							  	[`
							  	~An appeal is specifically related to a matter of academic decision, for example where a student does not agree with the marks awarded for a piece of assessed work.
							The procedure for that is detailed separately below; other matters of concern, which are not related to marks, can be raised as a complaint, for example against a member of staff, a student, or about facilities.
							~Cadres provides opportunities for students to raise matters of concern without risk of disadvantage through two approaches: academic appeals and complaints.
							`], 
							[
							`The Appeals Policy aims to:~• Enable the learner to enquire, question or appeal against an assessment decision~• Attempt to reach agreement between the learner and the Assessor at the earliest opportunity~• Standardise and record any appeal to ensure openness and fairness~• Facilitate a learner’s ultimate right of appeal to the Awarding Body, where appropriate~• Protect the interests of all learners and the integrity of the qualification.~Candidates will be informed of the college and the awarding body procedures for appealing an assessment decision during the induction and orientation session.
							~Unless stated otherwise in a programme specification or according to the policies of our awarding body, students may only appeal academic decisions on the following grounds:
							~• Factual errors
							~• Arithmetical Errors
							~• Irregularities in the assessment process
							~• Exceptional mitigating circumstances which were unknown to the verifiers or examination board and good reason can be presented as to why they were unknown.
							~- Appeals should be submitted through e-mails to the complaints and appeals department, stating clearly the grounds for the appeal.
							~- The complaints and appeals department shall ensure that the appeal is investigated within 10 working days of receipt of the appeal.
							~- A copy of the appeal e-mail and a report on the subsequent investigation shall be placed in the student’s file online on Cadres’s website, as well as original e-mail script being filed in the Appeals File.
							~- Entries into the Appeals Log File will not be destroyed less than three years from the date of the appeal.
							~- The complaints and appeals department may refer the case to the examination board, where applicable, requesting that it reconvenes to consider the grounds for the appeal.
							~- The complaints and appeals department shall inform the student of the outcome of the investigation and remind the student of the further awarding body appeals procedure, should they remain dissatisfied.
							~- The outcome of the investigation will be reported at the next Staff Meeting which may decide whether any action is to be taken as a result.
							~- The complaints and appeals department will forward to the awarding body a copy of all appeals received, which are not resolved by the complaints and appeals department within 28 days of receipt, and all resolved appeals where the awarding body requires this, and co-operate with the awarding body in respect of any action it needs to take to resolve the matter.
							~- The Appeals File shall be reviewed annually as part of the Academic Review process
							`], 

							[`

								~Cadres has procedures which encourage constructive engagement with the appeals and complaints process, and which offer opportunities for early and/or informal resolution.
			 
								~Cadres staff and administration will always be approachable and willing to listen to any complaint or advice that may be brought us.
								 
								~The staff will always respond in a polite manner and try their best to take reasonable steps to resolve the matter.
								 
								~If the matter is too serious for the informal approach, or it has been tried, but to no avail, then students should follow the formal complaints procedure.
								 
								~Students who wish to formally make a complaint to the company should contact the complaints And Appeals department through e-mail stating the following information:
								 
								~• Complaining Student Name and ID or Email
								~• Nature of Complaint, including names of individuals concerned
								~• What steps have already been taken by the complainant and the response received.
								 
								~The Complaints and Appeals department will appoint a senior member of staff to investigate the matter, who will respond to the student within five working days of receiving the complaint.
								 
								~If any student is not happy with the outcome of the investigation and feels that a formal complaint should be made to the awarding body, then they should check the awarding body’s website for details of their own complaint’s procedure.
								`]
						];

	for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}						
  }

	changeBody(header){
		if(header == "Appeals and Complaints") {
			  	this.title= header;
			  	this.subTitles = ["Introduction", "Appeals Procedure", "Complaints Procedure"];
			  	this.subTitle_bodies = [
										  	[`
										  	~An appeal is specifically related to a matter of academic decision, for example where a student does not agree with the marks awarded for a piece of assessed work.
										The procedure for that is detailed separately below; other matters of concern, which are not related to marks, can be raised as a complaint, for example against a member of staff, a student, or about facilities.
										~Cadres provides opportunities for students to raise matters of concern without risk of disadvantage through two approaches: academic appeals and complaints.
										`], 
										[
										`~The Appeals Policy aims to:~• Enable the learner to enquire, question or appeal against an assessment decision~• Attempt to reach agreement between the learner and the Assessor at the earliest opportunity~• Standardise and record any appeal to ensure openness and fairness~• Facilitate a learner’s ultimate right of appeal to the Awarding Body, where appropriate~• Protect the interests of all learners and the integrity of the qualification.~Candidates will be informed of the college and the awarding body procedures for appealing an assessment decision during the induction and orientation session.
										~Unless stated otherwise in a programme specification or according to the policies of our awarding body, students may only appeal academic decisions on the following grounds:
										~• Factual errors
										~• Arithmetical Errors
										~• Irregularities in the assessment process
										~• Exceptional mitigating circumstances which were unknown to the verifiers or examination board and good reason can be presented as to why they were unknown.
										~- Appeals should be submitted through e-mails to the complaints and appeals department, stating clearly the grounds for the appeal.
										~- The complaints and appeals department shall ensure that the appeal is investigated within 10 working days of receipt of the appeal.
										~- A copy of the appeal e-mail and a report on the subsequent investigation shall be placed in the student’s file online on Cadres’s website, as well as original e-mail script being filed in the Appeals File.
										~- Entries into the Appeals Log File will not be destroyed less than three years from the date of the appeal.
										~- The complaints and appeals department may refer the case to the examination board, where applicable, requesting that it reconvenes to consider the grounds for the appeal.
										~- The complaints and appeals department shall inform the student of the outcome of the investigation and remind the student of the further awarding body appeals procedure, should they remain dissatisfied.
										~- The outcome of the investigation will be reported at the next Staff Meeting which may decide whether any action is to be taken as a result.
										~- The complaints and appeals department will forward to the awarding body a copy of all appeals received, which are not resolved by the complaints and appeals department within 28 days of receipt, and all resolved appeals where the awarding body requires this, and co-operate with the awarding body in respect of any action it needs to take to resolve the matter.
										~- The Appeals File shall be reviewed annually as part of the Academic Review process
										`], 

										[`

											~Cadres has procedures which encourage constructive engagement with the appeals and complaints process, and which offer opportunities for early and/or informal resolution.
						 
											~Cadres staff and administration will always be approachable and willing to listen to any complaint or advice that may be brought us.
											 
											~The staff will always respond in a polite manner and try their best to take reasonable steps to resolve the matter.
											 
											~If the matter is too serious for the informal approach, or it has been tried, but to no avail, then students should follow the formal complaints procedure.
											 
											~Students who wish to formally make a complaint to the company should contact the complaints And Appeals department through e-mail stating the following information:
											 
											~• Complaining Student Name and ID or Email
											~• Nature of Complaint, including names of individuals concerned
											~• What steps have already been taken by the complainant and the response received.
											 
											~The Complaints and Appeals department will appoint a senior member of staff to investigate the matter, who will respond to the student within five working days of receiving the complaint.
											 
											~If any student is not happy with the outcome of the investigation and feels that a formal complaint should be made to the awarding body, then they should check the awarding body’s website for details of their own complaint’s procedure.
											`]
									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Privacy"){
				this.title= header;
			  	this.subTitles = ["Introduction", "While you use our services, we may:", "DISCLAIMER"];
			  	this.subTitle_bodies = [
										  	[`
										  	~Privacy policy further explains the type of information that “we, us and our” as in Cadres may collect about yourself “you” for our own purposes, how we use that information and why. 

										`], 
										[`
										~ • Collect your name and contact information as you create an account. We may also collect information relating to the actions that you perform while logged into your account. This data is used to provide account related functionalities to our users.
										~ • Collect demographic information about your location. This data is used by our business to understand our users and provide tailored services. 
										~ • Collect contact information such as name, e-mail, address and/or phone of our business clients and their employees with whom we may interact. This data is used to provide our business clients with ability to save preferences such as login and preferred language.
										~ • If you provide us feedback or contact us for support, we may collect your name and e-mail address, as well as any other content that you send to us, to reply.
										~ • Receive information about you from other sources, i.e. business partners, our affiliates, publicly available sources or third parties
										`], 

										[`

											~The information we gather shall only be used by Cadres to offer you tailored services and save your preferences. No other parties shall be taking advantages of such information.
										`]

									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Access Arrangements") {
			  	this.title= header;
			  	this.subTitles = ["Objectives", "Access Arrangements Types"];
			  	this.subTitle_bodies = [
										  	[`
										  	~The purpose of an access arrangement is to ensure, where possible, that barriers to assessment are removed for a disabled candidate preventing him/her from being placed at a substantial disadvantage as a consequence of persistent and significant difficulties. The integrity of the assessment is maintained, whilst at the same time providing access to assessments for a disabled candidate.

										`], 
										[`
										~Extra time: It is usually around 25% more time can be allocated to candidates with more severe difficulties and disability on an individual case by case basis. We know of one pupil allowed seven hours to complete a maths paper using eye gaze technology.

										~A reader/Computer reader: Both can be used for candidates who have visual impairments or a disability that affects their ability to read accurately themselves. In an exam that assesses reading ability a human reader is not allowed.

										~A scribe: Scribe can be allocated to candidates who have a disability or injury that affects their ability to write legibly.

										~Modified papers: These are papers which must be ordered well in advance of the exam in different sizes, fonts, or braille papers.

										~Supervised rest breaks: Supervised rest breaks, these are not included in the extra time allowance.

										`]

									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Data Protection") {
			  	this.title= header;
			  	this.subTitles = ["Data protection principles", "General provisions", "Lawful, fair and transparent processing", "Lawful purposes", "Data minimisation", "Accuracy", "Archiving / removal", "Security", "Breach"];
			  	this.subTitle_bodies = [
										  	[`
										  	~The company is committed to processing data in accordance with its responsibilities under the GDPR. 
											~Article 5 of the GDPR requires that personal data shall be:
											~a.	processed lawfully, fairly and in a transparent manner in relation to individuals;
											~b.	collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes will not be considered to be incompatible with the initial purposes;
											~c.	adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;
											~d.	accurate and, where necessary, kept up to date; every reasonable step will be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay;
											~e.	kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes subject to implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals; and
											~f.	processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures.”


										`], 
										[`
											~a.	This policy applies to all personal data processed by the company. 
											~b.	Cadres Corporate Security Department will take responsibility for the company’s ongoing compliance with this policy. 
											~c.	This policy will be reviewed at least annually.  


										`],
										[`
 										    ~a.	To ensure its processing of data is lawful, fair and transparent, the company will maintain a Register of Systems. 
											~b.	The Register of Systems will be reviewed at least annually. 
											~c.	Individuals have the right to access their personal data and any such requests made to the company will be dealt within 7 working days. 


										`],

										[`
 										    ~a.	All data processed by the company will be done on one of the following lawful bases: consent, contract, legal obligation, vital interests, public task or legitimate interests.
											~b.	The company will note the appropriate lawful basis in the Register of Systems.
											~c.	Where consent is relied upon as a lawful basis for processing data, evidence of opt-in consent will be kept with the personal data. 
											~d.	Where communications are sent to individuals based on their consent, the option for the individual to revoke their consent would be clearly available and systems would be in place to ensure such revocation is reflected accurately in the company’s systems.  


										`],

										[`
											~The company will ensure that personal data are adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed. 
										`],
										[`
 										    ~a.	The company will take reasonable steps to ensure personal data is accurate. 
											~b.	Where necessary for the lawful basis on which data is processed, steps will be put in place to ensure that personal data is kept up to date. 

										`],

										[`
 										    ~a.	To ensure that personal data is kept for no longer than necessary, the company will put in place an archiving policy for each area in which personal data is processed and review this process annually. 
											~b.	The archiving policy will consider what data should/must be retained, for how long, and why. 

										`],

										[`
											~a.	The company will ensure that personal data is stored securely using modern software that is kept-up-to-date.  
											~b.	Access to personal data will be limited to personnel who need access and appropriate security would be in place to avoid unauthorised sharing of information. 
											~c.	When personal data is deleted this would be done safely such that the data is irrecoverable. 
											~d.	Appropriate back-up and disaster recovery solutions will be in place. 

										`],

										[`
											~In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data, the company will promptly assess the risk to people’s rights and freedoms and if appropriate report this breach to the authorities in Egypt

										`]

									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Safeguarding") {
			  	this.title= header;
			  	this.subTitles = ["Aims", "Rationale", "Principles"];
			  	this.subTitle_bodies = [
										  	[`
										  	~• The aims of the company safeguarding policy are to provide an environment where all can learn and work safely; 
											~• The company will take every reasonable precaution to minimise risk while providing demanding, challenging and enjoyable training and development activities at our own premises and the premises of our employers and clients.


										`], 
										[`
										~The rationale of the policy is to contribute to the personal safety of all employees and candidates using our facilities and resources, through actively promoting awareness, good practice and sound procedures. The organisation is committed to ensure that all and specifically those that are vulnerable are kept safe from harm while they are involved with the organisation.
										`],

										[`

											~• Safeguarding has a meaning wider than people’s safety in general. The policy aims to ensure that all learners, staff, customers, linked employers, freelance trainers’ stakeholders and visitors are safe from harm and abuse, harassment and bullying. Harm and harassment have formal legal meanings within civil and criminal law.
											~• Safeguarding also includes all staff acting in a responsible way to avoid any false allegations of inappropriate behaviour being made about their conduct that would give cause for concern.
											~• This policy is directly linked to the company disciplinary procedures. All company personnel have a legal duty to report instances of harm and abuse in regulated activity to the upper management following action in accordance with staff disciplinary procedures where appropriate
											~• In addition to the legal aspects it is our policy to ensure that all persons learning and working with us respect each other and strive to help each other in a professional and caring manner.
											~• Any violations to the policy should be reported to the Corporate Safety Department.


										`]

									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Disability") {
			  	this.title= header;
			  	this.subTitles = ["Key staff involved in the policy", "Purpose of Policy", "Identifying the need for access arrangements", "Roles and responsibilities", "Requesting access arrangements", "Implementing access arrangements and the conduct of exams"];
			  	this.subTitle_bodies = [

										  	[`
										  	~Head of centre: Islam Mandouh
										  	~Exams Officer: May Adam
										  	~SENCo: Noha Elsayed
										  	~Assessor: Mazen Sabry
										  	`],

										  	
										  	[`
										  	~• This policy details how the company facilitates access to lectures, exams and assessments for disabled candidates, as defined under the terms of the Equality Act 2010, by outlining staff roles and responsibilities in order to abide by the national as well as the international standards for delivering better quality of education for everyone
											~• identifying the need for appropriate arrangements, reasonable adjustments and/or adaptations (referred to in this policy as ‘access arrangements’) 
											~• requesting access arrangements
											~• implementing access arrangements and the conduct of exams 



										`], 
										[`
										~Head of centre:
										~•  Is familiar with the entire contents, refers to and directs relevant company staff to the annually updated JCQ publications including GR and AA 
										~• Ensures staff roles, responsibilities and processes in identifying, requesting and implementing access arrangements for candidates are clearly defined and documented 
										~• Ensures a policy demonstrating the centre’s compliance with relevant legislation is in place 
										~• Ensures the assessment process is administered in accordance with the regulations and that the correct procedures are followed 
										~• Ensures that all assessments carried out and arrangements put in place comply with JCQ and awarding body regulations and guidance  

										`],

										[`

											~Special educational needs coordinator (SENCo):
											~• Has full knowledge and understanding of the contents, refers to and directs relevant centre staff to the annually updated JCQ publication AA. 
											~• Ensures arrangements put in place for exams/assessments reflect a candidate’s normal way of working within the centre.
											~• Ensures the quality of the access arrangements process within the centre. 
											~• Leads on the access arrangements process to facilitate access for candidates.
											~• Defines and documents roles, responsibilities and processes in identifying, requesting and implementing access arrangements (Access arrangements policy).
											~• Ensures the need for access arrangements for a candidate will be considered on a subject by subject basis 
											~• Works with teaching staff, relevant support staff and the exams officer to ensure centre-delegated and awarding body approved access arrangements are put in place for candidates taking internal and external exams/assessments.
											~• Provide information to evidence the normal way of working of a candidate. 
											~• Conducts appropriate assessments to identify the need(s) of a candidate. 
											~• Provides appropriate evidence to confirm the need(s) of a candidate.
											~Exam Officer:
											~• Support the SENCo in determining the need for and implementing access arrangements
											~• If not the appropriately qualified assessor, works with the person/persons appointed, on all matters relating to assessing candidates and the administration of the assessment process
											~• Ensures the qualified assessor(s) has access to the assessment objectives for the relevant specification(s) a candidate is undertaking 
											~• Completes appropriate documentation as required by the regulations of JCQ and the awarding body 
											~• Has detailed understanding of the JCQ publication AA

										`],

										[`
											~Special educational needs coordinator (SENCo):
											~• Determines if the arrangements identified for a candidate require prior approval from the awarding body before the arrangements are put in place or if approval is centre-delegated 
											~Maintains a (hard copy or electronic) file/e-folder for each candidate that will include:
											~• completed JCQ/awarding body application forms and evidence forms 
											~• appropriate evidence to support the need for the arrangement where required 
											~• appropriate evidence to support normal way of working within the centre 
											~• Presents the files/e-folders when requested by a JCQ Centre Inspector and addresses any queries/questions raised 
											~• Liaises with teaching staff regarding any appropriate modified paper requirements for candidates 
											~Exams officer:
											~• Is familiar with the entire contents of the annually updated JCQ publication GR and is aware of information contained in AA where this may be relevant to the EO role 
											~• Applies for approval from the awarding body where qualifications sit outside the scope of AAO 
											~• Ensures that arrangements, and approval where required, are in place before a candidate takes his/her first exam or assessment (which is externally assessed or internally assessed/externally moderated) 
											~• Ensures that where approval is required that this is applied for by the awarding body deadline 
											~• Liaises with the SENCo to ensure arrangements are in place to either order a noninteractive electronic (PDF) question paper or to open question paper packets in the secure room within 90 minutes of the published starting time for the exam.

										`],

										[`

											~Head of Centre: 
											~• Supports the SENCo, the exams officer and other relevant centre staff in ensuring appropriate arrangements, adjustments and adaptations are in place to facilitate access for disabled candidates to exams 
											~• Is familiar with the instructions for Invigilation arrangements for candidates with access arrangements and Access arrangements in ICE 2018-2019
											~• Ensures that any arrangements put in place do not unfairly disadvantage or advantage disabled candidates
											~• Appoints appropriate centre staff as facilitators to support candidates (practical assistant, prompter, Oral Language Modifier, reader, scribe or Communication Professional)   
											~• Senior staff responsible for the centre’s emergency evacuation procedures and the arrangements that may need to be in place for a candidate with a disability who may need assistance when an exam room is evacuated
											~Special educational needs coordinator (SENCo):
											~• Ensures appropriate arrangements, adjustments and adaptations are in place to facilitate access for candidates where they are disabled within the meaning of the Equality Act (unless a temporary emergency arrangement is required at the time of an exam) 
											~• Ensures a candidate is involved in any decisions about arrangements, adjustments and/or adaptations that may be put in place for him/her and ensures the candidate understands what will happen at exam time 
											~• Ensures that prior to any arrangements being put in place checks are made that arrangements do not impact on any assessment criteria/competence standards being tested 
											~• Monitors, in internal tests/mock exams, the use of arrangements granted to a candidate and where a candidate has never made use of the arrangement, may consider withdrawing the arrangement, provided the candidate will not be placed at a substantial disadvantage 
											~• Liaises with the exams officer (EO) regarding facilitation and invigilation of access arrangement candidates in exams 
											~• Liaises with the EO to ensure that invigilators are made aware of the Equality Act 2010 and are trained in disability issues 
											~Exams Officer:
											~• Understands and follows instructions for Invigilation arrangements for candidates with access arrangements
											~• Ensures exam information (JCQ information for candidates documents, individual exam timetable etc.) is adapted where this may be required for a disabled candidate to access it 
											~• Liaises with other relevant centre staff regarding the provision of appropriate rooming and equipment that may be required to facilitate access for disabled candidates to exams 
											~• Ensures facilitators supporting candidates are appropriately trained and understand the rules of the particular access arrangement(s) 
											~• Ensures a record of the content of training given to those facilitating an access arrangement for a candidate under examination conditions is kept and retained on file until the deadline for reviews of marking has passed or until any appeal, malpractice or other results enquiry has been completed, whichever is later 
											~• Ensures the facilitator is known by or introduced to the candidate prior to exams 
											~• Ensures a facilitator acting as a prompter is aware of the appropriate way to prompt depending on the needs of the candidate
											~Exams Officer:
											~• Ensures invigilators supervising access arrangement candidates are trained in their role and understand the invigilation arrangements required for access arrangement candidates and the role of any facilitator 
											~• Ensures cover sheets, where these are required by the arrangement are completed as required by facilitators 
											~• Liaises with the EO where a facilitator may be required to support a candidate requiring an emergency (temporary) access arrangement at the time of exams 
											~• Liaises with the SENCo and other relevant centre staff to ensure appropriate arrangements, adjustments and adaptations are in place to facilitate access for disabled candidates to exams
											~• Liaises with the SENCo to ensure exam information (JCQ information for candidates documents, individual exam timetable etc.) is adapted where this may be required for a disabled candidate to access it 
											~• Liaises with the SENCo regarding the facilitation and invigilation of access arrangement candidates and rooming of access arrangement candidates 
											~• Liaises with the SENCo to ensure that invigilators are made aware of the Equality Act 2010 and are trained in disability issues
											~Exams Officer:
											~• Ensures appropriate seating arrangements are in place where different arrangements may need to be made for a candidate to facilitate access to his/her exams 
											~• Ensures candidates with access arrangements are identified on exam room seating plans 
											~• Ensures invigilators are briefed prior to each exam session of the arrangements in place for a disabled candidate in their exam room 
											~• Checks in advance of dated exams/assessments that modified paper orders have arrived (and if not will contact the awarding body to ensure that papers are available when required) 
											~• Makes modifications that are permitted by the centre (a question paper copied onto coloured paper, an A4 to A3 enlarged paper or a paper printed on single sheets or where a question paper may need to be scanned into PDF format where a candidate is approved the use of a computer reader) that may be required and either accesses a non-interactive electronic (PDF) question paper or opens the exam question paper packet in the secure room no earlier than 90 minutes prior to the awarding body’s published start time of the exam
											~Exams Officer:
											~• Understands that where permitted/approved, secure exam question paper packets may need to be opened early so the facilitator may have access to the question paper 60 minutes prior to the awarding body’s published starting time for the exam in order to prepare
											~• Provides cover sheets prior to the start of an exam where required for particular access arrangements and ensures that these have been fully completed before candidates’ scripts are dispatched to examiners/markers 
											~• Has a process in place to deal with emergency (temporary) access arrangements as they arise at the time of exams in terms of rooming and invigilation 
											~Exams Officer:
											~• Understands that where permitted/approved, secure exam question paper packets may need to be opened early so the facilitator may have access to the question paper 60 minutes prior to the awarding body’s published starting time for the exam in order to prepare
											~• Provides cover sheets prior to the start of an exam where required for particular access arrangements and ensures that these have been fully completed before candidates’ scripts are dispatched to examiners/markers 
											~• Has a process in place to deal with emergency (temporary) access arrangements as they arise at the time of exams in terms of rooming and invigilation 

										`]

									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}
	}


}
