import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.css']
})
export class AdminProductsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  products:any;
  
  searchText:string;

  ngOnInit(): void {
    window.scrollTo(0, 0);
  	this.apiService.getProducts().subscribe(response=>{	
  		console.log(response);
  		
		this.products = response.data;
	});
  }

  deleteProduct(type, pk):void{  	
  	this.apiService.deleteProduct(type, pk).subscribe(response=>{	
  		console.log(response);  		
	});
  }

}
