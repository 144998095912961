
<section class="lcciPlan_wrapper" [style.display]="globals.lcciPlanWrapper_display">
	<img id="cross_icon" src="../../assets/icons/cross_black.svg" (click)="lcciPlan('none', '')">

	<div class="lcciPlan_content">
		<h1>Enroll</h1>
		<div class="cards_parent">
			<div class="cards" id="card1">
				<p id="title">Enroll</p>
				<p id="courseName">{{globals.productChosen_name}}</p>
				<p id="courseFees">Course Fees: $ {{globals.productChosen_price}}</p>
				<p id="registrationFees">Registration Fees: $ {{globals.productChosen_registrationFees}}</p>
				<p id="vat">Vat: 14%</p>
				<p class="extras">Level: {{globals.productChosen_level}}</p>
				<p class="extras">Learning Hours: {{globals.productChosen_learningHours}}</p>
				<p class="extras">Suite: {{globals.productChosen_suite}}</p>
				<p id="info">
					Your payment Plan will be paid on 
					Two installments throughout the 
					course. 
				</p>
				<!-- <p id="totalFees">Total: {{globals.productChosen_price}} LE</p> -->
				<button (click)="gateWay('flex')">Enroll</button>
			</div>	
		</div>
	</div>
</section>