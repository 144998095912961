
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="add" routerLink="./add-testimonial">
			<img src="../../assets/icons/add_white.svg">
			<p>Add Testimonial</p>
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<section class="testimonials_wrapper">
	<div class="testimonials_content">
		<div class="testimonial"  *ngFor="let testimonial of testimonials | filter:searchText">
			<p id="testimonialName">{{testimonial.name | titlecase}} <br> {{testimonial.date}}</p>
			<p routerLink="./edit-testimonial/{{testimonial.pk}}" id="edit">Edit</p>
			<p id="delete" (click)="deleteTestimonial(testimonial.pk)">Remove</p>
		</div>
	</div>
</section>