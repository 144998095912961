

<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Business Solutions</h1>
			<p>
				Cadres is an educational company that offers business solutions to a wide range of clients including entrepreneurs, startups, small businesses and even corporates. We offer you high-quality resources in order to enhance your business intelligence process, decisions, customer satisfaction and increase your revenues.

			</p>
		</div>
		<div class="img"><img src="../../assets/imgs/business-solutions/sect1.png" alt=""></div>
	</div>
</div>

<svg class="bg" id="bg_top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>
<div class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Business Consultation</h1>
			<p>
				The key word is business consultation.
				Cadres school of business provides business owners with a free to use platform, helping them grow their businesses through live chatting with our consultants. The consultations differ from getting the cheapest item in the supermarket to business planning, marketing planning, and recruiting the best employee matching your company needs with the market demands. You can also book an in real-life one to one meeting with our consultants telling you what exactly you could do to grow your business.
			</p>
			<button routerLink="./business-consultation">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect2.png">
		</div>
	</div>
</div>
<svg class="bg" id="bg_bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>


<div class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<h1>Corporate Training</h1>
			<p>
				Cadres offers inclusive business development programs to enhance corporate employees’ capabilities by providing a Training Needs Assessment that identifies their knowledge and skills. That is in order to properly direct them into the various fields of marketing, management, accounting, and finance. These corporate and business training 
			</p>
			<button routerLink="./corprate-training">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect3.png" alt="">
		</div>
	</div>
</div>


<svg class="bg" id="bg_top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>
<div class="sect4_wrapper sect4_wrapper_desktop">
	<div class="sect4_content">
		<div class="info">
			<h1>Marketing and Advertisement</h1>
			<p>
				Cadres offers a wide variety of professional business solutions that includes both marketing and advertising services. 
			</p>
			<div class="icons">
				<div class="icon" id="icon1">
					<img src="../../assets/icons/market-research.svg" alt="">
					<p>Market Research</p>
				</div>
				<div class="icon" id="icon2">
					<img src="../../assets/icons/strategy-and-planning.svg" alt="">
					<p>Strategy & Planning</p>
				</div>
				<div class="icon" id="icon3">
					<img src="../../assets/icons/media-planning-and-buying.svg" alt="">
					<p>Media planning & buying</p>
				</div>
				<div class="icon" id="icon4">
					<img src="../../assets/icons/seo-and-sem.svg" alt="">
					<p>SEO & SEM</p>
				</div>
				<div class="icon" id="icon5">
					<img src="../../assets/icons/web-design-and-development.svg" alt="">
					<p>Web design and development</p>
				</div>
			</div>
			<button routerLink="./marketing-and-advertisement">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect4.png" alt="">
		</div>
	</div>
</div>
<svg class="bg" id="bg_bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>


<div class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<h1>Event Management</h1>
			<p>
				Cadres gives you the opportunity to have your events managed and executed by a team of competent and experienced event managers. Small or large-scale events ranging from gigs, concerts, seminars, summits and more will be completely handled and made to fit the criteria set by our customers within the given timelines. Our expertise and qualifications fused together results in an outcome that surpasses expectations. 
			</p>
			<button routerLink="./event-management">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect5.png" alt="">
		</div>
	</div>
</div>

<svg class="bg" id="bg_top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>
<div class="sect6_wrapper">
	<div class="sect6_content">
		<div class="info">
			<h1>Recruting</h1>
			<p>
				Our Recruitment aid includes 4 different types of tests, all developed by our British partner, Pearson: Watson Critical Thinking, Iris Situational Judgmental Test, Differential Aptitude Test (DAT) and Sosie 2nd Generation Test.  
			</p>
			<button routerLink="./recruitment">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
		</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect6.png" alt="">
		</div>
	</div>
</div>
<svg class="bg" id="bg_bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>
