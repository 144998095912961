import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(private apiService: ApiService) { }

	appointments:any;
	quotes:any;
	contacts:any;

	  classes:any;

	  products:any;

	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void {
	window.scrollTo(0, 0);
	this.apiService.getAppointments().subscribe(response=>{	
		this.appointments = response;
	});

	this.apiService.getQuotes().subscribe(response=>{	
		this.quotes = response;
	});

	this.apiService.getContactRequests().subscribe(response=>{	
		this.contacts = response;
	});

	this.apiService.getProducts().subscribe(response=>{	
		this.products = response.data;		
	});

	this.apiService.getClasses().subscribe(response=>{	
		this.classes = response;
		console.log(response);
		
		// this.classes = this.turn2dArrayToAssoc2dArray(this.classes, 'courseName');
		
	});
  }

}
