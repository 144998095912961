import { Component, OnInit, Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-admin-sign-in',
  templateUrl: './admin-sign-in.component.html',
  styleUrls: ['./admin-sign-in.component.css']
})
export class AdminSignInComponent implements OnInit {

 constructor(private apiService:ApiService, private router: Router, public globals: Globals, public cookieService: CookieService) { }

  ngOnInit() {    
    // this.apiService.setLogged(true, "3", "instructor");
  }


  // const shajs = require('sha.js')


  dashBoardDiclaimer_display = "none";

  signInStatus:boolean = true;
  cookie:string = "";

  signIn=new FormGroup({
    email:new FormControl('',Validators.required),
    password:new FormControl('',Validators.required)
  });


  // setCookie(name,value,days) {
  //   this.cookieService.set(name, value, days, "; path=/");    
  // }

  log(){
    this.apiService.adminSignIn(this.signIn.value.email, this.signIn.value.password).subscribe(response=>{ 
      this.signInStatus = response.userPassword_status;
      // this.cookie = response.cookie;
      console.log(response);
      
      if(response.userPassword_status){
        this.router.navigateByUrl('/admin-dashboard'); 
        // var token = this.makeToken(response.user_pk);
        // this.setCookie("userid", this.cookie, 30); 
        this.apiService.setLogged(true, response.user_pk, response.user_type);
      }
    });
  }


}
