import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import {Globals} from '../globals';
import { ApiService } from '../api.service';


@Component({
  selector: 'navBar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) {}

  screenWidth:any;

  searchWrapper_height:string = "0";
  searchWrapper_padding:string = "0";
  menuWrapper_display:string = "none";
  searchResultContainer_display:string = "none";
  schoolOfBusinessSubLinks_display:string = "none";
  businessSolutionsSubLinks_display:string = "none";
  navBarColor:string;
  @ViewChild("school-of-business") schoolOfBusiness;
  userIcon_display:string = "";
  dashboardUrl:string = "";
  signInButtons_display:string = "";
  user_pk:string = "";

  searchText:string;

  products:any;

  ngOnInit(): void { 
    window.scrollTo(0, 0);
    this.screenWidth = window.innerWidth;
    this.apiService.getCoursesRounds().subscribe(response=>{ 
      this.products = response;
      // console.log(response);
      
    });
    // this.showSearch();
  }

  ngDoCheck(): void {

    if (window.location.href.indexOf("school-of-business") > -1){
      
      this.navBarColor = "blue";
      if (this.apiService.loggedIn_user()["pk"] != ""){
        this.userIcon_display = "block";
        this.dashboardUrl = this.apiService.loggedIn_user()["type"];
        this.signInButtons_display = "none";
        this.user_pk = "";
      }else{
        this.userIcon_display = "none";
        this.signInButtons_display = "flex";
      }
    }else{
      this.navBarColor = "orange";
      if (this.apiService.loggedIn_user()["pk"] != ""){
        this.userIcon_display = "block";
        this.dashboardUrl = this.apiService.loggedIn_user()["type"];
        this.signInButtons_display = "none";
        this.user_pk = "";
      }else{
        this.userIcon_display = "none";
        this.signInButtons_display = "flex";
      }
      
    }
  }

  showSearch(){
    var height:string;
    var padding:string;
    if(this.screenWidth<1000) {
        height = "115vw";
        padding = "30% 0 0 0";
    }else{
        height = "33vw";
        padding = "12.5% 0 0 0";
    }

    if (this.searchWrapper_height == height){
      this.searchWrapper_height = "0";
      this.searchWrapper_padding = "0";
    }else if (this.searchWrapper_height == "0"){
       this.searchWrapper_height = height;
       this.searchWrapper_padding = padding;
    }

  }

  showMenu(){
    this.showResults('none');
    if (this.menuWrapper_display == "block"){
      this.menuWrapper_display = "none";
    }else if (this.menuWrapper_display == "none"){
       this.menuWrapper_display = "block";
    }
  }

  showSublinks(mainLink){
    if(mainLink == "school-of-business") {
      if (this.schoolOfBusinessSubLinks_display == "block"){
        this.schoolOfBusinessSubLinks_display = "none";
      }else if (this.schoolOfBusinessSubLinks_display == "none"){
         this.schoolOfBusinessSubLinks_display = "block";
      }
    }else if(mainLink == "business-solutions") {
      if (this.businessSolutionsSubLinks_display == "block"){
        this.businessSolutionsSubLinks_display = "none";
      }else if (this.businessSolutionsSubLinks_display == "none"){
         this.businessSolutionsSubLinks_display = "block";
      }
    }
  }

  showHideSignWrapper(display, signCard){
    if(signCard == "signIn") {
      this.globals.signInCard_display = "block";
      this.globals.signUpCard_display = "none";
    }else if(signCard == "signUp") {
      this.globals.signUpCard_display = "block";
      this.globals.signInCard_display = "none";
    }
    this.globals.signWrapper_display = display;
  }

  showResults(display){
    this.searchResultContainer_display = display;
    // if (this.searchResultContainer_display == "block"){
    //   this.searchResultContainer_display = "none";
    // }else if (this.searchResultContainer_display == "none"){
    //    this.searchResultContainer_display = "block";
    // }
  }

}
