
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Differential aptitude test DAT</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/differential-test/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Differential Aptitude Test (DAT)</h1>
			<p>
				Psychometric test that assesses different types of aptitudes. This test is originally a profiling instrument that relates to success in different areas of employment. Its co-standardized tests provide a portrayal of strengths and weaknesses in an individual’s aptitude highlighting eight-points. 
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/differential-test/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<h1>The Eight Abilities</h1>
		<div class="info" id="info1">
			<p id="title"><span>Verbal</span> Reasoning</p>
			<ul>
				<li>Your understanding of ideas 
				expressed in words</li>
				<li>Your ability to think and 
				reason with words</li>
			</ul>
		</div>
		<div class="info" id="info2">
			<p id="title"><span>Numerical</span> Reasoning</p>
			<ul>
				<li>Your understanding of ideas expressed in numbers</li>
				<li>Your ability to think and reason with numbers</li>
			</ul>
		</div>
		<div class="info" id="info3">
			<p id="title"><span>Abstract</span> Reasoning</p>
			<ul>
				<li>Your understanding of ideas that are not expressed in words or numbers</li>
				<li>Your ability to think out problems even when there are no words to guide you</li>
			</ul>
		</div>
		<div class="info" id="info4">
			<p id="title"><span>Perceptual</span> speed and accuracy</p>
			<ul>
				<li>How efficiently can you do the important paperwork wherever records are made, filed or checked.</li>
			</ul>
		</div>
		<div class="info" id="info5">
			<p id="title"><span>Mechanical</span> Reasoning</p>
			<ul>
				<li>Your ability to grasp the common 
				principles of physics as you see 
				them in everyday life</li>
				<li>Your understanding of the laws 
				governing simple machinery, 
				tools and bodies in motion</li>
			</ul>
		</div>
		<div class="info" id="info6">
			<p id="title"><span>Space</span> Relations</p>
			<ul>
				<li>Your ability to visualise or form 
				mental pictures of solid objects 
				from looking at flat paper plans</li>
			</ul>
		</div>
		<div class="info" id="info7">
			<p id="title"><span>Spelling</span></p>
			<ul>
				<li>Your ability to detect correct and incorrect spellings of common English words</li>
			</ul>
		</div>
		<div class="info" id="info8">
			<p id="title"><span>Language</span> Usage</p>
			<ul>
				<li>How well is your usage of the English language in punctuation, capitalisation, and choice of words. </li>
			</ul>
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p id="title">What is DAT</p>
			<p>- Individual or Group test</p>
			<p>- 10-30 minutes per subtest</p>
			<p>- UK English</p>
			<p>- Used for career counselling and vocational guidance, candidate selection and personnel development</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/differential-test/sect4.png" alt="">
		</div>
	</div>
</section>