
<div class="editClass_wrapper">
	<img (click)="hideEditPopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="editClass_content">
		<form [formGroup]="getEditClass" (ngSubmit)="editClass()">
			<p id="title">Edit Class</p>
			<div class="inputs" id="input1">
				<legend>Class Name</legend>
				<input rows="1"  type="text" formControlName="name" [(ngModel)]="globals.editClassName_value">
			</div>
			<div class="inputs" id="input2">
				<legend>Zoom Link</legend>
				<input rows="1" type="text" formControlName="zoomLink" [(ngModel)]="globals.editClassZoomLink_value"> 
			</div>
			<div class="inputs" id="input3">
				<legend>Description</legend>
				<input rows="10" type="text" formControlName="description" [(ngModel)]="globals.editClassDescription_value">
			</div>
<!-- 			<div class="inputs" id="input4">
				<legend>Upload Video</legend>
				<div>
					<label for="upload_input">
					<div class="file_img"> 
						<img src="../../assets/icons/uploadFile.svg"/>
					</div>
					</label>
					<input onchange="file()" id="upload_input" type="file" name="instructorCV"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>

			<div class="inputs" id="input5">
				<legend>Upload Material</legend>
				<div>
					<label for="upload_input">
					<div class="file_img"> 
						<img src="../../assets/icons/uploadFile.svg"/>
					</div>
					</label>
					<input onchange="file()" id="upload_input" type="file" name="instructorCV"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div> -->

			<div class="inputs" id="input6">
				<legend>Set Date:</legend>
				<input type="date" formControlName="date" [(ngModel)]="globals.editClassDate_value">
				<!-- <div *ngIf="ge.controls.date.errors && ge.controls.date.touched" class="alert "
					role="alert">
				Date is Required
			</div> -->
			</div>
			<div class="inputs" id="input7">
				<legend>Set Time:</legend>
				<input type="time" step="60" formControlName="time" [(ngModel)]="globals.editClassTime_value">
				<!-- <div *ngIf="getAppointment.controls.time.errors && getAppointment.controls.time.touched" class="alert "
					role="alert">
				Time is Required
			</div> -->
			</div>
			<div class="inputs" id="input8">
				<input type="submit">
			</div>
		</form>
	</div>
</div>
