import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-news-and-events',
  templateUrl: './admin-news-and-events.component.html',
  styleUrls: ['./admin-news-and-events.component.css']
})
export class AdminNewsAndEventsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  type:string = "";

  news_display:string = "block";
  events_display:string = "none";
  news_bg:string = "#f1f1f1";
  events_bg:string = "#C5C5C5";

  news:any;
  events:any;
  newsOrEvents:any;

  searchText:string;



  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getNews().subscribe(response=>{	
		this.news = response;
		this.newsOrEvents = this.news;
	});
	this.apiService.getEvents().subscribe(response=>{	
		this.events = response;
	});
  	this.switch("news");
  }


  switch(filter):void{
  	if(filter == "news") {
		this.newsOrEvents = this.news;
		this.type = filter;
		this.news_display = "block";
		this.events_display = "none";
		this.news_bg = "#C5C5C5";
		this.events_bg = "#f1f1f1";
  	}else if(filter == "events") {
		this.newsOrEvents = this.events;
		this.type = filter;
		this.news_display = "none";
		this.events_display = "block";
		this.news_bg = "#f1f1f1";
		this.events_bg = "#C5C5C5";
  	}
  }

    deletenewsOrEvent(pk):void{  
	  	console.log(pk);
	  	if(this.type == "news") {
		  	this.apiService.deleteNews(pk).subscribe(response=>{			  		
		  		console.log(response);  		
			});
		}else if(this.type == "events") {
			console.log(1);
			
		  	this.apiService.deleteEvent(pk).subscribe(response=>{			
		  	console.log(2);
		  	  		  		
		  		console.log(response);  		
			});
		}

	  }

}
