
<div class="sect1_wrapper">
	<div class="sect1_content">
		<!-- <div class="cards_halfWidth" id="summary_conatiner"> -->
			<div class="cards_wrapper cards_quarterWidth" id="subscribers">
				<div class="cards_content">
					<p id="title">Subscribers:</p>
					<!-- <p id="weekly">weekly</p> -->
					<p id="number">568</p>
					<!-- <p id="viewDetails">View Details</p> -->
				</div>
			</div>

			<div class="cards_wrapper cards_quarterWidth" id="contactRequests">
				<div class="cards_content">
					<p id="title">Contact Requests:</p>
					<!-- <p id="weekly">weekly</p> -->
					<p id="number">{{contacts.length}}</p>
					<!-- <p id="viewDetails">View Details</p> -->
				</div>
			</div>

			<div class="cards_wrapper cards_quarterWidth" id="bookingRequests">
				<div class="cards_content">
					<p id="title">Booking Requests:</p>
					<!-- <p id="weekly">weekly</p> -->
					<p id="number">{{appointments.length}}</p>
					<!-- <p id="viewDetails">View Details</p> -->
				</div>
			</div>

			<div class="cards_wrapper cards_quarterWidth" id="quoteRequests">
				<div class="cards_content">
					<p id="title">Quote Requests:</p>
					<!-- <p id="weekly">weekly</p> -->
					<p id="number">{{quotes.length}}</p>
					<!-- <p id="viewDetails">View Details</p> -->
				</div>
			</div>
		<!-- </div> -->
		<div class="cards_wrapper cards_fullWidth" id="weeklyEarnings">
			<div class="cards_content">
			<p id="title">Weekly Earnings</p>

			</div>
			
		</div>
		
		<div class="cards_wrapper cards_halfWidth" id="enrollments">
			<div class="cards_content">
			<p id="title">Enrollments</p>
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Classes In Progress</p> -->
				<div class="headers">
					<p id="header1">Products:</p>
					<p id="header2"># of Students:</p>
				</div>
				<div class="data" *ngFor="let product of products;index as i;">
					<p>{{i+1}}. {{product.productName}} <br> <span>{{product.type}}</span></p>
					<p>{{product.numberOfEnrollments}}</p>
				</div>
			</div>
				<p id="viewDetails">View Details</p>
		</div>


		<div class="cards_wrapper cards_halfWidth" id="classes">
			<div class="cards_content">
			<p id="title">Classes</p>
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Classes In Progress</p> -->
				<div class="headers">
					<p id="header1">Class name:</p>
					<p id="header2">Tutor:</p>
					<p id="header3">Students:</p>
				</div>
				<div class="data" *ngFor="let class of classes; index as i;">
					<p>{{i+1}}. {{class.name}}</p>
					<p>{{class.instructorName}}</p>
					<p>{{class.date}}</p>
				</div>
			</div>
				<p id="viewDetails">View Details</p>
		</div>

		<div class="cards_wrapper cards_fullWidth" id="weeklyEarnings">
			<div class="cards_content">
			<p id="title">Invoices</p>
			<p id="viewDetails">View Details</p>

			</div>
			
		</div>
		<div class="cards_wrapper" id="messages">
			<div class="cards_content">
			<p id="title">Messages</p>
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p>No New Messages</p>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="calander">
			<div class="cards_content">
			<p id="title">Messages</p>
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p>No New Messages</p>
			</div>
		</div>

	</div>
</div>