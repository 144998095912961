
<book-an-appointment [style.display] = "globals.bookAppointmentWrapper_display"></book-an-appointment>

<section class="sect1_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/surviving-covid19/sect1.svg">
	</div>
	<div class="sect1_content">
		<div class="info">
			<h1>How Businesses <br> can survive COVID-19 crises</h1>
			<p>
				During the pandemic of COVID-19 most companies are 
				suffering from a massive crisis. The percussions people have 
				been taken to slow down the spread of COVID-19 could be 
				totally destructive for businesses. Since we cannot blame the 
				public for being cautious about their wellness. The only thing 
				we could do surviving our businesses is to make it digital. In 
				Cadres business solutions, we take you step by step through 
				the journey of digitalization
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/surviving-covid19/sect1.svg" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<p class="info">
			During the pandemic of COVID-19 most companies are suffering from massive crises. The percussions people take to slow down the spread of COVID-19 could be totally destructive for businesses. Since we cannot blame the public for being cautious about their wellness, businesses should adapt with the current situation through digitalising their functions. Cadres Business Solutions take you step by step through the journey of digitalisation. <span id="readMore" onclick="showMore('info_extra', 'block')">Read More</span>
		</p>
		<p class="info" id="info_extra">
			Cadres Business Solutions transforms all the business functions into a digital user-friendly interface experience. Manual financial systems are most likely going to waste your time organizing your paper in one place while recording the information might not be the hardest thing to do. However, manual auditing is quite challenging. Thus, using a digital financial system is pretty convenient for businesses.

			Moreover, we do keep records for customers’ data through high tech CRM systems that highly boost the “customer and supplier intimacy” concept. 

			Other than offering digital financial systems, Cadres Business Solutions also offers online Customer Relationship Management (CRM) systems.

			CRM systems aim to provide a cloud space for businesses to keep costumers details constantly updated. A CRM system could dramatically increase businesses value from customers’ perception. <span id="readLess" onclick="showMore('info_extra', 'none')">Read Less</span>

		</p>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<h1>A successful CRM should include the following key features</h1>
		<div class="info" id="info1">
			<div class="number">
				<p>1</p>
			</div>
			<p id="title">Contact management</p>
			<p>
				The information about customers 
				questions and conversations should be 
				accessible and instantly updated 
			</p>
		</div>
		<div class="info" id="info2">
			<div class="number">
				<p>2</p>
			</div>
			<p id="title">Lead management</p>
			<p>
				The system will help you making a 
				successful lead by tracking the pipeline 
				activities helping you making more 
				accurate targets
			</p>
		</div>
		<div class="info" id="info3">
			<div class="number">
				<p>3</p>
			</div>
			<p id="title">Quick interactions</p>
			<p>
				Quick interactions between employees 
				through texting
			</p>
		</div>
		<div class="info" id="info4">
			<div class="number">
				<p>4</p>
			</div>
			<p id="title">Sharing information</p>
			<p>
				A CRM system makes it much easier 
				for employees to share Information with 
				one another
			</p>
		</div>
		<div class="info" id="info5">
			<div class="number">
				<p>5</p>
			</div>
			<p id="title">Email tracking</p>
			<p>
				Email tracking could drastically increase 
				the company successful leads. 
			</p>
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="bg">
			<img src="../../assets/backgrounds/surviving-covid19/sect4.svg">
		</div>
		<p class="info">
			In a nutshell, CRM purpose is to make a value worth experience 
			with customers which aims to increase the overall profitability 
		</p>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="bg"></div>
	<div class="sect5_content">
		<p class="info">
			Furthermore, Enterprise Resource Planning (ERP) system is one big step into the digitalisation journey.  Enterprise Resource Planning (ERP) is designed to improve the productivity of your business, increasing visibility, streamlining, reducing overhead and costs
		</p>
	</div>
</section>

<section class="sect6_wrapper">
	<div class="sect6_content">
		<h1>There are many purposes for a perfectly utilised ERP system</h1>
		<div class="purposes_list">
			<div class="left">
				<div class="purposes" id="purpose1">
					<div class="number">
						<p>1</p>
					</div>
					<p>
						Making sure your team is doing
						their job efficiently by tracking their processes
					</p>
				</div>
				<div class="purposes" id="purpose2">
					<div class="number">
						<p>2</p>
					</div>
					<p>
						Sending alerts to different departments when conflicts elsewhere in the company might affect them 
					</p>
				</div>
				<div class="purposes" id="purpose3">
					<div class="number">
						<p>3</p>
					</div>
					<p>
						Developing strategic plans based on the enterprise wide knowledge 
					</p>
				</div>
				<div class="purposes" id="purpose4">
					<div class="number">
						<p>4</p>
					</div>
					<p>
						Processing orders, updating 
						accounts, and triggering alerts 
					</p>
				</div>
			</div>
			<div class="right">
				<div class="purposes" id="purpose5">
					<div class="number">
						<p>5</p>
					</div>
					<p>
						Managing manufacturing
						and supply chains 
					</p>
				</div>
				<div class="purposes" id="purpose6">
					<div class="number">
						<p>6</p>
					</div>
					<p>
						Tracking recruitment and hiring
					</p>
				</div>
				<div class="purposes" id="purpose7">
					<div class="number">
						<p>7</p>
					</div>
					<p>
					  Manging payroll, benefits, and personal information
					</p>
				</div>
			</div>
		</div>
		<button (click)="show('show')">
			Book an Appointment Now
		</button>
	</div>
</section>