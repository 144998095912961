

<section class="forms_wrapper">
	<div class="forms_content" >
		<div class="form">
			<div class="editor_parent">
				<label for="editor">
					<p id="title">Title</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '5vw'}"  [(ngModel)]="getEditVacancy.value.title"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Add Job Description:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}"  [(ngModel)]="getEditVacancy.value.description"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Add Job Summary:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}"  [(ngModel)]="getEditVacancy.value.summary"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Add Job Specifications:</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}"  [(ngModel)]="getEditVacancy.value.specification"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Add Interpersonal skills</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}"  [(ngModel)]="getEditVacancy.value.interpersonalSkills"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
			</div>

			<div class="editor_parent">
				<label for="editor">
					<p id="title">Add Benefits</p>
				</label>	
				<div class="editor">	
					<quill-editor [styles]="{height: '20vw'}"  [(ngModel)]="getEditVacancy.value.benefits"></quill-editor>
				</div>	
				<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
				<button (click)="editVacancy()">Edit</button>
			</div>

			
		</div>
	</div>
</section>
