


<div [style.color] = "color" [style.backgroundColor] = "backgroundColor" class="siteMap_wrapper siteMap_wrapper_desktop">
	<div class="siteMap_contents">
		<div class="sect1">
			<a [style.color] = "color" href="">Cadres School of Business</a>
			<br>
			<br>
			<a [style.color] = "color" href="">Business Solutions</a>
			<br>
			<br>
			<a [style.color] = "color" href="">Careers</a>
			<br>
			<br>
			<a [style.color] = "color" href="">News</a>
			<br>
			<br>
			<a [style.color] = "color" href="">About</a>
			<br>
			<br>
			<a [style.color] = "color" href="">Contact Us</a>
			<br>
			<br>
			<a [style.color] = "color" href="">FAQs</a>
			<br>
			<br>
			<a [style.color] = "color" routerLink="/privacy-and-policy">Privacy and Policy</a>
			<br>
			<br>
			<a [style.color] = "color" routerLink="/terms-and-conditions">Terms and Conditions</a>
		</div>
		<div class="sect2">
			<p>Email:</p>
			<p>info@mascof.com</p>

			<p>Mobile:</p>
			<p>+201094988474 / +201090773522</p>

			<p>Phone:</p>
			<p>+0224473000 / +0224474555</p>

			<p>Address:</p>
			<p>3065, 3rd Neighborhood, El-Mearag City,</p>
			<p>New Maadi Behind Maadi City Centre</p>

			<p>Loction:</p>
			<!-- <img src="../../assets/"> -->
		</div>

		<div class="sect3">
			<p>Folllow Us:</p>
			<a href="https://www.instagram.com/cadreseg/?hl=en"><img src="../../assets/icons/instagram.png"></a>
			<a href="https://www.facebook.com/CadresEG/"><img style="margin-left: 5%;" src="../../assets/icons/facebook.png"></a>
			<a href="https://twitter.com/cadreseg"><img style="margin-left: 5%;" src="../../assets/icons/twitter.png"></a>
			<a href="https://eg.linkedin.com/company/cadreseg"><img style="margin-left: 5%;" src="../../assets/icons/linkedIn.svg"></a>
		</div>

		<div class="sect4">
			<p>Subscribe to out news letter</p>
			<p>The latest News, Promotions, and Updates sent straight to your inbox every month</p>
			<div class="subscribe">
				<div class="txt_wrapper"><input type="text" placeholder="example@gmail.com"></div>
				<div class="btn_wrapper"><input type="submit" name="" value="Subscribe"></div>
			</div>
		</div>
	</div>
</div>

<div class="siteMap_wrapper siteMap_wrapper_mobile">
	<div class="siteMap_contents">
		<div class="sect1">
			<a href="school-of-business.html">Cadres School of Business</a>
			<br>
			<br>
			<a href="business-solutions.html">Business Solutions</a>
			<br>
			<br>
			<a href="careers.html">Careers</a>
			<br>
			<br>
			<a href="news-and-events.html">News</a>
			<br>
			<br>
			<a href="about.html">About</a>
			<br>
			<br>
			<a href="contact.html">Contact Us</a>
			<br>
			<br>
			<a href="503.html">FAQs</a>
			<br>
			<br>
			<a routerLink="/privacy-and-policy">Privacy and Policy</a>
			<br>
			<br>
			<a routerLink="/terms-and-conditions">Terms and Conditions</a>
		</div>
		<div class="sect2">
			<p>Email:</p>
			<p>info@mascof.com</p>

			<p>Mobile:</p>
			<p>+201094988474 / +201090773522</p>

			<p>Phone:</p>
			<p>+0224473000 / +0224474555</p>

			<p>Address:</p>
			<p>3065, 3rd Neighborhood, El-Mearag City,</p>
			<p>New Maadi Behind Maadi City Centre</p>

			<p>Loction:</p>
			<img src="../../assets/">
		</div>
		<div class="sect4">
			<p>Subscribe to out news letter</p>
			<p>The latest News, Promotions, and Updates sent straight to your inbox every month</p>
			<div class="subscribe">
				<div class="txt_wrapper"><input type="text" placeholder="example@gmail.com"></div>
				<div class="btn_wrapper"><input type="submit" name="" value="Subscribe"></div>
			</div>
		</div>
		<div class="sect3">
			<p>Folllow Us:</p>
			<a href="https://www.instagram.com/cadreseg/?hl=en"><img id="img1" src="../../assets/icons/instagram.png"></a>
			<a href="https://www.facebook.com/CadresEG/"><img src="../../assets/icons/facebook.png"></a>
			<a href="https://twitter.com/cadreseg"><img src="../../assets/icons/twitter.png"></a>
			<a href="https://eg.linkedin.com/company/cadreseg"><img src="../../assets/icons/linkedIn.svg"></a>
		</div>
	</div>
	<div class="footer">
	<hr>
		<p>&copy; Cadres 2020</p>
		<p>Created By MascoF Design And Development</p>
	</div>

</div>