

<section class="forms_wrapper">
	<div class="forms_content" >
		<div *ngFor="let section of sections" class="form" id="{{section.pageName}}{{section.section_pk}}" [style.width.%]="sectionWidth[section.sectionSize]" >
			<div>
				<label for="editor">
					<p id="title">{{section.title}}</p>
				</label>	
				<div class="editor" *ngIf="section.text.length > 0">	
					<quill-editor *ngIf="section.sectionSize == 'large'" [styles]="{height: '20vw'}" (onContentChanged)="contentChange($event, section.characterLimit)" [(ngModel)]="section.text"></quill-editor>
					<quill-editor *ngIf="section.sectionSize == 'small'" [styles]="{height: '10vw'}" (onContentChanged)="contentChange($event, section.characterLimit)" [(ngModel)]="section.text"></quill-editor>
				</div>	
				<span *ngIf="textLength > 0"> {{textLength}}</span>
				<span *ngIf="textLength == 0"> {{section.text.length}}</span>
				<div *ngIf="section.imagePath.length > 0" class="uploadFile">
					<legend>Add Image</legend>
					<div>
						<label for="ewrfg">
						<div class="file_img"> 
							<img src="../../assets/icons/uploadFile.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event)" id="ewrfg" type="file" name="sectionImg"/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div>
				<button (click)="editPage(section.section_pk, section.assetsFolder)">Edit</button>
			</div>
		</div>
	</div>
	<div class="filter">
		<div class="statusToggle">
			<div>
			  <p id="title">Status</p>
			</div>
			<div>
			   <p>Active</p>
				<label class="switch">
				  <input type="checkbox" checked>
				  <span class="slider round"></span>
				</label>
			</div>
			<div id="btn">
				<button>Update</button>
			</div>
		</div>
		<div class="sections" *ngFor="let section of sections" (click)="scrollTo(section.pageName+section.section_pk)">
			<p>Section {{section.sectionNumber}}</p>
		</div>
	</div>
</section>


<!-- <form>
	<quill-view-html [content]="text"></quill-view-html>
</form>
 -->