import { Component, OnInit, Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'lcci-sign-in',
  templateUrl: './lcci-sign-in.component.html',
  styleUrls: ['./lcci-sign-in.component.css']
})
@Injectable()
export class LcciSignInComponent implements OnInit {

  constructor(private api:ApiService, private router: Router, public globals: Globals) { }

  ngOnInit() {    
    // this.api.setLogged(true, "3", "instructor");
  }

  dashBoardDiclaimer_display = "none";

    signInStatus:boolean;

  
signUp_student=new FormGroup({
  firstName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  middleName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  lastName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.email]),
  password:new FormControl('',[Validators.required ,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)])

});


  signIn=new FormGroup({
    email:new FormControl('',Validators.required),
    password:new FormControl('',Validators.required)
  });

  checkBox:boolean = false;
  fromData_student(){
    if(this.checkBox) {
      this.api.signUp_student(this.signUp_student.value).subscribe(response=>{
        if(response['message'] == 'post created') {
      
          this.api.sendVerificationEmail(this.signUp_student.value.email).subscribe(response=>{
              console.log(response);
          }); 
         this.dashBoardDiclaimer_display = "block";  
        }else if(response['message'] == 'email already used') {
          alert("Account Already Exists");
        }else if(response['message'] == 'post failed') {
          alert("Sign Up failed. Please try again later");
        }
      });
    }
}






  log(){
    // console.log(this.signIn.value);
    this.api.signIn(this.signIn.value.email, this.signIn.value.password).subscribe(response=>{
      
      this.signInStatus = response.userPassword_status;

      if(response.userPassword_status){
        // this.router.navigate(['student-dashboard']);
        this.api.setLogged(true, response.user_pk, response.user_type);
        console.log(this.api.loggedIn_user());
        this.globals.lcciPlanWrapper_display = "flex";
        this.showHideSignWrapper("none");

      }
      console.log(response);
    });
  }

  showHideSignWrapper(display){
    this.globals.signWrapperLcci_display = display;
  }

  switchCard(signCard){
    if(signCard == "signIn") {
      this.globals.signInCardLcci_display = "block";
      this.globals.signUpCardLcci_display = "none";
     this.dashBoardDiclaimer_display = "none";
    }else if(signCard == "signUp") {
      this.globals.signUpCardLcci_display = "block";
      this.globals.signInCardLcci_display = "none";
    }
  }

  buttonState() {
    this.checkBox = !this.checkBox;
  }

}
