


<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro">
			<div class="cards_content">
				<div class="user_icon"><img src="../../assets/icons/user_pp.svg" alt=""></div>
				<p>Welcom Back {{instructor.firstName | titlecase}} </p>
			</div>
		</div>
		<div class="courses">
			<div class="course" *ngFor="let course of courses; index as i" id="course{{i+1}}" routerLink="/instructor-dashboard/course/{{course.courseName}}/{{course.round_pk}}">	
				<p id="title">{{course.courseName | truncateText}}</p>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="nextClass">
			<p id="title">Next Class</p>
			<div class="cards_content">
			<!-- 	<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration1.svg" alt="">
				</div>
				<p>No Registered Courses</p> -->
				<div class="data">	
					<p id="data1">{{classes[0].courseName}}</p>
					<p id="data2">{{classes[0].name}}</p>
					<p id="data3">{{classes[0].time}}</p>
					<p id="data4">{{classes[0].date}}</p>
					<a id="data5" href="{{classes[0].zoomLink}}">link</a>
				</div>
				<hr>
			</div>
			
		</div>
		
		<div class="cards_wrapper cards_halfWidth" id="classes">
			<p id="title">Classes</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Classes In Progress</p> -->
				<div class="headers">
					<p id="header1">Course name:</p>
					<p id="header2">Class:</p>
					<p id="header3">Time:</p>
					<p id="header4">Date:</p>
				</div>
				<div class="data" *ngFor="let class of classes; index as i">	
					<p>{{i+1}}. {{class.courseName | truncateText}}</p>
					<p>{{class.name}}</p>
					<p>{{class.time}}</p>
					<p>{{class.date}}</p>
					<a href="{{class.zoomLink}}">Start</a>

				</div>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="messages">
			<p id="title">Messages</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="messagesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p [style.display]="messagesPlaceHolder_display">No New Messages</p>
			</div>
			<div class="data">
				<div class="message"  *ngFor="let message of messages" routerLink="./message/{{message.message_pk}}">
					<div class="img"><img src="../../assets/icons/logo_orange.svg"></div>
					<p id="message"><span id="sender">From Cadres</span> <br> {{message.content | removeHtmlTags | slice:0:74}}<span *ngIf="message.content.length > 74">...</span></p>
					<div class="delete" (click)="deleteMessage(message.message_pk)">
						<img src="../../assets/icons/trash_black.png">
					</div>
				</div>
				<p id="noOtherMessages">No other messages.</p>
			</div>
		</div>

	</div>
</div>