import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

  dashboardThemes_cards:any = {
                        "default": {
                                    'background-color': '#F6F6FF',
                                    'color': '#050505' 
                                    },
                        "dark": {
                                    'background-color': '#242526',
                                    'color': '#E4E6EB' 
                                    },
                        "light": {
                                    'background-color': '#FFFFFF',
                                    'color': '#050505' 
                                    }
                    };

  dashboardThemes_navBar:any = {
                      "default": {
                                  'background-color': '#1E243E',
                                  'color': '#F7F7FF' 
                                  },
                      "dark": {
                                  'background-color': '#242526',
                                  'color': '#E4E6EB' 
                                  },
                      "light": {
                                  'background-color': '#FFFFFF',
                                  'color': '#050505' 
                                  }
                  };

  dashboardThemes_body:any = {
                      "default": {
                                  'background-color': '#494D64',
                                  },
                      "dark": {
                                  'background-color': '#18191A'
                                  },
                      "light": {
                                  'background-color': '#F0F2F5'
                                  }
                  };

  dashboardTheme_cards:any = this.dashboardThemes_cards[localStorage.getItem('theme')];
  dashboardTheme_navBar:any = this.dashboardThemes_navBar[localStorage.getItem('theme')];
  dashboardTheme_body:any = this.dashboardThemes_body[localStorage.getItem('theme')];
  sections:any;

  signWrapper_display:string = 'none';
  signInCard_display:string = 'none';
  signUpCard_display:string = 'none';

  signWrapperLcci_display:string = 'none';
  signInCardLcci_display:string = 'block';
  signUpCardLcci_display:string = 'none';

  applyInstructor_display:string = "none";

  bookAppointmentWrapper_display:string = "none";

  getQuoteWrapper_display:string = "none";


  lcciPlanWrapper_display:string = 'none';
  productChosen_round_pk:string = '';
  productChosen_course_pk:string = '';
  productChosen_name:string = '';
  productChosen_learningHours:string = '';
  productChosen_level:string = '';
  productChosen_suite:string = '';
  productChosen_price: any = '';
  productChosen_registrationFees:string = '';
  
  gatewayWrapper_display:string = 'none';
  
  addAssignmentWrapper_display:string = 'none';
  addAssignmentCourseFk_value:string = '';

  editAssignmentWrapper_display:string = 'none';
  editAssignmentCourseFk_value:string = '';
  editAssignmentPk_value:string = '';
  editAssignmentName_value:string = '';
  editAssignmentDateDue_value:string = '';


  createClassWrapper_display:string = 'none';
  createClassRoundFk_value:string = '';
  createClasesdiplomaCourseInstructor_fk:string = '';
  createClassFk_value:string = '';
  createClassRoundType_value:string = '';
  createClassRoundDiplomaCourses_value:any = [];

  editClassWrapper_display:string = 'none';
  editClassCourseFk_value:string = '';
  editClassFk_value:string = '';
  editClassName_value:string = '';
  editClassZoomLink_value:string = '';
  editClassDescription_value:string = '';
  editClassDate_value:string = '';
  editClassTime_value:string = '';

  addMaterialWrapper_display:string = 'none';
  addMaterialCourseFk_value:string = '';

  editMaterialWrapper_display:string = 'none';
  editMaterialCourseFk_value:string = '';
  editMaterialClassFk_value:string = '';
  editMaterialPk_value:string = '';
  editMaterialName_value:string = '';

  addQuizWrapper_display:string = 'none';
  addQuizCourseFk_value:string = '';

  editQuizWrapper_display:string = 'none';
  editQuizCourseFk_value:string = '';
  editQuizPk_value:string = '';
  editQuizName_value:string = '';
  editQuizDateDue_value:string = '';
  editQuizTimeDue_value:string = '';


  editExamWrapper_display:string = "none";
  editExamPk_value:string = '';
  editExamName_value:string = '';
  editExamDate_value:string = '';
  editExamTime_value:string = '';
  editExamLocation_value:string = '';

  addExamWrapper_display:string = "none";
  addRoundFk_value:string = '';

  diplomaRoundDiplomaFk_value:string = '';
  diplomaRoundName_value:string = '';
  diplomaRoundMaxCapacity_value:string = '';
  diplomaRoundStartDate_value:string = '';





  vacancyPk_value:string;
  
}