<div class="navBar_wrapper">
	<div class="navBar_content">
		<a id="dashBoard" routerLink="/admin-dashboard">Dashboard</a>
		<a id="tests" routerLink="/admin-dashboard/tests">Tests</a>
		<a id="requests" routerLink="/admin-dashboard/requests">Requests</a>
		<a id="vacancies" routerLink="/admin-dashboard/vacancies">Vacancies</a>
		<a id="invoices" routerLink="/admin-dashboard/invoices">Invoices</a>
		<a id="messages" routerLink="/admin-dashboard/messages">Messages</a>
		<a id="news-and-events" routerLink="/admin-dashboard/news-and-events">News And Events</a>
		<a id="students" routerLink="/admin-dashboard/students">Students</a>
		<a id="instructors" routerLink="/admin-dashboard/instructors">Instructors</a>
		<a id="testimonials" routerLink="/admin-dashboard/testimonials">Testimonials</a>
		<a id="products" routerLink="/admin-dashboard/products">Products</a>
		<a id="pages" routerLink="/admin-dashboard/pages">Pages</a>
		<a id="signOut" href="">Sign Out</a>
	</div>
</div>