import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'instructor-add-quiz',
  templateUrl: './instructor-add-quiz.component.html',
  styleUrls: ['./instructor-add-quiz.component.css']
})
export class InstructorAddQuizComponent implements OnInit {

	  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	form: FormGroup;
	uploadResponse:any;

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
    // this.apiService.setLogged(true, "3", "instructor");
  	this.form = this.formBuilder.group({
      quiz: ['']
    });
  }

    getAddQuiz=new FormGroup({
  quizName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  timeDue:new FormControl('',[Validators.required ,Validators.email]),
  dateDue:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)])

});



  onFileSelect(event, fileAttrName) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];     
      this.form.get(fileAttrName).setValue(file);
    }
  }

  onSubmit() {
    const formDataQuiz = new FormData();
    formDataQuiz.append('quiz', this.form.get('quiz').value);
    console.log("formdata");
    
    console.log(this.form);

    this.apiService.uploadQuiz(this.globals.addQuizCourseFk_value, formDataQuiz, this.getAddQuiz.value).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }


   hideAddQuizPopUp(){
  		this.globals.addQuizWrapper_display = "none";
	}


}
