
<section class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content" [ngStyle]="globals.dashboardTheme_cards">
		<div class="header">
			<div class="img">
				<img src="../../assets/icons/logo_orange.svg">
			</div>
			<div class="details" [ngStyle]="globals.dashboardTheme_cards">
				<p id="sender">Message from Cadres</p>
				<p id="date">{{message.createdAt}}</p>
			</div>
			<div class="delete" (click)="deleteMessage()">
				<img src="../../assets/icons/trash_black.png">
			</div>
		</div>
		<div class="content">
			<quill-view-html [content]="message.content"></quill-view-html>
		</div>
	</div>
</section>