import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardService } from './guard.service';
import { AdminGuardService } from './admin-guard.service';


import { HomeComponent } from './home/home.component';
import { SchoolOfBusinessComponent } from './school-of-business/school-of-business.component';
import { BusinessSolutionsComponent } from './business-solutions/business-solutions.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { NewsComponent } from './news/news.component';
import { CareersComponent } from './careers/careers.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { VacancyComponent } from './vacancy/vacancy.component'
import { LcciComponent } from './lcci/lcci.component';
import { DiplomasCoursesComponent } from './diplomas-courses/diplomas-courses.component';
import { DiplomaDetailsComponent } from './diploma-details/diploma-details.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CadresEnglishComponent } from './cadres-english/cadres-english.component';
import { VersentTestComponent } from './versent-test/versent-test.component';
import { PearsonTestComponent } from './pearson-test/pearson-test.component';
import { GeneralEnglishComponent } from './general-english/general-english.component';
import { BusinessEnglishComponent } from './business-english/business-english.component';
import { BusinessConsultationComponent } from './business-consultation/business-consultation.component';
import { UtilisingOurPlatformComponent } from './utilising-our-platform/utilising-our-platform.component';
import { CadresSupportStartupsComponent } from './cadres-support-startups/cadres-support-startups.component';
import { SurvivingCovid19Component } from './surviving-covid19/surviving-covid19.component';
import { CorprateTrainingComponent } from './corprate-training/corprate-training.component';
import { TrainingAssessmentComponent } from './training-assessment/training-assessment.component';
import { MarketingComponent } from './marketing/marketing.component';
import { BussinessAdministrationComponent } from './bussiness-administration/bussiness-administration.component';
import { AccountingAndFinancingComponent } from './accounting-and-financing/accounting-and-financing.component';
import { RecruitmentComponent } from './recruitment/recruitment.component';
import { WatsonGlaserComponent } from './watson-glaser/watson-glaser.component';
import { IrisTestComponent } from './iris-test/iris-test.component';
import { DifferentialTestComponent } from './differential-test/differential-test.component';
import { SosieGenerationComponent } from './sosie-generation/sosie-generation.component';
import { BusinessSolutionsVersentTestComponent } from './business-solutions-versent-test/business-solutions-versent-test.component';
import { EventManagementComponent } from './event-management/event-management.component';
import { ConferencesComponent } from './conferences/conferences.component';
import { SummitsComponent } from './summits/summits.component';
import { WebinarsAndEconferencesComponent } from './webinars-and-econferences/webinars-and-econferences.component';
import { ConcertsComponent } from './concerts/concerts.component';
import { MarketingAndAdvertisementComponent } from './marketing-and-advertisement/marketing-and-advertisement.component';
import { MarketResearchComponent } from './market-research/market-research.component';
import { WebsitesAndApplicationsComponent } from './websites-and-applications/websites-and-applications.component';
import { MediaAndContentProductionComponent } from './media-and-content-production/media-and-content-production.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';


import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';


import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { StudentCoursesComponent } from './student-courses/student-courses.component';
import { StudentClassesComponent } from './student-classes/student-classes.component';
import { StudentAssignmentsComponent } from './student-assignments/student-assignments.component';
import { StudentExamsComponent } from './student-exams/student-exams.component';
import { StudentQuizesComponent } from './student-quizes/student-quizes.component';
import { StudentGradesComponent } from './student-grades/student-grades.component';
import { StudentCertificatesComponent } from './student-certificates/student-certificates.component';
import { StudentPaymentsComponent } from './student-payments/student-payments.component';
import { StudentCourseComponent } from './student-course/student-course.component';
import { StudentClassComponent } from './student-class/student-class.component';
import { StudentAssignmentComponent } from './student-assignment/student-assignment.component';
import { StudentEditProfileComponent } from './student-edit-profile/student-edit-profile.component';
import { StudentMessageComponent } from './student-message/student-message.component';


import { InstructorDashboardComponent } from './instructor-dashboard/instructor-dashboard.component';
import { InstructorStudentsComponent } from './instructor-students/instructor-students.component';
import { InstructorStudentComponent } from './instructor-student/instructor-student.component';
import { InstructorCourseComponent } from './instructor-course/instructor-course.component';
import { InstructorClassesComponent } from './instructor-classes/instructor-classes.component';
import { InstructorClassComponent } from './instructor-class/instructor-class.component';
import { InstructorEditProfileComponent } from './instructor-edit-profile/instructor-edit-profile.component';
import { InstructorMessageComponent } from './instructor-message/instructor-message.component';

 
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminProductComponent } from './admin-product/admin-product.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminAddCourseComponent } from './admin-add-course/admin-add-course.component';
import { AdminAddDiplomaComponent } from './admin-add-diploma/admin-add-diploma.component';
import { AdminStudentsComponent } from './admin-students/admin-students.component';
import { AdminInstructorsComponent } from './admin-instructors/admin-instructors.component';
import { AdminVacanciesComponent } from './admin-vacancies/admin-vacancies.component';
import { AdminRequestsComponent } from './admin-requests/admin-requests.component';
import { AdminInstructorComponent } from './admin-instructor/admin-instructor.component';
import { AdminStudentComponent } from './admin-student/admin-student.component';
import { AdminInProgressProductsComponent } from './admin-in-progress-products/admin-in-progress-products.component';
import { AdminProductClassesComponent } from './admin-product-classes/admin-product-classes.component';
import { AdminEnrollmentsComponent } from './admin-enrollments/admin-enrollments.component';
import { AdminProductStudentsComponent } from './admin-product-students/admin-product-students.component';
import { AdminPagesComponent } from './admin-pages/admin-pages.component';
import { AdminRequestComponent } from './admin-request/admin-request.component';
import { AdminNewsAndEventsComponent } from './admin-news-and-events/admin-news-and-events.component';
import { AdminNewsComponent } from './admin-news/admin-news.component';
import { AdminEventComponent } from './admin-event/admin-event.component';
import { AdminAddEventComponent } from './admin-add-event/admin-add-event.component';
import { AdminAddNewsComponent } from './admin-add-news/admin-add-news.component';
import { AdminEditNewsComponent } from './admin-edit-news/admin-edit-news.component';
import { AdminEditEventComponent } from './admin-edit-event/admin-edit-event.component';
import { AdminTestimonialsComponent } from './admin-testimonials/admin-testimonials.component';
import { AdminAddTestimonialComponent } from './admin-add-testimonial/admin-add-testimonial.component';
import { AdminEditTestimonialComponent } from './admin-edit-testimonial/admin-edit-testimonial.component';
import { AdminEditPageComponent } from './admin-edit-page/admin-edit-page.component';
import { AdminAddVacancyComponent } from './admin-add-vacancy/admin-add-vacancy.component';
import { AdminEditVacancyComponent } from './admin-edit-vacancy/admin-edit-vacancy.component';
import { AdminAddProductComponent } from './admin-add-product/admin-add-product.component';
import { AdminNewMessageComponent } from './admin-new-message/admin-new-message.component';
import { AdminMessagesComponent } from './admin-messages/admin-messages.component';
import { AdminAddCourseRoundComponent } from './admin-add-course-round/admin-add-course-round.component';
import { AdminEditCourseRoundComponent } from './admin-edit-course-round/admin-edit-course-round.component';
import { AdminUnusedCodesComponent } from './admin-unused-codes/admin-unused-codes.component';
import { AdminTestsComponent } from './admin-tests/admin-tests.component';
import { AdminSignInComponent } from './admin-sign-in/admin-sign-in.component';
import { AdminAddDiplomaRoundComponent } from './admin-add-diploma-round/admin-add-diploma-round.component';
import { AdminEditDiplomaRoundComponent } from './admin-edit-diploma-round/admin-edit-diploma-round.component';
import { AdminAddDiplomaRoundInstructorsComponent } from './admin-add-diploma-round-instructors/admin-add-diploma-round-instructors.component';

const routes: Routes = [
	{path: '', component: HomeComponent},
	{path: 'home', component: HomeComponent},
	{path: 'school-of-business', component: SchoolOfBusinessComponent},
	{path: 'cadres/school-of-business', component: SchoolOfBusinessComponent},
	{path: 'business-solutions', component: BusinessSolutionsComponent},
	{path: 'careers', component: CareersComponent},
	{path: 'news-and-events', component: NewsAndEventsComponent},
	{path: 'news-and-events/news/:id', component: NewsComponent},
	{path: 'contact', component: ContactComponent},
	{path: 'about', component: AboutComponent},
	{path: 'privacy-and-policy', component: PrivacyAndPolicyComponent},
	{path: 'terms-and-conditions', component: TermsAndConditionsComponent},
	{path: 'underMaintenance', component: UnderMaintenanceComponent},
	// {path: '**', component: UnderMaintenanceComponent},
	{path: 'school-of-business/lcci', component: LcciComponent},
	{path: 'school-of-business/lcci/diplomas&courses', component: DiplomasCoursesComponent},
	{path: 'school-of-business/lcci/diplomas&courses/diploma-details/:diplomaName/:roundId', component: DiplomaDetailsComponent},
	{path: 'school-of-business/lcci/diplomas&courses/course-details/:courseName/:roundId', component: CourseDetailsComponent},
	{path: 'school-of-business/cadres-english', component: CadresEnglishComponent},
	{path: 'school-of-business/cadres-english/versent-test', component: VersentTestComponent},
	{path: 'school-of-business/cadres-english/pearson-test', component: PearsonTestComponent},
	{path: 'school-of-business/cadres-english/general-english', component: GeneralEnglishComponent},
	{path: 'school-of-business/cadres-english/business-english', component: BusinessEnglishComponent},
	{path: 'business-solutions/business-consultation', component: BusinessConsultationComponent},
	{path: 'business-solutions/business-consultation/utilising-our-platform', component: UtilisingOurPlatformComponent},
	{path: 'business-solutions/business-consultation/cadres-support-startups', component: CadresSupportStartupsComponent},
	{path: 'business-solutions/business-consultation/surviving-covid19', component: SurvivingCovid19Component},
	{path: 'business-solutions/corprate-training', component: CorprateTrainingComponent},
	{path: 'business-solutions/corprate-training/training-assessment', component: TrainingAssessmentComponent},
	{path: 'business-solutions/corprate-training/marketing', component: MarketingComponent},
	{path: 'business-solutions/corprate-training/business-administration', component: BussinessAdministrationComponent},
	{path: 'business-solutions/corprate-training/accounting-and-financing', component: AccountingAndFinancingComponent},
	{path: 'business-solutions/recruitment', component: RecruitmentComponent},
	{path: 'business-solutions/recruitment/watson-glaser', component: WatsonGlaserComponent},
	{path: 'business-solutions/recruitment/iris-test', component: IrisTestComponent},
	{path: 'business-solutions/recruitment/differential-test', component: DifferentialTestComponent},
	{path: 'business-solutions/recruitment/sosie-generation', component: SosieGenerationComponent},
	{path: 'business-solutions/recruitment/versent-test', component: BusinessSolutionsVersentTestComponent},
	{path: 'business-solutions/event-management', component: EventManagementComponent},
	{path: 'business-solutions/event-management/conferences', component: ConferencesComponent},
	{path: 'business-solutions/event-management/summits', component: SummitsComponent},
	{path: 'business-solutions/event-management/webinars-and-econferences', component: WebinarsAndEconferencesComponent},
	{path: 'business-solutions/event-management/concerts', component: ConcertsComponent},
	{path: 'business-solutions/marketing-and-advertisement', component: MarketingAndAdvertisementComponent},
	{path: 'business-solutions/marketing-and-advertisement/market-research', component: MarketResearchComponent},
	{path: 'business-solutions/marketing-and-advertisement/websites-and-applications', component: WebsitesAndApplicationsComponent},
	{path: 'business-solutions/marketing-and-advertisement/media-and-content-production', component: MediaAndContentProductionComponent},
	{path: 'business-solutions/marketing-and-advertisement/digital-marketing', component: DigitalMarketingComponent},
	{path: 'careers/vacancy/:id', component: VacancyComponent},


	{path: 'verifyEmail/:key', component: VerifyEmailComponent},



	{path: 'student-dashboard', component: StudentDashboardComponent, canActivate: [GuardService]},
	{path: 'student-dashboard/courses', component: StudentCoursesComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/classes', component: StudentClassesComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/assignments', component: StudentAssignmentsComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/exams', component: StudentExamsComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/quizes', component: StudentQuizesComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/grades', component: StudentGradesComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/certificates', component: StudentCertificatesComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/payments', component: StudentPaymentsComponent, canActivate: [GuardService]},
    {path: 'student-dashboard/course/:courseName', component: StudentCourseComponent, canActivate: [GuardService]},
	{path: 'student-dashboard/class/:courseName', component: StudentClassComponent, canActivate: [GuardService]},
	{path: 'student-dashboard/assignment/:courseName', component: StudentAssignmentComponent, canActivate: [GuardService]},
	{path: 'student-dashboard/edit-profile', component: StudentEditProfileComponent, canActivate: [GuardService]},
	{path: 'student-dashboard/message/:id', component: StudentMessageComponent},

	{path: 'instructor-dashboard', component: InstructorDashboardComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/students', component: InstructorStudentsComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/student/:studentName', component: InstructorStudentComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/course/:courseName/:round_fk', component: InstructorCourseComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/classes', component: InstructorClassesComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/course-classes/:courseName', component: InstructorClassComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/edit-profile', component: InstructorEditProfileComponent, canActivate: [GuardService]},
	{path: 'instructor-dashboard/message/:id', component: InstructorMessageComponent},

	{path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AdminGuardService]},
	{path: 'admin-dashboard/sign-in', component:  AdminSignInComponent},
	{path: 'admin-dashboard/product', component: AdminProductComponent},
	{path: 'admin-dashboard/products', component: AdminProductsComponent},
	{path: 'admin-dashboard/add-course', component: AdminAddCourseComponent},
	{path: 'admin-dashboard/add-diploma', component: AdminAddDiplomaComponent},
	{path: 'admin-dashboard/students', component: AdminStudentsComponent},
	{path: 'admin-dashboard/instructors', component: AdminInstructorsComponent},
	{path: 'admin-dashboard/vacancies', component: AdminVacanciesComponent},
	{path: 'admin-dashboard/requests', component: AdminRequestsComponent},
    {path: 'admin-dashboard/request/:type/:id', component: AdminRequestComponent},
	{path: 'admin-dashboard/instructor/:id', component: AdminInstructorComponent},
	{path: 'admin-dashboard/student/:id', component: AdminStudentComponent},
	{path: 'admin-dashboard/in-progress-products', component: AdminInProgressProductsComponent},
	{path: 'admin-dashboard/in-progress-products/product-classes/:productType/:productName', component: AdminProductClassesComponent},
	{path: 'admin-dashboard/enrollments', component: AdminEnrollmentsComponent},
	{path: 'admin-dashboard/enrollments/students/:productType/:productName', component: AdminProductStudentsComponent},
	{path: 'admin-dashboard/pages', component: AdminPagesComponent},
	{path: 'admin-dashboard/news-and-events', component: AdminNewsAndEventsComponent},
	{path: 'admin-dashboard/news-and-events/news', component: AdminNewsComponent},
	{path: 'admin-dashboard/news-and-events/event', component: AdminEventComponent},
	{path: 'admin-dashboard/news-and-events/add-events', component: AdminAddEventComponent},
	{path: 'admin-dashboard/news-and-events/add-news', component: AdminAddNewsComponent},
	{path: 'admin-dashboard/news-and-events/edit-news/:id', component: AdminEditNewsComponent},
	{path: 'admin-dashboard/news-and-events/edit-events/:id', component: AdminEditEventComponent},
	{path: 'admin-dashboard/testimonials', component: AdminTestimonialsComponent},
	{path: 'admin-dashboard/testimonials/add-testimonial', component: AdminAddTestimonialComponent},
	{path: 'admin-dashboard/testimonials/edit-testimonial/:id', component: AdminEditTestimonialComponent},
	{path: 'admin-dashboard/pages/edit-page/:id', component: AdminEditPageComponent},
	{path: 'admin-dashboard/vacancies/add-vacancy', component: AdminAddVacancyComponent},
	{path: 'admin-dashboard/vacancies/edit-vacancy/:id', component: AdminEditVacancyComponent},
	{path: 'admin-dashboard/products/add-product', component: AdminAddProductComponent},
	{path: 'admin-dashboard/messages', component: AdminMessagesComponent},
	{path: 'admin-dashboard/messages/new-message', component: AdminNewMessageComponent},
	{path: 'admin-dashboard/in-progress-products/add-course-round', component: AdminAddCourseRoundComponent},
	{path: 'admin-dashboard/in-progress-products/edit-course-round/:id', component: AdminEditCourseRoundComponent},
	{path: 'admin-dashboard/tests', component: AdminTestsComponent},
	{path: 'admin-dashboard/tests/unused-codes', component: AdminUnusedCodesComponent},
	{path: 'admin-dashboard/in-progress-products/add-diploma-round', component: AdminAddDiplomaRoundComponent},
	{path: 'admin-dashboard/in-progress-products/edit-diploma-round/:id', component: AdminEditDiplomaRoundComponent},
	{path: 'admin-dashboard/in-progress-products/add-diploma-round/assign-instructors', component: AdminAddDiplomaRoundInstructorsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  	anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
