import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-utilising-our-platform',
  templateUrl: './utilising-our-platform.component.html',
  styleUrls: ['./utilising-our-platform.component.css']
})
export class UtilisingOurPlatformComponent implements OnInit {

  constructor(public globals: Globals) { }
  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

  show(hideORshow):void {
  	if(hideORshow=="hide") {
  		this.globals.bookAppointmentWrapper_display = "none";
  	}else if(hideORshow=="show") {
  		this.globals.bookAppointmentWrapper_display = "block";
  	}
  }

}
