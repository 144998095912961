import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'student-quizes',
  templateUrl: './student-quizes.component.html',
  styleUrls: ['./student-quizes.component.css']
})
export class StudentQuizesComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) {}

student_pk:string = this.apiService.loggedIn_user()["pk"];
  courses:any;

	quizzes:any;	

  ngOnInit(): void {
  	 window.scrollTo(0, 0);
  	// this.apiService.setLogged(true, "10", "student");


	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		this.courses = response.data;
		console.log(this.courses);
		
	});

  	this.apiService.getQuizes_byStudentId(this.student_pk).subscribe(response=>{		
  		console.log(1);
  		
		this.quizzes = response.data;
		console.log(this.quizzes);
		var temp:any = [];	
		for (var i in this.quizzes) {		
			if(temp[this.quizzes[i].courseName]){
				temp[this.quizzes[i].courseName].push(this.quizzes[i]);
			}else{
				temp[this.quizzes[i].courseName] = [];
				temp[this.quizzes[i].courseName].push(this.quizzes[i]);
			}
		}
		this.quizzes = temp;
console.log(this.quizzes);
		
	});

  }
}
