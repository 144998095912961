

<instructor-edit-class [style.display]="globals.editClassWrapper_display"></instructor-edit-class>
<instructor-create-class [style.display]="globals.createClassWrapper_display"></instructor-create-class>
<instructor-add-material [style.display]="globals.addMaterialWrapper_display"></instructor-add-material>
<instructor-add-quiz [style.display]="globals.addQuizWrapper_display"></instructor-add-quiz>

<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="cards_fullWidth" id="intro">
			<div class="cards_content">
				<p>Classes</p>
			</div>
		</div>

		<div class="courses">
			<div class="course" id="course1">	
				<p id="title">{{courseName}}</p>
			</div>
			<div class="options" (click)="showPopUp(null, 'createClass')"><p>Create Class</p></div>
			<div class="options" (click)="showPopUp(null, 'addQuiz')"><p>Add Quiz</p></div>
			<div class="options" id="material" (click)="showPopUp(null, 'addMaterial')"><p>Add Material</p></div>
		</div>

		<div class="headers">
			<p id="header1">Upcoming Class</p>
		</div>

		<div class="coursesSummary" *ngFor="let class of classes; index as i;">
			<div class="summaries" id="summary1">
				<p>{{i+1}}. {{class.name}}</p>
			</div>
			<div class="summaries" id="summary2">
				<p>{{class.date}}</p>
				<p>{{class.time}}</p>
			</div>
			<div class="summaries" id="summary3">
				<p>Assignment</p>
			</div>
			<div class="summaries" id="summary3">
				<a (click)="showPopUp(i, 'editClass')">Edit</a>
			</div>
		</div>

	</div>
</div>
