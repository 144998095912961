
<apply-as-instructor></apply-as-instructor>

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>LCCI</h1>
			<p>
				Pearson LCCI qualifications are work-related qualifications, created to give professional learners the skills and knowledge needed to thrive in the workplace. Whether you are a student, learning centre or an employer, our popular and flexible LCCI qualifications provide the knowledge and skills to actually do the job. That is why they are trusted and valued by employers worldwide.
			</p>
		</div>
		<div class="cards_container">
			<p id="title">Our Categories:</p>
			<div class="cards" id="card1" onclick="scrollToElement('#sect3_wrapper')">
				<div class="img"><img src="../../assets/imgs/lcci/marketing.svg" alt=""></div>
				<div class="card_info">
					<p><br>Marketing<br><br></p>
					<div class="arrow"><img src="../../assets/icons/arrowDown_blue.svg" alt=""></div>
				</div>
			</div>
			<div class="cards" id="card2" onclick="scrollToElement('#sect5_wrapper')">
				<div class="img"><img src="../../assets/imgs/lcci/f&a.svg" alt=""></div>
				<div class="card_info">
					<p>Finance <br> & <br> Accounting</p>
					<div class="arrow"><img src="../../assets/icons/arrowDown_blue.svg" alt=""></div>
				</div>
			</div>
			<div class="cards" id="card3" onclick="scrollToElement('#sect4_wrapper')">
				<div class="img"><img src="../../assets/imgs/lcci/business.svg" alt=""></div>
				<div class="card_info">
					<p><br>Business<br><br></p>
					<div class="arrow"><img src="../../assets/icons/arrowDown_blue.svg" alt=""></div>
				</div>
			</div>
		</div>
	</div>
	<button routerLink="/school-of-business/lcci/diplomas&courses">
		Check out our courses & diplomas
		<img src="../../assets/icons/arrowRight_blue.svg">
	</button>
</section>
<div style="height: 150px; overflow: hidden;" ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M-3.10,91.28 C121.05,59.70 372.17,-37.00 500.27,104.11 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: #58595B;"></path></svg></div>


<section class="sect2_wrapper">
	<div class=sect2_content>
		<div class="info">
			<p id="title">About the qualifications</p>
			<p>
				Pearson LCCI qualifications are work-related qualifications, created to give professional learners the skills and knowledge needed to thrive in the workplace. Whether you are a student, learning centre or an employer, our popular and flexible LCCI qualifications provide the knowledge and skills to actually do the job. That is why they are trusted and valued by employers worldwide.
			</p>
		</div>
		<div class="iframe">
			<iframe src="https://www.youtube.com/embed/T49mY9zH1-Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	</div>
</section>


<section class="sect3_wrapper" id="sect3_wrapper">
	<div class="bg"></div>
	<div class="sect3_content">
		<div class="info">
			<p id="title">Marketing</p>
			<p>
				LCCI are work-related qualifications created to give 
				professional learners the skills and knowledge needed 
				to enter and thrive in the workplace. Our qualifications 
				have been designed in collaboration with employers and 
				professional bodies to ensure that they provide students 
				with the necessary industry-related skills. Having been 
				offered internationally for over 120 years, they were 
				originally developed by the London Chamber of 
				Commerce and Industry (LCCI) to address the need for 
				reliable, high-quality standards across international 
				workforces.

			</p>
		</div>

		
		<div class="img">
			<img src="../../assets/imgs/lcci/sect3.svg" alt="">
		</div>

		<div class="info info_extra">
			<p id=title>Example Job Roles</p>
			<p>
				Digital Marketing Executive, Copywriter, 
				<br>
				Communications Assistant, Sales Support, 
				<br>
				Communications Executive, Data Analyst, 
				<br>
				Digital/Online Content Co-ordinator.
			</p>
			<p>
				LCCI qualifications are modern,job focused & practical. They are supported 
				and developed by key employers and professional bodies such as the 
				Peter Jones Foundation, Coventry University, CIM, IMM and ACCA. LCCI 
				graduates can continue their studies to Diploma level, these provide all the 
				key skills needed for entry and middle management level roles.
			</p>
			<button id="readMore" onclick="showMore('info_extra', 'block')">
				Read more about LCCI Marketing Diplomas for Businesses
				<img src="../../assets/icons/chevronDown_blue.svg">
			</button>
			<button routerLink="./diplomas&courses">
				Explore More
				<img src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>

		<table class="pdf_table">
			<tr class="rows" id="row1">
				<th>
					Find out more about our new
					<br>
					LCCI qualifications:
				</th>
			</tr>
			<tr class="rows" id="row2">
				<td>
					<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
					<p>
						LCCI Marketing and business qualification guide
					</p>
					<label class="download_icon" for="pdf_download">
						<div>
							<img src="../../assets/icons/download.svg" alt="">
						</div>
					</label>
					<a id="pdf_download" href="/images/myw3schoolsimage.jpg" download></a>
				</td>
			</tr>
			<tr class="rows" id="row3">
				<td>
					<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
					<p>
						LCCI Diploma guide - Marketing and Business suites
					</p>
					<label class="download_icon" for="pdf_download">
						<div>
							<img src="../../assets/icons/download.svg" alt="">
						</div>
					</label>
					<a id="pdf_download" href="/images/myw3schoolsimage.jpg" download></a>
				</td>
			</tr>
			<tr class="rows" id="row4">
				<td>
					<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
					<p>
						LCCI IDM flyer
					</p>
					<label class="download_icon" for="pdf_download">
						<div>
							<img src="../../assets/icons/download.svg" alt="">
						</div>
					</label>
					<a id="pdf_download" href="/images/myw3schoolsimage.jpg" download></a>
				</td>
			</tr>
			<tr class="rows" id="row5">
				<td>
					<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
					<p>
						LCCI CIM flyer
					</p>
					<label class="download_icon" for="pdf_download">
						<div>
							<img src="../../assets/icons/download.svg" alt="">
						</div>
					</label>
					<a id="pdf_download" href="/images/myw3schoolsimage.jpg" download></a>
				</td>
			</tr>
		</table>
	</div>
</section>


<section class="sect4_wrapper" id="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p id="title">Business Management</p>
			<p>
				We have developed new business qualifications in 
				conjunction with our centres, employers and 
				professional bodies. They provide students with 
				industry-specific and practical skills, enabling them 
				to successfully apply their knowledge in the 
				workplace and enhance their career prospect
			</p>
			<button routerLink="/underMaintenance">
				Explore More
				<img src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/lcci/sect4.svg" alt="">
		</div>
	</div>
</section>

<section class="sect5_wrapper" id="sect5_wrapper">
	<div class="bg"></div>
	<div class="sect5_content">
		<div class="info">
			<p id="title">Finance and Accounting</p>
			<p>
				LCCI Diplomas are a recognized and effective way of combining our LCCI qualifications in related fields to create a unique and comprehensive demonstration of practical ability, skills and knowledge.
				Diploma structure for the LCCI Financial and Quantitative qualifications
				Our new Diplomas for the LCCI Financial and Quantitative qualifications, which are the successor for the previous Diploma structure, have been available since January 2016. These qualifications are at Levels 2 to 4, and suitable for students who want to:

			</p>
			<ul>
				<li>
					specialise in a particular finance role, the 
					Diplomas, will give LCCI students the technical 
					skills required to perform in their chosen fields
				</li>
				<li>
					progress onto Higher Education or into 
					Professional Body qualifications.
				</li>
			</ul>
			<button routerLink="/business-solutions/corprate-training/accounting-and-financing">
				Explore More
				<img src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>

		
		<div class="img">
			<img src="../../assets/imgs/lcci/sect3.svg" alt=""><
		</div>
	</div>
</section>


<section class="sect6_wrapper">
	<h1 id="h1">Why should employers make sure that their</h1>
	<h1>employees are LCCI graduates?</h1>
	<div class="sect6_content">
		<div class="info" id="info1">
			<div class="img">
				<img src="../../assets/imgs/lcci/sect6_1.svg" alt="">
			</div>
			<p id="title">Skilled in key areas of business</p>
			<p>
				LCCI qualifications have been 
				developed in conjunction with industry 
				and professional bodies, so you can 
				be sure that LCCI graduates are 
				trained in the latest skills that industry 
				needs. Our graduates gain critical 
				skills in areas that businesses have 
				reported a skills shortage.
			</p>
		</div>
		<div class="info" id="info2">
			<div class="img">
				<img src="../../assets/imgs/lcci/sect6_2.svg" alt="">
			</div>
			<p id="title">Committed and driven</p>
			<p>
				LCCI qualifications are demanding and 
				thorough - students need to be 
				committed and demonstrate an 
				appetite to stand out and aim high in 
				their future careers. These are the 
				qualities that will add value to your 
				company from day one.
			</p>
		</div>
		<div class="info" id="info3">
			<div class="img">
				<img src="../../assets/imgs/lcci/sect6_3.svg" alt="">
			</div>
			<p id="title">Practical problem solvers</p>
			<p>
				Our students are used to working with 
				live examples as an integral part of 
				LCCI qualifications. As a result, they 
				develop a practical mindset and ‘can 
				do’ attitude. 
			</p>
			<p>
				LCCI graduates are work-ready and 
				their skills are directly applicable to 
				your business.
			</p>
		</div>
	</div>
</section>



<student-and-instructor-signup-cards></student-and-instructor-signup-cards>