import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-edit-diploma',
  templateUrl: './admin-edit-diploma.component.html',
  styleUrls: ['./admin-edit-diploma.component.css']
})
export class AdminEditDiplomaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
