import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-request',
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.css']
})
export class AdminRequestComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
	this.route.params.subscribe(params =>{
			this.request_pk = params.id;
			this.type = params.type;		
		});
  }

  request_pk:string;
  type:string;
  request:any;

  appointment_display:string;
  quote_display:string;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    console.log(this.request_pk);
    
    if(this.type == 'appointment') {
	    this.apiService.getAppointment_byAppointmentId(this.request_pk).subscribe(response=>{	
			this.request = response[0];
			console.log(response);
		});
		this.appointment_display = 'block';
		this.quote_display = 'none';
    }else if(this.type == 'quote') {
	    this.apiService.getQuote_byQuoteId(this.request_pk).subscribe(response=>{		    	
			this.request = response[0];
			console.log(this.request);
			this.appointment_display = 'none';
			this.quote_display = 'block';
			
		});
    }      	
      	
  }

}
