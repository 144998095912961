import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitTobulletPoints'
})
export class StrToArrPipe implements PipeTransform {

  transform(value: string): any {
    return value.split('%', (value.match(/,/g) || []).length-1);
  }

}
