import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'instructor-edit-assignment',
  templateUrl: './instructor-edit-assignment.component.html',
  styleUrls: ['./instructor-edit-assignment.component.css']
})
export class InstructorEditAssignmentComponent implements OnInit {


	  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	form: FormGroup;
	uploadResponse:any;

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.form = this.formBuilder.group({
      assignment: ['']
    });
  }

  getEditAssignment=new FormGroup({ 
  assignmentName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  dateDue:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),


});



  onFileSelect(event, fileAttrName) {
    if (event.target.files.length > 0) {
      console.log(1);
      
      const file = event.target.files[0];     
      this.form.get(fileAttrName).setValue(file);
    }
  }

  onSubmit() {
    const formDataAssignment = new FormData();
    formDataAssignment.append('assignment', this.form.get('assignment').value);
    console.log("formdata");
    
    console.log(this.form.get('assignment').value);
    console.log(this.getEditAssignment.value.assignmentName);
    

    this.apiService.editAssignment(this.globals.editAssignmentPk_value, this.globals.editAssignmentCourseFk_value, this.getEditAssignment.value, formDataAssignment).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }


   hideEditAssignmentPopUp(){
  		this.globals.editAssignmentWrapper_display = "none";
	}
}
