import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summits',
  templateUrl: './summits.component.html',
  styleUrls: ['./summits.component.css']
})
export class SummitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

}
