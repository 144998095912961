<div class="sideBar_wrapper" [ngStyle]="globals.dashboardTheme_navBar">
	<div class="sideBar_content">
		<a id="dashBoard" routerLink="/student-dashboard" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Dashboard</a>
		<a id="courses" routerLink="/student-dashboard/courses" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Courses</a>
		<a id="classes" routerLink="/student-dashboard/classes" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Classes</a>
		<a id="assignments" routerLink="/student-dashboard/assignments" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Assignments</a>
		<a id="exams" routerLink="/student-dashboard/exams" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Exams</a>
		<a id="quizes" routerLink="/student-dashboard/quizes" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Quizes</a>
		<a id="grades" routerLink="/student-dashboard/grades" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="globals.dashboardTheme_navBar">Grades</a>
		<a id="payments" [ngStyle]="globals.dashboardTheme_navBar"> <!-- routerLink="./payments"> -->Payments</a>
		<a id="signOut" href="" [ngStyle]="globals.dashboardTheme_navBar">Sign Out</a>
		<a id="settings" href="" [ngStyle]="globals.dashboardTheme_navBar">Settings</a>
		<a id="help" href="" [ngStyle]="globals.dashboardTheme_navBar">Help</a>
	</div>
</div>