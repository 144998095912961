import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-add-product',
  templateUrl: './admin-add-product.component.html',
  styleUrls: ['./admin-add-product.component.css']
})
export class AdminAddProductComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) { }

    courseForm_display:string = "block";
	diplomaForm_display:string = "none";
	courses_bg:string = "#C5C5C5";
	diplomas_bg:string = "#f1f1f1";
	 
	suites: string[] = ['marketing', 'business adminstration', 'finance and accounting'];
	levels: string[] = ['1', '2', '3', '4'];
	instructors:any;
	testimonials:any;
	courses:any;
	form:FormGroup;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.form = this.formBuilder.group({
      specification: [],
      sampleAssesment: [],
      examMaterials: [],
      pastTrainingContent: [],
      studentSupport: [],
      teacherGuide: []
    });
	this.apiService.getCourses().subscribe(response=>{	
		this.courses = response.data;
		console.log(this.courses);
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
  		console.log(this.instructors);
	});
	this.apiService.getTestimonials().subscribe(response=>{	
		this.testimonials = response;	
		console.log(this.testimonials);
				
	});
	this.switch('courses');
  }

  getAddCourse=new FormGroup({
	'name': new FormControl(null),
	'description': new FormControl(null),
	'price': new FormControl(null),
	'learningHours': new FormControl(null),
	'level': new FormControl(null),
	'suite': new FormControl(null),
	'whoShouldAttend': new FormControl(null),
	'requirements': new FormControl(null),
	'relatedCourses': new FormControl(null),
	'relatedInstructors': new FormControl(null),
	'relatedTestimonials': new FormControl(null),
	'promoCode': new FormControl(null),
	'discountPercentage': new FormControl(null),
	'promoExpirationDate': new FormControl(null),
  });

  getAddDiploma=new FormGroup({
	'name': new FormControl(null),
	'description': new FormControl(null),
	'price': new FormControl(null),
	'learningHours': new FormControl(null),
	'coreCourses': new FormControl(null),
	'optionalCourses': new FormControl(null),
	'level': new FormControl(null),
	'suite': new FormControl(null),
	'whoShouldAttend': new FormControl(null),
	'requirements': new FormControl(null),
  });

  addProduct(type){  	

	if(type == "course") {
		console.log(this.form);
		console.log(this.getAddCourse.value);
		const publicMaterialsFiles = new FormData();
		publicMaterialsFiles.append('specification[]', this.form.get('specification').value);
		publicMaterialsFiles.append('sampleAssesment[]', this.form.get('sampleAssesment').value);
		publicMaterialsFiles.append('examMaterials[]', this.form.get('examMaterials').value);
		publicMaterialsFiles.append('pastTrainingContent[]', this.form.get('pastTrainingContent').value);
		publicMaterialsFiles.append('studentSupport[]', this.form.get('studentSupport').value);
		publicMaterialsFiles.append('teacherGuide[]', this.form.get('teacherGuide').value);		
	    this.apiService.addCourse(this.getAddCourse.value, publicMaterialsFiles).subscribe(response=>{
	    	console.log(response);
	    	this.router.navigateByUrl('/admin-dashboard/products'); 
	    });
	}else if(type == "diploma") {
	  	console.log(this.getAddDiploma.value);
	    this.apiService.addDiploma(this.getAddDiploma.value).subscribe(response=>{
	    	console.log(response);
	    	this.router.navigateByUrl('/admin-dashboard/products'); 
	    });
	    console.log(this.getAddDiploma.value.optionalCourses);
	    
	}

  }

 switch(filter):void{
  	if(filter == "courses") {
		this.courseForm_display = "block";
		this.diplomaForm_display = "none";
		this.courses_bg = "#C5C5C5";
		this.diplomas_bg = "#f1f1f1";
  	}else if(filter == "diplomas") {
		this.courseForm_display = "none";
		this.diplomaForm_display = "block";
		this.courses_bg = "#f1f1f1";
		this.diplomas_bg = "#C5C5C5";
  	}
  }

  onFileSelect(event, attrName) {  	
    if (event.target.files.length > 0) {
      const files = event.target.files;     
      this.form.get(attrName).setValue(files);
    }
  }

}
