import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-media-and-content-production',
  templateUrl: './media-and-content-production.component.html',
  styleUrls: ['./media-and-content-production.component.css']
})
export class MediaAndContentProductionComponent implements OnInit {

	constructor(public globals: Globals) { }


	
	getQuote_display:string;

	ngOnInit(): void { 
		window.scrollTo(0, 0);
		this.getQuote_display = "none"; 
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
		this.boxShadow_videoAndAudioProduction = this.boxShadow;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
	}
	
	screenWidth:any;
	boxShadow:string;
	boxShadow_videoAndAudioProduction:string;
	boxShadow_brandingAndPositioning:string;
	boxShadow_contentCreation:string;
	display1:string = "none";
	@ViewChild("sect5_wrapper") sect5_wrapper;
	sect2_title:string = "Video and Audio Production";
	sect2_para1:string = `
				Media content enhances users experience all over the used 
				marketing communication channels. Thus, Cadres translates the 
				business vision into relevant video and audio content, which reflect 
				the main features of products through creative direction.   
				As Cadres relies on innovation, by having an extensive observation of the consumer habits and behaviors towards their needs and wants by using simple methods that adds value and creates the brands’ identity through illustrations and animations. 
			`;
	sect2_img:string = "sect2_videoAndAudioProduction.png";
	sect3_para1:string = `
			Our graphic design teams are specifically specialized in drawing unique customer attention that would address your target audience and increase business conversions, ranging from web designs, infographics, typographies, photography’s, video animations and designs, and editorial designs and motion to visually attract your audience. Cadres digital strategy is to create for your business an online presence that suites both your domestic and international high quality standards
			`;
	sect3_img:string = "sect3_videoAndAudioProduction.svg";


	changeBody(bodyTitle):void{
		
		this.boxShadow_videoAndAudioProduction = "none";
		this.boxShadow_brandingAndPositioning = "none";
		this.boxShadow_contentCreation = "none";
		if(bodyTitle == "Video and Audio Production") {
			this.display1 = "none";
			this.boxShadow_videoAndAudioProduction = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				Media content enhances users experience all over the used 
				marketing communication channels. Thus, Cadres translates the 
				business vision into relevant video and audio content, which reflect 
				the main features of products through creative direction.   
				As Cadres relies on innovation, by having an extensive observation of the consumer habits and behaviors towards their needs and wants by using simple methods that adds value and creates the brands’ identity through illustrations and animations. 
			`;
			this.sect2_img = "sect2_videoAndAudioProduction.png";
			this.sect3_para1 = `
					Our graphic design teams are specifically specialized in drawing unique customer attention that would address your target audience and increase business conversions, ranging from web designs, infographics, typographies, photography’s, video animations and designs, and editorial designs and motion to visually attract your audience. Cadres digital strategy is to create for your business an online presence that suites both your domestic and international high quality standards
					`;
			this.sect3_img = "sect3_videoAndAudioProduction.svg";	
		}else if(bodyTitle == "Branding and Positioning") {
			this.display1 = "block";
			this.boxShadow_brandingAndPositioning = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				It is quite challenging to recall different brands through the fierce 
				competition in the markets lately. On the other hand, there are some 
				brands could be recalled easily whenever mobiles phones, fancy 
				cars, luxurious resorts, and sports snickers are mentioned. Simply, 
				this is the power of branding and positioning. It targets the 
				consumers’ subconscious. Cadres strategic plans for branding and 
				positioning keep businesses marketing activities up to standards.
			`;
			this.sect2_img = "sect2_brandingAndPositioning.png";
			this.sect3_para1 = `
				Cadres assures to build and position your business amongst the top 
				ranked websites and create innovative marketing campaigns to suit 
				your business profiles. As Cadres is mainly a business development 
				company that assures creative integrative and advertising tools to 
				build the brands’ image and create a unique identity in terms of 
				digital marketing, branding, corporate identity deigns, public relations 
				(PR), web and app development.
			`;
			this.sect3_img = "sect3_brandingAndPositioning.png";
		}else if(bodyTitle == "Content Creation") {
			this.display1 = "block";
			this.boxShadow_contentCreation = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				It is quite challenging to recall different brands through the fierce 
				competition in the markets lately. On the other hand, there are some 
				brands could be recalled easily whenever mobiles phones, fancy 
				cars, luxurious resorts, and sports snickers are mentioned. Simply, 
				this is the power of branding and positioning. It targets the 
				consumers’ subconscious. Cadres strategic plans for branding and 
				positioning keep businesses marketing activities up to standards.
			`;
			this.sect2_img = "sect2_contentCreation.png";
			this.sect3_para1 = `
				Cadres assures to build and position your business amongst the top 
				ranked websites and create innovative marketing campaigns to suit 
				your business profiles. As Cadres is mainly a business development 
				company that assures creative integrative and advertising tools to 
				build the brands’ image and create a unique identity in terms of 
				digital marketing, branding, corporate identity deigns, public relations 
				(PR), web and app development.
			`;
			this.sect3_img = "sect3_contentCreation.png";
		}
	}

    show(hideORshow):void {
      if(hideORshow=="hide") {
        this.globals.getQuoteWrapper_display = "none";
      }else if(hideORshow=="show") {
        this.globals.getQuoteWrapper_display = "block";
      }
    }

}