import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-admin-edit-page',
  templateUrl: './admin-edit-page.component.html',
  styleUrls: ['./admin-edit-page.component.css']
})
export class AdminEditPageComponent implements OnInit {

  constructor(private apiService: ApiService, private renderer: Renderer2, private viewportScroller: ViewportScroller, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
    this.route.params.subscribe(params =>{
        this.page_pk = params.id;      
      });
  }

  page_pk:number;
  sections:any;
  sectionWidth:any = {"large": 100, "small": 47.5};
  text:string = '';
  textLength:number = this.text.replace(/<[^>]*>/g, '').length;

    form: FormGroup = this.formBuilder.group({
      sectionImg: ['']
    });
  uploadResponse:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    this.apiService.pageSections_byPageId(this.page_pk).subscribe(response=>{
      this.sections = response.data;
     console.log(this.sections);
    });
  }


    turn2dArrayToAssoc2dArray(array, key) {  
    var temp:any = [];  
    for (var i in array) {          
      if(temp[array[i][key]]){
        temp[array[i][key]].push(array[i]);
      }else{
        temp[array[i][key]] = [];
        temp[array[i][key]].push(array[i]);
      }
    }
    return temp;
  }

  getEditPage=new FormGroup({
 	'text': new FormControl(null)
});


  editPage(section_pk, assetsFolder){    
      
    const sectionImg = new FormData();        
    
    sectionImg.append('sectionImg', this.form.get('sectionImg').value);    
    
    this.apiService.editPageSection(section_pk, this.text, assetsFolder, sectionImg).subscribe(response=>{          	
        console.log(response);
    });    
    

    
    
  }

  contentChange(e, maxLength){
    console.log(maxLength);
    this.text = e.html;
  	console.log();
  	console.log(this.textLength);
  	if(e.editor.getLength()-1 >= maxLength) {  		
  		e.editor.deleteText(maxLength, e.editor.getLength()-1);
  	}
  	this.textLength = e.editor.getLength()-1;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('sectionImg').setValue(file);
    }
  }

  scrollTo(sectionId){
    // document.querySelector(section_pk).scrollIntoView({behavior: 'smooth'});
    console.log(sectionId);
    this.viewportScroller.scrollToAnchor(sectionId);
    
    //     try {
    //       console.log(1);
          
    //   const errorField = this.renderer.selectRootElement('#'+section_pk);
    //     errorField.scrollIntoView();
    // } catch (err) {
    //   console.log(err);
      

    // }
  }




}
