import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
// import { Vacancy } from  '../vacancy';
import { StrToArrPipe } from '../str-to-arr.pipe';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.css']
})
export class VacancyComponent implements OnInit {

	vacancy:any;

	id:number;
	title:any;
	description:any;
	summary:any;
	specifications:any;
	interpersonalSkills:any;
	benefits:any;

	vacancies: [];
    // selectedVacancy:  Vacancy  = { job_pk :  null, jobTitle:null, jobDescription:  null};

    bulletPoint(string):void {
    	// this.string.split
    }

	constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
				this.id = params.id;			
			});
	}

	ngOnInit(): void { 
		window.scrollTo(0, 0);		
		this.apiService.getVacancy_byVacancyId(this.id).subscribe(response=>{
			this.vacancy = response.data[0];			
			console.log(this.vacancy);
			// this.vacancy = this.vacancies[this.id];
			this.title = this.vacancy.title;
			this.description = this.vacancy.description;
			this.summary= this.vacancy.summary;
			this.specifications= this.vacancy.specification;
			// this.specifications = this.specifications.split('%');
			this.interpersonalSkills= this.vacancy.interpersonalSkills;
			// this.interpersonalSkills = this.interpersonalSkills.split('%');
			this.benefits= this.vacancy.benefits;
		});


	}
}
