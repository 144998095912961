
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="productType" id="news" [style.background]="news_bg" (click)="switch('news')" >
			<p>News</p>
		</div>
		<div class="productType" id="events" [style.background]="events_bg" (click)="switch('events')" >
			<p>Events</p>
		</div>
		<div class="add" routerLink="./add-{{type}}">
			<img src="../../assets/icons/add_white.svg">
			<p>Add {{type}}</p>
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<section class="newsAndEvents_wrapper">
	<div class="newsAndEvents_content">
		<div class="newsOrEvent"  *ngFor="let newsOrEvent of newsOrEvents | filter:searchText" routerLink="/admin-dashboard/{{type}}/{{newsOrEvent.pk}}">
			<p id="newsOrEventName">{{newsOrEvent.title | titlecase}} <br> {{newsOrEvent.date}}</p>
			<p routerLink="./edit-{{type}}/{{newsOrEvent.pk}}" id="edit">Edit</p>
			<p id="delete" (click)="deletenewsOrEvent(newsOrEvent.pk)">Remove</p>
		</div>
	</div>
</section>