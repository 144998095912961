<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<div class="user_icon"><img src="../../assets/icons/user_pp.svg" alt=""></div>
				<p>Welcome Back {{student.firstName | titlecase}} </p>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="registeredCourses" [ngStyle]="globals.dashboardTheme_cards"> 
			<p id="title">Registered Courses</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="registeredCoursesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration1.svg" alt="">
				</div>
				<p [style.display]="registeredCoursesPlaceHolder_display">No Registered Courses</p>
				<div class="data" *ngFor="let course of courses | slice:0:4; index as i">
					<div class="img"><img src="../../assets/imgs/imgPlaceHolder3.svg"></div>
					<div>	
					<p id="data1">{{i+1}}. {{course.courseName}}</p>
					<!-- <p>Placement Teset Score: {{course.placementTestGrade}}</p> -->
					<!-- <p>Level: {{course.level}}</p> -->
				</div>
				</div>
			</div>
			
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="inProgress" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">In Progress</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="registeredCoursesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration2.svg" alt="">
				</div>
				<p [style.display]="registeredCoursesPlaceHolder_display">No Courses In Progress</p>
				<div class="data" *ngFor="let course of courses | slice:0:4; index as i">	
					<p id="data1">{{i+1}}. {{course.courseName }}</p>
					<div>
						<div class="progressBar">
							<div id="progress" [style.width] = "course.progress"> </div>
						</div>
						<p>{{course.progress}}</p>	
					</div>
				</div>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="messages" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">Messages</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="messagesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration9.svg" alt="">
				</div>
				<p [style.display]="messagesPlaceHolder_display">No New Messages</p>
			</div>
			<div class="data">
				<div class="message"  *ngFor="let message of messages" routerLink="./message/{{message.message_pk}}">
					<div class="img"><img src="../../assets/icons/logo_orange.svg"></div>
					<p id="message"><span id="sender">From Cadres</span> <br> {{message.content | removeHtmlTags | slice:0:74}}<span *ngIf="message.content.length > 74">...</span></p>
					<div class="delete" (click)="deleteMessage(message.message_pk)">
						<img src="../../assets/icons/trash_black.png">
					</div>
				</div>
				<p id="noOtherMessages">No other messages.</p>
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="classes" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">Classes</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="classesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p [style.display]="classesPlaceHolder_display">No Classes In Progress</p>
				<div class="headers" [style.display]="classesHeaders_display">
					<p id="header1">Course name:</p>
					<p id="header2">Class:</p>
					<p id="header3">Date:</p>
				</div>
				<div class="data" *ngFor="let class of classes | slice:0:4; index as i">	
					<p>{{i+1}}. {{class.courseName | truncateText}}</p>
					<p>{{class.name}}</p>
					<p>{{class.date}}</p>
					<p>{{class.status}}</p>
					<a href="{{class.zoomLink}}">Start</a>

				</div>
				<p>check more</p>
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="assignments" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">Assignments</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="assignmentsPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration5.svg" alt="">
				</div>
				<p [style.display]="assignmentsPlaceHolder_display">No Assignments Appointed</p>
				<div class="headers" [style.display]="assignmentsHeaders_display">
					<p id="header1">Course Name:</p>
					<p id="header2">Assignment:</p>
					<p id="header3">Due Date:</p>
				</div>
				<div class="data" *ngFor="let assignment of assignments | slice:0:4; index as i">	
					<p>{{i+1}}. {{assignment.courseName | truncateText}}</p>
					<p>{{assignment.assignmentName}}</p>
					<p>{{assignment.dateDue}}</p>
					<a [href]="assignment.path">Download</a>

				</div>
				
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="quizess" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">Exams</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="examsPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration6.svg" alt="">
				</div>
				<p [style.display]="examsPlaceHolder_display">No Exams</p>
				<div class="headers" [style.display]="examsHeaders_display">
					<p id="header1">Course Name:</p>
					<p id="header2">Exam Name:</p>
					<p id="header3">Due Date:</p>
					<p id="header3">Location:</p>
				</div>
				<div class="data" *ngFor="let exam of exams | slice:0:4; index as i">	
					<p>{{i+1}}. {{exam.courseName | truncateText}}</p>
					<p>{{exam.examName}}</p>
					<p>{{exam.date}}</p>
					<p>{{exam.location}}</p>

				</div>

			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="grades" [ngStyle]="globals.dashboardTheme_cards">
			<p id="title">Grades</p>
			<div class="cards_content">
				<div class="illustration" [style.display]="gradesPlaceHolder_display">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p [style.display]="gradesPlaceHolder_display">No Grades</p>

				<div class="headers" [style.display]="gradesHeaders_display">
					<p id="header1">Course Name:</p>
					<p id="header2">Percentage:</p>
					<p id="header3">Status:</p>
				</div>
				<div class="data" *ngFor="let grade of grades | slice:0:4; index as i">	
					<p id="data1">{{i+1}}. {{grade.courseName  | truncateText}}</p>
					<div>
						<div class="progressBar">
							<div id="progress" [style.width] = "grade.grade"> </div>
						</div>
						<p>{{grade.grade}}</p>	
					</div>
					<p id="data3">{{grade.status}}</p>
				</div>
			</div>
		</div>
		<!-- <div class="cards_wrapper cards_thirdWidth" id="certificates">
			<p id="title">Certificates</p>
			<div class="cards_content">
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration8.svg" alt="">
				</div>
				<p>No Certificates</p>
			</div>
		</div> -->
	
		<!-- <div class="cards_wrapper cards_thirdWidth" id="materials">
			<p id="title">Materials</p>
			<div class="cards_content">
				<div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration3.svg" alt="">
				</div>
				<p>No Materials</p>
			</div>
		</div> -->
		<!-- <div class="cards_wrapper cards_thirdWidth">
			<p id="title"></p>
			<div class="cards_content">
				<img src="../../assets/illustrations/student-dashboard/illustration.svg" alt="">
				<p></p>
			</div>
		</div> -->
	</div>
</div>