import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-unused-codes',
  templateUrl: './admin-unused-codes.component.html',
  styleUrls: ['./admin-unused-codes.component.css']
})
export class AdminUnusedCodesComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
	}


  codes:any;
  link:string;

  searchText:string;

  	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}



  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getUnusedCodes().subscribe(response=>{	
		this.codes = response.data;
		this.link = this.codes[0].link;	
	});
  }

    getAddCode=new FormGroup({
	  code:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  link:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

	});

  addCode(){
    this.getAddCode.value.link = this.link;
    this.apiService.addCode(this.getAddCode.value).subscribe(response=>{
    	console.log(response);
    });
  }

}
