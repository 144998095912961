
<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<p>My Assignments</p>
			</div>
		</div>

		<div class="coursesProgress">
			<div class="courseProgress" id="courseProgress1" [ngStyle]="globals.dashboardTheme_cards">	
				<p id="title">{{course.courseName}}</p>
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "course.progress"></div>
					</div>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Upcoming Assignments</p>
		</div>

		<div class="coursesSummary" *ngFor="let assignment of assignments;" [ngStyle]="globals.dashboardTheme_cards">
			<div class="summaries" id="summary1">
				<p>{{assignment.assignmentName}}</p>
			</div>
			<div class="summaries" id="summary2">
				<p>{{assignment.dateDue}}</p>
				<p>{{assignment.timeDue}}</p>
			</div>
			<div class="summaries" id="summary3">
				<a *ngIf="assignment.path != ''" href="{{assignment.path}}">{{assignment.assignmentName}} <img src="../../assets/icons/download.svg"></a>
				<p *ngIf="assignment.path == ''">No Assignment</p>
			</div>
			<div class="summaries" id="summary3">
				<p>
					Upload
				</p>
			</div>
		</div>

	</div>
</div>