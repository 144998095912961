import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';


@Component({
  selector: 'instructor-edit-class',
  templateUrl: './instructor-edit-class.component.html',
  styleUrls: ['./instructor-edit-class.component.css']
})
export class InstructorEditClassComponent implements OnInit {


  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

instructor_pk:string = this.apiService.loggedIn_user()["pk"];
form: FormGroup;
uploadResponse:any;
		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
    this.form = this.formBuilder.group({
      material: [''],
      video: ['']
    });
  }



getEditClass=new FormGroup({
  name:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  description:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  time:new FormControl('',[Validators.required ,Validators.email]),
  date:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  zoomLink:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  videoPath:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
   status:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),

});

 editClass(){	 	
    if(this.globals.createClassRoundType_value == 'course') {
      this.apiService.editCourseClass(this.getEditClass.value, this.globals.editClassFk_value).subscribe(response=>{
        console.log(response);
      });
    }else if(this.globals.createClassRoundType_value == 'diploma'){
      this.apiService.editDiplomaCourseClass(this.getEditClass.value, this.globals.editClassFk_value).subscribe(response=>{
        console.log(response);
      });
    }
    // this.onSubmit();
  }

   hideEditPopUp(){
  		this.globals.editClassWrapper_display = "none";
	}

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('material').setValue(file);
    }
  }

  // onSubmit() {
  //   const formDataMaterial = new FormData();
  //   formDataMaterial.append('material', this.form.get('material').value);
  //   const formDataVideo = new FormData();
  //   formDataVideo.append('video', this.form.get('video').value);
  //   console.log("formdata");
    
  //   console.log(this.form);

  //   this.apiService.uploadCourseMaterials(this.globals.createClassRoundFk_value, this.globals.createClassFk_value, 'material', formDataMaterial).subscribe(
  //     (res) => {
  //       this.uploadResponse = res;
  //         console.log(res);
  //     },
  //     (err) => {  
  //       console.log(err);
  //     }
  //   );
  //   this.apiService.uploadClassVideo(this.globals.createClassRoundFk_value, this.globals.createClassFk_value, formDataVideo).subscribe(
  //     (res) => {
  //       this.uploadResponse = res;
  //         console.log(res);
  //     },
  //     (err) => {  
  //       console.log(err);
  //     }
  //   );
  // }

}
