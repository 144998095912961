import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-message',
  templateUrl: './student-message.component.html',
  styleUrls: ['./student-message.component.css']
})
export class StudentMessageComponent implements OnInit {


 constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.message_pk = params.id;			
			});
	}

	message_pk:string;
  	student_pk:string = this.apiService.loggedIn_user()["pk"];


  message:any;

  ngOnInit(): void {
  	 window.scrollTo(0, 0);  	 
	this.apiService.message_byMessageId(this.message_pk).subscribe(response=>{	
		this.message = response.data[0];	
		console.log(this.message);
								
	});
  }

deleteMessage():void{      	
  	this.apiService.deleteMessage_byStudentId(this.student_pk, this.message_pk).subscribe(response=>{	  		  	
  		console.log(response);
	});
	this.router.navigateByUrl('/student-dashboard');	
  }

}
