

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Cadres English</h1>
			<p>
				As per our research team in Cadres, “Learn English” 
				or English learning is perhaps the top interest of 
				people who want to start a bright career path. In 
				addition, it is a more convenient channel of 
				communication between people from different 
				cultures
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/cadres-english/sect1.svg" alt="">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<p>
				The key word is business consultation.
				Cadres school of business provides business owners with a free to use platform helping them grow their businesses through live chatting with our consultants, the consultations differs from getting the cheapest item in the super market to business planning, marketing planning, and recruiting the best employee matching your company needs with the market demands,  you can also book an in real-life one to one meeting with our consultants telling you what you exactly should do growing your business
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect2.png" alt="">
		</div>
	</div>
</section>


<section class="sect3_wrapper">
	<div class="sect3_content sect3_content_desktop">
		<div class="info">
			<p>
				Cadres offer inclusive business development programs to enhance corporate employees’ skills by providing a needs training assessment to strengthen their knowledge and skills in various fields of marketing, management, accounting, and finance. These corporate and business training provided by Cadres are internationally awarded from Pearson as a global leading educational company. 
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect3.png" alt="">
		</div>
	</div>
</section>


<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p>
				The key word is business consultation.
				Cadres school of business provides business owners with a free to use platform helping them grow their businesses through live chatting with our consultants, the consultations differs from getting the cheapest item in the super market to business planning, marketing planning, and recruiting the best employee matching your company needs with the market demands,  you can also book an in real-life one to one meeting with our consultants telling you what you exactly should do growing your business
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect2.png" alt="">
		</div>
	</div>
</section>


<section class="sect5_wrapper">
	<div class="sect5_content">
		<div class="logos_container" id="logo1">
			<div class="img"><img src="../../assets/icons/versent.svg" alt=""></div>
			<p>Versent English Placement Test</p>
		</div>
		<div class="logos_container" id="logo2">
			<div class="img"><img src="../../assets/icons/pte.svg" alt=""></div>
			<p>Pearson Test of English Language</p>
		</div>
		<div class="logos_container" id="logo3">
			<div class="img"><img src="" alt=""></div>
			<p>Business English</p>
		</div>
		<div class="logos_container" id="logo4">
			<div class="img"><img src="" alt=""></div>
			<p>General English</p>
		</div>
	</div>
</section>

<section class="sect6_wrapper">
	<div class="bg"></div>
	<div class="sect6_content">
		<div class="info">
			<h1>Versent English Placement test</h1>
			<p>
				The key word is business consultation.
				Cadres school of business provides business owners with a free to use platform helping them grow their businesses through live chatting with our consultants, the consultations differs from getting the cheapest item in the super market to business planning, marketing planning, and recruiting the best employee matching your company needs with the market demands,  you can also book an in real-life one to one meeting with our consultants telling you what you exactly should do growing your business
			</p>
			<button routerLink="./versent-test" onclick="setTitle('Versent Test')">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_blue.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect2.png" alt="">
		</div>
	</div>
</section>

<section class="sect7_wrapper">
	<div class="sect7_content sect7_content_desktop">
		<div class="info">
			<h1>Pearson Test of English Language</h1>
			<p>
				Cadres offer inclusive business development programs to enhance corporate employees’ skills by providing a needs training assessment to strengthen their knowledge and skills in various fields of marketing, management, accounting, and finance. These corporate and business training provided by Cadres are internationally awarded from Pearson as a global leading educational company. 
			</p>
			<button routerLink="./pearson-test" onclick="setTitle('Pearson Test')">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_blue.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect3.png" alt="">
		</div>
	</div>
</section>

<section class="sect8_wrapper">
	<div class="bg"></div>
	<div class="sect8_content">
		<div class="info">
			<h1>General English Course</h1>
			<p>
				The key word is business consultation.
				Cadres school of business provides business owners with a free to use platform helping them grow their businesses through live chatting with our consultants, the consultations differs from getting the cheapest item in the super market to business planning, marketing planning, and recruiting the best employee matching your company needs with the market demands,  you can also book an in real-life one to one meeting with our consultants telling you what you exactly should do growing your business
			</p>
			<button routerLink="./general-english" onclick="setTitle('General English')">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_blue.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect2.png" alt="">
		</div>
	</div>
</section>

<section class="sect9_wrapper">
	<div class="sect9_content">
		<div class="info">
			<h1>Business English</h1>
			<p>
				Cadres offer inclusive business development programs to enhance corporate employees’ skills by providing a needs training assessment to strengthen their knowledge and skills in various fields of marketing, management, accounting, and finance. These corporate and business training provided by Cadres are internationally awarded from Pearson as a global leading educational company. 
			</p>
			<button routerLink="./business-english" onclick="setTitle('Business English')">
				Explore More
				<img id="btnImg" src="../../assets/icons/arrowRight_blue.svg">
			</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/business-solutions/sect3.png" alt="">
		</div>
	</div>
</section>