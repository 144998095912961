

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="productType" id="courses" [style.background]="courses_bg" (click)="switch('courses')" >
			<p>Courses</p>
		</div>
		<div class="productType" id="diplomas" [style.background]="diplomas_bg" (click)="switch('diplomas')" >
			<p>Diplomas</p>
		</div>
		<div class="title">
			<p>Enrollments</p>
		</div>
		<div class="searchBar">
			<p>Increass</p>
			<p>courses</p>
			<p>diplomas</p>
		</div>
	</div>
</section>


<section class="products_wrapper" id="courses" [style.display]="courses_display" >
	<div class="products_content">
		<div class="headers">
			<p>Product</p>
			<p id="numOfStudents">Number of Students</p>
		</div>
		<div class="row" *ngFor="let course of courses" >
			<div id="column1">
				<p>{{course.productName}}</p>
			</div>
			<div id="column2">
				<p>{{course.numberOfEnrollments}}</p>
				<button routerLink="./students/course/{{course.productName}}">View Students</button>
			</div>
		</div>
	</div>
</section>

<section class="products_wrapper" id="courses" [style.display]="diplomas_display" >
	<div class="products_content">
		<div class="headers">
			<p>Product</p>
			<p id="numOfStudents">Number of Students</p>
		</div>
		<div class="row" *ngFor="let diploma of diplomas" >
			<div id="column1">
				<p>{{diploma.productName}}</p>
			</div>
			<div id="column2">
				<p>{{diploma.numberOfEnrollments}}</p>
				<button routerLink="./students/diploma/{{diploma.productName}}">View Students</button>
			</div>
		</div>
	</div>
</section>