
<section class="quickActions_wrapper">
	<div class="quickActions_content">
		<div class="quickActions_container">
			<div class="actions">
				<img src="../../assets/icons/messaging.svg">
				<p>Message</p>
			</div>
			<div class="actions">
				<img src="../../assets/icons/settings.svg">
				<p>Setting</p>
			</div>
		</div>
	</div>
</section>

<div class="student_wrapper">
	<div class="pp">
		<img id="pp" src="../../assets/icons/user_pp.svg">
	</div>
	<div class="student_content">
			<div class="inputs" id="input1">
				<legend>First Name</legend>
				<p rows="1"  type="text" formControlName="firstName">{{student.firstName}}</p>
			</div>
			<div class="inputs" id="input2">
				<legend>Last Name</legend>
				<p rows="1"  type="text" formControlName="lastName">{{student.lastName}}</p>
			</div>
			<div class="inputs" id="input3">
				<legend>Email</legend>
				<p rows="1" type="text" formControlName="email">{{student.email}}</p> 
			</div>
			<div class="inputs" id="input4">
				<legend>Mobile Number</legend>
				<p rows="10" type="text" formControlName="mobileNumber">{{student.mobileNumber}}</p>
			</div>
			<div class="inputs" id="input5">
				<legend>Address</legend>
				<p rows="10" type="text" formControlName="address">{{student.address}}</p>
			</div>
			<div class="inputs" id="input6">
				<legend>Postal Code</legend>
				<p rows="10" type="text" formControlName="postalCode">{{student.postalCode}}</p>
			</div>
			<div class="inputs" id="input8">
				<legend>DOB:</legend>
				<p type="date" formControlName="dob">{{student.dob}}</p>
			</div>
			<div class="inputs" id="input8">
				<legend>Gender:</legend>
				<p type="date" formControlName="dob">{{student.dob}}</p>
			</div>
	</div>
</div>

<section class="courses_wrapper">
	<p>Courses</p>
	<div class="courses_content" *ngFor="let course of courses">
		<div class="course">
			<p>{{course.courseName}}</p>
		</div>
	</div>
</section>