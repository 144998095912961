import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { MaterialModule } from './materials';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { QuillModule } from'ngx-quill';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Globals } from './globals';

import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SchoolOfBusinessComponent } from './school-of-business/school-of-business.component';
import { BusinessSolutionsComponent } from './business-solutions/business-solutions.component';
import { CareersComponent } from './careers/careers.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { LcciComponent } from './lcci/lcci.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { DiplomasCoursesComponent } from './diplomas-courses/diplomas-courses.component';
import { DiplomaDetailsComponent } from './diploma-details/diploma-details.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CadresEnglishComponent } from './cadres-english/cadres-english.component';
import { VersentTestComponent } from './versent-test/versent-test.component';
import { PearsonTestComponent } from './pearson-test/pearson-test.component';
import { GeneralEnglishComponent } from './general-english/general-english.component';
import { BusinessEnglishComponent } from './business-english/business-english.component';
import { BusinessConsultationComponent } from './business-consultation/business-consultation.component';
import { UtilisingOurPlatformComponent } from './utilising-our-platform/utilising-our-platform.component';
import { CadresSupportStartupsComponent } from './cadres-support-startups/cadres-support-startups.component';
import { SurvivingCovid19Component } from './surviving-covid19/surviving-covid19.component';
import { CorprateTrainingComponent } from './corprate-training/corprate-training.component';
import { TrainingAssessmentComponent } from './training-assessment/training-assessment.component';
import { MarketingComponent } from './marketing/marketing.component';
import { BussinessAdministrationComponent } from './bussiness-administration/bussiness-administration.component';
import { AccountingAndFinancingComponent } from './accounting-and-financing/accounting-and-financing.component';
import { EventManagementComponent } from './event-management/event-management.component';
import { RecruitmentComponent } from './recruitment/recruitment.component';
import { WatsonGlaserComponent } from './watson-glaser/watson-glaser.component';
import { IrisTestComponent } from './iris-test/iris-test.component';
import { DifferentialTestComponent } from './differential-test/differential-test.component';
import { SosieGenerationComponent } from './sosie-generation/sosie-generation.component';
import { ConferencesComponent } from './conferences/conferences.component';
import { SummitsComponent } from './summits/summits.component';
import { WebinarsAndEconferencesComponent } from './webinars-and-econferences/webinars-and-econferences.component';
import { ConcertsComponent } from './concerts/concerts.component';
import { MarketingAndAdvertisementComponent } from './marketing-and-advertisement/marketing-and-advertisement.component';
import { MarketResearchComponent } from './market-research/market-research.component';
import { MediaAndContentProductionComponent } from './media-and-content-production/media-and-content-production.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { WebsitesAndApplicationsComponent } from './websites-and-applications/websites-and-applications.component';
import { BusinessSolutionsVersentTestComponent } from './business-solutions-versent-test/business-solutions-versent-test.component';
import { VacancyComponent } from './vacancy/vacancy.component';
import { NewsComponent } from './news/news.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { BookAnAppointmentComponent } from './book-an-appointment/book-an-appointment.component';
import { StrToArrPipe } from './str-to-arr.pipe';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { LcciSignInComponent } from './lcci-sign-in/lcci-sign-in.component';
import { ApplyAsInstructorComponent } from './apply-as-instructor/apply-as-instructor.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ProductPlanComponent } from './product-plan/product-plan.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';





import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { StudentDashboardBannerComponent } from './student-dashboard-banner/student-dashboard-banner.component';
import { StudentDashboardSideBarComponent } from './student-dashboard-side-bar/student-dashboard-side-bar.component';
import { TruncateTextPipe } from './truncateText.pipe';
import { StudentCoursesComponent } from './student-courses/student-courses.component';
import { StudentClassesComponent } from './student-classes/student-classes.component';
import { StudentAssignmentsComponent } from './student-assignments/student-assignments.component';
import { StudentExamsComponent } from './student-exams/student-exams.component';
import { StudentQuizesComponent } from './student-quizes/student-quizes.component';
import { StudentGradesComponent } from './student-grades/student-grades.component';
import { StudentCertificatesComponent } from './student-certificates/student-certificates.component';
import { StudentPaymentsComponent } from './student-payments/student-payments.component';
import { StudentCourseComponent } from './student-course/student-course.component';
import { StudentClassComponent } from './student-class/student-class.component';
import { StudentAssignmentComponent } from './student-assignment/student-assignment.component';
import { StudentEditProfileComponent } from './student-edit-profile/student-edit-profile.component';
import { StudentMessageComponent } from './student-message/student-message.component';


import { InstructorDashboardComponent } from './instructor-dashboard/instructor-dashboard.component';
import { InstructorStudentsComponent } from './instructor-students/instructor-students.component';
import { InstructorStudentComponent } from './instructor-student/instructor-student.component';
import { InstructorCourseComponent } from './instructor-course/instructor-course.component';
import { InstructorClassesComponent } from './instructor-classes/instructor-classes.component';
import { InstructorClassComponent } from './instructor-class/instructor-class.component';
import { InstructorEditClassComponent } from './instructor-edit-class/instructor-edit-class.component';
import { InstructorEditProfileComponent } from './instructor-edit-profile/instructor-edit-profile.component';
import { InstructorDashboardBannerComponent } from './instructor-dashboard-banner/instructor-dashboard-banner.component';
import { InstructorDashboardSideBarComponent } from './instructor-dashboard-side-bar/instructor-dashboard-side-bar.component';
import { InstructorCreateClassComponent } from './instructor-create-class/instructor-create-class.component';
import { InstructorAddAssignmentComponent } from './instructor-add-assignment/instructor-add-assignment.component';
import { InstructorAddMaterialComponent } from './instructor-add-material/instructor-add-material.component';
import { InstructorAddQuizComponent } from './instructor-add-quiz/instructor-add-quiz.component';
import { InstructorEditMaterialComponent } from './instructor-edit-material/instructor-edit-material.component';
import { InstructorEditQuizComponent } from './instructor-edit-quiz/instructor-edit-quiz.component';
import { InstructorEditAssignmentComponent } from './instructor-edit-assignment/instructor-edit-assignment.component';
import { InstructorMessageComponent } from './instructor-message/instructor-message.component';


import { AdminDashboardBannerComponent } from './admin-dashboard-banner/admin-dashboard-banner.component';
import { AdminDashboardSideBarComponent } from './admin-dashboard-side-bar/admin-dashboard-side-bar.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminProductComponent } from './admin-product/admin-product.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminAddCourseComponent } from './admin-add-course/admin-add-course.component';
import { AdminAddDiplomaComponent } from './admin-add-diploma/admin-add-diploma.component';
import { AdminStudentsComponent } from './admin-students/admin-students.component';
import { AdminInstructorsComponent } from './admin-instructors/admin-instructors.component';
import { AdminVacanciesComponent } from './admin-vacancies/admin-vacancies.component';
import { AdminRequestsComponent } from './admin-requests/admin-requests.component';
import { AdminInstructorComponent } from './admin-instructor/admin-instructor.component';
import { AdminStudentComponent } from './admin-student/admin-student.component';
import { AdminInProgressProductsComponent } from './admin-in-progress-products/admin-in-progress-products.component';
import { AdminProductClassesComponent } from './admin-product-classes/admin-product-classes.component';
import { AdminEnrollmentsComponent } from './admin-enrollments/admin-enrollments.component';
import { AdminProductStudentsComponent } from './admin-product-students/admin-product-students.component';
import { AdminPagesComponent } from './admin-pages/admin-pages.component';
import { AdminRequestComponent } from './admin-request/admin-request.component';
import { AdminNewsAndEventsComponent } from './admin-news-and-events/admin-news-and-events.component';
import { AdminNewsComponent } from './admin-news/admin-news.component';
import { AdminEventComponent } from './admin-event/admin-event.component';
import { AdminAddEventComponent } from './admin-add-event/admin-add-event.component';
import { AdminAddNewsComponent } from './admin-add-news/admin-add-news.component';
import { AdminEditNewsComponent } from './admin-edit-news/admin-edit-news.component';
import { AdminEditEventComponent } from './admin-edit-event/admin-edit-event.component';
import { AdminTestimonialsComponent } from './admin-testimonials/admin-testimonials.component';
import { AdminAddTestimonialComponent } from './admin-add-testimonial/admin-add-testimonial.component';
import { AdminEditTestimonialComponent } from './admin-edit-testimonial/admin-edit-testimonial.component';
import { AdminEditPageComponent } from './admin-edit-page/admin-edit-page.component';
import { AdminAddVacancyComponent } from './admin-add-vacancy/admin-add-vacancy.component';
import { AdminEditVacancyComponent } from './admin-edit-vacancy/admin-edit-vacancy.component';
import { RemoveHtmlTagsPipe } from './remove-html-tags.pipe';
import { AdminEditCourseComponent } from './admin-edit-course/admin-edit-course.component';
import { AdminEditDiplomaComponent } from './admin-edit-diploma/admin-edit-diploma.component';
import { AdminAddProductComponent } from './admin-add-product/admin-add-product.component';
import { AdminAddFilesComponent } from './admin-add-files/admin-add-files.component';
import { AdminNewMessageComponent } from './admin-new-message/admin-new-message.component';
import { AdminMessagesComponent } from './admin-messages/admin-messages.component';
import { AdminAddCourseRoundComponent } from './admin-add-course-round/admin-add-course-round.component';
import { AdminEditCourseRoundComponent } from './admin-edit-course-round/admin-edit-course-round.component';
import { AdminUnusedCodesComponent } from './admin-unused-codes/admin-unused-codes.component';
import { AdminTestsComponent } from './admin-tests/admin-tests.component';
import { AdminEditExamComponent } from './admin-edit-exam/admin-edit-exam.component';
import { AdminAddExamComponent } from './admin-add-exam/admin-add-exam.component';
import { AdminSignInComponent } from './admin-sign-in/admin-sign-in.component';
import { StudentAndInstructorSignupCardsComponent } from './student-and-instructor-signup-cards/student-and-instructor-signup-cards.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AdminAddDiplomaRoundComponent } from './admin-add-diploma-round/admin-add-diploma-round.component';
import { AdminEditDiplomaRoundComponent } from './admin-edit-diploma-round/admin-edit-diploma-round.component';
import { AdminAddDiplomaRoundInstructorsComponent } from './admin-add-diploma-round-instructors/admin-add-diploma-round-instructors.component';





@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    AboutComponent,
    SchoolOfBusinessComponent,
    BusinessSolutionsComponent,
    CareersComponent,
    NewsAndEventsComponent,
    ContactComponent,
    FooterComponent,
    LcciComponent,
    UnderMaintenanceComponent,
    SignUpFormComponent,
    LcciSignInComponent,
    DiplomasCoursesComponent,
    DiplomaDetailsComponent,
    CourseDetailsComponent,
    CadresEnglishComponent,
    VersentTestComponent,
    PearsonTestComponent,
    GeneralEnglishComponent,
    BusinessEnglishComponent,
    BusinessConsultationComponent,
    UtilisingOurPlatformComponent,
    CadresSupportStartupsComponent,
    SurvivingCovid19Component,
    CorprateTrainingComponent,
    TrainingAssessmentComponent,
    MarketingComponent,
    BussinessAdministrationComponent,
    AccountingAndFinancingComponent,
    EventManagementComponent,
    RecruitmentComponent,
    WatsonGlaserComponent,
    IrisTestComponent,
    DifferentialTestComponent,
    SosieGenerationComponent,
    ConferencesComponent,
    SummitsComponent,
    WebinarsAndEconferencesComponent,
    ConcertsComponent,
    MarketingAndAdvertisementComponent,
    MarketResearchComponent,
    MediaAndContentProductionComponent,
    DigitalMarketingComponent,
    WebsitesAndApplicationsComponent,
    BusinessSolutionsVersentTestComponent,
    VacancyComponent,
    GetAQuoteComponent,
    BookAnAppointmentComponent,
    StrToArrPipe,
    PrivacyAndPolicyComponent,
    TermsAndConditionsComponent,
    ApplyAsInstructorComponent,
    PaymentGatewayComponent,
    ProductPlanComponent,
    LoadingScreenComponent,


    StudentDashboardBannerComponent,
    StudentDashboardSideBarComponent,
    StudentDashboardComponent,
    TruncateTextPipe,
    StudentCoursesComponent,
    StudentClassesComponent,
    StudentAssignmentsComponent,
    StudentExamsComponent,
    StudentQuizesComponent,
    StudentGradesComponent,
    StudentCertificatesComponent,
    StudentPaymentsComponent,
    StudentCourseComponent,
    StudentClassComponent,
    StudentAssignmentComponent,
    StudentMessageComponent,
    InstructorDashboardComponent,
    InstructorStudentsComponent,
    InstructorStudentComponent,
    InstructorClassesComponent,
    InstructorClassComponent,
    InstructorCourseComponent,
    InstructorEditClassComponent,
    StudentEditProfileComponent,
    InstructorEditProfileComponent,
    InstructorDashboardBannerComponent,
    InstructorDashboardSideBarComponent,
    InstructorCreateClassComponent,
    InstructorAddAssignmentComponent,
    InstructorAddMaterialComponent,
    InstructorAddQuizComponent,
    InstructorEditMaterialComponent,
    InstructorEditQuizComponent,
    InstructorEditAssignmentComponent,
    AdminDashboardBannerComponent,
    AdminDashboardSideBarComponent,
    AdminDashboardComponent,
    AdminProductComponent,
    AdminProductsComponent,
    AdminAddCourseComponent,
    AdminAddDiplomaComponent,
    AdminStudentsComponent,
    AdminInstructorsComponent,
    AdminVacanciesComponent,
    AdminRequestsComponent,
    AdminInstructorComponent,
    AdminStudentComponent,
    VerifyEmailComponent,
    AdminInProgressProductsComponent,
    AdminProductClassesComponent,
    AdminEnrollmentsComponent,
    AdminProductStudentsComponent,
    AdminPagesComponent,
    AdminRequestComponent,
    AdminNewsAndEventsComponent,
    NewsComponent,
    AdminNewsComponent,
    AdminEventComponent,
    AdminAddEventComponent,
    AdminAddNewsComponent,
    AdminEditNewsComponent,
    AdminEditEventComponent,
    AdminTestimonialsComponent,
    AdminAddTestimonialComponent,
    AdminEditTestimonialComponent,
    AdminEditPageComponent,
    AdminAddVacancyComponent,
    AdminEditVacancyComponent,
    RemoveHtmlTagsPipe,
    AdminEditCourseComponent,
    AdminEditDiplomaComponent,
    AdminAddProductComponent,
    AdminAddFilesComponent,
    AdminNewMessageComponent,
    AdminMessagesComponent,
    AdminAddCourseRoundComponent,
    AdminEditCourseRoundComponent,
    InstructorMessageComponent,
    AdminUnusedCodesComponent,
    AdminTestsComponent,
    AdminEditExamComponent,
    AdminAddExamComponent,
    AdminSignInComponent,
    StudentAndInstructorSignupCardsComponent,
    GalleryComponent,
    AdminAddDiplomaRoundComponent,
    AdminEditDiplomaRoundComponent,
    AdminAddDiplomaRoundInstructorsComponent,


    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],             
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],        
            [{ 'color': [] }, { 'background': [] }],        
            [{ 'align': [] }],
            ['link'] 
        ]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule
  ],
  providers: [
    Title,
    Globals,
    CookieService
    
  ],
  bootstrap: [AppComponent]
})


export class AppModule {}


                      