import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-edit-testimonial',
  templateUrl: './admin-edit-testimonial.component.html',
  styleUrls: ['./admin-edit-testimonial.component.css']
})
export class AdminEditTestimonialComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
		this.route.params.subscribe(params =>{
			this.testimonial_pk = params.id;			
		});
	}

	testimonial_pk:number;
	testimonial:any;

	form: FormGroup;
	uploadResponse:any;
		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	
    this.form = this.formBuilder.group({
      avatar: ['']
    });
    
    this.apiService.getTestimonial_byTestimonialId(this.testimonial_pk).subscribe(response=>{	
		this.testimonial = response[0];	
		console.log(this.testimonial);
		
	});
  }



getEditTestimonial=new FormGroup({
  name:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  text:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
});

  editTestimonial(){
    const testimonialImg = new FormData();
    testimonialImg.append('avatar', this.form.get('avatar').value);
    this.apiService.editTestimonial(this.testimonial_pk, this.getEditTestimonial.value, testimonialImg).subscribe(response=>{
    	console.log(response);
    });
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    }
  }

}
