
<div class="banner_wrapper" [ngStyle]="globals.dashboardTheme_navBar">
	<div class="banner_content">
		<div (click)="showMenu()" class="hambIcon">
			<img src="../../assets/icons/hamb.svg">
		</div>
		<div class="icons" id="logo_icon" routerLink="/home">
            <img src="../../assets/icons/logo_blue.svg" alt="">
		</div>
		<div class="icons" id="notification_icon">
			<img src="../../assets/icons/notification.svg" alt="">
		</div>
		<div class="icons" id="mail_icon">
			<img src="../../assets/icons/mail.svg" alt="">
		</div>
		<div class="icons" id="calander_icon">
			<img src="../../assets/icons/calander.svg" alt="">
		</div>
		<div class="icons" id="user_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
              <g id="user_pp" transform="translate(-17)">
                <g id="notification" transform="translate(17 -21)">
                </g>
                <g id="Icon_feather-user-check" data-name="Icon feather-user-check" transform="translate(32.973 9)">
                  <path id="Path_1578" data-name="Path 1578" d="M37.027,36.711V31.974A9.474,9.474,0,0,0,27.553,22.5H10.974A9.474,9.474,0,0,0,1.5,31.974v4.737" transform="translate(0 10.422)" fill="none" stroke="#449DD1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_1579" data-name="Path 1579" d="M25.7,13.974A9.474,9.474,0,1,1,16.224,4.5,9.474,9.474,0,0,1,25.7,13.974Z" transform="translate(3.04)" fill="none" stroke="#449DD1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </g>
            </svg>

            <ul class="dropdown" [ngStyle]="globals.dashboardTheme_cards">
            	<div>
				  <li routerLink="student-dashboard/edit-profile">My Profile</li>
				  <li routerLink="student-dashboard/courses">My Courses</li>
				  <li routerLink="student-dashboard/payments">My Payments</li>
				  <li (click)="showToggles()">Displays</li>
				</div>
				 <li id="coursesAndDiplomas">Brows<span>e Courses & Dip</span>lomas</li>
				 <button id="btn1"><p>Sign Out</p></button>
			  </ul>
			  <ul class="dropdown toggles" [style.display]="toggles_display" [ngStyle]="globals.dashboardTheme_cards">
			  	<div (click)="showToggles()" id="back_btn">
			  		<!-- <img src="../assets/imgs"> -->
			  		<p>Back</p>
			  	</div>
		  		<div class="toggle_parent">
			  		<p>Default</p>
				  	<label class="toggle">
						<input type="checkbox" [checked]="isDefaultTheme" (click)="storeToggleTheme('default')">
						<span class="checkmark"></span>
					</label>
				</div>

				<div class="toggle_parent">
					<p>Dark</p>
					<label class="toggle">
						<input type="checkbox" [checked]="isDarkTheme" (click)="storeToggleTheme('dark')">
						<span class="checkmark"></span>
					</label>
				</div>

				<div class="toggle_parent">
					<p>Light</p>
					<label class="toggle">
						<input type="checkbox" [checked]="isLightTheme" (click)="storeToggleTheme('light')">
						<span class="checkmark"></span>
					</label>
				</div>
			  </ul>
		</div>
	</div>
</div>

<div class="menu_mobile_wrapper" id="menu_mobile_wrapper" *ngIf="menuWrapper_display == 'block'" [ngStyle]="globals.dashboardTheme_navBar">
	<div class="menu_mobile_content">
		<a id="dashBoard" routerLink="/student-dashboard" (click)="showMenu()">Dashboard</a>
		<a id="courses" routerLink="/student-dashboard/courses" (click)="showMenu()">Courses</a>
		<a id="classes" routerLink="/student-dashboard/classes" (click)="showMenu()">Classes</a>
		<a id="assignments" routerLink="/student-dashboard/assignments" (click)="showMenu()">Assignments</a>
		<a id="exams" routerLink="/student-dashboard/exams" (click)="showMenu()">Exams</a>
		<a id="quizes" routerLink="/student-dashboard/quizes" (click)="showMenu()">Quizes</a>
		<a id="grades" routerLink="/student-dashboard/grades" (click)="showMenu()">Grades</a>
		<a id="payments"> <!-- routerLink="./payments"> -->Payments</a>
		<a id="signOut" href="">Sign Out</a>
		<a id="settings" href="">Settings</a>
		<a id="help" href="">Help</a>
	</div>
</div>
