import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-messages',
  templateUrl: './admin-messages.component.html',
  styleUrls: ['./admin-messages.component.css']
})
export class AdminMessagesComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  messages:any;
  
  searchText:string;
  
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getMessages().subscribe(response=>{	  		  	
  		this.messages = response.data;
  	});
  }

  deleteMessage(pk):void{      	
  	this.apiService.deleteMessage(pk).subscribe(response=>{	  		  	
  		console.log(response);  		
	});
  }

}
