import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'instructor-create-class',
  templateUrl: './instructor-create-class.component.html',
  styleUrls: ['./instructor-create-class.component.css']
})
export class InstructorCreateClassComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}


	form: FormGroup;
	uploadResponse:any;

  diplomaCourses:any = [];

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.form = this.formBuilder.group({
      material: [''],
      video: ['']
    });
  }



getCreateClass=new FormGroup({
  name:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  description:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  time:new FormControl('',[Validators.required ,Validators.email]),
  date:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  zoomLink:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  videoPath:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
   status:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
   diploma_course_instructor_fk:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),

});


createClass(){
    if(this.globals.createClassRoundType_value == 'course') {
      this.apiService.createCourseClass(this.getCreateClass.value, this.globals.createClassRoundFk_value).subscribe(response=>{
        console.log(response);
      });
    }else if(this.globals.createClassRoundType_value == 'diploma'){
      this.apiService.createDiplomaCourseClass(this.getCreateClass.value, this.getCreateClass.value.diploma_course_instructor_fk).subscribe(response=>{
      	console.log(response);
      });
    }
    // this.uploadMaterials();
  }

   hideCreatePopUp(){
  		this.globals.createClassWrapper_display = "none";
	}



  onFileSelect(event, fileAttrName) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];     
      this.form.get(fileAttrName).setValue(file);
    }
  }

  uploadMaterials() {
    const formDataMaterial = new FormData();
    formDataMaterial.append('material', this.form.get('material').value);
    // const formDataVideo = new FormData();
    // formDataVideo.append('video', this.form.get('video').value);
    console.log("formdata");
    
    console.log(this.form);

    this.apiService.uploadCourseMaterials(this.globals.createClassRoundFk_value, this.globals.createClassFk_value, 'material', formDataMaterial).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
    // this.apiService.uploadClassVideo(this.globals.createClassRoundFk_value, this.globals.createClassFk_value, formDataVideo).subscribe(
    //   (res) => {
    //     this.uploadResponse = res;
    //       console.log(res);
    //   },
    //   (err) => {  
    //     console.log(err);
    //   }
    // );
  }

}
