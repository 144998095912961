
<section class="sect1_wrapper">
	<div class="bg"><img src="../../assets/backgrounds/business-consultation/sect1.svg" alt=""></div>
	<div class="sect1_content">
		<div class="info">
			<h1>The key word is Business Consultations</h1>
			<p>
				Cadres School of Business provides business owners with a free to use 
				platform helping them grow their businesses through live chatting with our 
				consultants. Consultations differ from getting the cheapest item in the 
				supermarket to business planning, marketing strategic planning, and 
				recruiting the best employees matching your company needs with the 
				market demands.  Real-life meetings with our consultants could be booked 
				to facilitate the planning process for expanding your business
			</p>
		</div>
		<div class="illustration"><img src="../../assets/imgs/business-consultation/sect1.svg" alt=""></div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="bg" id="bg1">
		<img src="../../assets/backgrounds/business-consultation/sect2.svg" alt="">
	</div>
	<div class="bg" id="bg2">
		<img src="../../assets/backgrounds/business-consultation/sect1.svg" alt="">
	</div>
	<div class="sect2_content">
		<div class="cards" id="card1">
			<div class="img">
				<img src="../../assets/imgs/business-consultation/card1.png" alt="">
			</div>
			<div class="info">
				<h1>How to utilise the use of our platform</h1>
				<p>
					Cadres is a highly dynamic website made to give our users the best experience they could ever get from a website, it’s very responsive and handy, the designs we use make it much easier for the users to actually get what they want, you can finalize payment, book … Read More 
				</p>
				<button routerLink="./utilising-our-platform">
					Read More
					<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
				</button>
			</div>
		</div>
		<div class="cards" id="card2">
			<div class="info">
				<h1>How Cadres supports startups</h1>
				<p>
					In Cadres Business Solutions, our corporate responsibility is all about supporting start-ups to push the whole economy forward and boost the domestic products and services microeconomy. Cadres totally funds digital marketing activities for Egyptian Start-ups.  Read More
				</p>
				<button routerLink="./cadres-support-startups">
					Read More
					<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
				</button>
			</div>
			<div class="img">
				<img src="../../assets/imgs/business-consultation/card2.png" alt="">
			</div>
		</div>
		<div class="cards" id="card3">
			<div class="img">
				<img src="../../assets/imgs/business-consultation/card3.png" alt="">
			</div>
			<div class="info">
				<h1>How Businesses can survive COVID-19 crises</h1>
				<p>
					During the pandemic of COVID-19 most companies are 
					suffering from a massive crisis. The percussions people 
					have been taken to slow down the spread of COVID-19 
					could be totally destructive for businesses. Since we 
					cannot blame the public for being cautious about their 
					wellness … Read More
				</p>
				<button routerLink="./surviving-covid19">
					Read More
					<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
				</button>
			</div>
		</div>
	</div>
</section>