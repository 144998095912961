import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-consultation',
  templateUrl: './business-consultation.component.html',
  styleUrls: ['./business-consultation.component.css']
})
export class BusinessConsultationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

}
