
<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="form_wrapper">
			<div class="form_content">
				<form [formGroup]="getContact" (ngSubmit)="fromData()">
					<div>
						<legend>Full Name:</legend>
						<input type="text" placeholder="John Doe" formControlName="fullName">
					</div>
					<div>
						<legend>Phone Number:</legend>
						<input type="text" placeholder="010*******" formControlName="phoneNumber">
					</div>
					<div>
						<legend id="email_legend">Email:</legend>
						<input type="text" placeholder="Example@gmail.com" formControlName="email">
					</div>
					<div>
						<legend>Company</legend>
						<input type="text" placeholder="Cadres" formControlName="companyName">
					</div>
					<div id="message">
						<legend style="float: none;">Message</legend>
						<textarea name="" id="" cols="30" rows="10" placeholder="Hi there… write your message here……" formControlName="message"></textarea>
					</div>
					<input type="submit" name="" value="Send">
				</form>	
			</div>
		</div>
		<div class="details_wrapper">
			<div class="details_content">
				<p class="title" id="phoneNumber">Phone Number:</p>
				<p>+201094988474 / +201090773522 <br> +0224473000 / +0224474555</p>
				<p class="title">Email:</p>
				<p>Info@cadreseg.com</p>
				<p class="title">Address:</p>
				<p>3065, 3rd Neighborhood, El-Mearag City, <br> New Maadi Behind Maadi City Centre</p>
				<p class="title">Location:</p>
				<div class="map">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.8173642261327!2d31.306320114618476!3d29.984678328349567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458399a311b604d%3A0x8cc7850122a593d!2sCadres!5e0!3m2!1sen!2seg!4v1594903254891!5m2!1sen!2seg"  frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
				</div>
			</div>
		</div>
	</div>
</div>