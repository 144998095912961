import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'student-dashboard-banner',
  templateUrl: './student-dashboard-banner.component.html',
  styleUrls: ['./student-dashboard-banner.component.css']
})
export class StudentDashboardBannerComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) {}

	menuWrapper_display:string = "none";

  isDefaultTheme:boolean = true;
  isDarkTheme:boolean = false;
  isLightTheme:boolean = false;
  toggles_display:string = "none";


  ngOnInit(): void {
    // this.apiService.setLogged(true, "10", "student");
    this.isDefaultTheme = localStorage.getItem('theme') === "default" ? true : false;
    this.isDarkTheme = localStorage.getItem('theme') === "dark" ? true : false;
    this.isLightTheme = localStorage.getItem('theme') === "light" ? true : false;
  }

    showMenu(){
      
    if (this.menuWrapper_display == "block"){
      this.menuWrapper_display = "none";
    }else if (this.menuWrapper_display == "none"){
       this.menuWrapper_display = "block";
    }
      // console.log(this.menuWrapper_display);
  }

  showToggles(){
     this.toggles_display = this.toggles_display === "block" ? "none" : "block";
  }

  storeToggleTheme(theme){
    console.log("Light " + this.isLightTheme);
    console.log("Default " + this.isDefaultTheme);
    console.log("Dark " + this.isDarkTheme);
    
    if(theme == "dark") {
      this.isDarkTheme = true;
      this.isLightTheme = false;
      this.isDefaultTheme = false;
      localStorage.setItem('theme', "dark");      
    }else if(theme == "light") { 
      this.isLightTheme = true;     
      this.isDarkTheme = false;
      this.isDefaultTheme = false;
      localStorage.setItem('theme', "light");
    }
    else if(theme == "default") {
      this.isDefaultTheme = true;
      this.isLightTheme = false;
      this.isDarkTheme = false;
      localStorage.setItem('theme', "default");
    }
  }

}
