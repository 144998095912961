import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { FormBuilder, FormGroup } from  '@angular/forms';
import {Globals} from '../globals';

@Component({
  selector: 'app-pearson-test',
  templateUrl: './pearson-test.component.html',
  styleUrls: ['./pearson-test.component.css']
})
export class PearsonTestComponent implements OnInit {

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, public globals: Globals) {}

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

   gateWay(display):void {   
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
  }

    lcciPlan(display):void {
      if(display == "flex") {
        this.globals.productChosen_name = "Pearson Test of English Language";
        this.globals.productChosen_learningHours = "N/A";
        this.globals.productChosen_level = "N/A";          
        this.globals.productChosen_suite = "N/A";          
        this.globals.productChosen_price = "300";
        this.globals.productChosen_registrationFees = "N/A";
        if(this.apiService.loggedIn_user()["pk"] != ""){          
          this.globals.signWrapperLcci_display = "none";
          this.globals.lcciPlanWrapper_display = display;
        }else{
          this.globals.signWrapperLcci_display = "flex";
        }
      }else{
        this.globals.lcciPlanWrapper_display = display;
        this.globals.signWrapperLcci_display = display;
      }
    }


  showHideSignWrapper(display){
    this.globals.signInCard_display = "block";
    this.globals.signWrapper_display = display;
    this.gateWay('none');
    this.lcciPlan('none');
  }

}
