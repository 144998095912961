import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit  {

  constructor(private apiService: ApiService, public globals: Globals, private elementRef: ElementRef) { }

  vacancies:string;

  ngOnInit(): void { 
	window.scrollTo(0, 0);
	this.apiService.getVacancies().subscribe(response=>{		
		this.vacancies = response.data;					
	}); 

  }
	// openVacancy(pk):void{
	// 	this.globals.vacancyPk_value = pk;
	// }

  showApplyInstructorWrapper(display){
    this.globals.applyInstructor_display = display;
  }

}
