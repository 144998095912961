import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-add-course-round',
  templateUrl: './admin-add-course-round.component.html',
  styleUrls: ['./admin-add-course-round.component.css']
})
export class AdminAddCourseRoundComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
	}

	instructors:any;
	courses:any;


  ngOnInit(): void {
  	window.scrollTo(0, 0);
	this.apiService.getCourses().subscribe(response=>{	
		this.courses = response.data;
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
	});
  }


  getAddRound=new FormGroup({
  roundName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  course_fk:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  instructor_fk:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  maxCapacity:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  startDate:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  addRound(){
    this.apiService.createCourseRound(this.getAddRound.value).subscribe(response=>{
    	console.log(response);
    });
  }

}
