import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-instructor-dashboard',
  templateUrl: './instructor-dashboard.component.html',
  styleUrls: ['./instructor-dashboard.component.css']
})
export class InstructorDashboardComponent implements OnInit {

  constructor(private apiService: ApiService) {}

    instructor_pk:string = this.apiService.loggedIn_user()["pk"];


  messagesPlaceHolder_display:string = "block";

    instructor:any;

  courses:any;

  classes:any;

  messages:any;


    
  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.apiService.getInstructor_byInstructorId(this.instructor_pk).subscribe(response=>{  
    this.instructor = response.data[0];
  });

  this.apiService.getCourses_byInstructortId(this.instructor_pk).subscribe(response=>{  
    this.courses = response.data;
    console.log(this.courses);
    
    // this.courses_placementTestGrade = this.courses.firstName;
  });

    this.apiService.getClasses_byInstructortId(this.instructor_pk).subscribe(response=>{    
    this.classes = response;
  });

  this.apiService.getMessages_byInstructorId(this.instructor_pk).subscribe(response=>{    
    if(response.message) {
      this.messagesPlaceHolder_display = "block";
    }else{
      this.messagesPlaceHolder_display = "none";
      this.messages = response.data;
      
    }
    
  });
  
  }

  deleteMessage(pk):void{       
    this.apiService.deleteMessage_byInstructorId(this.instructor_pk, pk).subscribe(response=>{            
      console.log(response);      
  });
  }

}
