import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'app-admin-add-diploma-round-instructors',
  templateUrl: './admin-add-diploma-round-instructors.component.html',
  styleUrls: ['./admin-add-diploma-round-instructors.component.css']
})
export class AdminAddDiplomaRoundInstructorsComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	diploma_fk:any;
	diplomas:any;
	courses:any;
	instructors:any;

  assignedInstructors:any = {};

  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getDiplomas().subscribe(response=>{	
		this.diplomas = response.data;
	});
	this.apiService.getDiplomaCourses_byDiplomaId(this.globals.diplomaRoundDiplomaFk_value).subscribe(response=>{	
		this.courses = response.data;
    console.log(this.courses);
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
	});
  }


getAddRound=new FormGroup({
  diploma_fk:new FormControl(null),
  roundName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  assignedInstructors:new FormControl(null),
  maxCapacity:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  startDate:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  addRound(){
    this.getAddRound.value.diploma_fk = this.globals.diplomaRoundDiplomaFk_value;
    this.getAddRound.value.roundName = this.globals.diplomaRoundName_value;
    this.getAddRound.value.assignedInstructors = this.assignedInstructors;
    this.getAddRound.value.maxCapacity = this.globals.diplomaRoundMaxCapacity_value;
    this.getAddRound.value.startDate = this.globals.diplomaRoundStartDate_value;
    console.log(this.getAddRound.value);
    
    this.apiService.createDiplomaRound(this.getAddRound.value).subscribe(response=>{
    	console.log(response);
      this.router.navigateByUrl('/admin-dashboard/in-progress-products'); 
    });
  }

  assignInstructor(diploma_course_pk, instructor_fk){
    console.log("blahh");
    if(this.assignedInstructors[diploma_course_pk]){
      this.assignedInstructors[diploma_course_pk] = instructor_fk;
    }else{
      this.assignedInstructors[diploma_course_pk] = instructor_fk;
    }
    // this.assignedInstructors["diploma_course_pk"].push(instructor_fk);
    console.log(this.assignedInstructors);
    
  }

}