import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-grades',
  templateUrl: './student-grades.component.html',
  styleUrls: ['./student-grades.component.css']
})
export class StudentGradesComponent implements OnInit {

  
  constructor(private apiService: ApiService, public globals: Globals) { }

  courses:any;

	grades:any;

  ngOnInit(): void {
  	 window.scrollTo(0, 0);

	this.apiService.getCourses_byStudentId(1).subscribe(response=>{	
		this.courses = response.data;
	});

  	this.apiService.getGrades_byStudentId(1).subscribe(response=>{		
		this.grades = response;
		console.log(this.grades);
		// var temp:any = [];	
		// for (var i in this.grades) {		
		// 	if(temp[this.grades[i].courseName]){
		// 		temp[this.grades[i].courseName].push(this.grades[i]);
		// 	}else{
		// 		temp[this.grades[i].courseName] = [];
		// 		temp[this.grades[i].courseName].push(this.grades[i]);
		// 	}
		// }
		// this.grades = temp;

		
	});

  }

}
