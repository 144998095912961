
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Summits</h1>
			<p>
				Summits are held for people to come together with the purpose 
				of learning and sharing different ideas and concepts. They are a 
				great opportunity for organizations and businesses to see how 
				far they have achieved, and understand more about the work of 
				others in their business fields to identify where to direct their 
				focus
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/concerts/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Summits are held for people to come together with the purpose of learning and sharing different ideas and concepts. They are a great opportunity for organizations and businesses to see how far they have come, and understand more about the work of others in their business fields to identify where to direct their focus. It is more than a simple meeting with lecture style speeches, and a key point for companies to be driven forward. Therefore, the cost and time spent in hosting a summit is definitely worth it. And here comes our role, where we organize every aspect of the event
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/summits/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info" id="info1">
			<div>
				<p><span>Production</span> companies</p>
			</div>
			<div>
				<p><span>Invitation</span> cards</p>
			</div>
			<div>
				<p><span>Menu</span></p>
			</div>
			<div>
				<p><span>Entertainment</span> booking</p>
			</div>
		</div>
		<div class="info" id="info2">
			<div>
				<p><span>Guest</span> list</p>
			</div>
			<div>
				<p><span>Online</span> marketing</p>
			</div>
			<div>
				<p><span>Housekeeping</span></p>
			</div>
			<div>
				<p><span>Manage</span> audio visual equipment</p>
			</div>
		</div>
		<div class="info" id="info3">
			<div>
				<p><span>Summit</span> theme, logo and branding</p>
			</div>
			<div>
				<p><span>Online</span> booking</p>
			</div>
			<div>
				<p><span>Ushering</span> arrangement</p>
			</div>
			<div>
				<p><span>Detailed</span> financial records</p>
			</div>
		</div>
		<div class="info" id="info4">
			<div>
				<p><span>Summit</span> venue booking</p>
			</div>
			<div>
				<p><span>Decoration</span> plan</p>
			</div>
			<div>
				<p><span>Security</span> and safety plans</p>
			</div>
			<div>
				<p><span>Audit</span> coordination </p>
			</div>
		</div>
		<div class="info" id="info5">
			<div>
				<p><span>Accommodation</span> booking</p>
			</div>
			<div>
				<p><span>Banquet</span> and Catering</p>
			</div>
			<div>
				<p><span>First</span> aid</p>
			</div>
			<div>
				<p><span>Media</span> press</p>
			</div>
		</div>
	</div>
</section>