import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-pages',
  templateUrl: './admin-pages.component.html',
  styleUrls: ['./admin-pages.component.css']
})
export class AdminPagesComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  pages:any;
  
  searchText:string;

  ngOnInit(): void {
	this.apiService.getPages().subscribe(response=>{	
		this.pages = response;
		console.log(this.pages);
		
	});
  }

}
