import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { FormBuilder, FormGroup } from  '@angular/forms';
import {Globals} from '../globals';

@Component({
  selector: 'app-business-english',
  templateUrl: './business-english.component.html',
  styleUrls: ['./business-english.component.css']
})
export class BusinessEnglishComponent implements OnInit {

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, public globals: Globals) {}

  registrationFees:any = {"2": "80", "3": "90", "4": "100"};
  courseName:string = "Business English";
  courses:any;
  coursesAssoc:any;


  turn2dArrayToAssoc2dArray(array) {  
    var temp:any = [];  
    for (var i in array) {          
      if(temp[array[i].courseName]){
        temp[array[i].courseName].push(array[i]);
      }else{
        temp[array[i].courseName] = [];
        temp[array[i].courseName].push(array[i]);
      }
    }
    return temp;
  }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
    this.apiService.getCourses().subscribe(response=>{        
      this.courses = response.data;
      this.coursesAssoc = this.turn2dArrayToAssoc2dArray(this.courses);       
    });
  }

   gateWay(display):void {   
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
  }

    lcciPlan(display):void {          
      if(display == "flex") {              
        this.globals.productChosen_name = this.coursesAssoc[this.courseName][0].courseName;
        this.globals.productChosen_learningHours = this.coursesAssoc[this.courseName][0].learningHours;
        this.globals.productChosen_level = this.coursesAssoc[this.courseName][0].level;          
        this.globals.productChosen_suite = this.coursesAssoc[this.courseName][0].suite;          
        this.globals.productChosen_price = this.coursesAssoc[this.courseName][0].price;              
        this.globals.productChosen_registrationFees = this.registrationFees[this.globals.productChosen_level];
        if(this.apiService.loggedIn_user()["pk"] != ""){          
          this.globals.signWrapperLcci_display = "none";
          this.globals.lcciPlanWrapper_display = display;
        }else{
          this.globals.signWrapperLcci_display = "flex";
        }
      }else{
        this.globals.lcciPlanWrapper_display = display;
        this.globals.signWrapperLcci_display = display;
      }
    }


  showHideSignWrapper(display){
    this.globals.signInCard_display = "block";
    this.globals.signWrapper_display = display;
    this.gateWay('none');
    this.lcciPlan('none');
  }

}
