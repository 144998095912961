

<div [style.display] = "getQuote_display" class="getQuote_wrapper" id="getQuote_wrapper" onload="setTitle('Careers', true)">
	<img (click)="show('hide')" id="cross_icon" src="../../assets/icons/cross_orange.svg">
	<div class="getQuote_content">
		<form [formGroup]="getQuote" (ngSubmit)="fromData()">
			<div>
				<h1>Get A Quote</h1>
			</div>
			<fieldset id="fullName">
				<legend>Full Name</legend>
				<input type="text" placeholder="John" formControlName="fullName">
				<div *ngIf="getQuote.controls.fullName.errors && getQuote.controls.fullName.touched" class="alert"
						role="alert">
					Full Name is Required
				</div>
			</fieldset>
			<fieldset id="title">
				<legend>Title</legend>
				<input type="text" placeholder="Doe" formControlName="title">
				<div *ngIf="getQuote.controls.title.errors && getQuote.controls.title.touched" class="alert "
						role="alert">
					Title is Required
				</div>
			</fieldset>
			<fieldset id="email">
				<legend>Email</legend>
				<input type="text" placeholder="john.doe@email.com" formControlName="email">
				<div *ngIf="getQuote.controls.email.errors && getQuote.controls.email.touched" class="alert "
						role="alert">
					Email is Required
				</div>
			</fieldset>
			<fieldset id="companyName">
				<legend>Company Name</legend>
				<input type="text" placeholder="" formControlName="companyName">
				<div *ngIf="getQuote.controls.companyName.errors && getQuote.controls.companyName.touched" class="alert "
						role="alert">
					Company Name is Required
				</div>
			</fieldset>
			<fieldset id="functions">
				<legend>Functions</legend>
				<input type="text" placeholder="" formControlName="functions">
				<div *ngIf="getQuote.controls.functions.errors && getQuote.controls.functions.touched" class="alert "
						role="alert">
					Functions is Required
				</div>
			</fieldset>
			<fieldset id="industry">
				<legend>Industry</legend>
				<input type="text" placeholder="" formControlName="industry">
				<div *ngIf="getQuote.controls.industry.errors && getQuote.controls.industry.touched" class="alert "
						role="alert">
					Industry is Required
				</div>
			</fieldset>
			<div class="dateOfBirth">
				<p id="title">Date of Birth</p>
				<input type="date" min="" step="1" value="dd/mm/yyyy" formControlName="dateOfBirth">
				<div *ngIf="getQuote.controls.dateOfBirth.errors && getQuote.controls.dateOfBirth.touched" class="alert "
					role="alert">
					Date of Birth is Required
				</div>
			</div>
			  <!-- <input type="radio"> -->
<!-- 		<div class="ts-cs">
			<input type="checkbox" id="ts-cs_student" name="gender" value="ts-cs">
			<label for="ts-cs">I agree to terms and conditions</label>
		</div> -->
		<div class="radios">
		  <input type="radio" id="individual" name="businessType" formControlName="businessType" value="individual" (click)="extraRadios('none')" checked>
		  <label (click)="extraRadios('none')" for="individual">Individual</label><br>
		  <input type="radio" id="corprate" name="businessType" formControlName="businessType" value="corprate" (click)="extraRadios('flex')">
		  <label (click)="extraRadios('flex')" for="corprate">Corprate</label><br>  
		</div>

		<div class="radios" [style.display]="extraRadios_display">
			<div class="left">
				<p>Business Size</p>
				<input type="radio" id="micro" formControlName="businessSize" name="businessSize" value="micro" checked>
				<label for="micro">Micro Business</label><br>
				<input type="radio" id="small" formControlName="businessSize" name="businessSize" value="small">
				<label for="small">Small Business</label><br>
				<input type="radio" id="medium" formControlName="businessSize" name="businessSize" value="medium">
				<label for="medium">Medium Business</label><br>
				<input type="radio" id="corprate" formControlName="businessSize" name="businessSize" value="corprate">
				<label for="corprate">Corprate</label><br>  
			</div>
			<div class="right">
				<p>Revenues annually</p>
				<input type="radio" id="micro" formControlName="revenueSize" name="revenueSize" value="micro" checked>
				<label for="micro">Micro Business</label><br>
				<input type="radio" id="small" formControlName="revenueSize" name="revenueSize" value="small">
				<label for="small">Small Business</label><br>
				<input type="radio" id="medium" formControlName="revenueSize" name="revenueSize" value="medium">
				<label for="medium">Medium Business</label><br>
				<input type="radio" id="corprate" formControlName="revenueSize" name="revenueSize" value="corprate">
				<label for="corprate">Corprate</label><br>  
			</div>
		</div>

			<input type="submit" value="Submit">
		</form>
	</div>
</div>

