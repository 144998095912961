
<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Event Management</h1>
			<p>
				Cadres gives you the opportunity to have your events managed and executed by a team of competent and experienced event managers. Small or large-scale events ranging from gigs, concerts, seminars, summits and more will be completely handled and made to fit the criteria set by our customers within the given timelines. Our expertise and qualifications fused together results in an outcome that surpasses expectations.
			</p>
		</div>
		<div class="illustration"><img src="../../assets/imgs/event-management/sect1.svg" alt=""></div>

	</div>
</div>

<div class="bg">
	<img src="../../assets/backgrounds/event-management/sect2-3.svg">

</div>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info" id="info1">
			<div class="icon"></div>
			<h1>Conferences:</h1>
			<p>
				Industry conferences are the perfect opportunity for companies and startups to gain more experience in the field they are in. No matter how much experience a company has… <a id="readMore" routerLink="./conferences">Read More</a>
			</p>
		</div>
		<hr>
		<div class="info" id="info2">
			<div class="icon"></div>
			<h1>Summits:</h1>
			<p>
				Summits are held for people to come 
				together with the purpose of learning and 
				sharing different ideas and concepts. 
				They are a great opportunity for 
				organizations and… <a id="readMore" routerLink="./summits">Read More</a> 
			</p>
		</div>
	</div>
</section>
<hr>
<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info" id="info1">
			<div class="icon"></div>
			<h1>Webinars and E-conferences:</h1>
			<p>
				Cadres hosts business webinars to elevate Egypt’s economy. We stretch a helping hand for Egyptian Start-ups to collaborate for mutually beneficial relations. We invite… <a id="readMore" routerLink="./webinars-and-econferences">Read More</a>
			</p>
		</div>
		<hr>
		<div class="info" id="info2">
			<div class="icon"></div>
			<h1>Concerts:</h1>
			<p>
				Organizing a concert or a gig with the proper content that could keep the audience engaged, whether it is a play, track or other materials are essential for a successful event. <a id="readMore" routerLink="./concerts">Read More</a>
			</p>
		</div>
	</div>
</section>