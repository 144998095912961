import {NgModule} from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
	imports: [MatRadioModule, MatSelectModule],
	exports: [MatRadioModule, MatSelectModule],
})
export class MaterialModule {}