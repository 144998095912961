
<get-a-quote [style.display] = "globals.getQuoteWrapper_display"></get-a-quote>


<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Cadres provide skilled expertise to gather information about your business potential buyers’ persona, target audience, competitor analysis, and customers’ needs to determine how feasible and successful your product or service are. As we conduct competent marketing and management analysis, to ensure the buying behaviour in addition to customer influences regarding their decisions to accomplish higher business conversions
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/digital-marketing/sect1.png" alt="">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sections_wrapper">
	<div class="sections_content">
		<p [style.box-shadow]="boxShadow_seoAndSem" (click)='changeBody("SEO & SEM")' id="seoAndSem">SEO & SEM</p>
		<p [style.box-shadow]="boxShadow_digitalMediaBuyingAndPlanning" (click)='changeBody("Digital Media buying and Planning")' id="digitalMediaBuyingAndPlanning">Digital Media buying and Planning</p>
		<p [style.box-shadow]="boxShadow_socialMediaManagement" (click)='changeBody("Social Media Management")' id="socialMediaManagement">Social Media Management</p>
	</div>
</section>


<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>{{sect2_title}}</h1>
			<p>{{sect2_para1}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/digital-marketing/{{sect2_img}}" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<p>{{sect3_para1}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/digital-marketing/{{sect3_img}}" alt="">
		</div>
	</div>
</section>

<section [style.display]="display1" class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p>{{sect4_para1}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/digital-marketing/{{sect4_img}}" alt="">
		</div>
	</div>
</section>

<section [style.display]="display2" class="sect4-2_wrapper">
	<div class="sect4-2_content">
		<div class="info">
			<p>{{sect4_para1}}</p>
		</div>
	</div>
</section>


<button (click)="show('show')">
		Get a Quote
</button>
