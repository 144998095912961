
<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<p>My Classes</p>
			</div>
		</div>

		<div class="coursesProgress">
			<div class="courseProgress" *ngFor="let course of courses; index as i" id="courseProgress{{i+1}}" routerLink="/student-dashboard/course/{{course.courseName}}" [ngStyle]="globals.dashboardTheme_cards">	
				<p id="title">{{course.courseName | truncateText}}</p>
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "course.progress"></div>
					</div>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Upcoming Class</p>
		</div>

		<div class="coursesSummary" *ngFor="let course of courses; index as i" routerLink="/student-dashboard/class/{{course.courseName}}" [ngStyle]="globals.dashboardTheme_cards">
			<div class="summaries" id="summary1">
				<p>{{i+1}}. {{course.courseName  | truncateText}}</p>
			</div>
			<div class="summaries" id="summary2" *ngFor="let class of classes[course.courseName] | slice:0:3;">
				<p>{{class.date}}</p>
				<p>{{class.time}}</p>
			</div>
		</div>

	</div>
</div>