
<section [style.display]="globals.gatewayWrapper_display" class="paymentGateway_wrapper">
	<img id="cross_icon" src="../../assets/icons/cross_blue.svg" (click)="gateWay('none')">
	<div class="paymentGateway_content">
		<h1>Enroll</h1>
		<p>{{globals.productChosen_name}}</p>
		<p>Learing Hounrs:  {{globals.productChosen_learningHours}}</p>
		<p>Price: ${{globals.productChosen_price * discount}}</p>
		<div class="redeemCode_container">
			<h3>Add Promo Code</h3>
			<input type="text" name="" [(ngModel)]="discountCode">
			<button (click)="redeemCode()">Redeem</button>
		</div>
		<div class="inputs uploadSig_container">
			<h3>Upload Signature</h3>
			<div class="canvas_container">
				<p>Sign Below</p>
				<canvas #signatureCanvas></canvas>
				<button (click)="clearCanvas()">Clear</button>
			</div>	
			<p>Or</p>
			<input (change)="onFileSelect($event)" onchange="file()" id="upload_input" type="file" name="file"/>
		</div>
		<div class="ts-cs">
			<input (click)="buttonState()" type="checkbox" id="ts-cs_student" name="gender" value="ts-cs" [checked]="isChecked">
			<label for="ts-cs">I agree to terms and conditions</label>
		</div>
		<div #paypalRef [style.display]="payButton_display"> </div>
		<!-- <button (click)="pay(50)" class="btn btn-info btn-block">Pay $50</button> -->
	</div>
</section>