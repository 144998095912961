import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-product-classes',
  templateUrl: './admin-product-classes.component.html',
  styleUrls: ['./admin-product-classes.component.css']
})
export class AdminProductClassesComponent implements OnInit {

	constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
			this.productType = params.productType;
			this.productName = params.productName;			
		});
	}

	productType:string;
	productName:string;
	product:any;
	classes:any;

	searchText:string;

	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void {
  	window.scrollTo(0, 0);  	
  // 	if(this.productType == 'course') {
	 //  	this.apiService.getClasses().subscribe(response=>{	
		// 	this.classes = response;
		// 	this.classes = this.turn2dArrayToAssoc2dArray(this.classes)[this.productName];
			
		// });
  // 	}else if(this.productType == 'diploma') {  		
  // 		this.apiService.getClasses_byDiplomaRoundId().subscribe(response=>{	
		// 	this.classes = response;		
		// 	var temp:any = [];		
		// 	for (var i in this.classes) {										
		// 		if(temp[this.classes[i].diplomaName]){
		// 			temp[this.classes[i].diplomaName].push(this.classes[i]);
		// 		}else{
		// 			temp[this.classes[i].diplomaName] = [];
		// 			temp[this.classes[i].diplomaName].push(this.classes[i]);
		// 		}
		// 	}
			
		// 	this.classes = temp[this.productName];		
		// });
  // 	}
  	
  }

}
