
<div class="news_wrapper">
	<div class="news_content">
		<p id="title">Edit News</p>
		<form [formGroup]="getEditNews" (ngSubmit)="editNews()">
			<div class="inputs" id="input1">
				<legend>Title</legend>
				<input rows="1"  type="text" formControlName="title" [(ngModel)]="news.title">
			</div>
			<div class="inputs" id="input2">
				<legend>Description</legend>
				<input rows="1"  type="text" formControlName="description" [(ngModel)]="news.description">
			</div>
			<div class="inputs" id="input3">
				<legend>Information</legend>
				<textarea rows="1" type="text" formControlName="info" [(ngModel)]="news.info"> </textarea>
			</div>
			<div class="inputs" id="input4">
				<legend>Date:</legend>
				<input type="date" formControlName="date" [(ngModel)]="news.date">
			</div>
			<div class="inputs" id="input5">
				<legend>Upload Material</legend>
				<div>
					<label for="upload_inputMaterial">
					<div class="file_img"> 
						<img src="../../assets/icons/add_black.svg"/>
					</div>
					</label>
					<input (change)="onFileSelect($event)" id="upload_inputMaterial" type="file" name="news"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>
			<div class="inputs" id="input6">
				<input type="submit">
			</div>
		</form>
	</div>
</div>