

	
	<h1 id="h1">Our Policies</h1>
	
	<div class="catogeries">
		<p (click) = "changeBody('Appeals and Complaints')">Appeals and Complaints</p>
		<p (click) = "changeBody('Privacy')">Privacy</p>
		<p (click) = "changeBody('Access Arrangements')">Access Arrangements</p>
		<p (click) = "changeBody('Data Protection')">Data Protection</p>
		<p (click) = "changeBody('Safeguarding')">Safeguarding</p>
		<p (click) = "changeBody('Disability')">Disability</p>
	</div>


	<div class="pages" id="page1">
		<h2>{{title}}</h2>
		<div *ngFor="let subTitle of subTitles; index as i">
			<h3 >{{subTitle}}</h3>
			<p *ngFor="let subTitle_body of subTitle_bodies[i];">{{subTitle_body}}</p>
		</div>
		
	</div>

