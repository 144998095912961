import { Component, OnInit, Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.css']
})
@Injectable()
export class SignUpFormComponent implements OnInit {

  constructor(private apiService:ApiService, private router: Router, public globals: Globals, public cookieService: CookieService) { }

  ngOnInit() {    
    // this.apiService.setLogged(true, "3", "instructor");
  }


  // const shajs = require('sha.js')


  dashBoardDiclaimer_display = "none";

  signInStatus:boolean = true;
  cookie:string = "";

signUp_student=new FormGroup({
  firstName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  middleName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  lastName:new FormControl('',[Validators.required ,Validators.pattern(/[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.email]),
  password:new FormControl('',[Validators.required ,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)])

});


  signIn=new FormGroup({
    email:new FormControl('',Validators.required),
    password:new FormControl('',Validators.required)
  });

  checkBox:boolean = false;
  fromData_student(){
    if(this.checkBox) {
      this.apiService.signUp_student(this.signUp_student.value).subscribe(response=>{
        if(response['message'] == 'post created') {
      
          this.apiService.sendVerificationEmail(this.signUp_student.value.email).subscribe(response=>{
              console.log(response);
          }); 
         this.dashBoardDiclaimer_display = "block";  
        }else if(response['message'] == 'email already used') {
          alert("Account Already Exists");
        }else if(response['message'] == 'post failed') {
          alert("Sign Up failed. Please try again later");
        }
      });
    }
}


  setCookie(name,value,days) {
    // if (days) {
    //     var date = new Date();
    //     date.setTime(date.getTime() + (days*24*60*60*1000));
    //     expires = "; expires=" + date.toUTCString();
    // }
    this.cookieService.set(name, value, days, "; path=/");    
  }

  log(){
    this.apiService.signIn(this.signIn.value.email, this.signIn.value.password).subscribe(response=>{ 
      this.signInStatus = response.userPassword_status;
      this.cookie = response.cookie;
      if(response.userPassword_status){
        // var token = this.makeToken(response.user_pk);
        this.setCookie("userid", this.cookie, 30); 
        this.apiService.setLogged(true, response.user_pk, response.user_type);
        this.showHideSignWrapper("none"); 
      }
    });
  }

  showHideSignWrapper(display){
    this.globals.signWrapper_display = display;
  }

  switchCard(signCard){
    if(signCard == "signIn") {
      this.globals.signInCard_display = "block";
      this.globals.signUpCard_display = "none";
     this.dashBoardDiclaimer_display = "none";

    }else if(signCard == "signUp") {
      this.globals.signUpCard_display = "block";
      this.globals.signInCard_display = "none";
    }
  }

  buttonState() {
    this.checkBox = !this.checkBox;
  }

}
