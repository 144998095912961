
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {Globals} from './globals';


@Injectable({
  providedIn: 'root'
})
export class GuardService {
 constructor(private api:ApiService, private router: Router, public globals: Globals){}

 returnedBool:boolean;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
  	// if(!this.api.isLoggedIn) {
  	// 	// this.router.navigate(['home']);
  	// 	this.globals.signInCard_display = "block";
  	// 	this.globals.signWrapper_display = "flex";
  	// }
    return true;
  
}

}
