import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-instructor-classes',
  templateUrl: './instructor-classes.component.html',
  styleUrls: ['./instructor-classes.component.css']
})
export class InstructorClassesComponent implements OnInit {

  constructor(private apiService: ApiService) { }

	instructor_pk:string = this.apiService.loggedIn_user()["pk"];

  courses:any;

	classes:any;


  ngOnInit(): void {
  	 window.scrollTo(0, 0);
  	 // this.apiService.setLogged(true, "3", "instructor");
  	 	// this.instructor_pk = this.apiService.loggedIn_user()["pk"];

  	 	console.log(1);
  	 	
	this.apiService.getCourses_byInstructortId(this.instructor_pk).subscribe(response=>{	
		console.log(response);
		this.courses = response.data;
		
	});

  	this.apiService.getClasses_byInstructortId(this.instructor_pk).subscribe(response=>{		
		this.classes = response;
		var temp:any = [];	
		for (var i in this.classes) {		
			if(temp[this.classes[i].courseName]){
				temp[this.classes[i].courseName].push(this.classes[i]);
			}else{
				temp[this.classes[i].courseName] = [];
				temp[this.classes[i].courseName].push(this.classes[i]);
			}
		}
		this.classes = temp;
		console.log(this.classes);
		

		
	});

  }

}
