import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'app-admin-edit-diploma-round',
  templateUrl: './admin-edit-diploma-round.component.html',
  styleUrls: ['./admin-edit-diploma-round.component.css']
})
export class AdminEditDiplomaRoundComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,  public globals: Globals) { 
    this.route.params.subscribe(params =>{
        this.round_pk = params.id;      
      });
  }
  round_pk:any;
  round:any;
  instructors:any;
  courses:any;
  classes:any; 
  students:any;
  exams:any;
  exam:any;
  class:any;


  ngOnInit(): void {
  	window.scrollTo(0, 0);
    this.apiService.diplomaRound_byRoundId(this.round_pk).subscribe(response=>{	
		this.round = response;
		// console.log(this.round);
		
	});
      	
	this.apiService.getCourses().subscribe(response=>{	
		this.courses = response.data;
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
	});

  	this.apiService.getClasses_byDiplomaRoundId(this.round_pk).subscribe(response=>{	  			
		this.classes = response.data;		
	});

	this.apiService.getExams_byRoundId(this.round_pk).subscribe(response=>{	
		this.exams = response;
	});

	this.apiService.getStudents_byDiplomaRoundId(this.round_pk).subscribe(response=>{	
		this.students = response.data;
	});
      	
  }



  getRound=new FormGroup({
	  roundName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  startDate:new FormControl('',[Validators.required, Validators.pattern(/[\sS]*/)]),
	  maxCapacity:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	});

   editRound(){
   	console.log(this.getRound.value);
   	
    this.apiService.editDiplomaRound(this.getRound.value, this.round_pk).subscribe(response=>{    	
    	console.log(response);
    	
    });
  }

  showPopUp(index, popup){
  	if(popup == "editClass") {  		
  		if(this.globals.editClassWrapper_display == "none") {
	  		this.class = this.classes[index];
	  		this.globals.editClassFk_value = this.class.class_pk;
	  		// console.log(this.globals.editClassFk_value);
	  		this.globals.createClassRoundType_value = 'diploma';
	  		// this.globals.editClassWrapper_display = "block";
	  		this.globals.editClassName_value = this.class.name;
			this.globals.editClassZoomLink_value = this.class.zoomLink;
			this.globals.editClassDescription_value = this.class.description;
			this.globals.editClassDate_value = this.class.date;
			this.globals.editClassTime_value = this.class.time;
	  		this.globals.editClassWrapper_display = "flex";
	  		// console.log(this.globals.editClassWrapper_display);
	  	}else if(this.globals.editClassWrapper_display = "block") {
	  		this.globals.editClassWrapper_display = "none";
	  	}
  	}else if(popup == "createClass"){
	  	if(this.globals.createClassWrapper_display == "none") {
	  		this.globals.createClassRoundFk_value = this.round_pk;
	  		this.globals.createClassRoundType_value = 'diploma';
		    this.apiService.getDiplomaCourses_byRoundId(this.round_pk).subscribe(response=>{  		    	
        		this.globals.createClassRoundDiplomaCourses_value = response.data;
		      });
	  		this.globals.createClassWrapper_display = "flex";
	  		// console.log(this.globals.createClassWrapper_display);
	  	}else if(this.globals.createClassWrapper_display = "block") {
	  		this.globals.createClassWrapper_display = "none";
	  	}
	}else if(popup == "editExam") {  		
  		if(this.globals.editExamWrapper_display == "none") {
	  		this.exam = this.exams[index];
	  		this.globals.editExamPk_value = this.exam.exam_pk;	  					  		
	  		this.globals.editExamName_value = this.exam.examName;
			this.globals.editExamDate_value = this.exam.date;
			this.globals.editExamTime_value = this.exam.time;
			this.globals.editExamLocation_value = this.exam.location;
	  		this.globals.editExamWrapper_display = "flex";	  		
	  	}else if(this.globals.editExamWrapper_display = "block") {
	  		this.globals.editExamWrapper_display = "none";
	  	}
  	}
  	else if(popup == "addExam"){
	  	if(this.globals.addExamWrapper_display == "none") {
	  		this.globals.addRoundFk_value = this.round_pk;
	  		this.globals.addExamWrapper_display = "flex";	
	  	}else if(this.globals.addExamWrapper_display = "block") {
	  		this.globals.addExamWrapper_display = "none";
	  	}
	}
  	
  }

}