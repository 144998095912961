import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';


@Component({
  selector: 'book-an-appointment',
  templateUrl: './book-an-appointment.component.html',
  styleUrls: ['./book-an-appointment.component.css']
})
export class BookAnAppointmentComponent implements OnInit {

  constructor(private api:ApiService, public globals: Globals) { }


  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }

    getAppointment=new FormGroup({
  fullName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.email]),
  mobileNumber:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  companyName:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  date:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
   time:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
  title:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)])

});


  fromData(){    
    var serviceRequired:any = window.location.href.split("/");
    serviceRequired = serviceRequired[serviceRequired.length-1];
    
    this.api.bookAppointment(this.getAppointment.value, serviceRequired).subscribe(response=>{
      console.log(response);
    });
  }

    show(hideORshow):void {
    if(hideORshow=="hide") {
      this.globals.bookAppointmentWrapper_display = "none";
    }else if(hideORshow=="show") {
      this.globals.bookAppointmentWrapper_display = "block";
    }
  }


}
