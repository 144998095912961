import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import {Globals} from '../globals';

@Component({
  selector: 'app-diploma-details',
  templateUrl: './diploma-details.component.html',
  styleUrls: ['./diploma-details.component.css', './extras.component.css', './testimonials.component.css', './relatedCourses.component.css']
})
export class DiplomaDetailsComponent implements OnInit {

	courseName:string;
  round_pk:any;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.courseName = params.courseName;
        this.round_pk = params.roundId;	
			});
	}

  screenWidth:any;

	courses:any;
	courses_fk:any;
  relatedCourses:any;
  relatedInstructors:any;
  relatedTestimonials:any;
  round:any;
	course_pk:number;
  registrationFees:any = {"2": "80", "3": "90", "4": "100"};
  coursesAssoc:any;

	materials:any;


    diplomas:any;
    diplomaCourses:any;
    diplomaCoreCourses:any;
    diplomaOptionalCourses:any;

    carouselDot1_backgroundColor:string = "#F7F7FF";
    carouselDot2_backgroundColor:string = "transparent";
    carouselEndIndex:number = 2;



	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
    this.apiService.diplomaRound_byRoundId(this.round_pk).subscribe(response=>{  
      this.round = response;
      this.apiService.diploma_byDiplomaId(this.round['diploma_fk']).subscribe(response=>{ 
        this.diplomas = response.data[0];
      });
    });
    this.apiService.getDiplomaCourses_byRoundId(this.round_pk).subscribe(response=>{	
		this.courses = response.data;
		console.log(response);
		
		console.log(this.turn2dArrayToAssoc2dArray(this.courses, 'type'));
		this.diplomaCourses = this.turn2dArrayToAssoc2dArray(this.courses, 'type');
		this.diplomaCoreCourses = this.diplomaCourses['core'];
		this.diplomaOptionalCourses =this.diplomaCourses['optional'];
		
		this.courses_fk = this.diplomaCoreCourses[1]['course_fk'];//change index to 0
	    console.log(this.courses);
	      this.apiService.relatedCourses_byCourseId(this.courses_fk).subscribe(response=>{ 
	        this.relatedCourses = response;            
	      });
	      this.apiService.relatedInstructors_byCourseId(this.courses_fk).subscribe(response=>{ 
	        this.relatedInstructors = response.data;
	      });
	      this.apiService.relatedTestimonials_byCourseId(this.courses_fk).subscribe(response=>{ 
	        this.relatedTestimonials = response.data;
	      });
		});


  	this.apiService.getMaterials_byCourseId().subscribe(response=>{			
  		
  		this.materials = response.data;
  		this.materials = this.turn2dArrayToAssoc2dArray(this.materials, "courseName")[this.courseName];
  		var temp:any = [];	
  		for (var i in this.materials) {					
  			if(temp[this.materials[i].materialType]){
  				temp[this.materials[i].materialType].push(this.materials[i]);
  			}else{
  				temp[this.materials[i].materialType] = [];
  				temp[this.materials[i].materialType].push(this.materials[i]);
  			}
  		}
  		this.materials = temp;
  		
  	});
  }

  gateWay(display):void {   
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
  }

    lcciPlan(display, courseName, round_pk = '0'):void {
      if(display == "flex") {        
        this.globals.productChosen_name = this.courses.courseName;        
        this.globals.productChosen_learningHours = this.courses.learningHours;        
        this.globals.productChosen_level = this.courses.level;                        
        this.globals.productChosen_suite = this.courses.suite;                          
        this.globals.productChosen_price = this.courses.price;        
        this.globals.productChosen_registrationFees = this.registrationFees[this.globals.productChosen_level];        
        if(this.apiService.loggedIn_user()["pk"] != ""){     
                  
          this.globals.signWrapperLcci_display = "none";
          this.globals.lcciPlanWrapper_display = display;
          this.globals.productChosen_course_pk = this.courses.course_pk;
          this.globals.productChosen_round_pk = round_pk;
          
        }else{
          this.globals.signWrapperLcci_display = "flex";
        }
      }else{
        this.globals.lcciPlanWrapper_display = display;
        this.globals.signWrapperLcci_display = display;
      }
    }


  showHideSignWrapper(display){
    this.globals.signInCard_display = "block";
    this.globals.signWrapper_display = display;
    this.gateWay('none');
    this.lcciPlan('none', '');
  }

  changeCarousel(nextOrPrev){
    if(nextOrPrev == 'next') {
      this.carouselEndIndex = 4;
      this.carouselDot1_backgroundColor = "transparent";
      this.carouselDot2_backgroundColor = "#F7F7FF";
    }else if(nextOrPrev == 'prev') {
      this.carouselEndIndex = 2;
      this.carouselDot1_backgroundColor = "#F7F7FF";
      this.carouselDot2_backgroundColor = "transparent";
    }
  }

  navigateTo(courseName, round_pk){    
    this.router.navigateByUrl("/school-of-business/lcci/diplomas&courses/course-details/"+courseName+"/"+round_pk);
  }


}
