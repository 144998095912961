
<lcci-sign-in></lcci-sign-in>
<payment-gateway></payment-gateway>
<product-plan></product-plan>

<section class="sect1_wrapper">
	<div class="bg"></div>
	<div class="sect1_content">
		<div class="info">
			<p id="title">Versent English Placement Test</p>
			<p>
				This test is designed to automatically evaluate the 
				reading, writing, speaking and listening skills of English 
				language learners. Academic institutions and private 
				schools across the world depend on Versant in the 
				evaluation process of their students’ English skills.
			</p>
			<button (click)="lcciPlan('flex')">Enroll</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/vet/sect1.png" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<p id="title">Title</p>
			<p>
				Versant uses auto-marking technology that automatically reports the student’s current capabilities in details and further offers suggestions for improvement. Versant makes it easier to integrate results into your enrollment process through its score mappings to common scales like GSE, CEFR, and TOEFL
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/vet/sect2.png" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content sect3_content_desktop">
		<div class="img">
			<img src="../../assets/imgs/vet/sect3.png" alt="">
		</div>
		<div class="info">
			<p id="title">A look into the Versant English placement test</p>
			<p>
				If you are a private university student or have ever applied to one you might be familiar with the Versant test, it is very similar to the English test you have to take and pass before you join. The test evaluates the English skills of students/applicants through their response to questions and essay writing style they provide via computer, their answers and writing styles allow us to evaluate their English skills
			</p>
		</div>
	</div>
	<div class="sect3_content sect3_content_mobile">
		<div class="info">
			<p id="title">A look into the Versant English placement test</p>
			<p>
				If you are a private university student or have ever applied to one you might be familiar with the Versant test, it is very similar to the English test you have to take and pass before you join. The test evaluates the English skills of students/applicants through their response to questions and essay writing style they provide via computer, their answers and writing styles allow us to evaluate their English skills
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/vet/sect3.png" alt="">
		</div>
	</div>
</section>


<section class="sect4_wrapper">
	<div class="sect4_content">
		<p id="title">The test evaluates a student through 3 sections</p>
		<div class="info" id="info1">
			<p id="title">Conversations</p>
			<p>
				this section evaluates listening skills. 
				Test takers hear a recorded 
				conversation between two people, 
				followed by a question which they 
				have to answer in a simple form
			</p>
		</div>
		<div class="info" id="info2">
			<p id="title">Summary and Opinion</p>
			<p>
				this section evaluates reading and 
				writings skills. Test takers are required 
				to summarize and give their opinion 
				on a passage they are given
			</p>
		</div>
		<div class="info" id="info3">
			<p id="title">Sentence Building</p>
			<p>
				this section evaluates speaking and 
				reading skills. Test takers are required 
				to rearrange a group of three short 
				phrases presented in random order to 
				make a sentence
			</p>
		</div>
		<div class="testDuration">
			<svg xmlns="http://www.w3.org/2000/svg" width="167.416" viewBox="0 0 167.416 190.127">
			  <path id="Path_7778" data-name="Path 7778" d="M79,0c43.63,0,30.047,35.37,30.047,79S122.63,158,79,158A79,79,0,0,1,79,0Z" transform="translate(0 46.93) rotate(-25)" fill="#449dd1"/>
			</svg>

			<p>Test Duration is 50 Minutes </p>
		</div>
	</div>
		<button (click)="lcciPlan('flex')">Enroll</button>
</section>