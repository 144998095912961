import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-add-news',
  templateUrl: './admin-add-news.component.html',
  styleUrls: ['./admin-add-news.component.css']
})
export class AdminAddNewsComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) { 
	}

  ngOnInit(): void {
  	  	this.form = this.formBuilder.group({
      news: ['']
    });
  }

  form: FormGroup;
  uploadResponse:any;


  getAddNews=new FormGroup({
  title:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  description:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  info:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  date:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  addNews(){
    const newsImg = new FormData();
    newsImg.append('news', this.form.get('news').value);
    console.log(this.getAddNews.value);
    
    this.apiService.addNews(this.getAddNews.value, newsImg).subscribe(response=>{
      console.log(1);
      
    	console.log(response);
    });
  }

    onFileSelect(event) {
	    if (event.target.files.length > 0) {
	      const file = event.target.files[0];     
	      this.form.get('news').setValue(file);
	    }
	  }

}
