

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>CADRES School of Business</h1>
			<p>
				CSB has partnered with Pearson LLC to empower youth with today&apos;s essential qualifications by offering a wide portfolio of business educational services. Our digital experience opens the doors for uniquely tailored training strategies to those who want a helping hand to start their business
			</p>
		</div>
		<div class="img"><img src="../../assets/imgs/school-of-business/sect1.png" alt=""></div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sect2_wrapper">
	<div class="bg"></div>
	<div class="sect2_content">
		<div class="info">
			<p id="title">Cadres English Learning</p>
			<p>
				As per our research team in Cadres, “Learn English” or English learning is perhaps the top interest of people who want to start a bright career path. In addition, it is a more convenient channel of communication between people from different cultures.
			</p>
			<button routerLink="./cadres-english">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="cards_container">
			<div routerLink="./cadres-english/general-english" id="card1" class="cards" onclick="setTitle('General English')">
				<p>General <br> English</p>
			</div>
			<div routerLink="./cadres-english/business-english" onclick="setTitle('Business English')" class="cards">  
				<p>Business <br> English</p>
			</div>
			<div routerLink="./cadres-english/pearson-test" id="card3" class="cards" onclick="setTitle('Pearson Test')">
				<p>PTE <br> Test</p>
			</div>
			<div routerLink="./cadres-english/versent-test" id="card4" class="cards" onclick="setTitle('Versent Test')">
				<p>Versent <br> Test</p>
			</div>
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="bg"></div>
	<div class="sect3_content">
		<div class="info">
			<p id="title">LCCI Courses & Diplomas</p>
			<p>
				Pearson LCCI qualifications are work-related qualifications, created to give professional learners the skills and knowledge needed to thrive in the workplace. Whether you are a student, learning centre or an employer, our popular and flexible LCCI qualifications provide the knowledge and skills to actually do the job ...
			</p>
			<button routerLink="./lcci">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="cards_container">
			<div routerLink="/business-solutions/corprate-training/marketing" id="card1" class="cards">
				<p>Marketing</p>
			</div>
			<div routerLink="/business-solutions/corprate-training/accounting-and-financing" id="card2" class="cards">
				<p>Finance <br> & <br>Accounting</p>
			</div>
			<div routerLink="/business-solutions/corprate-training/business-administration" id="card3" class="cards">
				<p>Business <br> Administration</p>
			</div>
		</div>
	</div>
</section>


<section class="sect4_wrapper">
	<div class="bg"></div>
	<div class="sect4_content">
		<div class="info">
			<p id="title">MBAs</p>
			<p>
				Cadres presents Master of Business Administration (MBA) degree from Pearson. BTEC Level 7 Extended Diploma in Strategic Management is equivalent to the master’s degree according to Joint of Councils for Qualification (JCQ) in UK. Leadership as an MBA program for learners who want to develop their skills to progress in workplace. ...
			</p>
			<button routerLink="/underMaintenance">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/icons/categories/mbas.svg">
		</div>
	</div>
</section>


