import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlTags'
})
export class RemoveHtmlTagsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
	value = value.replace(/<[^>]*>/g, '');
    return value;
  }

}
