import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-market-research',
  templateUrl: './market-research.component.html',
  styleUrls: ['./market-research.component.css']
})

export class MarketResearchComponent implements OnInit {

	constructor(public globals: Globals) { }


	getQuote_display:string;

	ngOnInit(): void { 
		window.scrollTo(0, 0);
		this.getQuote_display = "none"; 
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
		this.boxShadow_marketResearch = this.boxShadow;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
	}
	
	screenWidth:any;
	boxShadow:string;
	boxShadow_marketResearch:string;
	boxShadow_strageticPlanning:string;
	boxShadow_measurementsAndAnalytics:string;
	display1:string;
	display2:string = "none";
	display3:string;
	@ViewChild("sect5_wrapper") sect5_wrapper;
	sect2_title:string = "Market Research";
	sect2_para1:string = `
				Cadres provide skilled expertise to gather information about
				your business potential buyers’ persona, target audience,
				 competitor analysis, and customers’ needs to determine how 
				 feasible and successful your product or service are. As we
				  conduct competent marketing and management analysis, to 
				  ensure the buying behaviour in addition to customer influences 
				  regarding their decisions to accomplish higher business conversions…
			`;
	sect2_para2:string = `
			Cadres offer high proficiency while conducting market research 
			based on a set of techniques to gather information and understand 
			the company’s target market. Thus, by using such data, businesses 
			will be able to design their product or service according to the 
			market needs and wants which improves the company’s image and 
			user experience. 
			`;

	sect3_para1:string = `
			Cadres stands by their values by being highly qualified and skilled to conduct efficient 
			market research to ensure practical market research through conducting
			`;

	sect4_para1:string = `
					Surveys to analyse and categorise the 
					business needs based on users’ and 
					employees’ experience.
				`;
	sect4_para2:string = `
					Interviews to follow insightful trends 
					from stakeholders to understand the 
					business target market and potential 
					customers.
				`;
	sect4_para3:string = `
					Focus Groups from a trained 
					moderator to understand the business 
					conversions and leads surrounding the 
					product, user experience, and the 
					marketing message to gain more in-
					depth insights about the business.
				`;
	sect4_para4:string = `
					Observation through overt and covert 
					observations to fully understand the 
					customers’ preferences and study their 
					purchasing behaviour
				`;


	changeBody(bodyTitle):void{
		
		this.boxShadow_marketResearch = "none";
		this.boxShadow_strageticPlanning = "none";
		this.boxShadow_measurementsAndAnalytics = "none";
		if(bodyTitle == "Market Research") {
			this.display1 = "block";
			this.display2 = "none";
			this.boxShadow_marketResearch = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
						Cadres provide skilled expertise to gather information about
						your business potential buyers’ persona, target audience,
						 competitor analysis, and customers’ needs to determine how 
						 feasible and successful your product or service are. As we
						  conduct competent marketing and management analysis, to 
						  ensure the buying behaviour in addition to customer influences 
						  regarding their decisions to accomplish higher business conversions…
					`;
			this.sect2_para2 = `
					Cadres offer high proficiency while conducting market research 
					based on a set of techniques to gather information and understand 
					the company’s target market. Thus, by using such data, businesses 
					will be able to design their product or service according to the 
					market needs and wants which improves the company’s image and 
					user experience. 
					`;

			this.sect3_para1 = `
					Cadres stands by their values by being highly qualified and skilled to conduct efficient 
					market research to ensure practical market research through conducting
					`;

			this.sect4_para1 = `
							Surveys to analyse and categorise the 
							business needs based on users’ and 
							employees’ experience.
						`;
			this.sect4_para2 = `
							Interviews to follow insightful trends 
							from stakeholders to understand the 
							business target market and potential 
							customers.
						`;
			this.sect4_para3 = `
							Focus Groups from a trained 
							moderator to understand the business 
							conversions and leads surrounding the 
							product, user experience, and the 
							marketing message to gain more in-
							depth insights about the business.
						`;
			this.sect4_para4 = `
							Observation through overt and covert 
							observations to fully understand the 
							customers’ preferences and study their 
							purchasing behaviour
						`;
		}else if(bodyTitle == "Strategic Planning") {
			this.display1 = "block";
			this.display2 = "none";
			this.display3 = "none";
			this.boxShadow_strageticPlanning = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
						Cadres Business Solution services offer your company strategic 
						planning and marketing, documents, and records your strategic 
						business mission, vision, values, long-term and short-term goals and 
						objectives. Cadres provide an efficient and effective digital strategic 
						planning process to foster an increase in productivity to contribute to 
						business success and development.
					`;
			this.sect2_para2 = `
					Cadres Business Solution offers a professional strategic plan to establish the direction of the business in the short and long term; thus, Cadres develops an efficient strategy to examine and analyse the strategic business process. Cadres follow a specific action plan in terms of:
					`;

			this.sect3_para1 = `
					
					`;

			this.sect4_para1 = `
							Determining the business plan to identify the 
							strategic issues, market opportunities and 
							threats, customer current and future insights, 
							employee inputs, and synthesise the business 
							SWOT analysis.
						`;
			this.sect4_para2 = `
							Managing the business performance by auditing by 
							communicating the new plan to the whole 
							organisation, set and establish schedules, leverage 
							the tools available at the organisation, and have 
							updated quarterly and annual financial plan
						`;
			this.sect4_para3 = `
							Building the plan using SWOT, organisational 
							goals, key performance indicators (KPIs), 
							departmental goals, and budgeting the one-
							year plan.
						`;
			this.sect4_para4 = `
							Developing a strategic business plan to construct 
							the organisation’s core mission, vision, values, 
							competitive advantage, current objectives/ 
							strategies, and forecast the business financial 
							position.
						`;
		}else if(bodyTitle == "Measurements and Analytics") {
			this.display1 = "none";
			this.display2 = "block";
			this.display3 = "none";
			
			this.boxShadow_measurementsAndAnalytics = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `Cadres provides a wide variety of measurements and analytics tools through the latest techniques and strategies in order to efficiently accomplish marketing campaigns’ objectives. In addition, matching what the market demands with the business services`;

		}
	}

    show(hideORshow):void {
      if(hideORshow=="hide") {
        this.globals.getQuoteWrapper_display = "none";
      }else if(hideORshow=="show") {
        this.globals.getQuoteWrapper_display = "block";
      }
    }

}