
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="title">
			<p>{{productName}}</p>
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<div class="data_wrapper">
	<div class="data_content">
		<div class="row" *ngFor="let class of classes | filter:searchText" >
			<div id="column1">
				<p>{{class.name}} <br> {{class.courseName | truncateText}}</p>
			</div>
			<div>
				<p>{{class.date}} <br> {{class.time}}</p>
			</div>
			<div>
				<p>Add Material</p>
			</div>
			<div id="column4">
				<p>Cancel Class</p>
			</div>
		</div>
	</div>
</div>