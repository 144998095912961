import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-students',
  templateUrl: './admin-students.component.html',
  styleUrls: ['./admin-students.component.css']
})
export class AdminStudentsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  students:any;
  
  searchText:string;
  
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getStudents().subscribe(response=>{	  		  	
		this.students = response.data;
	});
  }

  deleteStudent(pk):void{      	
  	this.apiService.deleteStudent(pk).subscribe(response=>{	  		  	
  		console.log(response);  		
	});
  }

}
