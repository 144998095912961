
<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<p>My Grades</p>
			</div>
		</div>

		<div class="coursesProgress">
			<div class="courseProgress" *ngFor="let course of courses; index as i" id="courseProgress{{i+1}}" routerLink="/student-dashboard/course/{{course.courseName}}" [ngStyle]="globals.dashboardTheme_cards">	
				<p id="title">{{course.courseName | truncateText}}</p>
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "course.progress"></div>
					</div>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Grades</p>
		</div>

		<div class="coursesSummary" *ngFor="let grade of grades; index as i" [ngStyle]="globals.dashboardTheme_cards">
			<div class="summaries" id="summary1">
				<p id="data1">{{i+1}}. {{grade.courseName  | truncateText}}</p>
			</div>
			<div class="summaries" id="summary2">
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "grade.grade"> </div>
					</div>
					<p>{{grade.grade}}</p>	
				</div>
			</div>

			<div class="summaries" id="summary3">
				<p id="data3">{{grade.status}}</p>
			</div>
		</div>


	</div>
</div>