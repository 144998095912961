
<book-an-appointment [style.display] = "globals.bookAppointmentWrapper_display"></book-an-appointment>


<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>How Cadres supports startups</h1>
			<p>
				In Cadres Business Solutions, our corporate responsibility is all about supporting start-ups to push the whole economy forward and boost the domestic products and services microeconomy. Cadres totally funds digital marketing activities for Egyptian Start-ups.
			</p>
			<p>
				We support startups and NGO’s working on the United Nations Sustainable Development Goals such as woman empowerment, human rights, gender equality, quality education, renewable energy, decent work and economic growth, climate change, peace, justice, and strong institutions.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/cadres-support-startups/sect1.svg" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="card_small">
			<p>
				<span>In Cadres Business Solutions</span>, our corporate 
				responsibility is all about supporting start-ups to 
				push the whole economy forward and boost the 
				domestic products and services microeconomy. 
				Cadres totally funds digital marketing activities for 
				Egyptian Start-ups.
			</p>
		</div>
		<div class="card_large">
			<p id="title">Our Process</p>
			<p>
				We totally fund online campaigns for the start-ups working on the United Nations SDG's
			</p>
			<p>
				No more poverty, according to the United Nations more than 700 million are living on an extreme poverty. Zero hunger, as a matter of fact third of the world food is going to waste, yet 821 million people do not have enough food to eat. Good health, wellbeing and vaccination succeeded in dropping death rates caused by Measles to 80% between 2000/2017 Gender equality, one of three women have experienced physical or/and sexual abuse. Clean water and sanitation, 40% of the population suffer from sacristy of water. <span id="readMore" onclick="showMore('info_extra', 'block')">Read More</span>
			</p>
			<p id="info_extra">
				There are more goals set by the United Nations to elevate people’s standards of living by 2030
				<br>
				Other than funding your digital marketing, from our perspective of Corporate Social Responsibility we could help start-ups by making bulk discounts for making Business Solutions affordable. Business solutions could help you with recruiting the best candidate matching your needs, managing your events, training your employees boosting their productivity.

			</p>
			<button (click) = "show('show')">Book an Appointment Now</button>
		</div>
	</div>
</section>


