import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.css']
})
export class DigitalMarketingComponent implements OnInit {

	constructor(public globals: Globals) { }


	

	getQuote_display:string;

	ngOnInit(): void { 
		window.scrollTo(0, 0);
		this.getQuote_display = "none";  
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
		this.boxShadow_seoAndSem = this.boxShadow;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.screenWidth = window.innerWidth;
		if(this.screenWidth<1000) {
			this.boxShadow = "0 11.5vw 0 0 #FCAF17";
		}else{
			this.boxShadow = "0 3vw 0 0 #FCAF17";
		}
	}
	
	screenWidth:any;
	boxShadow:string;
	boxShadow_seoAndSem:string;
	boxShadow_digitalMediaBuyingAndPlanning:string;
	boxShadow_socialMediaManagement:string;
	display1:string = "block";
	display2:string = "none";
	@ViewChild("sect5_wrapper") sect5_wrapper;
	sect2_title:string = "SEO & SEM";
	sect2_para1:string = `
				Cadres aims to add value for marketing efforts in terms of paying 
				close attention to the SEO and SEM usages for search engines to 
				expose their website rankings. SEO and SEM is a growing field of 
				expertise that boosts businesses to create awareness and 
				importance within the searcher mindset to build more sales and 
				brand online presence.
			`;
	sect2_img:string = "sect2_seoAndSem.png";
	sect3_para1:string = `
			Cadres SEO/ SEM search engine marketing efforts allows businesses to gain traffic and visibility from both organic and paid reach using specific keywords. SEO “Search Engine Optimization” affects the visibility of the business internet presence through natural and organic reach; as SEM “Search Engine Marketing” is a form of e-marketing to seek to promote websites by increasing their visibility in Search Engine Result Pages (SERPs) through the use of contextual advertising, paid inclusion, and Pay-Per-Click (PPC).
			`;
	sect3_img:string = "sect3_seoAndSem.png";
	sect4_para1:string = `
		Cadres pays attention to elevate the business productivity and 
		profitability through keyword search, onsite optimization, link building, 
		reporting and analysing by constructing actions aimed at increasing 
		the number and quality of inbound links to a webpage. Such SEO/ 
		SEM marketing efforts consists of several factors of content, linking 
		structure, social media efforts, and its’ website position. Thus, our 
		professional team ensures the successful of a business in terms of 
		website ranking, website and social media searches, consumer 
		perception, effective keyword phrases strategies, traffic generation, 
		and higher search engines.
	`;
	sect4_img:string = "sect4_seoAndSem.png";


	changeBody(bodyTitle):void{
		
		this.boxShadow_seoAndSem = "none";
		this.boxShadow_digitalMediaBuyingAndPlanning = "none";
		this.boxShadow_socialMediaManagement = "none";
		if(bodyTitle == "SEO & SEM") {
			this.display1 = "block";
			this.display2 = "none";
			this.boxShadow_seoAndSem = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				Cadres aims to add value for marketing efforts in terms of paying 
				close attention to the SEO and SEM usages for search engines to 
				expose their website rankings. SEO and SEM is a growing field of 
				expertise that boosts businesses to create awareness and 
				importance within the searcher mindset to build more sales and 
				brand online presence.
			`;
			this.sect2_img = "sect2_seoAndSem.png";
			this.sect3_para1 = `
					Cadres SEO/ SEM search engine marketing efforts allows businesses to gain traffic and visibility from both organic and paid reach using specific keywords. SEO “Search Engine Optimization” affects the visibility of the business internet presence through natural and organic reach; as SEM “Search Engine Marketing” is a form of e-marketing to seek to promote websites by increasing their visibility in Search Engine Result Pages (SERPs) through the use of contextual advertising, paid inclusion, and Pay-Per-Click (PPC).
					`;
			this.sect3_img = "sect3_seoAndSem.png";	
			this.sect4_para1 =  `
					Cadres pays attention to elevate the business productivity and 
					profitability through keyword search, onsite optimization, link building, 
					reporting and analysing by constructing actions aimed at increasing 
					the number and quality of inbound links to a webpage. Such SEO/ 
					SEM marketing efforts consists of several factors of content, linking 
					structure, social media efforts, and its’ website position. Thus, our 
					professional team ensures the successful of a business in terms of 
					website ranking, website and social media searches, consumer 
					perception, effective keyword phrases strategies, traffic generation, 
					and higher search engines.
			`;
			this.sect4_img = "sect4_seoAndSem.png";
		}else if(bodyTitle == "Digital Media buying and Planning") {
			this.display1 = "block";
			this.display2 = "none";
			this.boxShadow_digitalMediaBuyingAndPlanning = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				Cadres propose Business solutions to prosper the media planning 
				process of strategically selecting various media platforms to 
				perform digital advertising and marketing to achieve business 
				campaign goals. Boosting your business conversions will facilitate 
				media buying for targeted consumers to generate business leads, 
				traffic, and reconcile businesses strategies via RTB and DSP.
				Cadres advertising media planning and media buying services 
				depend on strategically selecting the appropriate platform to 
				boost the business ad campaigns as both media planning and 
				buying operate together to outperform and execute the most 
				profitable media strategy.
			`;
			this.sect2_img = "sect2_digitalMediaBuyingAndPlanning.png";
			this.sect3_para1 = `
				Media Planning requires synthesis research about the market needs, consumers’ demands, and the overall market requirements.  Therefore, to create a successful media strategy, a corporation must be able to outline their main goals and objectives of performing digital marketing, determine their target market, craft and plan the business message to their targeted audience, executing the plan by choosing the appropriate ad campaigns, and monitor changes of the stakeholders and the competitors
			`;
			this.sect3_img = "sect3_digitalMediaBuyingAndPlanning.png";
			this.sect4_para1 = `
				Media Buying uses the media planning strategies to maintain the 
				outlined media plan, target audience, and the budgeting techniques 
				set. Thus, competent media buying demonstrates and foster research 
				on various media outlets, and respective markets to serve and reach 
				the potential consumers. While understanding, forming and 
				maintaining vendor relationships, the ability to negotiate the budget is 
				advantageous to monitor the results of the plan. 
			`;
			this.sect4_img = "sect4_digitalMediaBuyingAndPlanning.png"; 
		}else if(bodyTitle == "Social Media Management") {
			this.display1 = "none";
			this.display2 = "block";
			this.boxShadow_socialMediaManagement = this.boxShadow;
			this.sect2_title = bodyTitle;
			this.sect2_para1 = `
				Businesses always search for the most convenient channel to reach their customers. As a result of living in a digital world social media is considered one of the most supporting tools. However, it is a highly competitive market. Thus, businesses need an effective Social Media Management. Therefore, Cadres provides reliable digital Marketing services.
			`;
			this.sect2_img = "sect2_socialMediaManagement.png";
			this.sect3_para1 = `
				Social Media Marketing or SMM is the usage of social media 
				platforms to perform and connect with your audience and 
				consumers. Cadres uses highly technological and professional tools 
				to build your brand, increase sales, and drive website traffic. Cadres 
				aims to publish content on your social media to engage social media 
				profiles, listen, and understand your followers. 
			`;
			this.sect3_img = "sect3_socialMediaManagement.png";
			this.sect4_para1 = `
				As Cadres values integrity and assures high levels of performances by developing a strategic plan, plan and publish the strategy based on the market research performed, listen and understand the followers engagement, and reporting/ auditing the performance of the business.
			`;
			this.sect4_img = "sect4_socialMediaManagement.png"; 
		}
	}

	show(hideORshow):void {
	  	if(hideORshow=="hide") {
	  		this.globals.getQuoteWrapper_display = "none";
	  	}else if(hideORshow=="show") {
	  		this.globals.getQuoteWrapper_display = "block";
	  	}
	  }

}