import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'product-plan',
  templateUrl: './product-plan.component.html',
  styleUrls: ['./product-plan.component.css']
})
export class ProductPlanComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

gateWay(display):void {   
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
  }

  lcciPlan(display, courseName):void {
      if(display == "flex") {
        
      }else{
        this.globals.lcciPlanWrapper_display = display;
        this.globals.signWrapperLcci_display = display;
      }
    }

}
