import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-instructor',
  templateUrl: './admin-instructor.component.html',
  styleUrls: ['./admin-instructor.component.css']
})
export class AdminInstructorComponent implements OnInit {

	instructor_pk:any;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
				this.instructor_pk = params.id;			
			});
	}
  
  instructor:any;


  courses:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    console.log(this.instructor_pk);
    
    this.apiService.getInstructor_byInstructorId(this.instructor_pk).subscribe(response=>{	
		this.instructor = response.data[0];
		console.log(response);
	});

    this.apiService.getCourses_byInstructortId(this.instructor_pk).subscribe(response=>{	
		this.courses = response.data;
		console.log(this.courses);
		
	});
      	
      	
  }

}
