import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
@Component({
  selector: 'app-admin-product-students',
  templateUrl: './admin-product-students.component.html',
  styleUrls: ['./admin-product-students.component.css']
})
export class AdminProductStudentsComponent implements OnInit {

	constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
			this.productType = params.productType;
			this.productName = params.productName;			
		});
	}

	productType:string;
	productName:string;
	product:any;
	students:any;

	searchText:string;

	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}



  ngOnInit(): void {
  	 window.scrollTo(0, 0);

 // 	this.apiService.getProducts().subscribe(response=>{	
	// 	this.product = response.data;			
	// 	this.product = this.turn2dArrayToAssoc2dArray(this.product);	
	// 	console.log(response.data);
		
	// 	console.log(this.product);		
	// });	
  	if(this.productType == 'course') {
	  	this.apiService.getCourses().subscribe(response=>{	
			this.product = response.data;
			this.product = this.turn2dArrayToAssoc2dArray(this.product)[this.productName][0];			
/*		  	this.apiService.getStudents_byCourseId(this.product.course_pk).subscribe(response=>{	
				this.students = response.data;
			});*/
		});
  		
  	}else if(this.productType == 'diploma') {  		
  // 		this.apiService.getDiplomas().subscribe(response=>{	
		// 	this.product = response.data;		
		// 	var temp:any = [];		
		// 	for (var i in this.product) {										
		// 		if(temp[this.product[i].diplomaName]){
		// 			temp[this.product[i].diplomaName].push(this.product[i]);
		// 		}else{
		// 			temp[this.product[i].diplomaName] = [];
		// 			temp[this.product[i].diplomaName].push(this.product[i]);
		// 		}
		// 	}
		// 	this.product = temp[this.productName][0];	
		//  //  	this.apiService.getStudents_byDiplomaId(this.product.diploma_pk).subscribe(response=>{	
		// 	// 	this.students = response.data;
		// 	// });	
		// });
  	}
  }

}
