import { Component, DoCheck, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from './api.service';
import {Globals} from './globals';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals,  public cookieService: CookieService) { }

	body:string = "red";
	routerOutlet_display:string = "none";
	mainWebsiteNav_display:string;
	mainWebsiteFooter_display:string;
	studentDashboardNav_display:string;
	instructorDashboardNav_display:string;
	adminDashboardNav_display:string;
	color:string = 'red';
	canRender:boolean;

	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

	ngDoCheck(): void {
	  	if (window.location.href.indexOf("student-dashboard") > -1){
	  		this.mainWebsiteFooter_display = "none";
	  		this.mainWebsiteNav_display = "none";
	  		this.studentDashboardNav_display = "block";
	  		this.instructorDashboardNav_display = "none";
	  		this.adminDashboardNav_display = "none";
	  	}else if (window.location.href.indexOf("instructor-dashboard") > -1){
	  		this.mainWebsiteNav_display = "none";
	  		this.mainWebsiteFooter_display = "none";
	  		this.studentDashboardNav_display = "none";
	  		this.instructorDashboardNav_display = "block";
	  		this.adminDashboardNav_display = "none";
	  	}else if (window.location.href.indexOf("admin-dashboard") > -1){
	  		this.mainWebsiteNav_display = "none";
	  		this.mainWebsiteFooter_display = "none";
	  		this.studentDashboardNav_display = "none";
	  		this.instructorDashboardNav_display = "none";
	  		this.adminDashboardNav_display = "block";
	  	}else if (window.location.href.indexOf("contact") > -1){
	  		this.mainWebsiteNav_display = "block";
	  		this.mainWebsiteFooter_display = "none";
	  		this.studentDashboardNav_display = "none";
	  		this.instructorDashboardNav_display = "none";
	  		this.adminDashboardNav_display = "none";
	  	}else{
	  		this.mainWebsiteNav_display = "block";
	  		this.mainWebsiteFooter_display = "block";
	  		this.studentDashboardNav_display = "none";
	  		this.instructorDashboardNav_display = "none";
	  		this.adminDashboardNav_display = "none";
	  	}
	}
	
	ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.canRender = true;
    this.apiService.pageSections().subscribe(response=>{
		setTimeout(()=>{
			this.globals.sections = this.turn2dArrayToAssoc2dArray(response.data, "pageName");
			this.apiService.isRemebered({"cookie": this.cookieService.get("userid")}).subscribe(response=>{
	              // console.log(response);
                  this.apiService.setLogged(true, response.user_pk, response.user_type);
	          });
			// console.log(this.globals.sections);
			window.scrollTo(0, 0);
			this.routerOutlet_display = "block";
			this.canRender = false;

		}, 2000)

    });
  }




}
