

<div class="bookAppointment_wrapper" id="bookAppointment_wrapper" onload="setTitle('Careers', true)">
	<img (click)="show('hide')" id="cross_icon" src="../../assets/icons/cross_orange.svg">
	<div class="bookAppointment_content">
		<form [formGroup]="getAppointment" (ngSubmit)="fromData()">
			<div>
				<h1>Book An Appointment</h1>
			</div>
			<fieldset id="fullName">
				<legend>Full Name</legend>
				<input type="text" name="firstName_instructor" placeholder="John" formControlName="fullName">
				<div *ngIf="getAppointment.controls.fullName.errors && getAppointment.controls.fullName.touched" class="alert "
						role="alert">
					Full Name is Required
				</div>
			</fieldset>
			<fieldset id="email">
				<legend>Email</legend>
				<input type="text" name="lastName_instructor" placeholder="Doe" formControlName="email"> 
				<div *ngIf="getAppointment.controls.email.errors && getAppointment.controls.email.touched" class="alert "
						role="alert">
					Email is Required
				</div>
			</fieldset>
			<fieldset id="mobileNumber">
				<legend>Mobile Number</legend>
				<input type="text" name="email_instructor" placeholder="john.doe@email.com " formControlName="mobileNumber">
				<div *ngIf="getAppointment.controls.mobileNumber.errors && getAppointment.controls.mobileNumber.touched" class="alert "
						role="alert">
					Mobile is Required
				</div>
			</fieldset>
			<fieldset id="companyName">
				<legend>Company Name</legend>
				<input type="text" name="mobileNumber_instructor" placeholder="0102******"  formControlName="companyName">
				<div *ngIf="getAppointment.controls.companyName.errors && getAppointment.controls.companyName.touched" class="alert "
						role="alert">
					Company Name is Required
				</div>
			</fieldset>
			<div class="date">
				<p id="title">Set Appointment Date:</p>
				<input type="date" step="1" value="dd/mm/yyyy" formControlName="date">
				<div *ngIf="getAppointment.controls.date.errors && getAppointment.controls.date.touched" class="alert "
						role="alert">
					Date is Required
				</div>
			</div>
			<div class="time">
				<p id="title">Set Appointment Time:</p>
				<input type="time" min="" step="1" formControlName="time">
				<div *ngIf="getAppointment.controls.time.errors && getAppointment.controls.time.touched" class="alert "
						role="alert">
					Time is Required
				</div>
			</div>
			<input type="submit" value="Submit">
		</form>
	</div>
</div>

