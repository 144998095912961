

<section class="filter_wrapper">
	<div class="addVacancy" routerLink="./add-product">
		<img src="../../assets/icons/add_white.svg">
		<p>Add Product</p>
	</div>
	<div class="filter_content">
		<p id="title">Filter By:</p>
		<div class="filters_container">
			<div class="dropDowns" id="level">
				<p>Type</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="dropDowns" id="category">
				<p>Category</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="dropDowns" id="learningHours">
				<p>Level</p>
				<img src="../../assets/icons/chevronDown_blue.svg">
			</div>
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="products_wrapper">
	<div class="products_content" *ngFor="let product of products | filter:searchText">
		<div class="product">
			<p id="productName">{{product.productName}}</p>
			<p id="edit">Edit</p>
			<p id="delete" (click)="deleteProduct(product.type, product.pk)">Delete</p>
		</div>
	</div>
</section>