import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-course',
  templateUrl: './student-course.component.html',
  styleUrls: ['./student-course.component.css']
})
export class StudentCourseComponent implements OnInit {

courseName:string;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.courseName = params.courseName;			
			});


  }


  	student:any;
  	student_pk:string = this.apiService.loggedIn_user()["pk"];

	courses:any;
	// courses_placementTestGrade:string;

	classes:any;

	assignments:any;	

	exams:any;	

	grades:any;	

	materials:any;	

	registeredCoursesPlaceHolder_display:string = "none";
	materialsPlaceHolder_display:string = "none";
	messagesPlaceHolder_display:string = "block";
	classesPlaceHolder_display:string = "none";
	assignmentsPlaceHolder_display:string = "none";
	examsPlaceHolder_display:string = "none";
	gradesPlaceHolder_display:string = "none";
	classesHeaders_display:string = "none";
	assignmentsHeaders_display:string = "none";
	examsHeaders_display:string = "none";
	gradesHeaders_display:string = "none";

		
  ngOnInit(): void {
  	window.scrollTo(0, 0); 
  	console.log(0);
  	 	 	
  	this.apiService.getStudent_byStudentId(this.student_pk).subscribe(response=>{	
		this.student = response.data[0];
	});

	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		if(response.message) {
			this.registeredCoursesPlaceHolder_display = "block";
		}else{
			this.registeredCoursesPlaceHolder_display = "none";
			this.courses = response.data;
		}
		this.courses = this.turn2dArrayToAssoc2dArray(this.courses)[this.courseName][0];
		// console.log(this.courses);
		
		
	});

  	this.apiService.getClasses_byStudentId(this.student_pk).subscribe(response=>{
		if(response.message) {
			this.classesPlaceHolder_display = "block";
			this.classesHeaders_display = "none";
		}else{
			this.classesPlaceHolder_display = "none";
			this.classesHeaders_display = "flex";
			this.classes = response;
			
		}
		
		this.classes = this.turn2dArrayToAssoc2dArray(this.classes)[this.courseName];

	});

	this.apiService.getAssignments_byStudentId(this.student_pk).subscribe(response=>{	
		if(response.message) {			
			this.assignmentsPlaceHolder_display = "block";
			this.assignmentsHeaders_display = "none";
		}else{			
			this.assignmentsPlaceHolder_display = "none";
			this.assignmentsHeaders_display = "flex";
			this.assignments = response.data;
		}
		this.assignments = this.turn2dArrayToAssoc2dArray(this.assignments)[this.courseName];
	});

	this.apiService.getExams_byStudentId(this.student_pk).subscribe(response=>{		
		if(response.message) {
			this.examsPlaceHolder_display = "block";
			this.examsHeaders_display = "none";
		}else{
			this.examsPlaceHolder_display = "none";
			this.examsHeaders_display = "flex";
			this.exams = response;
			
		}

		this.exams = this.turn2dArrayToAssoc2dArray(this.exams)[this.courseName];
		
	});

	this.apiService.getGrades_byStudentId(this.student_pk).subscribe(response=>{		
		if(response.message) {
			this.gradesPlaceHolder_display = "block";
			this.gradesHeaders_display = "none";
		}else{
			this.gradesPlaceHolder_display = "none";
			this.gradesHeaders_display = "flex";
			this.grades = response;
			
		}
		
	});

		this.apiService.getMaterials_byStudentId(this.student_pk).subscribe(response=>{	
			console.log(1);
			
			console.log(response);
			console.log(2);
			
			if(response.message) {
				this.materialsPlaceHolder_display = "block";
			}else{
				this.materialsPlaceHolder_display = "none";
				this.materials = response.data;
			}
			console.log(this.materials);
			
			this.materials = this.turn2dArrayToAssoc2dArray(this.materials)[this.courseName];
			console.log(this.materials);
			
			
		});


	
	
  }

  	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}

}
