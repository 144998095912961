import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'apply-as-instructor',
  templateUrl: './apply-as-instructor.component.html',
  styleUrls: ['./apply-as-instructor.component.css']
})
export class ApplyAsInstructorComponent implements OnInit {


  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder, public globals: Globals) { 
	}

  form: FormGroup;
  uploadResponse:any;


  ngOnInit(): void {
  	this.form = this.formBuilder.group({
      instructorCV: ['']
    });
  }



  getAppliedInstructor=new FormGroup({
  firstName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  lastName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  email:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  mobileNumber:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  applyInstructor(){
    const instructorCVFile = new FormData();
    instructorCVFile.append('instructorCV', this.form.get('instructorCV').value);
    this.apiService.applyInstructor(this.getAppliedInstructor.value, instructorCVFile).subscribe(response=>{
    	console.log(response);
    });
  }

    onFileSelect(event) {
      console.log(1);
      
	    if (event.target.files.length > 0) {
        console.log(2);
        
	      const file = event.target.files[0];
        console.log(file);
          console.log(3);
          
	      this.form.get('instructorCV').setValue(file);
        console.log(4);
        
        console.log(this.form);
        
	    }
	  }
  showApplyInstructorWrapper(display){
    this.globals.applyInstructor_display = display;
  }

}
