import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.css']
})
export class NewsAndEventsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

	news:any;
	events:any;

  ngOnInit(): void {
   window.scrollTo(0, 0);
 	this.apiService.getNews().subscribe(response=>{	
		this.news = response;		
	});
	this.apiService.getEvents().subscribe(response=>{	
		this.events = response;
		console.log(this.events);
		console.log(this.events.length);
		
	});
  }

}
