import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-edit-profile',
  templateUrl: './student-edit-profile.component.html',
  styleUrls: ['./student-edit-profile.component.css']
})
export class StudentEditProfileComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) { }

  student_pk:any = this.apiService.loggedIn_user()["pk"];
  student:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    // this.apiService.setLogged(true, "10", "student");
    console.log(this.student_pk);
    
    this.apiService.getStudent_byStudentId(this.student_pk).subscribe(response=>{	
		this.student = response.data[0];
		console.log(response);
	});
      	
      	
  }


  getUser=new FormGroup({
	  firstName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  lastName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  email:new FormControl('',[Validators.required ,Validators.email]),
	  mobileNumber:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  address:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  postalCode:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
     password:new FormControl('',[Validators.required ,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]),
	   dob:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)])

	});

   editUser(){	 	
    this.apiService.editStudent(this.getUser.value, this.student_pk).subscribe(response=>{    	
    	console.log(response);
    	
    });
  }

}
