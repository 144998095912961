
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="productType" id="courses" [style.background]="courses_bg" (click)="switch('courses')" >
			<p>Courses</p>
		</div>
		<div class="productType" id="diplomas" [style.background]="diplomas_bg" (click)="switch('diplomas')" >
			<p>Diplomas</p>
		</div>
		<div class="addRound" routerLink="./add-{{addType}}-round">
			<img src="../../assets/icons/add_white.svg">
			<p>Add Round</p>
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<section class="products_wrapper" id="courses" [style.display]="courses_display" >
	<div class="products_content">
		<div class="cards" id="card1" *ngFor="let course of courses | filter:searchText">
			<p id="title">{{course | removeHtmlTags | titlecase}}</p>
			<div class="headers">
				<p>Rounds</p>
				<p>Instructor</p>
				<p>Students</p>
				<p>Start Date</p>
			</div>
			<div class="data"  *ngFor="let round of coursesRounds[course]" routerLink="./edit-course-round/{{round.round_pk}}">
				<p>{{round.roundName}}</p>
				<p>{{round.instructorName}}</p>
				<p>{{round.numberOfEnrollments}}</p>
				<p>{{round.startDate}}</p>
			</div>
			<!-- <p id="viewMore"><u>View More</u></p> -->
		</div>
	</div>
</section>

<section class="products_wrapper" id="diplomas" [style.display]="diplomas_display" >
	<div class="products_content">
		<div class="cards" id="card1" *ngFor="let diploma of diplomas | filter:searchText">
			<p id="title">{{diploma | removeHtmlTags | titlecase}}</p>
			<div class="headers">
				<p>Rounds</p>
				<!-- <p>Instructor</p> -->
				<p>Students</p>
				<p>Start Date</p>
			</div>
			<div class="data"  *ngFor="let round of diplomasRounds[diploma]" routerLink="./edit-diploma-round/{{round.round_pk}}">
				<p>{{round.roundName}}</p>
				<!-- <p>{{round.instructorName}}</p> -->
				<p>{{round.numberOfEnrollments}}</p>
				<p>{{round.startDate}}</p>
			</div>
			<!-- <p id="viewMore"><u>View More</u></p> -->
		</div>
	</div>
</section>