<div class="instructorSignUp_wrapper" id="instructorSignUp_wrapper" onload="setTitle('Careers', true)" [style.display]="globals.applyInstructor_display"> 
	<img (click)="showApplyInstructorWrapper('none')" id="cross_icon" src="../../assets/icons/cross_orange.svg">
	<div class="instructorSignUp_content">
		<form [formGroup]="getAppliedInstructor" (ngSubmit)="applyInstructor()">
			<div id="title">
				<h1>Join as Instructor</h1>
			</div>
			<fieldset id="firstName">
				<legend>First Name</legend>
				<input type="text" name="firstName_instructor" formControlName="firstName" placeholder="John">
			</fieldset>
			<fieldset id="lastName">
				<legend>Last Name</legend>
				<input type="text" name="lastName_instructor" formControlName="lastName" placeholder="Doe">
			</fieldset>
			<fieldset id="email">
				<legend>Email</legend>
				<input type="text" name="email_instructor" formControlName="email" placeholder="john.doe@email.com">
			</fieldset>
			<fieldset id="mobileNumber">
				<legend>Mobile Number</legend>
				<input type="text" name="mobileNumber_instructor" formControlName="mobileNumber" placeholder="0102******">
			</fieldset>
			<fieldset id="uploadCV">
				<legend>Upload CV</legend>
				<label for="uploadCV_input">
					<div class="uploadCV_img"> 
						<img src="../../assets/icons/uploadFile.svg"/>
					</div>
				</label>
				<input (change)="onFileSelect($event)" id="uploadCV_input" type="file" name="instructorCV"/>
				<p id="fileName">No File Chosen</p>
			</fieldset>
			<input type="submit" value="Submit">
		</form>
	</div>
	
</div>