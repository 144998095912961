
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Training Need Assessment Page</h1>
			<p>
				The qualifications offered by Cadres is awarded by Pearson LCCI 
				diplomas to give detailed insights and specialized knowledge 
				needed to compete within the market. Our qualifications are 
				designed in collaboration with professional bodies 
				and instructors to ensure quality industry-related skills.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/training-assessment/sect1.svg" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="bg">
		<svg xmlns="http://www.w3.org/2000/svg" width="771.79" height="auto" viewBox="0 0 771.79 820.716">
		  <path id="blob" d="M276.451,72.856c31.768,33.753,73.463,34.25,140.473,53.112,67.506,18.366,160.327,54.6,181.671,103.741,21.84,49.141-27.8,110.69-102.252,116.151-74.455,5.956-174.226-43.681-227.834-37.228-53.112,6.949-59.564,69.988-86.368,104.734-26.8,34.25-72.966,39.71-112.676,25.315S-3.5,389.54-26.334,349.334c-23.329-40.206-36.731-86.368-9.431-116.647,26.8-30.279,93.814-45.17,117.64-83.887C105.7,109.588,86.341,46.549,99.247,1.875c12.906-44.177,57.579-69.988,90.836-49.637C223.34-26.914,244.684,39.6,276.451,72.856Z" transform="matrix(0.53, -0.848, 0.848, 0.53, 73.498, 541.087)" fill="#fcaf17"/>
		</svg>
	</div>
	<div class="sect2_content">
		<div class="info">
			<p>
				Cadres conduct a needs assessment analysis to establish their current competency, performance, and position within the market through a broad spectrum of valuations through
			</p>
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="bg" id="bg1"></div>
	<div class="bg" id="bg2"></div>
	<div class="sect3_content">
		<p id="title"><span>Identifying</span> the business gap and market needs</p>
		<p>
			Cadres examines the business current departmental and state through performing a gap market 
			analysis, in terms of evaluating the current and required knowledge, skills, and attitude.
		</p>
		<p id="title"><span>Determining</span> and designing of needs analysis</p>
		<p>
			Cadres develops a strategic plan to a specific target group to be trained within the business through 
			conducting questionnaires, interviews, surveys, focus groups, HR records, competency analysis, and 
			discussions with the relevant bodies to fulfil the market need for 
		</p>
		<p id="title"><span>Training</span> options assessment </p>
		<p>
			The gap analysis generates a list of potential employee training needs, which is assessed based on the 
			goals and objectives needed for the organization, both currently and in the long-term. 
		</p>
		<p id="title"><span>Report</span> training needs and recommended training plans</p>
		<p>
			Cadres is a certified educational business that provides corporate training courses for employees and 
			business development through the LCCI Pearson Diplomas. Offering a broad range of subject areas in 
			English Language, marketing, management, IT, and quantitative finance and accounting. LCCI 
			qualifications equip both corporate and individual learners with the needed market expertise to prosper 
			in their careers.
		</p>
	</div>
</section>