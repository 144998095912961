
<div class="createClass_wrapper">
	<img (click)="hideCreatePopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="createClass_content">
		<form [formGroup]="getCreateClass" (ngSubmit)="createClass()">
			<p id="title">Add new Class</p>
			<div class="left">		
				<div class="inputs" id="input1">
					<legend>Class Name</legend>
					<input rows="1"  type="text" formControlName="name">
				</div>
				
				<div class="inputs" id="input3">
					<legend>Description</legend>
					<input rows="10" type="text" formControlName="description">
				</div>
	<!-- 			<div class="inputs" id="input4">
					<legend>Upload Video</legend>
					<div>
						<label for="upload_inputVideo">
						<div class="file_img"> 
							<img src="../../assets/icons/uploadFile.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'video')" id="upload_inputVideo" type="file" name="video"/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div> -->

	<!-- 			<div class="inputs" id="input5">
					<legend>Upload Material</legend>
					<div>
						<label for="upload_inputMaterial">
						<div class="file_img"> 
							<img src="../../assets/icons/uploadFile.svg"/>
						</div>
						</label>
						<input (change)="onFileSelect($event, 'material')" id="upload_inputMaterial" type="file" name="material"/>
						<p id="fileName">No File Chosen</p>
					</div>
				</div> -->
				<div class="inputs" id="input6">
					<legend>Set Date:</legend>
					<input type="date" formControlName="date">
					<!-- <div *ngIf="ge.controls.date.errors && ge.controls.date.touched" class="alert "
						role="alert">
					Date is Required
				</div> -->
				</div>
				<div class="inputs" id="input7">
					<legend>Set Time:</legend>
					<input type="time" step="60" formControlName="time">
					<!-- <div *ngIf="getAppointment.controls.time.errors && getAppointment.controls.time.touched" class="alert "
						role="alert">
					Time is Required
				</div> -->
				</div>
				<div class="inputs" id="input8">
					<input type="submit">
				</div>
			</div>
			<div class="right">
				<div class="inputs" id="input2">
					<legend>Zoom Link</legend>
					<input rows="1" type="text" formControlName="zoomLink"> 
				</div>
				<div class="courses_parent" *ngIf="globals.createClassRoundType_value == 'diploma'">
					<div class="courses_container">
						<div id="title">
							<p>Course</p>
						</div>
						<div class="courses" *ngFor="let course of globals.createClassRoundDiplomaCourses_value" >
							<p>{{course.courseName | removeHtmlTags}}</p>
							<div class="radios">
								<!-- (click)="assignInstructor(course.diploma_course_pk, course.course_pk)" -->
								<input  type="radio" name="course" [value]="course.diploma_course_instructor_fk" formControlName="diploma_course_instructor_fk">
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
