
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Concerts</h1>
			<p>
				Organizing a concert or a gig with the proper content that could 
				keep the audience engaged, whether it is a play, track or other 
				materials are essential for a successful event. 
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/concerts/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Organizing a concert or a gig with the proper content that 
				could keep the audience engaged, whether it is a play, 
				track or other materials are essential for a successful 
				event. Our expertise in event management makes us the 
				perfect candidates for a job like this, where we will 
				coordinate and execute an event that lives up to 
				expectations
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/concerts/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/concerts/sect3.svg">
	</div>
	<div class="sect3_content">
		<div class="info" id="info1">
			<p><span>Production</span> companies</p>
			<p><span>Media</span> Press</p>
			<p><span>Security</span> and safety plans</p>
			<p><span>Entertainment</span> booking</p>
		</div>
		<div class="info" id="info2">
			<p><span>Entertainment</span> booking</p>
			<p><span>Detailed</span> financial records</p>
			<p><span>Decorations</span> and room layouts </p>
		</div>
		<div class="info" id="info3">
			<p><span>Audit</span> coordination </p>
			<p><span>Ushering</span> arrangement </p>
			<p><span>First</span> Aid</p>
		</div>
	</div>
</section>