import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'instructor-dashboard-side-bar',
  templateUrl: './instructor-dashboard-side-bar.component.html',
  styleUrls: ['./instructor-dashboard-side-bar.component.css']
})
export class InstructorDashboardSideBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
