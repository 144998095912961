
<section class="quickActions_wrapper">
	<div class="quickActions_content">
		<div class="quickActions_container">
			<div class="actions" id="type">
				<p>{{type | titlecase}}</p>
			</div>
			<div class="actions" id="message">
				<img src="../../assets/icons/messaging.svg">
				<p>Message</p>
			</div>
		</div>
	</div>
</section>

<div class="request_wrapper">
	<div class="request_content">
		<div class="inputs" id="input1">
			<legend>Full Name</legend>
			<p >{{request.fullName}}</p>
		</div>
		<div class="inputs" id="input3">
			<legend>Email</legend>
			<p>{{request.email}}</p> 
		</div>
		<div class="inputs" id="input4">
			<legend>Mobile Number</legend>
			<p>{{request.mobileNumber}}</p>
		</div>
		<div class="inputs" id="input5">
			<legend>Company Name</legend>
			<p>{{request.companyName}}</p>
		</div>
		<div class="inputs" id="input6" [style.display]="quote_display">
			<legend>Functions</legend>
			<p>{{request.functions}}</p>
		</div>
		<div class="inputs" id="input7" [style.display]="quote_display">
			<legend>Industry</legend>
			<p>{{request.industry}}</p>
		</div>
		<div class="inputs" id="input8">
			<legend>Service Required</legend>
			<p>{{request.serviceRequired}}</p>
		</div>
		<div class="inputs" id="input9" [style.display]="quote_display">
			<legend>Date of Birth</legend>
			<p>{{request.dateOfBirth}}</p>
		</div>
	</div>
	<div class="extras">
		<div class="inputs" id="input1" [style.display]="quote_display">
			<p>{{request.businessType}}</p>
		</div>
		<div *ngIf="request.businessType == 'corprate'" class="inputs" id="input2" [style.display]="quote_display">
			<legend>Business Size</legend>
			<p>{{request.businessSize}}</p>
		</div>
		<div *ngIf="request.businessType == 'corprate'" class="inputs" id="input3" [style.display]="quote_display">
			<legend>Revenue Annually</legend>
			<p>{{request.revenueSize}}</p>
		</div>
		<div class="inputs" id="input10" [style.display]="appointment_display">
			<legend>Date</legend>
			<p>{{request.date}}</p>
		</div>
		<div class="inputs" id="input11" [style.display]="appointment_display">
			<legend>Time</legend>
			<p>{{request.time}}</p>
		</div>
	</div>
</div>

<!-- 
<div class="request_wrapper" >
	<div class="request_content">
		<div class="inputs" id="input1">
			<legend>Full Name</legend>
			<p >{{request.fullName}}</p>
		</div>
		<div class="inputs" id="input3">
			<legend>Email</legend>
			<p>{{request.email}}</p> 
		</div>
		<div class="inputs" id="input4">
			<legend>Mobile Number</legend>
			<p>{{request.mobileNumber}}</p>
		</div>
		<div class="inputs" id="input5">
			<legend>Company Name</legend>
			<p>{{request.companyName}}</p>
		</div>
		<div class="inputs" id="input6">
			<legend>Service Required</legend>
			<p>{{request.serviceRequired}}</p>
		</div>
		<div class="inputs" id="input7">
			<legend>Date</legend>
			<p>{{request.date}}</p>
		</div>
		<div class="inputs" id="input8">
			<legend>Time</legend>
			<p>{{request.time}}</p>
		</div>
	</div>
</div>
 -->