
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="add_parent">
			<div class="add" routerLink="./add-code">
				<img src="../../assets/icons/add_white.svg">
				<p>Add Code</p>
			</div>
			<div class="input">
				<input [(ngModel)]="getAddCode.value.code" type="text" name="" placeholder="Insert new code here…">
			</div>
			<button (click)="addCode()">Save</button>
		</div>
		<div class="title">
			<p [(ngModel)]="link">{{link}}</p>
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<section class="codes_wrapper">
	<div class="codes_content">
		<div class="code"  *ngFor="let code of codes | filter:searchText">
			<p id="codeName">Code: <span>{{code.code}}</span></p>
			<p routerLink="./edit-code/{{code.pk}}" id="edit">Edit</p>
		</div>
	</div>
</section>