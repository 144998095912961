
<div class="banner_wrapper">
	<div class="banner_content">
		<div (click)="showMenu()" class="hambIcon">
			<img src="../../assets/icons/hamb.svg">
		</div>
		<div class="icons" id="logo_icon" routerLink="/home">
            <img src="../../assets/icons/logo_blue.svg" alt="">
		</div>
		<div class="icons" id="notification_icon">
			<img src="../../assets/icons/notification.svg" alt="">
		</div>
		<div class="icons" id="mail_icon">
			<img src="../../assets/icons/mail.svg" alt="">
		</div>
		<div class="icons" id="calander_icon">
			<img src="../../assets/icons/calander.svg" alt="">
		</div>
		<div class="icons" id="user_icon" routerLink="instructor-dashboard/edit-profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
              <g id="user_pp" transform="translate(-17)">
                <g id="notification" transform="translate(17 -21)">
                </g>
                <g id="Icon_feather-user-check" data-name="Icon feather-user-check" transform="translate(32.973 9)">
                  <path id="Path_1578" data-name="Path 1578" d="M37.027,36.711V31.974A9.474,9.474,0,0,0,27.553,22.5H10.974A9.474,9.474,0,0,0,1.5,31.974v4.737" transform="translate(0 10.422)" fill="none" stroke="#449DD1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_1579" data-name="Path 1579" d="M25.7,13.974A9.474,9.474,0,1,1,16.224,4.5,9.474,9.474,0,0,1,25.7,13.974Z" transform="translate(3.04)" fill="none" stroke="#449DD1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </g>
            </svg>
		</div>
	</div>
</div>

<div class="menu_mobile_wrapper" id="menu_mobile_wrapper" [style.display]="menuWrapper_display">
	<div class="menu_mobile_content">
		<a id="dashBoard" routerLink="/instructor-dashboard" (click)="showMenu()">Dashboard</a>
		<a id="courses" routerLink="/instructor-dashboard/students" (click)="showMenu()">Students</a>
		<a id="classes" routerLink="/instructor-dashboard/classes" (click)="showMenu()">Classes</a>
		<a id="assignments" routerLink="/instructor-dashboard/support" (click)="showMenu()">Support</a>
		<a id="signOut" href="">Sign Out</a>
	</div>
</div>