import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-courses',
  templateUrl: './student-courses.component.html',
  styleUrls: ['./student-courses.component.css']
})
export class StudentCoursesComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) { }

  	student_pk:string = this.apiService.loggedIn_user()["pk"];

	courses:any;

	classes:any;	
	classes_time:any = [];
	classes_date:any = [];

	assignments:any;	
	assignments_dateDue:any = [];

  ngOnInit(): void {
  	 window.scrollTo(0, 0);
   // 	this.apiService.setLogged(true, "46", "student");
  	// this.student_pk = this.apiService.loggedIn_user()["pk"];

	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		this.courses = response.data;
		console.log(this.courses);
	});

  	this.apiService.getClasses_byStudentId(this.student_pk).subscribe(response=>{		
		this.classes = response;		
		
	});

	this.apiService.getAssignments_byStudentId(this.student_pk).subscribe(response=>{		
		this.assignments = response.data;
		for (var i in this.assignments) {		
			var dateDue = (this.assignments[i].dateDue).split("/");		
			[dateDue[0], dateDue[1]] = [dateDue[1], dateDue[0]];
			dateDue = dateDue.toString().replace(",", "/").replace(",", "/");
			this.assignments_dateDue.push(dateDue);		
		}
	});
  }

}
