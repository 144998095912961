import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'instructor-edit-material',
  templateUrl: './instructor-edit-material.component.html',
  styleUrls: ['./instructor-edit-material.component.css']
})
export class InstructorEditMaterialComponent implements OnInit {

	  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	form: FormGroup;
	uploadResponse:any;

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.form = this.formBuilder.group({
      material: ['']
    });
  }

  getEditMaterial=new FormGroup({ 
  materialName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});



  onFileSelect(event, fileAttrName) {
    if (event.target.files.length > 0) {
      console.log(1);
      
      const file = event.target.files[0];     
      this.form.get(fileAttrName).setValue(file);
    }
  }

  onSubmit() {
    const formDataMaterial = new FormData();
    formDataMaterial.append('material', this.form.get('material').value);
    console.log("formdata");
    
    console.log(this.form.get('material').value);
    console.log(this.getEditMaterial.value.materialName);
    

    this.apiService.editMaterial(this.globals.editMaterialPk_value, this.globals.editMaterialCourseFk_value,  this.globals.editMaterialClassFk_value, this.getEditMaterial.value.materialName, formDataMaterial).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }


   hideEditMaterialPopUp(){
  		this.globals.editMaterialWrapper_display = "none";
	}

}
