
<div class="editAssignment_wrapper">
	<img (click)="hideEditAssignmentPopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="editAssignment_content">
		<form [formGroup]="getEditAssignment" (ngSubmit)="onSubmit()">
			<p id="title">Edit Assignment</p>
			<div class="inputs" id="input1">
				<legend>Assignment Name</legend>
				<input rows="1"  type="text" formControlName="assignmentName"  [(ngModel)]="globals.editAssignmentName_value">
			</div>
			<div class="inputs" id="input6">
				<legend>Due Date:</legend>
				<input type="date" formControlName="dateDue" [(ngModel)]="globals.editAssignmentDateDue_value">
				<!-- <div *ngIf="ge.controls.date.errors && ge.controls.date.touched" class="alert "
					role="alert">
				Date is Required
			</div> -->
			</div>
			<div class="inputs" id="input5">
				<legend>Upload Assignment</legend>
				<div>
					<label for="drtyf">
					<div class="file_img"> 
						<img src="../../assets/icons/uploadFile.svg"/>
					</div>
					</label>
					<input (change)="onFileSelect($event, 'assignment')" id="drtyf" type="file" name="assignment"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>

			<div class="inputs" id="input8">
				<input type="submit">
			</div>
		</form>
	</div>
</div>
