import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-classes',
  templateUrl: './student-classes.component.html',
  styleUrls: ['./student-classes.component.css']
})
export class StudentClassesComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) { }

	student_pk:string = this.apiService.loggedIn_user()["pk"];

  courses:any;

	classes:any;

  ngOnInit(): void {
  	 window.scrollTo(0, 0);

	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		this.courses = response.data;
	});

  	this.apiService.getClasses_byStudentId(this.student_pk).subscribe(response=>{		
		this.classes = response;
		console.log(this.classes);
		var temp:any = [];	
		for (var i in this.classes) {		
			if(temp[this.classes[i].courseName]){
				temp[this.classes[i].courseName].push(this.classes[i]);
			}else{
				temp[this.classes[i].courseName] = [];
				temp[this.classes[i].courseName].push(this.classes[i]);
			}
		}
		this.classes = temp;

		console.log(this.classes);
		

		
	});

  }


}
