
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="title">
			<p><strong>Stundets Enrolled</strong></p>
		</div>
		<div class="title" id="total">
			<!-- <p># of Students {{students.length}}</p> -->
		</div>
		<div class="title">
			<!-- <p>{{productName}}</p> -->
		</div>
		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>


<section class="students_wrapper">
	<div class="students_content">
		<!-- <div class="student"  *ngFor="let student of students | filter:searchText">
			<p routerLink="/admin-dashboard/student/{{student.student_pk}}">{{student.firstName}} {{student.lastName}}</p>
			<p>Remove</p>
		</div> -->
	</div>
</section>
