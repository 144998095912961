import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from  'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

	constructor(private httpClient: HttpClient) { }
 
	getVacancies(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/vacancies.php");
	}

	getVacancy_byVacancyId(vacancy_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleVacancy_byVacancyId.php?vacancy_pk="+vacancy_pk);
	}

	getNews(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/news.php");
	}

	getNews_byNewsId(news_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleNews_byNewsId.php?news_pk="+news_pk+"");
	}

	getEvents(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/events.php");
	}

	getEvent_byEventId(event_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleEvent_byEventId.php?event_pk="+event_pk+"");
	}

	getTestimonials(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/testimonials.php");
	}

	getTestimonial_byTestimonialId(testimonial_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleTestimonial_byTestimonialId.php?testimonial_pk="+testimonial_pk+"");
	}

	requestContact(data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/requestContact.php", data);
	}

	requestQuote(data, serviceRequired):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addQuote.php?serviceRequired="+serviceRequired+"", data);
	}

	bookAppointment(data, serviceRequired):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/bookAppointment.php?serviceRequired="+serviceRequired+"", data);
	}

	getCourses(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/courses.php");
	}

	getMaterials_byCourseId(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/materials_byCourseId.php");
	}

	course_byCourseId(course_pk): Observable<any>{				
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleCourse.php?course_pk="+course_pk);
	}

	relatedCourses_byCourseId(course_fk): Observable<any>{				
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/relatedCourses_byCourseId.php?course_fk="+course_fk);
	}

	relatedInstructors_byCourseId(course_fk): Observable<any>{				
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/relatedInstructors_byCourseId.php?course_fk="+course_fk);
	}

	relatedTestimonials_byCourseId(course_fk): Observable<any>{				
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/relatedTestimonials_byCourseId.php?course_fk="+course_fk);
	}


	loggedInStatus = false;
	user_pk = "";
	user_type = "";
	user_typeOfAccess = "";
	user = [];

	signUp_student(data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/createStudent.php", data);
	}

	signUp_instructor(data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/createInstructor.php", data);
	}

	applyInstructor(data, cvFile):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/instructorApply.php?firstName="+data['firstName']+"&lastName="+data['lastName']+"&email="+data['email']+"&mobileNumber="+data['mobileNumber'], cvFile);
	}
	
	signIn(email,password):Observable<any> {
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/user_exists.php?email="+email+"&password="+password+"")
	}

	sendVerificationEmail(email):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/email.php?email="+email, email);
	}

	isRemebered(data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/isLoggedIn.php", data);
	}


	setLogged(value:boolean, pk:string, type:string) {
		this.loggedInStatus = value;
		this.user_pk = pk
		this.user_type = type;
	}

	get isLoggedIn() {
		return this.loggedInStatus; 
	}

	public loggedIn_user() {
		this.user["type"] = [];
		this.user["type"].push(this.user_type);
		this.user["pk"] = [];
		this.user["pk"].push(this.user_pk);
		return this.user; 
	}
 
	uploadSignature(data, student_fk, course_fk):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/uploadSignature.php?student_pk="+student_fk+"&course_pk="+course_fk, data);
	}

	regiserStudentToCourse(student_fk, round_fk, signature):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/registerCourse_byStudentId.php", {"student_fk": Number(student_fk), "round_fk": Number(round_fk), "signature": signature});
	}

	regiserStudentToTest(student_fk, test_fk, signature):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/registerTest_byStudentId.php", {"student_fk": Number(student_fk), "test_fk": Number(test_fk), "signature": signature});
	}

	getStudent_byStudentId(student_fk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleStudent_byStudentId.php?student_pk="+student_fk+"");
	}

	getAssignments_byStudentId(student_fk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/assignments_byStudentId.php?student_pk="+student_fk+"");
	}

	getMaterials_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/materials_byStudentId.php?student_pk="+student_fk+"");
	}

	getCourses_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/courses_byStudentId.php?student_pk="+student_fk+"");
	}

	getClasses_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/classes_byStudentId.php?student_pk="+student_fk+"");
	}

	getExams_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/exams_byStudentId.php?student_pk="+student_fk+"");
	}

	getQuizes_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/quizes_byStudentId.php?student_pk="+student_fk+"");
	}

	getGrades_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/grades_byStudentId.php?student_pk="+student_fk+"");
	}

	getCertificates_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/certificates_byStudentId.php?student_pk="+student_fk+"");
	}

	getMessages_byStudentId(student_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/messages_byStudentId.php?student_pk="+student_fk+"");
	}

	deleteMessage_byStudentId(student_fk, message_fk): Observable<any>{									
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteMessage_byStudentId.php?student_fk="+student_fk+"&message_fk="+message_fk);
	}

	editStudent(data, student_fk):Observable<any>{
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editStudent.php?student_fk="+student_fk, data);
	}



	// TEACHER DASHBOARD

	getInstructor_byInstructorId(instructor_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleInstructor_byInstructorId.php?instructor_pk="+instructor_pk+"");
	}

	getCourses_byInstructortId(instructor_pk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/courses_byInstructorId.php?instructor_pk="+instructor_pk+"");
	}


	getClasses_byInstructortId(instructor_pk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/classes_byInstructorId.php?instructor_pk="+instructor_pk+"");
	}

	getStudents_byInstructortId(instructor_pk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/students_byInstructorId.php?instructor_pk="+instructor_pk+"");
	}

	getExams_byInstructortId(instructor_pk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/exams_byInstructorId.php?instructor_pk="+instructor_pk+"");
	}

	createCourseClass(data, round_fk):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/createCourseClass.php?round_fk="+round_fk, data);
	}

	createDiplomaCourseClass(data, diplomaCourseInstructor_fk):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/createDiplomaCourseClass.php?diplomaCourseInstructor_fk="+diplomaCourseInstructor_fk, data);
	}

	editCourseClass(data, class_fk):Observable<any>{
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editCourseClass.php?class_fk="+class_fk, data);
	}

	editDiplomaCourseClass(data, class_fk):Observable<any>{
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editDiplomaCourseClass.php?class_fk="+class_fk, data);
	}

	editMaterial(material_pk, course_fk, class_fk, materialName, data):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editMaterial.php?material_pk="+material_pk+"&course_fk="+course_fk+"&class_fk="+class_fk+"&materialName="+materialName, data);
	}



	uploadCourseMaterials(course_pk , class_pk, materialName, data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/uploadMaterial.php?course_pk="+course_pk+"&class_pk="+class_pk+"&materialName="+materialName, data);
	}

	uploadClassVideo(course_pk , class_pk, data):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/uploadClassVideo.php?course_pk="+course_pk+"&class_pk="+class_pk, data);
	}

	uploadQuiz(course_pk, quiz, data):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addQuiz.php?course_pk="+course_pk+"&quizName="+data["quizName"]+"&timeDue="+data["timeDue"]+"&dateDue="+data["dateDue"], quiz);
	}

	editQuiz(quiz_pk, course_fk, quizDetails, data):Observable<any>{				
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editQuiz.php?quiz_pk="+quiz_pk+"&course_fk="+course_fk+"&quizName="+quizDetails.quizName+"&timeDue="+quizDetails.timeDue+"&dateDue="+quizDetails.dateDue, data);
	}

	editInstructor(data, instructor_fk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editInstructor.php?instructor_fk="+instructor_fk, data);
	}

	uploadAssignment(course_pk, assignment, data):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addAssignment.php?course_pk="+course_pk+"&assignmentName="+data["assignmentName"]+"&dateDue="+data["dateDue"], assignment);
	}

	editAssignment(assignment_pk, course_fk, assignmentDetails, data):Observable<any>{						
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editAssignment.php?assignment_pk="+assignment_pk+"&course_fk="+course_fk+"&assignmentName="+assignmentDetails.assignmentName+"&dateDue="+assignmentDetails.dateDue, data);
	}

	getQuizes_byInstructorId(instructor_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/quizes_byInstructorId.php?instructor_fk="+instructor_fk+"");
	}

	getCourseMaterials_byInstructorId(instructor_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/courseMaterials_byInstructorId.php?instructor_fk="+instructor_fk+"");
	}

	getAssignments_byInstructorId(instructor_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/assignments_byInstructorId.php?instructor_fk="+instructor_fk+"");
	}

	getMessages_byInstructorId(instructor_fk):Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/messages_byInstructorId.php?instructor_pk="+instructor_fk+"");
	}

	deleteMessage_byInstructorId(instructor_fk, message_fk): Observable<any>{									
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteMessage_byInstructorId.php?instructor_fk="+instructor_fk+"&message_fk="+message_fk);
	}







	// ADMIN DAHSBOARD

	adminSignIn(email,password):Observable<any> {
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/adminUser_exists.php?email="+email+"&password="+password+"")
	}

	getStudents(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/students.php");
	}

	deleteStudent(student_pk): Observable<any>{				
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteStudent_byStudentId.php?student_pk="+student_pk);
	}

	getInstructors(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/instructors.php");
	}

	deleteInstructor(instructor_pk): Observable<any>{									
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteInstructor_byInstructorId.php?instructor_pk="+instructor_pk);
	}

	getProducts(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/products.php");
	}

	deleteProduct(productType, product_pk): Observable<any>{		
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteProduct.php?productType="+productType+"&product_pk="+product_pk);
	}

	getClasses(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/classes.php");
	}

	getAppointments(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/appointments.php");
	}

	getAppointment_byAppointmentId(appointment_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleAppointment_byAppointmentId.php?appointment_pk="+appointment_pk);
	}

	getQuotes(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/quotes.php");
	}

	getQuote_byQuoteId(quote_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleQuote_byQuoteId.php?quote_pk="+quote_pk);
	}

	getContactRequests(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/contactRequests.php");
	}

	getContactRequest_byContactRequestId(contact_pk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleContactRequest_byContactRequestId.php?contact_pk="+contact_pk);
	}

	deleteNews(news_pk): Observable<any>{									
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteNews_byNewsId.php?news_pk="+news_pk);
	}

	deleteEvent(event_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteEvent_byEventId.php?event_pk="+event_pk);
	}

	addNews(data, img):Observable<any>{				
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addNews.php?title="+data['title']+"&description="+data['description']+"&info="+data['info']+"&date="+data['date'], img);
	}

	editNews(news_pk, data, img):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editNews.php?news_pk="+news_pk+"&title="+data['title']+"&description="+data['description']+"&info="+data['info']+"&date="+data['date'], img);
	}

	addEvent(data, img):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addEvent.php?title="+data['title']+"&description="+data['description']+"&info="+data['info']+"&date="+data['date'], img);
	}

	editEvent(event_pk, data, img):Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editEvent.php?event_pk="+event_pk+"&title="+data['title']+"&description="+data['description']+"&info="+data['info']+"&date="+data['date'], img);
	}

	addTestimonial(data, img):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addTestimonial.php?name="+data['name']+"&text="+data['text'], img);
	}

	editTestimonial(testimonial_pk, data, img):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editTestimonial.php?testimonial_pk="+testimonial_pk+"&name="+data['name']+"&text="+data['text'], img);
	}

	deleteTestimonial(testimonial_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteTestimonial_byTestimonialId.php?testimonial_pk="+testimonial_pk);
	}

	addVacancy(data):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addVacancy.php", data);
	}

	editVacancy(data, vacancy_pk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editVacancy.php?vacancy_pk="+vacancy_pk, data);
	}

	deleteVacancy(vacancy_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteVacancy_byVacancyId.php?vacancy_pk="+vacancy_pk);
	}

	addCourse(data, files):Observable<any>{						
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addCourse.php?name="+data['name']+"&suite"+data['suite']+"&level="+data['level']+"&learningHours="+data['learningHours']+"&description="+data['description']+"&price="+data['price']+"&requirements="+data['requirements']+"&whoShouldAttend="+data['whoShouldAttend']+"&relatedCourses="+data['relatedCourses']+"&relatedInstructors="+data['relatedInstructors']+"&relatedTestimonials="+data['relatedTestimonials'], files);
	}

	editCourse(data, course_pk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editCourse.php?course_pk="+course_pk, data);
	}

	deleteCourse(course_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteCourse_byCourseId.php?course_pk="+course_pk);
	}


	getPages(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/pages.php");
	}

	pageSections(): Observable<any>{																	
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/pageSections.php");
	}

	pageSections_byPageId(page_fk): Observable<any>{																	
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/pageSections_byPageId.php?page_fk="+page_fk);
	}

	editPageSection(pageSection_pk, text, assetsFolder, img): Observable<any>{																																
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/editPage.php?pageSection_pk="+pageSection_pk+"&text="+text+"&pageFolder="+assetsFolder, img);
	}

	getMessages(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/messages.php");
	}

	message_byMessageId(message_pk): Observable<any>{																	
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleMessage_byMessageId.php?message_pk="+message_pk);
	}

	newMessage(recipientType, data): Observable<any>{				
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/newMessage.php?recipientType="+recipientType, data);
	}

	deleteMessage(message_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteMessage_byMessageId.php?message_pk="+message_pk);
	}

	getCoursesRounds(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/coursesRounds.php");
	}

	createCourseRound(data): Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addCourseRound.php", data);
	}

	courseRound_byRoundId(round_pk): Observable<any>{		
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleCourseRound_byCourseRoundId.php?courseRound_pk="+round_pk);
	}

	editCourseRound(data, round_pk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editCourseRound.php?round_pk="+round_pk, data);
	}

	

	getUnusedCodes(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/unUsedCodes.php");
	}

	addCode(data): Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addCode.php", data);
	}

	getClasses_byCourseRoundId(round_fk):Observable<any>{		
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/classes_byCourseRoundId.php?round_fk="+round_fk+"");
	}
	
	getStudents_byCourseRoundId(round_fk):Observable<any>{					
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/students_byCourseRoundId.php?round_pk="+round_fk+"");
	}

	getExams_byRoundId(round_fk):Observable<any>{							
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/exams_byRoundId.php?round_pk="+round_fk+"");
	}

	editExam(exam_pk, data):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editExam.php?exam_pk="+exam_pk, data);
	}

	addExam(round_fk, data): Observable<any>{
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addExam.php?round_fk="+round_fk, data);
	}

	getDiplomas(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/diplomas.php");
	}

	diploma_byDiplomaId(diploma_pk): Observable<any>{				
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleDiploma.php?diploma_pk="+diploma_pk);
	}

	getDiplomaCourses_byDiplomaId(diploma_fk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/diplomaCourses_byDiplomaId.php?diploma_fk="+diploma_fk);
	}

	getDiplomaCourses_byRoundId(round_fk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/diplomaCourses_byRoundId.php?round_fk="+round_fk);
	}

	addDiploma(data):Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addDiploma.php", data);
	}

	editDiploma(data, diploma_pk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editDiploma.php?diploma_pk="+diploma_pk, data);
	}

	deleteDiploma(diploma_pk): Observable<any>{																	
		return this.httpClient.delete("https://www.cadreseg.com/backend-php/api/deleteDiploma_byDiplomaId.php?diploma_pk="+diploma_pk);
	}

	getDiplomasRounds(): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/diplomasRounds.php");
	}

	createDiplomaRound(data): Observable<any>{		
		return this.httpClient.post("https://www.cadreseg.com/backend-php/api/addDiplomaRound.php", data);
	}

	diplomaRound_byRoundId(round_pk): Observable<any>{		
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/singleDiplomaRound_byDiplomaRoundId.php?diplomaRound_pk="+round_pk);
	}

	editDiplomaRound(data, round_pk):Observable<any>{		
		return this.httpClient.put("https://www.cadreseg.com/backend-php/api/editDiplomaRound.php?round_pk="+round_pk, data);
	}

	getStudents_byDiplomaRoundId(round_fk):Observable<any>{					
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/students_byDiplomaRoundId.php?round_pk="+round_fk+"");
	}

	getClasses_byDiplomaRoundId(round_fk): Observable<any>{
		return this.httpClient.get("https://www.cadreseg.com/backend-php/api/classes_byDiplomaRoundId.php?round_fk="+round_fk+"");
	}

}
