

<apply-as-instructor></apply-as-instructor>


<div class="sect1_wrapper">
	<div class="bg"><img src="../../assets/backgrounds/careers/sect1.png" alt=""></div>
	<div class="sect1_content">
		<div class="info">
			<h1>Why Join CADRES</h1>
			<p>
				Have you ever wondered what it takes to achieve big dreams? It has always been commitment and dedication, the qualities you do already have. Everyone is uniquely different and innovative in his or her own way and we believe in your capabilities. We are here to open the doors for change not only for you, but also for the whole community. In cadres, our main concern is stretching a helping hand to bring you a better tomorrow.
			</p>
			<button id="vacancies_btn" onclick="scrollToElement('#sect4_wrapper')">
				Check out vacancies
				<img id="btnImg" src="../../assets/icons/arrowDown_black.svg">
			</button>
		</div>
		<div class="img"><img src="../../assets/imgs/careers/sect1.png" alt=""></div>
	</div>
</div>


<div class="sect2_wrapper sect2_wrapper_desktop">
	<div class="sect2_content">
		<div class="info">
			<p id="title">CADRES Working Environment </p>
			<p>
				Cadres ensures a fair opportunity to everyone. We aim to build a healthy working environment that is full of fun and proficiency at the very same time. Our code of business conduct and ethics act as shield for all learners, staff, customers, stakeholders, and visitors, making them safe from any sort of harm and abuse
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/careers/sect2_1.png">
		</div>
	</div>
</div>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<p id="title">Youth-oriented Internship Programs</p>
			<p>
				In cadres, we do value youth. They do not only have a critical business role to play, but also a social role to push the whole economy forward. Our internships are designed to open the doors for university students and high school seniors who want to experience the marketplace through real business situations.
				We believe that only youth can elevate a business’s productivity to an unbelievable extent. Our programs will prepare you for grabbing the market’s best opportunities that would allow you to develop an authentic talent for the years to come.
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/careers/sect2_2.png">
		</div>
	</div>
</section>

<div class="sect4_wrapper sect3_wrapper_desktop">
	<div class="sect4_content">
		<div class="info">
			<p id="title">Fresh Graduates Vacancies</p>
			<p>
				We believe that organizations are being built by devotion and loyalty. Loyalty is developed through a better quality of life and privileged standards of living. Therefore, we invest in our own Cadres to bring out the best qualities within themselves to be worthy future leaders. Our employees will enjoy a wide variety of benefits that would support them while taking the first step in their career path. 
			</p>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/careers/sect3_1.png">
		</div>
	</div>
</div>

<section class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<p id="title">Lecturers Vacancies</p>
			<p>
				Cadres have partnered with Pearson to deliver a better quality of business education in Egypt.  Our considerations are drawn to build a potential academic team who have combined solid knowledge with practical experience in the marketplace. We are seeking genuine calibres to share our organizational goals that focus on equipping Egyptian youth as well as entrepreneurs with an international guidance toolkit. We are quite aware with the significant role lecturers play in the business scene today. Hence, our main concern is to support our lecturers with a wide variety of assisting tools for establishing a mutually beneficial collaboration.  
			</p>
			<button (click)="showApplyInstructorWrapper('flex')">Upload your CV</button>
		</div>
		<div class="img">
			<div id="opacity"></div>
			<img src="../../assets/imgs/careers/sect3_2.png">
		</div>
	</div>
</section>



<div class="sect6_wrapper" id="sect6_wrapper">
	<p id="title">Vacancies</p>
	<div class="sect6_content">
			<div class="cards" id="card1" *ngFor="let vacancy of vacancies; index as i;" routerLink="./vacancy/{{vacancy.vacancy_pk}}">
				<p id="title">{{vacancy.title | removeHtmlTags}}</p>
				<p id="info">
					{{vacancy.summary| removeHtmlTags | slice:1:250}} ...
				</p>
				<p id="date">End Date 20/8/2020</p>
			</div>
			<button>
				<p>Next</p>
				<img id="btnImg" src="../../assets/icons/arrowRight_orange.svg">
			</button>
	</div>
</div>