import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-enrollments',
  templateUrl: './admin-enrollments.component.html',
  styleUrls: ['./admin-enrollments.component.css']
})
export class AdminEnrollmentsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  	courses_display:string = "none";
	diplomas_display:string = "block";
	courses_bg:string = "#f1f1f1";
	diplomas_bg:string = "#C5C5C5";

	products:any;
	courses:any;
	diplomas:any;

	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void {
	window.scrollTo(0, 0);
	this.switch('courses');
	this.apiService.getProducts().subscribe(response=>{	
		console.log(response);
		this.products = response.data;
		
		this.products = this.turn2dArrayToAssoc2dArray(this.products, 'type');
		this.courses = this.products['course'];
		this.diplomas = this.products['diploma'];
	});
  }


   switch(filter):void{
  	if(filter == "courses") {
		this.courses_display = "block";
		this.diplomas_display = "none";
		this.courses_bg = "#C5C5C5";
		this.diplomas_bg = "#f1f1f1";
  	}else if(filter == "diplomas") {
		this.courses_display = "none";
		this.diplomas_display = "block";
		this.courses_bg = "#f1f1f1";
		this.diplomas_bg = "#C5C5C5";
  	}
  }

}
