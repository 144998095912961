import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-new-message',
  templateUrl: './admin-new-message.component.html',
  styleUrls: ['./admin-new-message.component.css']
})
export class AdminNewMessageComponent implements OnInit {

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) { }


    studentNewMessage_display:string = "block";
	instructorNewMessage_display:string = "none";
	students_bg:string = "#C5C5C5";
	instructors_bg:string = "#f1f1f1";

  instructors:any;
  students:any;

getNewMessage=new FormGroup({
	'sender': new FormControl(null),
	'content': new FormControl(null),
	'recipientInstructors': new FormControl(null),
	'recipientStudents': new FormControl(null),
  });

  ngOnInit(): void {
  	this.apiService.getStudents().subscribe(response=>{	
		this.students = response.data;
  		console.log(this.students);
	});
	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
  		console.log(this.instructors);
	});
  }

  sendMessage(recipientType):void{
  	this.getNewMessage.value.sender = 0;
  	console.log(this.getNewMessage.value);
  	
  	this.apiService.newMessage(recipientType, this.getNewMessage.value).subscribe(response=>{	
  		console.log(response);
	});
  }

   switch(filter):void{
  	if(filter == "students") {
		this.studentNewMessage_display = "block";
		this.instructorNewMessage_display = "none";
		this.students_bg = "#C5C5C5";
		this.instructors_bg = "#f1f1f1";
  	}else if(filter == "instructors") {
		this.studentNewMessage_display = "none";
		this.instructorNewMessage_display = "block";
		this.students_bg = "#f1f1f1";
		this.instructors_bg = "#C5C5C5";
  	}
  }

}
