
<book-an-appointment [style.display] = "globals.bookAppointmentWrapper_display"></book-an-appointment>


<section class="sect1_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/utilising-our-platform/sect1.svg">
	</div>
	<div class="sect1_content">
		<div class="info">
			<h1>How to utilise the use of our platform </h1>
			<p>
				Cadres is a highly dynamic website made to give our users the best experience they could ever get from a website, it’s very responsive and handy, the designs we use make it much easier for the users to actually get what they want, you can finalize payment, book a meeting with our consultants and more. 
			</p>
			<p>
				Cadres School of Business provides business owners with a free to use platform helping them grow their businesses through live chatting with our consultants. Consultations differ from getting the cheapest item in the supermarket to business planning, marketing strategic planning, and recruiting the best employees matching your company needs with the market demands.  Real-life meetings with our consultants could be booked to facilitate the planning process for expanding your business
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/utilising-out-platform/sect1.svg" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<section class="sect2_content">
		<p>
			<strong>By definition</strong> a business consultant is a professional who analyses businesses, creates solutions to problems and helps organisations developing efficient plans to match their company’s goals. Our consultants use contemporary techniques as well as strategies to guide businesses through their journey of achieving organisational objectives. Consultations could save the company a huge amount of time figuring what exactly the company should do for its own good.  In US  more than 2B $ were given to consultants for their counselling.
		</p>
	</section>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<h1>According to Harvard Business Review there are a hierarchy of consulting purposes</h1>
		<div class="purposes_list">
			<div class="left">
				<div class="purposes" id="purpose1">
					<div class="number">
						<p>1</p>
					</div>
					<p>
						Providing information
					</p>
				</div>
				<div class="purposes" id="purpose2">
					<div class="number">
						<p>2</p>
					</div>
					<p>
						Solving client’s problems
					</p>
				</div>
				<div class="purposes" id="purpose3">
					<div class="number">
						<p>3</p>
					</div>
					<p
					>Making a analysis which may necessitate redefinition of the problems
				</p>
				</div>
				<div class="purposes" id="purpose4">
					<div class="number">
						<p>4</p>
					</div>
					<p>
						Marking recommendations 
						based on the analysis
					</p>
				</div>
			</div>
			<div class="right">
				<div class="purposes" id="purpose5">
					<div class="number">
						<p>5</p>
					</div>
					<p>
						Assisting with implementations of
						the recommendations of their analysis 
					</p>
				</div>
				<div class="purposes" id="purpose6">
					<div class="number">
						<p>6</p>
					</div>
					<p>
						Executing corrective actions 
					</p>
				</div>
				<div class="purposes" id="purpose7">
					<div class="number">
						<p>7</p>
					</div>
					<p>
						Training clients solving
						similar problems in the future 
					</p>
				</div>
				<div class="purposes" id="purpose8">
					<div class="number">
						<p>8</p>
					</div>
					<p>
						Permanently improving 
						organizational effectiveness
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="bg"></div>
	<div class="sect4_content">
		<p>
			In Cadres school of Busines, our consultants are a group of experienced 
			DBAs and MBAs holders combining academic knowledge with practical 
			experience providing your company with its needs.
		</p>
		<button (click)="show('show')">Book an Appointment Now</button>
	</div>
</section>