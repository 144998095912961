
<div class="newRound_wrapper">
	<div class="newRound_content">
		<p id="title">New Round</p>
		<form [formGroup]="getAddRound" (ngSubmit)="addRound()">
			<div class="courseInstructors_parent">
				<p id="title">Assign an instructor to each course</p>
				<div class="courseInstructors_container" *ngFor="let course of courses">
					<div id="courseName">
						<p>{{course.courseName | removeHtmlTags | slice:0:22}}<span *ngIf="course.courseName.length>=32">...</span></p>
					</div>
					<div class="instructors" *ngFor="let instructor of instructors" >
						<img src="../../assets/icons/user_pp.svg">
						<p>{{instructor.firstName}} {{instructor.lastName}}</p>
						<div class="radios">
							<input (click)="assignInstructor(course.diploma_course_pk, instructor.instructor_pk)" type="radio" id="corprate" [name]="course.courseName" [value]="instructor.instructor_pk" >
						</div>
					</div>
				</div>
			</div>
			<div class="inputs" id="input9">
				<input type="submit">
			</div>
		</form>
	</div>
</div>
