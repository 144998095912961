
<div class="news_wrapper">
	<div class="news_content">
		<p id="title">Add Event</p>
		<form [formGroup]="getAddEvent" (ngSubmit)="addEvent()">
			<div class="inputs" id="input1">
				<legend>Title</legend>
				<input rows="1"  type="text" formControlName="title">
			</div>
			<div class="inputs" id="input2">
				<legend>Description</legend>
				<input rows="1"  type="text" formControlName="description">
			</div>
			<div class="inputs" id="input3">
				<legend>Information</legend>
				<textarea rows="1" type="text" formControlName="info"> </textarea>
			</div>
			<div class="inputs" id="input4">
				<legend>Date:</legend>
				<input type="date" formControlName="date">
			</div>
			<div class="inputs" id="input5">
				<legend>Upload Image</legend>
				<div>
					<label for="upload_inputMaterial">
					<div class="file_img"> 
						<img src="../../assets/icons/add_black.svg"/>
					</div>
					</label>
					<input (change)="onFileSelect($event)" id="upload_inputMaterial" type="file" name="news"/>
					<p id="fileName">No File Chosen</p>
				</div>
			</div>
			<div class="inputs" id="input6">
				<input type="submit">
			</div>
		</form>
	</div>
</div>