
<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<p>My Courses</p>
			</div>
		</div>

		<div class="coursesProgress">
			<div class="courseProgress" *ngFor="let course of courses; index as i" id="courseProgress{{i+1}}" routerLink="/student-dashboard/course/{{course.courseName}}" [ngStyle]="globals.dashboardTheme_cards">	
				<p id="title">{{course.courseName | truncateText}}</p>
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "course.progress"></div>
					</div>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Course Name</p>
			<p id="header2">Upcoming Class</p>
			<p id="header3">Assignment</p>
			<p id="header4">Quizes</p>
			<p id="header5">Exams</p>
			<p id="header6">Progress</p>
		</div>

		<div class="coursesSummary" *ngFor="let course of courses; index as i" [ngStyle]="globals.dashboardTheme_cards">
			<div class="summaries" id="summary1">
				<p>{{i+1}}. {{course.courseName  | truncateText}}</p>
			</div>
			<div class="summaries" id="summary2">
				<p>{{classes[i].name}}</p>
				<p>{{classes[i].date}}</p>
			</div>
			<div class="summaries" id="summary3">
				<p>{{assignments[i].assignmentName}}</p>
				<p>{{assignments_dateDue[i]}}</p>
			</div>
			<div class="summaries" id="summary4">
				<p>Quiz</p>
			</div>
			<div class="summaries" id="summary5">
				<p>Exam</p>
			</div>
			<div class="summaries" id="summary6">
				<div class="courseProgress">
					<span class="progressBar">
						<span id="progress" [style.width] = "course.progress"></span>
					</span>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

	</div>
</div>