
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Iris Situational Judgmental Test</h1>
			<p>
				A measure that businesses use to examine job applicants’ 
				critical thinking and verbal critical reasoning skills. The 
				test examines hard-skills appraisal and is for recruitment 
				to measure critical thinking ability levels in job, university, 
				and business school applicants.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/iris-test/sect1.svg">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>IRIS situational judgement test </h1>
			<p>
				A test that measures the efficiency of a person’s decision-
				making and judgement skills when faced with situations they 
				are likely to face in the roles they are applying for. IRIS 
				places candidates in realistic work scenarios; each scenario 
				is task-driven to provide practical and relevant content for 
				the roles they applied for in the organization.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/iris-test/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="left">
			<p id="title">What is IRIS situational judgement test?</p>
			<p>- Available on request</p>
			<p>- Untimed </p>
			<p>-	UK English</p>
			<p>- Pre-hiring process does not need supervision </p>
   			<p>- Helps applicants for customer service, junior management 
  and sales roles</p>
		</div>
		<div class="right">
			<p id="title">Why rely on Iris judgement test?</p>
			<div>	
				<p>
					<span><p>1</p></span> Saves your time and budget through cost-effective online sifting
				</p>
			</div>
			<div>	
				<p>
					<span><p>2</p></span> Saves your time and budget through cost-effective online sifting
				</p>
			</div>
			<div>	
				<p>
					<span><p>3</p></span> Aids applicants to select and create an image of their roles.
				</p>
			</div>
		</div>
	</div>
</section>