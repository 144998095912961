import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(public globals: Globals) { }

	subTitles:any;
	subTitle_bodies:any;

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  this.globals.signWrapper_display = "none";
  	this.subTitles = ["Registration and Administration: "];
  	this.subTitle_bodies = [
							  	[`
								~•	When a candidate enrolls in the General English course, full price of the course 4050EGP for online/4950EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) has to be paid in advance.
								~•	When a candidate enrolls in the Business English course, full price of the course 4050EGP for online/4950EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) has to be paid in advance.
								~•	There is an availability of installment in which candidates are allowed to pay the monthly fees of course 1350EGP for online/1650EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) in advance.
								~•	If a candidate wants to change the starting date of the course, they must notify us 15 days before the starting date. Otherwise, cancellation fees will be applied as per our cancellation matrix.

							`]
						];

	for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}						
  }


changeBody(header){
		if(header == "Registration and Administration") {
			  	this.subTitles = [header];
			  	this.subTitle_bodies = [
										  	[`
											~•	When a candidate enrolls in the General English course, full price of the course 4050EGP for online/4950EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) has to be paid in advance.
											~•	When a candidate enrolls in the Business English course, full price of the course 4050EGP for online/4950EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) has to be paid in advance.
											~•	There is an availability of installment in which candidates are allowed to pay the monthly fees of course 1350EGP for online/1650EGP for offline + material fees 400EGP + Versant English Placement test fee 300EGP (not applicable for A1 and A1- students) in advance.
											~•	If a candidate wants to change the starting date of the course, they must notify us 15 days before the starting date. Otherwise, cancellation fees will be applied as per our cancellation matrix.

										`]
									];

				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
		}else if(header == "Students Resposibility"){
			  	this.subTitles = [header];
			  	this.subTitle_bodies = [
										  	[`
										  	~Students are responsible for practicing their language skills 1 hour at least per day through course duration.

										`]
										];


				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
	
	}else if(header == "Cancellation fees"){
			  	this.subTitles = [header];
			  	this.subTitle_bodies = [
										  	[`
										  	~•	If a candidate cancels more than three weeks before course starts, the full amount will be refunded.
											~•	If a candidate cancels more than one week before course starts, 70% of course fees will be refunded (excluding versant test fees).
											~•	If a candidate cancels less than one week prior course starts 60% of course fees will be refunded (excluding versant test fees).
											~•	If a candidate cancels after course starts, the amount of the month (first, second or third) candidate endures is not refundable. 
											~•	The deadline of making late payments of the course second and third months is one week, with a 5% penalty.
											~•	 Cadres reserves the right to cancel courses of students who do not have acceptable conduct (violating Cadres safeguarding policy).
											~•	Missing more 25% of courses, candidate will be dismissed.
											~•	No refund will be offered for missed classes.

										`]
										];


				for (var i = 0; i < this.subTitle_bodies.length; ++i) {this.subTitle_bodies[i] = this.subTitle_bodies[i][0].split("~");}
	
	}
}

}
