
<div class="editUser_wrapper">

	<div class="editUser_content">
		<form [formGroup]="getUser" (ngSubmit)="editUser()">
			<p id="title">Edit Profile</p>
			<div class="pp">
				<img id="pp" src="../../assets/icons/user_pp.svg">
				<a>Change</a>
			</div>
			<div class="inputs" id="input1">
				<legend>First Name</legend>
				<input rows="1"  type="text" formControlName="firstName" [(ngModel)]="instructor.firstName">
			</div>
			<div class="inputs" id="input2">
				<legend>Last Name</legend>
				<input rows="1"  type="text" formControlName="lastName" [(ngModel)]="instructor.lastName">
			</div>
			<div class="inputs" id="input3">
				<legend>Email</legend>
				<input rows="1" type="text" formControlName="email" [(ngModel)]="instructor.email"> 
			</div>
			<div class="inputs" id="input4">
				<legend>Mobile Number</legend>
				<input rows="10" type="text" formControlName="mobileNumber" [(ngModel)]="instructor.mobileNumber">
			</div>
			<div class="inputs" id="input5">
				<legend>Address</legend>
				<input rows="10" type="text" formControlName="address" [(ngModel)]="instructor.address">
			</div>
			<div class="inputs" id="input6">
				<legend>Postal Code</legend>
				<input rows="10" type="text" formControlName="postalCode" [(ngModel)]="instructor.postalCode">
			</div>
			<div class="inputs" id="input7">
				<legend>Password</legend>
				<input rows="10" type="text" formControlName="password">
			</div>
			<div class="inputs" id="input8">
				<legend>Set Date:</legend>
				<input type="date" formControlName="dob" [(ngModel)]="instructor.dob">

			</div>
			<div class="inputs" id="input9">
				<input type="submit">
			</div>
		</form>
	</div>
</div>