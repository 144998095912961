<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro">
			<div class="cards_content">
				<p>My Students</p>
			</div>
		</div>
		<div class="students">
			<div class="student" *ngFor="let student of students; index as i" id="student{{i+1}}">	
				<div class="user_icon"><img src="../../assets/icons/user_pp.svg" alt=""></div>
				<p id="title">{{student.firstName + " " + student.lastName | titlecase}} <br> {{student.courseName}}</p>
			</div>
		</div>
	</div>
</div>