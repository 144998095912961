
<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro">
			<div class="cards_content">
				<p>My Classes</p>
			</div>
		</div>

		<div class="courses">
			<div class="course" *ngFor="let course of courses; index as i" id="course{{i+1}}" routerLink="/instructor-dashboard/course/{{course.courseName}}">	
				<p id="title">{{course.courseName | truncateText}}</p>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Upcoming Class</p>
		</div>

		<div class="coursesSummary" *ngFor="let course of courses; index as i">
			<div class="summaries" id="summary1">
				<p>{{i+1}}. {{course.courseName  | truncateText}}</p>
			</div>
			<div class="summaries" id="summary2" *ngFor="let class of classes[course.courseName] | slice:0:4;;">
				<p>{{class.name}}</p>
				<p>{{class.date}}</p>
				<a routerLink="/instructor-dashboard/course-classes/{{course.courseName}}">Edit</a>
			</div>
		</div>

	</div>
</div>