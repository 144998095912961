
<section class="sect1_wrapper">
	<div class="info_extra desktop">
		<div>
			<p id="title">CIM</p>
			<p>The Chartered Institute of Marketing</p>
			<p id="title">MIS</p>
			<p>The Marketing Institute Singapore </p>
			<p id="title">IMM</p>
			<p>The Institute of Marketing Malaysia</p>
		</div>
	</div>
	<div class="sect1_content">
		<div class="info">
			<h1>Marketing</h1>
			<p>
				students to flexibly tailor their modules and skills based on their preferences as the LCCI Marketing diplomas are compromised of Level 2 and Level 3 to suit the students’ skills set towards their area of interest. The LCCI Diplomas is individually specialized for students looking to gain proficiency in Marketing
			</p>
			<div class="img mobile">
				<img src="../../assets/imgs/marketing/sect1.png" alt="">
			</div>
			<p>
				With the introduction of the LCCI Marketing Diplomas, Cadres offers 
				both qualified LCCI diplomas and Advanced Diplomas in Marketing 
				for all specializations, which compromises of both core and optional 
				modules (if applicable). The qualifying duration for each diploma and 
				advanced LCCI diplomas is 24 months. The available LCCI marketing 
				diplomas have been developed in collaboration with
			</p>
		</div>
		<div class="img desktop">
			<img src="../../assets/imgs/marketing/sect1.png" alt="">
		</div>
	</div>
	<div class="info_extra mobile">
		<div>
			<p id="title">CIM</p>
			<p>The Chartered Institute of Marketing</p>
			<p id="title">MIS</p>
			<p>The Marketing Institute Singapore </p>
			<p id="title">IMM</p>
			<p>The Institute of Marketing Malaysia</p>
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<p id="title">The available LCCI marketing diplomas include:</p>
		<div class="info left">
			<h1>Level 2</h1>
			<p>DIPLOMA OF MARKETING </p>
			<p>ADVANCED DIPLOMA OF MARKETING</p>
		</div>
		<div class="info right">
			<h1>Level 3</h1>
			<p>DIPLOMA IN MARKETING</p>
			<p>DIPLOMA IN MARKETING COMMUNICATION</p>
			<p>DIPLOMA IN SALES AND MARKETING</p>
			<p>DIPLOMA IN DIGITAL MARKETING</p>
			<p>ADVANCED DIPLOMA IN MARKETING</p>
			<p>ADVANCED DIPLOMA IN SALES AND MARKETING </p>
			<p>ADVANCED DIPLOMA IN MARKETING COMMUNICATIONS</p>
			<p>ADVANCED DIPLOMA IN DIGITAL MARKETING</p>
		</div>
	</div>
</section>