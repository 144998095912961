import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-vacancies',
  templateUrl: './admin-vacancies.component.html',
  styleUrls: ['./admin-vacancies.component.css']
})
export class AdminVacanciesComponent implements OnInit {


  constructor(private apiService: ApiService) { }

  vacancies:string;
  
  searchText:string;

  ngOnInit(): void { 
	window.scrollTo(0, 0);
	this.apiService.getVacancies().subscribe(response=>{		
		this.vacancies = response.data;					
	}); 

  }

  deleteVacancy(pk):void{        
    this.apiService.deleteVacancy(pk).subscribe(response=>{       
      console.log(response);      
    });
  }

}
