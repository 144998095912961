
<section class="filter_wrapper">
	<div class="filter_content">
		<!-- <div class="title" id="type">
			<div (click)="dropDown('block')" class="header">	
				<p>Type:</p>
				<img src="../../assets/icons/chevronDown_black.svg">
			</div>
			<div class="types title" [style.display]="dropDown_display">
				<p (click)="switch('appointments')" id="typeAppointment">Appointments</p>
				<p (click)="switch('quotes')" id="typeQuote">Quotes</p>
				<p (click)="switch('contact')" id="typeContact">Contact</p>
			</div>
		</div> -->
		<a class="title" id="viewCodes" routerLink="./unused-codes">
			View Codes
		</a>

		<!-- <div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div> -->
	</div>
</section>