import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { FormBuilder, FormGroup } from  '@angular/forms';
import {Globals} from '../globals';

@Component({
  selector: 'app-diplomas-courses',
  templateUrl: './diplomas-courses.component.html',
  styleUrls: ['./diplomas-courses.component.css', './filter.component.css']
})
export class DiplomasCoursesComponent implements OnInit {

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, public globals: Globals) {}

  a:any = 100/9;
  b:any = 100-this.a;
  courses_display:string = "none";
  diplomas_display:string = "flex";
  courses_bg:string = "#f1f1f1";
  diplomas_bg:string = "#C5C5C5";


  registrationFees:any = {"2": "80", "3": "90", "4": "100"};

  courses:any;
  coursesRounds:any;
  coursesAssoc:any;

    diplomas:any;
    diplomasRounds:any;
    diplomasAssoc:any;
  // disclaimer_display:string ;




  turn2dArrayToAssoc2dArray(array, key) {  
    var temp:any = [];  
    for (var i in array) {          
      if(temp[array[i][key]]){
        temp[array[i][key]].push(array[i]);
      }else{
        temp[array[i][key]] = [];
        temp[array[i][key]].push(array[i]);
      }
    }
    return temp;
  }


// @ViewChild('paypalRef', {static: true}) private paypalRef: ElementRef;
// handler:any = null;


  ngOnInit(): void {     
  	window.scrollTo(0, 0);
    this.switch("diplomas");
    // this.apiService.setLogged(true, "10", "student");
    // this.disclaimer_display = "none";
    this.globals.lcciPlanWrapper_display = "none";    
    
    this.apiService.getCourses().subscribe(response=>{        
      this.courses = response.data;
      this.coursesAssoc = this.turn2dArrayToAssoc2dArray(this.courses, 'courseName');          
    });

    this.apiService.getCoursesRounds().subscribe(response=>{  
      this.coursesRounds = response;      
    });

    this.apiService.getDiplomas().subscribe(response=>{        
      this.diplomas = response.data;      
      this.diplomasAssoc = this.turn2dArrayToAssoc2dArray(this.diplomas, 'diplomaName');                    
    });

    this.apiService.getDiplomasRounds().subscribe(response=>{  
      this.diplomasRounds = response;
      console.log(this.diplomasRounds);
      
    });

  }
  

  gateWay(display):void {   
    this.globals.gatewayWrapper_display = display;
    this.globals.lcciPlanWrapper_display = "none";
  }

    lcciPlan(display, courseName, round_pk = '0'):void {
      if(display == "flex") {

        this.globals.productChosen_name = this.coursesAssoc[courseName][0].courseName;
        this.globals.productChosen_learningHours = this.coursesAssoc[courseName][0].learningHours;
        this.globals.productChosen_level = this.coursesAssoc[courseName][0].level;          
        this.globals.productChosen_suite = this.coursesAssoc[courseName][0].suite;          
        this.globals.productChosen_price = this.coursesAssoc[courseName][0].price;
        this.globals.productChosen_registrationFees = this.registrationFees[this.globals.productChosen_level];
        if(this.apiService.loggedIn_user()["pk"] != ""){          
          this.globals.signWrapperLcci_display = "none";
          this.globals.lcciPlanWrapper_display = display;
          this.globals.productChosen_course_pk = this.coursesAssoc[courseName][0].course_pk;
          this.globals.productChosen_round_pk = round_pk;
        }else{
          this.globals.signWrapperLcci_display = "flex";
        }
      }else{
        this.globals.lcciPlanWrapper_display = display;
        this.globals.signWrapperLcci_display = display;
      }
    }


  showHideSignWrapper(display){
    this.globals.signInCard_display = "block";
    this.globals.signWrapper_display = display;
    this.gateWay('none');
    this.lcciPlan('none', '');
  }

   switch(filter):void{
    if(filter == "courses") {
    this.courses_display = "flex";
    this.diplomas_display = "none";
    this.courses_bg = "#C5C5C5";
    this.diplomas_bg = "#f1f1f1";
    }else if(filter == "diplomas") {
    this.courses_display = "none";
    this.diplomas_display = "flex";
    this.courses_bg = "#f1f1f1";
    this.diplomas_bg = "#C5C5C5";
    }
  }




}
