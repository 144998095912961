import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {

  constructor(private apiService: ApiService, public globals: Globals) {}


  	student:any;
  	student_pk:string = this.apiService.loggedIn_user()["pk"];

	courses:any;
	// courses_placementTestGrade:string;

	classes:any;

	assignments:any;	

	exams:any;	

	grades:any;

	messages:any;		

	registeredCoursesPlaceHolder_display:string = "none";
	messagesPlaceHolder_display:string = "block";
	classesPlaceHolder_display:string = "none";
	assignmentsPlaceHolder_display:string = "none";
	examsPlaceHolder_display:string = "none";
	gradesPlaceHolder_display:string = "none";
	classesHeaders_display:string = "none";
	assignmentsHeaders_display:string = "none";
	examsHeaders_display:string = "none";
	gradesHeaders_display:string = "none";

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);  
  	// this.apiService.setLogged(true, "46", "student");
  	// this.student_pk = this.apiService.loggedIn_user()["pk"];

  	this.apiService.getStudent_byStudentId(this.student_pk).subscribe(response=>{	
		this.student = response.data[0];
	});

	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		if(response.message) {
			this.registeredCoursesPlaceHolder_display = "block";
		}else{
			this.registeredCoursesPlaceHolder_display = "none";
			this.courses = response.data;
			
		}
	});

  	this.apiService.getClasses_byStudentId(this.student_pk).subscribe(response=>{
		if(response.message) {
			this.classesPlaceHolder_display = "block";
			this.classesHeaders_display = "none";
		}else{
			this.classesPlaceHolder_display = "none";
			this.classesHeaders_display = "flex";
			this.classes = response;
			
		}
	});

	this.apiService.getAssignments_byStudentId(this.student_pk).subscribe(response=>{	
		if(response.message) {			
			this.assignmentsPlaceHolder_display = "block";
			this.assignmentsHeaders_display = "none";
		}else{			
			this.assignmentsPlaceHolder_display = "none";
			this.assignmentsHeaders_display = "flex";
			this.assignments = response.data;
			console.log(this.assignments);
			
			
		}
	});

	this.apiService.getExams_byStudentId(this.student_pk).subscribe(response=>{		
		if(response.message) {
			this.examsPlaceHolder_display = "block";
			this.examsHeaders_display = "none";
		}else{
			this.examsPlaceHolder_display = "none";
			this.examsHeaders_display = "flex";
			this.exams = response;
			
		}
		
	});

	this.apiService.getGrades_byStudentId(this.student_pk).subscribe(response=>{		
		if(response.message) {
			this.gradesPlaceHolder_display = "block";
			this.gradesHeaders_display = "none";
		}else{
			this.gradesPlaceHolder_display = "none";
			this.gradesHeaders_display = "flex";
			this.grades = response;
			
		}
		
	});

	this.apiService.getMessages_byStudentId(this.student_pk).subscribe(response=>{		
		if(response.message) {
			this.messagesPlaceHolder_display = "block";
		}else{
			this.messagesPlaceHolder_display = "none";
			this.messages = response.data;
			
		}
		
	});

	
	
  }

deleteMessage(pk):void{      	
  	this.apiService.deleteMessage_byStudentId(this.student_pk, pk).subscribe(response=>{	  		  	
  		console.log(response);  		
	});
  }

}
