
<!-- <div class="disclaimer">
	<p>Apologies for the inconvenience but not all pages are available yet as this website is currently under construction.</p>
</div>
 -->

 <div class="search_wrapper" [style.height]="searchWrapper_height" [style.padding]="searchWrapper_padding"> 
 	<div class="dummy_wrapper" (click)="showResults('none')"></div>
 	<div class="search_content">
 		<div class="searchBar" (click)="showResults('block')">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<img src="../../assets/icons/search_white.svg">
		</div>
		<div class="searchResult_container" [style.display]="searchResultContainer_display">
			<p *ngFor="let product of products | filter:searchText | slice:0:5" routerLink="school-of-business/lcci/diplomas&courses/course-details/{{product.courseName | removeHtmlTags}}/{{product.round_pk}}" (click)="showSearch()">{{product.courseName | removeHtmlTags}}</p>
		</div>
		<div class="pages">
			<div class="columns">
				<a (click)="showSearch()" routerLink="school-of-business/cadres-english">English Courses</a>
				<a (click)="showSearch()" routerLink="school-of-business/lcci">LCCI</a>
				<a (click)="showSearch()" routerLink="/underMaintenance">MBAs</a>
			</div>
			<div class="columns">
				<a (click)="showSearch()" routerLink="business-solutions/business-consultation">Business Consultation</a>
				<a (click)="showSearch()" routerLink="business-solutions/corprate-training">Corprate Training</a>
				<a (click)="showSearch()" routerLink="business-solutions/marketing-and-advertisement">Marketing & Advertisement</a>
				<a (click)="showSearch()" routerLink="business-solutions/event-management">Event Management</a>
				<a (click)="showSearch()" routerLink="business-solutions/recruitment">Recruiting</a>
			</div>
			<div class="columns" id="column3">
				<a id="careers" (click)="showSearch()" routerLink='/careers'>Careers</a>
				<a id="news-and-events" (click)="showSearch()" routerLink='/news-and-events'>News</a>
				<a id="about" (click)="showSearch()" routerLink='/about'>About</a>
				<a id="contact" (click)="showSearch()" routerLink='/contact'>Contact</a>
			</div>
		</div>
 	</div>
 </div>

 <nav class="navBar_wrapper">
	<div class="navBar_content">
		<div (click)="showMenu()" class="hambIcon">
			<img src="../../assets/icons/hamb.svg">
		</div>
		<div [style.fill] = "navBarColor" class="logoIcon" routerLink='/home'>
			<img src="../../assets/icons/logo_{{navBarColor}}.svg">
		</div>
		<div class="pages">
			<a id="index" routerLink='/home' routerLinkActive="active" onclick="setTitle('Home', true)">Home</a>
		</div>


		<div class="pages">
			<a class="dropbtn" id="school-of-business" routerLink='/school-of-business' routerLinkActive="active" onclick="setTitle('Cadres School of Business', true)">Cadres School of Business
					<img src="../../assets/icons/chevronDown_black.svg">
			</a>
			  <ul class="dropdown">
			  	  <!-- <div id="notch"></div> -->
				  <li routerLink="school-of-business/cadres-english">English Courses</li>
				  <li routerLink="school-of-business/lcci">LCCI</li>
				  <li routerLink="/underMaintenance">MBAs</li>
			  </ul>
		</div>


		<div class="pages">
			<a id="business-solutions" routerLink='/business-solutions' routerLinkActive="active" onclick="setTitle('Business Solutions', true)">
				Business Solutions
				<img src="../../assets/icons/chevronDown_black.svg">
			</a>
		    <ul class="dropdown">
		    	<!-- <div id="notch"></div> -->
				<li routerLink="business-solutions/business-consultation">Business Consultation</li>
				<li routerLink="business-solutions/corprate-training">Corprate Training</li>
				<li routerLink="business-solutions/marketing-and-advertisement">Marketing & Advertisement</li>
				<li routerLink="business-solutions/event-management">Event Management</li>
				<li routerLink="business-solutions/recruitment">Recruiting</li>
			</ul>
		</div>
		<div class="pages">
			<a id="careers" routerLink='/careers' routerLinkActive="active" onclick="setTitle('Careers', true)">Careers</a>
		</div>
		<div class="pages">
			<a id="news-and-events" routerLink='/news-and-events' routerLinkActive="active" onclick="setTitle('News', true)">News</a>
		</div>
		<div class="pages">
			<a id="about" routerLink='/about' routerLinkActive="active" onclick="setTitle('About', true)">About</a>
		</div>
		<div class="pages">
			<a id="contact" routerLink='/contact' routerLinkActive="active" onclick="setTitle('Contact', true)">Contact</a>
		</div>
		<p [style.display]="signInButtons_display" (click)="showHideSignWrapper('flex', 'signUp')" id="joinAs">Sign Up</p>
		<p [style.display]="signInButtons_display" (click)="showHideSignWrapper('flex', 'signIn')">Sign In</p>
		<div [style.display] = "userIcon_display" class="userIcon" routerLink='/{{dashboardUrl}}-dashboard'>
			<img src="../../assets/icons/user_pp.svg">
		</div>
		<div class="searchIcon" (click)="showSearch()">
			<img src="../../assets/icons/search_black.svg">
		</div>
	</div>
</nav>


<div class="menu_mobile_wrapper" id="menu_mobile_wrapper" [style.display]="menuWrapper_display">
	<div class="menu_mobile_content">
		<div [style.display] = "userIcon_display" class="links"  routerLink='/{{dashboardUrl}}-dashboard'>
			<img id="userIcon" src="../../assets/icons/user_pp.svg">
			<a href="">My Dashboard</a>
		</div>
		<div class="links">
			<a id="index" style="margin-left: 0%;" routerLink='/home' routerLinkActive="active" onclick="setTitle('Home', true)">Home</a>
		</div>
		<div class="links">
			<a class="dropbtn" id="school-of-business" routerLink='/school-of-business' routerLinkActive="active" onclick="setTitle('Cadres School of Business', true)">Cadres School of Business
			</a>
			<img src="../../assets/icons/chevronDown_orange.svg" (click)="showSublinks('school-of-business')" >
		</div>
		<ul class="subLinks" [style.display]="schoolOfBusinessSubLinks_display">
			<li routerLink="school-of-business/cadres-english" (click)="showMenu()">English Courses</li>
			<li routerLink="school-of-business/lcci" (click)="showMenu()">LCCI</li>
			<li routerLink="/underMaintenance" (click)="showMenu()">MBAs</li>
		</ul>
		<div class="links">
			<a id="business-solutions" routerLink='/business-solutions' routerLinkActive="active" onclick="setTitle('Business Solutions', true)">Business Solutions</a>
			<img src="../../assets/icons/chevronDown_orange.svg" (click)="showSublinks('business-solutions')" >
		</div>
	    <ul class="subLinks" [style.display]="businessSolutionsSubLinks_display">
			<li routerLink="business-solutions/business-consultation" (click)="showMenu()">Business Consultation</li>
			<li routerLink="business-solutions/corprate-training" (click)="showMenu()">Corprate Training</li>
			<li routerLink="business-solutions/marketing-and-advertisement" (click)="showMenu()">Marketing & Advertisement</li>
			<li routerLink="business-solutions/event-management" (click)="showMenu()">Event Management</li>
			<li routerLink="business-solutions/recruitment" (click)="showMenu()">Recruiting</li>
		</ul>
		<div class="links">
			<a id="careers" routerLink='/careers' routerLinkActive="active" onclick="setTitle('Careers', true)">Careers</a>
		</div>
		<div class="links">
			<a id="news-and-events" routerLink='/news-and-events' routerLinkActive="active" onclick="setTitle('News', true)">News</a>
		</div>
		<div class="links">
			<a id="about" routerLink='/about' routerLinkActive="active" onclick="setTitle('About', true)">About</a>
		</div>
		<div class="links">
			<a id="contact" routerLink='/contact' routerLinkActive="active" onclick="setTitle('Contact', true)">Contact</a>
		</div>
		<button id="btn1" (click)="showHideSignWrapper('flex', 'signUp')" [style.display]="signInButtons_display"><p>Join as Student</p></button>
		<div class="links" id="link9" [style.display]="signInButtons_display">
			<a id="signIn" (click)="showHideSignWrapper('flex', 'signIn')">Sign In</a>
		</div>
	</div>
</div>
