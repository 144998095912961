import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';

@Component({
  selector: 'admin-add-exam',
  templateUrl: './admin-add-exam.component.html',
  styleUrls: ['./admin-add-exam.component.css']
})
export class AdminAddExamComponent implements OnInit {

	  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router, public globals: Globals, private formBuilder: FormBuilder) { 
	}

	form: FormGroup;
	uploadResponse:any;

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);
  }

  getAddExam=new FormGroup({ 
  examName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  date:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  time:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
  location:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),

});

  onSubmit() {
    this.apiService.addExam(this.globals.addRoundFk_value, this.getAddExam.value).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }


   hideAddExamPopUp(){
  		this.globals.addExamWrapper_display = "none";
	}

}
