

<lcci-sign-in></lcci-sign-in>

<payment-gateway></payment-gateway>

<product-plan></product-plan>

<section class="sect1_wrapper">
	<div class="sect1_content">
		<p id="title" class="mobile">{{courses.courseName}}</p>
		<div class="img">
			<img src="../../assets/imgs/imgPlaceHolder3.svg" alt="">
		</div>
		<div class="info">
			<p id="title" class="desktop">{{courses.courseName}}</p>
			<p id="description">
				{{courses.description}}
			</p>
			<p id="price">£{{courses.price}}</p>
		</div>
	</div>
</section>

<section class="extras_wrapper">
	<div class="extras_content">
		<div class="startDate">
			<p class="title">Starts</p>
			<p id="date"> {{round.startDate | date: 'LLL'}} {{round.startDate | date: 'd'}}</p>
		</div>
		<div class="capacity">
            <svg viewBox="0 0 36 36" class="circular-chart">

                <path class="that-circle" stroke="#707070" stroke-dasharray="100 50" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />

                <path class="that-circle" stroke="#3FA500" attr.stroke-dasharray="{{round.numberOfEnrollments*10}}, {{100-(round.numberOfEnrollments*10)}}" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />
            </svg>
            <div class="percentage">
	            <p id="percentage">
		            	{{(round.numberOfEnrollments*100)/(round.maxCapacity) | number: '1.0-0'}}%
		            </p>
                <p>booked</p>
            </div>
        </div>
        <div class="level">
        	<p class="title">Level</p>
        	<div class="squares" *ngIf="courses.level == '1'">
        		<div class="filled"></div>
        		<div></div>
        		<div></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '2'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '3'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '4'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        	</div>
        </div>
        <div class="suite">
        	<p class="title">Suite</p>
        	<div class="squares">
        		{{courses.suite}}
        	</div>
        </div>
        <div class="learningHours">
        	<p class="title">Learning Hours</p>
        	<div class="squares">
        		{{courses.learningHours}}
        	</div>
        </div>
        <div class="enroll">
        	<button (click)="lcciPlan('flex', round.courseName, round.round_pk)">
				<p>Enroll</p>
			</button>
        </div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="left">
			<div class="whoShouldAttend">
				<p id="title">Who Should Attend?</p>
				<p>
					{{courses.requirements}}
				</p>
			</div>
			<div class="relatedInstructors" *ngIf="relatedInstructors['message'] != ''">
				<p id="title">Instructors</p>
				<div class="instructor" *ngFor="let instructor of relatedInstructors">
					<div class="img">
						<img *ngIf="instructor.profilePhoto_path != null" [src]="instructor.profilePhoto_path">
						<img *ngIf="instructor.profilePhoto_path == null" src="../../assets/icons/user_pp.svg">
					</div>
					<div class="info">
						<p id="name">{{instructor.firstName | titlecase}} {{instructor.lastName | titlecase}}</p>
						<p>{{instructor.speciality | titlecase}}</p>
						<p>{{instructor.numberOfCoursesTaught}} Course<span *ngIf="instructor.numberOfCoursesTaught > 1">s</span></p>
					</div>
				</div>
			</div>
		</div>
		<div class="right">
			<table class="spec_table">
				<tr class="rows" id="row1">
					<th>
						<p>Specification</p>
						<div class="img">
							<img src="../../assets/icons/chevronDown_blue.svg" alt="">
						</div>
					</th>
				</tr>
				<tr class="rows"*ngFor = "let material of materials['Specification']; index as i" id="row{{i+2}}">
					<td>
						<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
						<p>
							{{material.materialName}}
						</p>
							<a class="download_icon" href="{{material.link}}">
								<img src="../../assets/icons/download.svg" alt="">
							</a>
					</td>
				</tr>
				</table>
				<table class="sample_table">
					<tr class="rows" id="row1">
					<th>
						<p>Sample Assesment Material</p>
						<div class="img">
							<img src="../../assets/icons/chevronDown_blue.svg" alt="">
						</div>
					</th>
				</tr>
				<tr class="rows"*ngFor = "let material of materials['Sample Assessment Material']; index as i" id="row{{i+2}}">
					<td>
						<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
						<p>
							{{material.materialName}}
						</p>
							<a class="download_icon" href="{{material.link}}">
								<img src="../../assets/icons/download.svg" alt="">
							</a>
					</td>
				</tr>
			</table>
			<table class="exam_table">
				<tr class="rows" id="row1">
					<th>
						<p>Exam Materials</p>
						<div class="img">
							<img src="../../assets/icons/chevronDown_blue.svg" alt="">
						</div>
					</th>
				</tr>
				<tr class="rows"*ngFor = "let material of materials['Exam Materials']; index as i" id="row{{i+2}}">
					<td>
						<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
						<p>
							{{material.materialName}}
						</p>
							<a class="download_icon" href="{{material.link}}">
								<img src="../../assets/icons/download.svg" alt="">
							</a>
					</td>
				</tr>
			</table>
			<table class="material_table">
				<tr class="rows" id="row1">
					<th>
						<p>Teaching & learning materials</p>
						<div class="img">
							<img src="../../assets/icons/chevronDown_blue.svg" alt="">
						</div>
					</th>
				</tr>
				<tr class="rows"*ngFor = "let material of materials['Student Support']; index as i" id="row{{i+2}}">
					<td>
						<div class="pdf_icon"><img src="../../assets/icons/pdf.svg" alt=""></div>
						<p>
							{{material.materialName}}
						</p>
							<a class="download_icon" href="{{material.link}}">
								<img src="../../assets/icons/download.svg" alt="">
							</a>
					</td>
				</tr>
			</table>
			<div class="requirements">
				<p id="title">Requirements</p>
				<ul>
					<li>
						Requirement 1 Do excepteur ad anim duis sit velit veniam sit ut in ut proident duis incididunt ut dolore occaecat occaecat.
					</li>
					<li>
						Requirement 2 Do excepteur ad anim duis sit velit veniam sit ut in ut proident duis incididunt ut dolore occaecat occaecat.
					</li>
				</ul>
			</div>
		</div>
	</div>
</section>



<section class="sect3_wrapper" *ngIf="relatedTestimonials['message'] != ''">
	<p id="title" class="mobile">Testimonials</p>
	<div class="sect3_content">
		<p id="title" class="desktop">Testimonials</p>
		<div class="img arrows" id="back_button" (click)="changeCarousel('prev')">
			<img src="../../assets/icons/chevronLeft_black.svg">
		</div>
		<div class="testimonial" *ngFor="let testimonial of relatedTestimonials | slice:carouselEndIndex-2:carouselEndIndex">
			<div class="img">
				<img *ngIf="testimonial.imgPath != null" [src]="testimonial.imgPath">
				<img *ngIf="testimonial.imgPath == null" src="../../assets/icons/user_pp.svg">
			</div>
			<div class="info">
				<p id="name">{{testimonial.name}}</p>
				<p>{{testimonial.text}}</p>
			</div>
		</div>
		<div class="img arrows" id="back_button" (click)="changeCarousel('next')">
			<img src="../../assets/icons/chevronRight_black.svg">
		</div>
		<div class="dots">
			<div class="dot" id="dot1" [style.backgroundColor]="carouselDot1_backgroundColor"></div>
			<div class="dot" id="dot2" [style.backgroundColor]="carouselDot2_backgroundColor"></div>
		</div>
	</div>
</section>





<section class="extras_wrapper">
	<div class="extras_content">
		<div class="startDate">
			<p class="title">Starts</p>
			<p> {{round.startDate | date: 'LLL'}} {{round.startDate | date: 'd'}}</p>
		</div>
		<div class="capacity">
            <svg viewBox="0 0 36 36" class="circular-chart">

                <path class="that-circle" stroke="#707070" stroke-dasharray="100 50" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />

                <path class="that-circle" stroke="#3FA500" attr.stroke-dasharray="{{round.numberOfEnrollments*10}}, {{100-(round.numberOfEnrollments*10)}}" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />
            </svg>
            <div class="percentage">
	            <p id="percentage">{{round.numberOfEnrollments*10}}%</p>
                <p>booked</p>
            </div>
        </div>
        <div class="level">
        	<p class="title">Level</p>
        	<div class="squares" *ngIf="courses.level == '1'">
        		<div class="filled"></div>
        		<div></div>
        		<div></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '2'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '3'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div></div>
        	</div>
        	<div class="squares" *ngIf="courses.level == '4'">
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        		<div class="filled"></div>
        	</div>
        </div>
        <div class="suite">
        	<p class="title">Suite</p>
        	<div class="squares">
        		{{courses.suite}}
        	</div>
        </div>
        <div class="learningHours">
        	<p class="title">Learning Hours</p>
        	<div class="squares">
        		{{courses.learningHours}}
        	</div>
        </div>
        <div class="enroll">
        	<button (click)="lcciPlan('flex', round.courseName, round.round_pk)">
				<p>Enroll</p>
			</button>
        </div>
	</div>
</section>

<section class="sect4_wrapper">
	<p id="title" class="mobile">Related Courses</p>
	<div class="sect4_content">
		<p id="title" class="desktop">Related Courses</p>
		<div class="course" *ngFor="let course of relatedCourses">
			<div class="top">
				<img src="../../assets/imgs/imgPlaceHolder2.svg">
				<div id="opacity"></div>
					<div class="capacity">
			            <svg viewBox="0 0 36 36" class="circular-chart">

			                <path class="that-circle" stroke="#707070" stroke-dasharray="100 50" d="M18 2.0845
			                                a 15.9155 15.9155 0 0 1 0 31.831
			                                a 15.9155 15.9155 0 0 1 0 -31.831" />

			                <path class="that-circle" stroke="#3FA500" attr.stroke-dasharray="{{course.numberOfEnrollments*10}}, {{100-(course.numberOfEnrollments*10)}}" d="M18 2.0845
			                                a 15.9155 15.9155 0 0 1 0 31.831
			                                a 15.9155 15.9155 0 0 1 0 -31.831" />
			            </svg>
			            <div class="percentage">
				            <p id="percentage">{{course.numberOfEnrollments*10}}%</p>
			                <p>booked</p>
			            </div>
			        </div>
			</div>
			<div class="bottom">
				<p id="name">{{course.courseName | slice:0:34}} <span *ngIf="course.courseName.length > 34">...</span></p>
				<p id="price">${{course.price}}</p>
				<p id="startDate">{{course.startDate | date: 'LLL'}} {{course.startDate | date: 'd'}}</p>
				<button (click)="navigateTo(course.courseName, course.round_pk)">View Course</button>
			</div>
		</div>
	</div>
</section>
