
<div class="editExam_wrapper">
	<img (click)="hideEditExamPopUp()" id="cross" src="../../assets/icons/cross_orange.svg">
	<div class="editExam_content">
		<form [formGroup]="getEditExam" (ngSubmit)="onSubmit()">
			<p id="title">Edit Exam</p>
			<div class="inputs" id="input1">
				<legend>Exam Name</legend>
				<input rows="1"  type="text" formControlName="examName"  [(ngModel)]="globals.editExamName_value">
			</div>
			<div class="inputs" id="input6">
				<legend>Date:</legend>
				<input type="date" formControlName="date" [(ngModel)]="globals.editExamDate_value">
				<!-- <div *ngIf="ge.controls.date.errors && ge.controls.date.touched" class="alert "
					role="alert">
				Date is Required
			</div> -->
			</div>
			<div class="inputs" id="input7">
				<legend>Time:</legend>
				<input type="time" step="60" formControlName="time" [(ngModel)]="globals.editExamTime_value">
				<!-- <div *ngIf="getAppointment.controls.time.errors && getAppointment.controls.time.touched" class="alert "
					role="alert">
				Time is Required
			</div> -->
			</div>
			<div class="inputs" id="input1">
				<legend>Exam Location</legend>
				<input rows="1"  type="text" formControlName="location"  [(ngModel)]="globals.editExamLocation_value">
			</div>
			<div class="inputs" id="input8">
				<input type="submit">
			</div>
		</form>
	</div>
</div>




