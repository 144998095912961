import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-student',
  templateUrl: './admin-student.component.html',
  styleUrls: ['./admin-student.component.css']
})
export class AdminStudentComponent implements OnInit {

	student_pk:any;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
				this.student_pk = params.id;			
			});
	}
  
  student:any;


  courses:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    console.log(this.student_pk);
    
    this.apiService.getStudent_byStudentId(this.student_pk).subscribe(response=>{	
		this.student = response.data[0];
		console.log(response);
	});

    this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
    	console.log(response);
    	
		this.courses = response.data;
		console.log(this.courses);
		
	});
      	
      	
  }

}
