import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Globals} from '../globals'; //dashboard

@Component({
  selector: 'app-student-assignments',
  templateUrl: './student-assignments.component.html',
  styleUrls: ['./student-assignments.component.css']
})
export class StudentAssignmentsComponent implements OnInit {

    constructor(private apiService: ApiService, public globals: Globals) { }

  	student_pk:string = this.apiService.loggedIn_user()["pk"];
  courses:any;

	assignments:any;	

	turn2dArrayToAssoc2dArray(array, key) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i][key]]){
				temp[array[i][key]].push(array[i]);
			}else{
				temp[array[i][key]] = [];
				temp[array[i][key]].push(array[i]);
			}
		}
		return temp;
	}

  ngOnInit(): void {
  	 window.scrollTo(0, 0);

	this.apiService.getCourses_byStudentId(this.student_pk).subscribe(response=>{	
		this.courses = response.data;
	});

	 this.apiService.getAssignments_byStudentId(this.student_pk).subscribe(response=>{		
		this.assignments = response.data;
		this.assignments = this.turn2dArrayToAssoc2dArray(this.assignments, 'courseName');
		console.log(this.assignments);
		
	});

  }

}
