

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="filters_container">
			<div class="addInstructor">
				<img src="../../assets/icons/add_white.svg">
				<p>Add Instructor</p>
			</div>
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="instructors_wrapper">
	<div class="instructors_content">
		<div class="instructor"  *ngFor="let instructor of instructors | filter:searchText" routerLink="/admin-dashboard/instructor/{{instructor.instructor_pk}}">
			<p id="instructorName">{{instructor.firstName}} {{instructor.middleName}} {{instructor.lastName}}</p>
			<p id="delete" (click)="deleteInstructor(instructor.instructor_pk)">Remove</p>
		</div>
	</div>
</section>