import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'instructor-edit-profile',
  templateUrl: './instructor-edit-profile.component.html',
  styleUrls: ['./instructor-edit-profile.component.css']
})
export class InstructorEditProfileComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  instructor_pk:any = this.apiService.loggedIn_user()["pk"];
  instructor:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    // this.apiService.setLogged(true, "3", "instructor");
    console.log(this.instructor_pk);
    
    this.apiService.getInstructor_byInstructorId(this.instructor_pk).subscribe(response=>{	
		this.instructor = response.data[0];
		console.log(response);
	});
      	
      	
  }


  getUser=new FormGroup({
	  firstName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  lastName:new FormControl('',[Validators.required ,Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  email:new FormControl('',[Validators.required ,Validators.email]),
	  mobileNumber:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  address:new FormControl('',[Validators.required, Validators.pattern(/[a-zA-Z][^#&<>\"~;$^%{}?]/)]),
	  postalCode:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)]),
     password:new FormControl('',[Validators.required ,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]),
	   dob:new FormControl('',[Validators.required ,Validators.pattern(/[\sS]*/)])

	});

   editUser(){	 	
    this.apiService.editInstructor(this.getUser.value, this.instructor_pk).subscribe(response=>{    	
    	console.log(response);
    	
    });
  }

}
