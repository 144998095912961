import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-admin-edit-vacancy',
  templateUrl: './admin-edit-vacancy.component.html',
  styleUrls: ['./admin-edit-vacancy.component.css']
})
export class AdminEditVacancyComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
 	private router: Router) { 
    this.route.params.subscribe(params =>{
        this.vacancy_pk = params.id;      
      });
  }

  vacancy_pk:number;
  vacancy:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
    this.apiService.getVacancy_byVacancyId(this.vacancy_pk).subscribe(response=>{
      this.vacancy = response.data[0];      
     console.log(this.vacancy);
		this.getEditVacancy.value.title = this.vacancy.title;
		console.log(this.getEditVacancy.value.title);
		
		this.getEditVacancy.value.description = this.vacancy.description;
		this.getEditVacancy.value.summary = this.vacancy.summary;
		this.getEditVacancy.value.specification = this.vacancy.specification;
		this.getEditVacancy.value.interpersonalSkills = this.vacancy.interpersonalSkills;
		this.getEditVacancy.value.benefits = this.vacancy.benefits;
    });
   }

    getEditVacancy=new FormGroup({
	'title': new FormControl(null),
	'description': new FormControl(null),
	'summary': new FormControl(null),
	'specification': new FormControl(null),
	'interpersonalSkills': new FormControl(null),
	'benefits': new FormControl(null),

	});

  editVacancy(){
  	console.log(this.getEditVacancy.value);
  	
    this.apiService.editVacancy(this.getEditVacancy.value, this.vacancy_pk).subscribe(response=>{
    	console.log(response);
    });
  }

}
