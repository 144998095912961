import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {Globals} from '../globals';


@Component({
  selector: 'app-instructor-class',
  templateUrl: './instructor-class.component.html',
  styleUrls: ['./instructor-class.component.css']
})
export class InstructorClassComponent implements OnInit {

	courseName:string;

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router,  public globals: Globals) { 
		this.route.params.subscribe(params =>{
				this.courseName = params.courseName;			
			});
	}

	instructor_pk:string = this.apiService.loggedIn_user()["pk"];

courses:any;
class:any;

	classes:any;
	class_fk:string;
	editClass_display:string = "none";

	turn2dArrayToAssoc2dArray(array) {	
		var temp:any = [];	
		for (var i in array) {					
			if(temp[array[i].courseName]){
				temp[array[i].courseName].push(array[i]);
			}else{
				temp[array[i].courseName] = [];
				temp[array[i].courseName].push(array[i]);
			}
		}
		return temp;
	}


		
  ngOnInit(): void {
  	window.scrollTo(0, 0);

	this.apiService.getCourses_byInstructortId(this.instructor_pk).subscribe(response=>{	
		this.courses = response.data;
		this.courses = this.turn2dArrayToAssoc2dArray(this.courses)[this.courseName];
	});

  	this.apiService.getClasses_byInstructortId(this.instructor_pk).subscribe(response=>{		
		this.classes = response;		
		this.classes = this.turn2dArrayToAssoc2dArray(this.classes)[this.courseName];	
		// console.log(this.classes);
		// console.log(this.class);
		
		// console.log(this.classes);
				
	});
	
  }


  showPopUp(index, popup){
  	if(popup == "editClass") {
  		if(this.globals.editClassWrapper_display == "none") {
  		this.class = this.classes[index];
  		this.globals.editClassFk_value = this.class.class_pk;
  		// console.log(this.globals.editClassFk_value);
  				
  		// this.globals.editClassWrapper_display = "block";
  		this.globals.editClassName_value = this.class.name;
		this.globals.editClassZoomLink_value = this.class.zoomLink;
		this.globals.editClassDescription_value = this.class.description;
		this.globals.editClassDate_value = this.class.date;
		this.globals.editClassTime_value = this.class.time;
  		this.globals.editClassWrapper_display = "flex";
  		// console.log(this.globals.editClassWrapper_display);
  		

	  	}else if(this.globals.editClassWrapper_display = "block") {
	  		this.globals.editClassWrapper_display = "none";
	  	}
  	}else if(popup == "createClass"){
	  	if(this.globals.createClassWrapper_display == "none") {
	  		this.globals.createClassRoundFk_value = this.courses[0]["round_pk"];
	  		this.globals.createClassRoundType_value = 'diploma'; //check?
	  		this.globals.createClassWrapper_display = "flex";
	  		// console.log(this.globals.createClassWrapper_display);
	  	}else if(this.globals.createClassWrapper_display = "block") {
	  		this.globals.createClassWrapper_display = "none";
	  	}
	}else if(popup == "addMaterial"){
	  	if(this.globals.addMaterialWrapper_display == "none") {
	  		this.globals.addMaterialWrapper_display = "flex";
	  		this.globals.addMaterialCourseFk_value = this.courses[0]["course_pk"];
	  		// console.log(this.globals.addMaterialWrapper_display);
	  	}else if(this.globals.addMaterialWrapper_display = "block") {
	  		this.globals.addMaterialWrapper_display = "none";
	  	}
	}else if(popup == "addQuiz"){
	  	if(this.globals.addQuizWrapper_display == "none") {
	  		this.globals.addQuizWrapper_display = "flex";
	  		this.globals.addQuizCourseFk_value = this.courses[0]["course_pk"];
	  		// console.log(this.globals.addQuizWrapper_display);
	  	}else if(this.globals.addQuizWrapper_display = "block") {
	  		this.globals.addQuizWrapper_display = "none";
	  	}
	}
  	
  }



}
