
<div class="sect1_wrapper" [ngStyle]="globals.dashboardTheme_body">
	<div class="sect1_content">
		<div class="cards_wrapper cards_fullWidth" id="intro" [ngStyle]="globals.dashboardTheme_cards">
			<div class="cards_content">
				<p>My Classes</p>
			</div>
		</div>

		<div class="coursesProgress">
			<div class="courseProgress" id="courseProgress1" [ngStyle]="globals.dashboardTheme_cards">	
				<p id="title">{{course.courseName}}</p>
				<div>
					<div class="progressBar">
						<div id="progress" [style.width] = "course.progress"></div>
					</div>
					<p>{{course.progress}}</p>	
				</div>
			</div>
		</div>

		<div class="headers">
			<p id="header1">Upcoming Class</p>
		</div>

		<div class="coursesSummary" *ngFor="let class of classes;" [ngStyle]="globals.dashboardTheme_cards">
			<div class="summaries" id="summary1">
				<p>{{class.name}}</p>
			</div>
			<div class="summaries" id="summary2">
				<p>{{class.date}}</p>
				<p>{{class.pk}}</p>
			</div>
			<div class="summaries" id="summary3">
				<a *ngIf="assignments[class.pk] !== undefined" href="{{assignments[class.pk][0].path}}">{{assignments[class.pk][0].assignmentName}} <img src="../../assets/icons/download.svg"></a>
				<p *ngIf="assignments[class.pk] == undefined">No Assignment</p>
			</div>
			<div class="summaries" id="summary4">
				<a href="{{class.zoomLink}}">Start</a>
			</div>
		</div>

	</div>
</div>