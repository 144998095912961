import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-instructor-message',
  templateUrl: './instructor-message.component.html',
  styleUrls: ['./instructor-message.component.css']
})
export class InstructorMessageComponent implements OnInit {

 constructor(private apiService: ApiService, private route: ActivatedRoute,
    private router: Router) { 
		this.route.params.subscribe(params =>{
				this.message_pk = params.id;			
			});
	}

	message_pk:string;
  	instructor_pk:string = this.apiService.loggedIn_user()["pk"];


  message:any;

  ngOnInit(): void {
  	 window.scrollTo(0, 0);  	 
	this.apiService.message_byMessageId(this.message_pk).subscribe(response=>{	
		this.message = response.data[0];	
		console.log(this.message);
								
	});
  }

deleteMessage():void{      	
  	this.apiService.deleteMessage_byInstructorId(this.instructor_pk, this.message_pk).subscribe(response=>{	  		  	
  		console.log(response);
	});
	this.router.navigateByUrl('/instructor-dashboard');	
  }
}

