import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-dashboard-banner',
  templateUrl: './admin-dashboard-banner.component.html',
  styleUrls: ['./admin-dashboard-banner.component.css']
})
export class AdminDashboardBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
