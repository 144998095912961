import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-instructors',
  templateUrl: './admin-instructors.component.html',
  styleUrls: ['./admin-instructors.component.css']
})
export class AdminInstructorsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  searchText:string;

  instructors:any;

  ngOnInit(): void {
  	window.scrollTo(0, 0);
  	this.apiService.getInstructors().subscribe(response=>{	
		this.instructors = response.data;
  		console.log(this.instructors);
	});
  }

  deleteInstructor(pk):void{    
  	
  	this.apiService.deleteInstructor(pk).subscribe(response=>{	  	
  		  console.log(response);
        
	});
  }

}
