
<instructor-create-class [style.display]="globals.createClassWrapper_display"></instructor-create-class>
<instructor-edit-class [style.display]="globals.editClassWrapper_display"></instructor-edit-class>

<admin-add-exam [style.display]="globals.addExamWrapper_display"></admin-add-exam>
<admin-edit-exam [style.display]="globals.editExamWrapper_display"></admin-edit-exam>


<section class="editRound_wrapper">

	<div class="editRound_content">
		<form [formGroup]="getRound" (ngSubmit)="editRound()">
			<p id="title">Edit Profile</p>
			<div class="inputs" id="input1">
				<legend>Round Name</legend>
				<input rows="1"  type="text" formControlName="roundName" [(ngModel)]="round.roundName">
			</div>
			<div class="inputs" id="input6">
				<legend>Seats Available</legend>
				<input rows="10" type="text" formControlName="maxCapacity" [(ngModel)]="round.maxCapacity">
			</div>
			<div class="inputs" id="input8">
				<legend>Set Date:</legend>
				<input type="date" formControlName="startDate" [(ngModel)]="round.startDate">
			</div>
			<div class="inputs" id="input2">
				<legend>Course</legend>
				<mat-form-field>
						<mat-label>{{round.courseName}}</mat-label>
						<mat-select formControlName="course_fk">
							<mat-option *ngFor="let course of courses" [value]="course.course_pk">{{course.courseName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
			</div>
			<div class="inputs" id="input3">
				<legend>Instructor</legend>
				<mat-form-field>
					<mat-label>{{round.instructorName}}</mat-label>
						<mat-select formControlName="instructor_fk">
							<mat-option *ngFor="let instructor of instructors" [value]="instructor.instructor_pk" [(ngModel)]="round.instructorName">{{instructor.firstName | removeHtmlTags}} {{instructor.lastName | removeHtmlTags}}</mat-option>
						</mat-select>
					</mat-form-field>
			</div>
			<div class="inputs" id="input9">
				<input type="submit">
			</div>
		</form>
	</div>
</section>

<section class="editRoundItems_wrapper">
	<div class="editRoundItems_content">
		<div class="cards_wrapper cards_halfWidth" id="classes">
			<p id="title">Classes</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration4.svg" alt="">
				</div>
				<p>No Classes In Progress</p> -->
				<div class="headers">
					<p id="header1">Course name:</p>
					<p id="header2">Class:</p>
					<p id="header3">Date:</p>
					<p id="header4">Time:</p>
				</div>
				<div class="data" *ngFor="let class of classes; index as i">
					<p>{{i+1}}. {{class.courseName | truncateText}}</p>
					<p>{{class.name}}</p>
					<p>{{class.date}}</p>
					<p>{{class.time}}</p>
					<a (click)="showPopUp(i, 'editClass')">edit</a>
				</div>
				<div class="add_btn" (click)="showPopUp('', 'createClass')">
					<img src="../../assets/icons/add_white.svg">
					<p>Add Class</p>
				</div>
			</div>
		</div>
		<div class="cards_wrapper cards_halfWidth" id="quizess">
			<p id="title">Exams</p>
			<div class="cards_content">

				<div class="headers">
					<p id="header1">Course:</p>
					<p id="header2">Name:</p>
					<p id="header3">Date:</p>
					<p id="header3">Location:</p>
				</div>
				<div class="data" *ngFor="let exam of exams; index as i">	
					<p>{{i+1}}. {{exam.courseName | truncateText}}</p>
					<p>{{exam.examName}}</p>
					<p>{{exam.date}}</p>
					<p>{{exam.location}}</p>
					<a (click)="showPopUp(i, 'editExam')">edit</a>
				</div>

				<div class="add_btn" (click)="showPopUp('', 'addExam')">
					<img src="../../assets/icons/add_white.svg">
					<p>Add Exam</p>
				</div>
			</div>
		</div>
		<div class="cards_wrapper cards_thirdWidth" id="students">
			<p id="title">My Students</p>
			<div class="cards_content">
				<!-- <div class="illustration">
					<img src="../../assets/illustrations/student-dashboard/illustration2.svg" alt="">
				</div>
				<p>No Courses In Progress</p> -->
				<div class="data" *ngFor="let student of students; index as i">	
					<p id="data1" *ngIf="student.student_pk is null">{{i+1}}. {{student.firstName + " " + student.lastName | titlecase}}</p>
				</div>
			</div>
		</div>
	</div>
</section>