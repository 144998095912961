
<section class="filter_wrapper">
	<div class="filter_content">
		<div class="title" id="type">
			<div (click)="dropDown('block')" class="header">	
				<p>Type:</p>
				<img src="../../assets/icons/chevronDown_black.svg">
			</div>
			<div class="types title" [style.display]="dropDown_display">
				<p (click)="switch('appointments')" id="typeAppointment">Appointments</p>
				<p (click)="switch('quotes')" id="typeQuote">Quotes</p>
				<p (click)="switch('contact')" id="typeContact">Contact</p>
			</div>
		</div>
		<div  class="title" id="totalAppointments">
			<p># of Appointments {{appointments.length}}</p>
		</div>
		<div  class="title" id="totalQuotes">
			<p># of Quotes {{quotes.length}}</p>
		</div>

		<div class="searchBar">
			<img src="../../assets/icons/search_black.svg">
			<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
			<p>Search</p>
		</div>
	</div>
</section>

<section class="requests_wrapper" [style.display]="appointments_display">
	<div class="requests_content">
		<table>
		  <tr>
		    <th>Name</th>
		    <th>Date</th> 
		  </tr>
		  <tr *ngFor="let appointment of appointments | filter:searchText" routerLink="/admin-dashboard/request/appointment/{{appointment.pk}}">
		    <td>{{appointment.fullName}}</td>
		    <td>{{appointment.date}}</td>
		  </tr>
		</table>
	</div>
</section>

<section class="requests_wrapper" [style.display]="quotes_display">
	<div class="requests_content">
		<table >
		  <tr>
		    <th>Name</th>
		    <th>Date</th> 
		  </tr>
		  <tr *ngFor="let quote of quotes | filter:searchText" routerLink="/admin-dashboard/request/quote/{{quote.pk}}">
		    <td>{{quote.fullName}}</td>
		    <td>{{quote.date}}</td>
		  </tr>
		</table>
	</div>
</section>