<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">

<loading-screen *ngIf="canRender"></loading-screen>

<navBar [style.display]="mainWebsiteNav_display"></navBar>
<sign-up-form></sign-up-form>

<student-dashboard-banner [style.display]="studentDashboardNav_display"></student-dashboard-banner>
<student-dashboard-side-bar [style.display]="studentDashboardNav_display"></student-dashboard-side-bar>

<instructor-dashboard-banner [style.display]="instructorDashboardNav_display"></instructor-dashboard-banner>
<instructor-dashboard-side-bar [style.display]="instructorDashboardNav_display"></instructor-dashboard-side-bar>

<admin-dashboard-banner [style.display]="adminDashboardNav_display"></admin-dashboard-banner>
<admin-dashboard-side-bar [style.display]="adminDashboardNav_display"></admin-dashboard-side-bar>
<lcci-sign-in></lcci-sign-in>


<router-outlet [style.display]="routerOutlet_display"></router-outlet>


<siteMap [style.display]="mainWebsiteFooter_display"></siteMap>