
<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>CORPORATE TRAINING </h1>
			<p>
				Cadres offer inclusive business development programs to enhance corporate employees’ skills by providing a needs training assessment to strengthen their knowledge and skills in various fields of marketing, management, accounting, and finance. These corporate and business training provided by Cadres are internationally awarded from Pearson as a global leading educational company. 
			</p>
		</div>
		<div class="illustration"><img id="illustration1" src="../../assets/imgs/corprate-training/sect1.svg"></div>
	</div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sect2_wrapper">
	<div class="bg">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="2560" height="1305.784" viewBox="0 0 2560 1">
		  <defs>
		    <clipPath id="clip-path">
		      <rect id="Rectangle_1002" data-name="Rectangle 1002" width="2560" height="846.766" fill="#58595b"/>
		    </clipPath>
		    <clipPath id="clip-path-2">
		      <rect id="Rectangle_1002-2" data-name="Rectangle 1002" width="2560" height="882.099" fill="#58595b"/>
		    </clipPath>
		  </defs>
		  <g id="Group_2074" data-name="Group 2074" transform="translate(0 -1796.604)">
		    <g id="Group_1973" data-name="Group 1973" transform="translate(0 600.604)">
		      <g id="Group_1969" data-name="Group 1969" transform="translate(0 459.018)">
		        <g id="Group_1968" data-name="Group 1968" clip-path="url(#clip-path)">
		          <path id="Path_6800" data-name="Path 6800" d="M2560,0H0V846.766l244.842-17.531C489.685,810.382,979.37,777.308,1469.054,688c363.648-66.323,727.3-160,1090.945-247.588Z" fill="#58595b"/>
		          <rect id="Rectangle_1001" data-name="Rectangle 1001" width="28.957" height="436.41" transform="translate(2531.043)" fill="#58595b"/>
		        </g>
		      </g>
		      <g id="Group_1972" data-name="Group 1972">
		        <g id="Group_1968-2" data-name="Group 1968" clip-path="url(#clip-path-2)">
		          <path id="Path_6800-2" data-name="Path 6800" d="M0,882.1H2560V0L2315.158,18.262C2070.315,37.9,1580.63,72.357,1090.945,165.393,727.3,234.484,363.648,332.073,0,423.312Z" fill="#58595b"/>
		          <rect id="Rectangle_1001-2" data-name="Rectangle 1001" width="28.957" height="454.62" transform="translate(0 427.479)" fill="#58595b"/>
		        </g>
		      </g>
		    </g>
		  </g>
		</svg>
	</div>	

	<div class="sect2_content">
		<div class="cards" id="card1">
			<div class="img">
				<img src="../../assets/imgs/corprate-training/sect2.1.svg" alt="">
			</div>
			<p id="title">Training Need Assessment</p>
			<p id="info">
				The qualifications offered by Cadres is awarded by Pearson LCCI diplomas to give …. 
			</p>
			<button routerLink="./training-assessment">Explore More</button>
		</div>
		<div class="cards" id="card2">
			<div class="img">
				<img src="../../assets/imgs/corprate-training/sect2.2.svg" alt="">
			</div>
			<p id="title">Marketing</p>
			<p id="info">
				Students to flexibly tailor their modules and skills based on their preferences as the LCCI ….
			</p>
			<button routerLink="./marketing">Explore More</button>
		</div>
		<div class="cards" id="card3">
			<div class="img">
				<img src="../../assets/imgs/corprate-training/sect2.3.svg" alt="">
			</div>
			<p id="title">Business administration</p>
			<p id="info">
				LCCI Business diplomas are made to provide more flexibility for students in their offerings ….
			</p>
			<button routerLink="./business-administration">Explore More</button>
		</div>
		<div class="cards" id="card4">
			<div class="img">
				<img src="../../assets/imgs/corprate-training/sect2.4.svg" alt="">
			</div>
			<p id="title">Accounting & Finance</p>
			<p id="info">
				The International financial and quantitative LCCI qualification cover a wide range of ….
			</p>
			<button routerLink="./accounting-and-financing">Explore More</button>
		</div>
		<div class="cards" id="card5">
			<div class="img">
				<img src="../../assets/imgs/corprate-training/sect2.5.svg" alt="">
			</div>
			<p id="title">Business English</p>
			<p id="info">
				Designed for students who are seeking to develop their English Language skills within ….
			</p>
			<button>Explore More</button>
		</div>
	</div>
</section>