import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-websites-and-applications',
  templateUrl: './websites-and-applications.component.html',
  styleUrls: ['./websites-and-applications.component.css']
})
export class WebsitesAndApplicationsComponent implements OnInit {

	constructor(public globals: Globals) { }


	ngOnInit(): void { 
		window.scrollTo(0, 0);
	}

    show(hideORshow):void {
      if(hideORshow=="hide") {
        this.globals.getQuoteWrapper_display = "none";
      }else if(hideORshow=="show") {
        this.globals.getQuoteWrapper_display = "block";
      }
    }
}