
<div class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Marketing and Advertisement</h1>
			<p>
				Cadres offers a wide variety of professional business solutions that includes both marketing and advertising services
			</p>
		</div>
		<div class="illustration"><img id="illustration1" src="../../assets/imgs/marketing-and-advertisement/sect1.svg"></div>

		<div class="icons">
			<div class="icon" id="icon1">
				<img src="../../assets/icons/market-research.svg" alt="">
				<p>Market Research</p>
			</div>
			<div class="icon" id="icon2">
				<img src="../../assets/icons/strategy-and-planning.svg" alt="">
				<p>Strategy & Planning</p>
			</div>
			<div class="icon" id="icon3">
				<img src="../../assets/icons/media-planning-and-buying.svg" alt="">
				<p>Media planning & buying</p>
			</div>
			<div class="icon" id="icon4">
				<img src="../../assets/icons/seo-and-sem.svg" alt="">
				<p>SEO & SEM</p>
			</div>
			<div class="icon" id="icon5">
				<img src="../../assets/icons/web-design-and-development.svg" alt="">
				<p>Web design and development</p>
			</div>
		</div>
	</div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>


<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Market Research</h1>
			<p>
				Cadres propose Business solutions to prosper the media planning process of strategically selecting various media platforms to perform digital advertising and marketing to achieve business campaign goals. Boosting your business conversions will facilitate media buying for targeted consumers to generate business leads, traffic, and reconcile businesses strategies via RTB and DSP
			</p>
			<button routerLink="./market-research">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img desktop">
			<img src="../../assets/imgs/marketing-and-advertisement/sect2.png" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<h1>Websites And Applications </h1>
			<p>
				Cadres propose Business solutions to prosper the media planning process of strategically selecting various media platforms to perform digital advertising and marketing to achieve business campaign goals. Boosting your business conversions will facilitate media buying for targeted consumers to generate business leads, traffic, and reconcile businesses strategies via RTB and DSP
			</p>
			<button routerLink="./websites-and-applications">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/marketing-and-advertisement/sect3.png" alt="">
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<h1>Media And Content Production </h1>
			<p>
				Cadres propose Business solutions to prosper the media planning process of strategically selecting various media platforms to perform digital advertising and marketing to achieve business campaign goals. Boosting your business conversions will facilitate media buying for targeted consumers to generate business leads, traffic, and reconcile businesses strategies via RTB and DSP
			</p>
			<button routerLink="./media-and-content-production">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img desktop">
			<img src="../../assets/imgs/marketing-and-advertisement/sect4.png" alt="">
		</div>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<h1>Digital Marketing</h1>
			<p>
				Cadres propose Business solutions to prosper the media planning process of strategically selecting various media platforms to perform digital advertising and marketing to achieve business campaign goals. Boosting your business conversions will facilitate media buying for targeted consumers to generate business leads, traffic, and reconcile businesses strategies via RTB and DSP
			</p>
			<button routerLink="./digital-marketing">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/marketing-and-advertisement/sect5.png" alt="">
		</div>
	</div>
</section>
