
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Why Choose CADRES</h1>
			<p>
				In cadres we have reached the
				middle ground between academic
				knowledge and practical
				experience for delivering worldclass educational services in its
				simplest form. On the other hand,
				we aim to support Egyptian
				Businesses along their journey of
				success in order to push the whole
				economy forward.
			</p>
			<button routerLink="/about">
				Read More
				<img id="btnImg" src="../../assets/icons/arrowRight_white.svg">
			</button>
		</div>
		<div class="img">
			<img src="../../assets/imgs/home/sect1.svg">
		</div>
	</div>
</section>