
<div class="newRound_wrapper">
	<div class="newRound_content">
		<p id="title">New Round</p>
		<div class="inputs" id="input1">
			<legend>Round Name</legend>
			<input rows="1"  type="text" [(ngModel)]="globals.diplomaRoundName_value">
		</div>
		<div class="inputs" id="input6">
			<legend>Seats Available</legend>
			<input rows="10" type="text" [(ngModel)]="globals.diplomaRoundMaxCapacity_value">
		</div>
		<div class="inputs" id="input8">
			<legend>Set Date:</legend>
			<input type="date" [(ngModel)]="globals.diplomaRoundStartDate_value">
		</div>
		<div class="dropDowns_parent" id="suite">
		   <mat-form-field>
				<mat-label>Diplomas</mat-label>
				<mat-select [(ngModel)]="globals.diplomaRoundDiplomaFk_value">
					<mat-option *ngFor="let diploma of diplomas" [value]="diploma.diploma_pk">{{diploma.diplomaName | removeHtmlTags}}</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- <span *ngIf="textLength > 0">{{textLength}}</span> -->
		</div>
		<div class="assignTeachers_btn">
			<a routerLink="./assign-instructors">Assign Instructors</a>
		</div>
	</div>
</div>
