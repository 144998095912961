import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-requests',
  templateUrl: './admin-requests.component.html',
  styleUrls: ['./admin-requests.component.css']
})
export class AdminRequestsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

	appointments_bg:string = "#f1f1f1";
	quotes_bg:string = "#C5C5C5";
	appointments_display:string = 'block';
	quotes_display:string = 'none';
	dropDown_display:string = ' none'

	appointments:any;
	quotes:any;

	searchText:string;

  ngOnInit(): void {
	window.scrollTo(0, 0);
	this.apiService.getAppointments().subscribe(response=>{	
		this.appointments = response;
	console.log(this.appointments[0].pk);
	});

	this.apiService.getQuotes().subscribe(response=>{	
		this.quotes = response;
		
	});
	this.switch('appointments');
  }


   switch(filter):void{
   	console.log(filter);
   	
		if(filter == "appointments") {			
			this.appointments_bg = "#C5C5C5";
			this.quotes_bg = "#f1f1f1";
			this.appointments_display = 'block';
			this.quotes_display = 'none';
			this.dropDown('none');
			
		}else if(filter == "quotes") {
			this.appointments_bg = "#f1f1f1";
			this.quotes_bg = "#C5C5C5";
			this.appointments_display = 'none';
			this.quotes_display = 'block';
			this.dropDown('none');

		}
	}

	dropDown(display):void{
   		this.dropDown_display = display;
	}

}
