import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-instructor-students',
  templateUrl: './instructor-students.component.html',
  styleUrls: ['./instructor-students.component.css']
})
export class InstructorStudentsComponent implements OnInit {

    constructor(private apiService: ApiService) {}


  	instructor_pk:string = this.apiService.loggedIn_user()["pk"];


	students:any;

		
  ngOnInit(): void {
  	window.scrollTo(0, 0);

	this.apiService.getStudents_byInstructortId(this.instructor_pk).subscribe(response=>{	
		this.students = response.data;
		console.log(this.students);
	});
	
  }

}
