<svg class="bg" id="bg_top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"
><defs><style>.a{fill:#58595B; }</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>
<section class="sect6_wrapper">
	<div class="sect6_content">
		<h2>Ready To Get Started?</h2>
		<div class="cards" id="card1">
			<div class="img"><img src="../../assets/illustrations/illustration4.svg" alt=""></div>
			<div class="info">
				<h3>Become a Student</h3>
				<p>
					Join the first completely British business educational experience for postgraduate studies in Egypt. We have been equipped with an international guidance to offer world-leading business education. Team up with us to be an internationally qualified entrepreneur.
				</p>
				<button (click)="showHideSignWrapper('flex', 'signUp')">Join Now</button>
			</div>
		</div>
		<div class="cards" id="card2">
			<div class="img"><img src="../../assets/illustrations/illustration5.svg" alt=""></div>
			<div class="info">
				<h3>Become a Instructor</h3>
				<p>
					Today&apos;s world demands knowledge to survive. In cadres, we believe in the power of knowledge. We do value those who sacrifice their lives to pass knowledge to others who will certainly make a difference. Join forces with Cadres to deliver Egypt&apos;s youth a better quality of business education.
				</p>
				<button (click)="showApplyInstructorWrapper('flex')">Join Now</button>
			</div>
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/>
</svg>