
<get-a-quote [style.display] = "globals.getQuoteWrapper_display"></get-a-quote>

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>WEB DEVELOPMENT/ WEB DESIGN (UX/UI)</h1>
			<p>
				As Cadres offer a wide spectrum of highly professional services in 
				web design and web development to ensure a dynamic user 
				interface and user experience (UI/ UX). Cadres Business solution 
				aims to facilitate your internet presence, by executing strategic 
				techniques to build the website in with a pixel perfect layout for the 
				webpages, icons, typography, and other intricate features.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/websites-and-applications/sect1.png" alt="">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>


<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Cadres business analyse, plan, design, and support company’s needs by following our methodology that combines targeted marketing goals using innovation solutions for successful completion and delivery of the business website. Within the tech industry, UX and UI design are used to encompass all interactions between potential and active customers and the company
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/websites-and-applications/sect2.png" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<p>
				UX design is entirely compromised within the digital fields as it refers to the user’s experience by considering all factors and elements of the users’ cognitive thinking, as Cadres ensures developing and improving the quality of interaction between the user and the website
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/websites-and-applications/sect3.png" alt="">
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p>
				UI design is a conglomeration of tasks focused on the optimization of a product or a service for an effective and enjoyable user interface design; to complement the interactivity and presentation of the users.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/websites-and-applications/sect4.png" alt="">
		</div>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="sect5_content">
		<div class="info">
			<svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67">
			  <g id="Group_2295" data-name="Group 2295" transform="translate(-859 -3962)">
			    <circle id="Ellipse_282" data-name="Ellipse 282" cx="33.5" cy="33.5" r="33.5" transform="translate(859 3962)" fill="#58595b"/>
			    <path id="Path_7058" data-name="Path 7058" d="M4.963,0A4.963,4.963,0,0,1,9.926,4.963l-4.881,37.4c0,.548.548,0,0,0h0c-.548,0,0,.548,0,0L0,4.963A4.963,4.963,0,0,1,4.963,0Z" transform="translate(887.289 3974.407)" fill="#f7f7ff"/>
			  </g>
			</svg>
			<p>
				As Cadres considers all the visuals, interactive elements of a product or a service interface by including icons, spacing, typography, colour schemes, and responsive designs. Our highly dynamic tech team and graphic design build applications and websites by following and adhering to the UX/ UI development. As the goal of Cadres while building UX and UI design is to visually guide the user through an intuitive interface and experience to strengthen the brands’ visual assets for a consistent, coherent, and aesthetically pleasing user experience and interface. 
			</p>
		</div>
	</div>
</section>


<button (click)="show('show')">
		Get a Quote
</button>
