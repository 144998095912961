
<div class="sign_wrapper" id="sign_wrapper" [style.display]="globals.signWrapper_display">
	<img (click)="showHideSignWrapper('none')" id="cross_icon" src="../../assets/icons/cross_white.svg">
	<div [style.display]="globals.signInCard_display" class="signCard_wrapper" id="signInCard_wrapper">
		<h1>Sign In</h1>
		<form action="/action_page.php" [formGroup]="signIn" (ngSubmit)="log()">
			<input type="email" placeholder="john.doe@email.com" formControlName="email">
			<input type="password" placeholder="**********" formControlName="password">
			<div *ngIf="!signInStatus" class=""
				role="alert">
				<p [style.color]="'#A94483'">Invalid username or password. Please try again.</p>
			</div>
			<input type="submit" value="Sign In">
			<p class="switchCard_text" (click)="switchCard('signUp')">Don't Have an Account?</p>
		</form>
	</div>
	<div [style.display]="globals.signUpCard_display" class="signCard_wrapper" id="signUnCard_wrapper">
		<h1>Join as Student</h1>
		<form [formGroup]="signUp_student" (ngSubmit)="fromData_student()">				
			<input type="text" placeholder="John" formControlName="firstName">

			<input type="text" placeholder="Doe" formControlName="middleName">

			<input type="text" placeholder="Doe" formControlName="lastName">

			<input type="email" placeholder="john.doe@email.com" formControlName="email">

			<input type="password" placeholder="**********" formControlName="password">
			<!-- <div *ngIf="signUp_student.controls.firstName.errors && signUp_student.controls.firstName.touched" class="alert alert-danger"
				role="alert">
				<p>First Name is Required </p>
			</div>
			<div *ngIf="signUp_student.controls.middleName.errors && signUp_student.controls.middleName.touched" class="alert alert-danger"
				role="alert">
				<p>middle Name is Required </p>
				<p>First letter Must be capital</p>
				<p>Max Length 20</p>
			</div>
			<div *ngIf="signUp_student.controls.lastName.errors && signUp_student.controls.lastName.touched" class="alert alert-danger"
				role="alert">
				<p>Last Name is Required </p>
				<p>First letter Must be capital</p>
				<p>Max Length 20</p>
			</div>
			<div *ngIf="signUp_student.controls.email.errors && signUp_student.controls.email.touched" class="alert alert-danger "
				role="alert">
				Email is Required
			</div>
			<div *ngIf="signUp_student.controls.password.errors && signUp_student.controls.password.touched"
				class="alert alert-danger" role="alert">
				<p>Please enter password </p>
				<p>Must a Number and more than 8 characters</p>
			</div> -->
			
			<div class="ts-cs">
				<input (click)="buttonState()" type="checkbox" id="ts-cs_student" name="gender" value="ts-cs">
				<label for="ts-cs">I agree to terms and conditions</label>
			</div>
			<!-- *ngIf="!signUp_student.controls.firstName.errors && !signUp_student.controls.middleName.errors && !signUp_student.controls.lastName.errors && !signUp_student.controls.email.errors && !signUp_student.controls.password.errors" -->
			<input type="submit" value="Sign Up">
			<p class="switchCard_text" (click)="switchCard('signIn')">Already Have an Account?</p>
		</form>
	</div>
	<div class="img">
		<img src="../../assets/illustrations/signIn/sect1.svg">
	</div>
	<div [style.display]="dashBoardDiclaimer_display" class=dashBoardDiclaimer_wrapper>
		<div class="dashBoardDiclaimer_content">
			<h1>Dislaimer</h1>
			<p>We have sent you an email to verify your account. <br> You can now sign in and checkout your dashboard</p>
			<p (click)="switchCard('signIn')">Sign in Now</p>
		</div>
	</div>
</div>
