import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-business-solutions-versent-test',
  templateUrl: './business-solutions-versent-test.component.html',
  styleUrls: ['./business-solutions-versent-test.component.css']
})
export class BusinessSolutionsVersentTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }



}
