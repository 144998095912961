
<get-a-quote [style.display] = "globals.getQuoteWrapper_display"></get-a-quote>

<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Title</h1>
			<p>
				Cadres provide skilled expertise to gather information about your business potential buyers’ persona, target audience, competitor analysis, and customers’ needs to determine how feasible and successful your product or service are. As we conduct competent marketing and management analysis, to ensure the buying behaviour in addition to customer influences regarding their decisions to accomplish higher business conversions
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/media-and-content-production/sect1.png" alt="">
		</div>
	</div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2557.78 193.55"><defs><style>.a{fill:#58595B;}</style></defs><path class="a" d="M2557.78-1574.92V193.55S2097.07-17.74,1460.35,81.76,0,193.55,0,193.55H0V-1574.92Z"/></svg>

<section class="sections_wrapper">
	<div class="sections_content">
		<p [style.box-shadow]="boxShadow_videoAndAudioProduction" (click)='changeBody("Video and Audio Production")' id="videoAndAudioProduction">Video and Audio Production</p>
		<p [style.box-shadow]="boxShadow_brandingAndPositioning" (click)='changeBody("Branding and Positioning")' id="brandingAndPositioning">Branding and Positioning</p>
		<p [style.box-shadow]="boxShadow_contentCreation" (click)='changeBody("Content Creation")' id="contentCreation">Content Creation</p>
	</div>
</section>


<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>{{sect2_title}}</h1>
			<p>{{sect2_para1}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/media-and-content-production/{{sect2_img}}" alt="">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info">
			<p>{{sect3_para1}}</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/media-and-content-production/{{sect3_img}}" alt="">
		</div>
	</div>
</section>

<section [style.display]="display1" class="sect4_wrapper">
	<div class="sect4_content">
		<div class="info">
			<p>
				By adding a value proposition to your business and constructing your brands’ emotion and 
				personality to create a positive user experience and establishing a preference relevant to your 
				brand through the business offerings to compete within the market. Cadres marketing and 
				advertising team aim to portray the business values and mission to achieve the best results
			</p>
		</div>
	</div>
</section>


<button (click)="show('show')">
		Get a Quote
</button>
