
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>Sosie 2nd Generation</h1>
			<p>
				a test to assess personality traits such as responsibility, stress resistance, sociability, and original thinking. It further tests an individual’s value system including achievement, materialism, conviction, orderliness, goal orientation and variety
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/sosie-generation/sect1.png">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<div class="info">
			<h1>Sosie 2nd Generation Test</h1>
			<p>
				SOSIE is the most reliable test used in the process of predicting an individual’s motivations, behavior and adaptation to a role through identifying their personality and values system.
			</p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/sosie-generation/sect2.png">
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="bg"></div>
	<p id="title">The 8 personality traits that determine the characteristics of someone’s behavior</p>
	<div class="sect3_content">
		<p>Responsibility</p>
		<p>Stress Resistance</p>
		<p>Dominance</p>
		<p>Cautiousness</p>
		<p>Sociability</p>
		<p>Original Thinking</p>
		<p>Personal Relations</p>
		<p><span>Strength</span></p>
	</div>
</section>

<section class="sect4_wrapper">
	<p id="title">The 6 interpersonal values of an individual are examined to predict how they<br>
	would work with teams andothers</p>
	<div class="sect4_content">
		<p>Recognitions</p>
		<p>Independence</p>
		<p>Power</p>
		<p>Support</p>
		<p>Benevolence</p>
		<p>Conformity</p>
	</div>
</section>

<section class="sect5_wrapper">
	<div class="bg"></div>
	<p id="title">The 6 personal values of the individual are also examined to explore their<br>
	motivations in work and professional activities</p>
	<div class="sect5_content">
		<p><span>Goal orientation</span></p>
		<p>Achievement</p>
		<p>Variety</p>
		<p>Conviction</p>
		<p>Materialism</p>
		<p>Materialism</p>
		<p>Orderliness</p>
	</div>
</section>

<section class="sect6_wrapper">
	<div class="sect6_content">
		<div class="info">
			<svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67">
			  <g id="Group_2295" data-name="Group 2295" transform="translate(-859 -3962)">
			    <circle id="Ellipse_282" data-name="Ellipse 282" cx="33.5" cy="33.5" r="33.5" transform="translate(859 3962)" fill="#58595b"/>
			    <path id="Path_7058" data-name="Path 7058" d="M4.963,0A4.963,4.963,0,0,1,9.926,4.963l-4.881,37.4c0,.548.548,0,0,0h0c-.548,0,0,.548,0,0L0,4.963A4.963,4.963,0,0,1,4.963,0Z" transform="translate(887.289 3974.407)" fill="#f7f7ff"/>
			  </g>
			</svg>
			<p>
				You get the SOSIE results quickly, the nature of the exam is 
				that its ipsiative questions format which make it almost 
				impossible for two candidates to get the same version or 
				fake responses. Multiple reports are available, such as a 
				basic profile report, sales report, manager report, customer 
				service report and a candidate feedback report.
			</p>
		</div>
	</div>
</section>

<section class="sect7_wrapper">
	<div class="sect7_content">
		<div class="info">
			<p id="title">What is SOSIE 2nd Generation Test</p>
			<p>- Online Administration</p>
			<p>- 30 minutes-Untimed</p>
			<p>- 80 items</p>
			<p>- UK English, US English, Australian English, Brazilian Portuguese,   Danish, Dutch, French, German, Indian English, Norwegian, and Swedish</p>
			<p>- Roles:  Managers, Sales, Teachers and Customer service representative </p>
		</div>
		<div class="img">
			<img src="../../assets/imgs/sosie-generation/sect7.png" alt="">
		</div>
	</div>
</section>

