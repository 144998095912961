
<lcci-sign-in></lcci-sign-in>
<payment-gateway></payment-gateway>
<product-plan></product-plan>

<section class="sect1_wrapper">
	<div class="bg"></div>
	<div class="sect1_content">
		<div class="top">
			<div class="info">
				<p id="title">Business English</p>
				<p>
					Cadres is offering Pearson’s richest Business Partner curriculum for business English. This course acts as a bridge to fill the gap between classroom and global workplace.  Just like Roadmap, our Business Partner curriculum is meant to facilitate the process of learning English alongside aid in boosting the progress to become more employable.
				</p>
			</div>
			<div class="img">
				<img src="../../assets/imgs/general-english/sect1.svg" alt="">
			</div>
		</div>
		<div class="bottom">
			<div class="info">
				<p>
					The business partner curriculum is designed for candidates with no work experience such as senior students or fresh-graduates. It further provides suggestions and alternative materials for learners who are already in work and want to improve their career progression.
				</p>
			</div>
			<div class="img">
				<img src="../../assets/imgs/imgPlaceHolder.svg" alt="">
			</div>
		</div>
	</div>
</section>
<button (click)="lcciPlan('flex')">Enroll</button>

<section class="sect2_wrapper">
	<div class="sect2_content">
		<h1>Business Partner provides you with real life business content</h1>
		<div class="info" id="info1">
			<div class="img">
				<img src="../../assets/icons/fast.svg">
			</div>
			<p>
				Introductory authentic videos of unit topics, meant to expose students to real English and real-life problems they might encounter
			</p>
		</div>
		<div class="info" id="info2">
			<div class="img">
				<img src="../../assets/icons/fair.svg">
			</div>
			<p>
				Students can use Financial Times articles to practice their reading skills and be exposed to authentic English.
			</p>
		</div>
		<div class="info" id="info3">
			<div class="img">
				<img src="../../assets/icons/recognised.svg">
			</div>
			<p>
				Students are assigned case studies that allow them to practice their business as well as communication skills 
			</p>
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<div class="info" id="info1">
			<div class="number">
				<p>1</p>
			</div>
			<p>
				Communication and practical business skills are two sets of skills that are built in the curriculum through business workshop case studies
			</p>
		</div>
		<div class="info" id="info2">
			<div class="number">
				<p>2</p>
			</div>
			<p>
				Negotiation, presentation, building relationships and small talk skills are enhanced in the practical skills training
			</p>
		</div>
		<div class="info" id="info3">
			<div class="number">
				<p>3</p>
			</div>
			<p>
				Functional language is acquired by course students alongside their ability to practice their writing, grammar and pronunciation skills
			</p>
		</div>
	</div>
</section>

<section class="sect4_wrapper">
	<div class="sect4_content">
		<h1>Why Business Partner is flexible for students and teachers</h1>
		<p>
			Teachers are allowed in the curriculum to pick the lessons that would suit their students, 
			each lesson can be taught independently. Reading Bank, Writing Bank and Functional 
			Language Bank are additional resources that support students
		</p>
	</div>
	<button (click)="lcciPlan('flex')">Enroll</button>
</section>