

<section class="filter_wrapper">
	<div class="filter_content">
		<div class="filters_container">
			<div class="searchBar">
				<img src="../../assets/icons/search_black.svg">
				<input [(ngModel)]="searchText" type="text" placeholder="Type ...">
				<p>Search</p>
			</div>
		</div>
	</div>
</section>

<section class="pages_wrapper">
	<div class="pages_content" *ngFor="let page of pages | filter:searchText">
		<div class="page">
			<p id="pageName">{{page.name}}</p>
			<p id="edit" routerLink="./edit-page/{{page.pk}}">Edit</p>
			<p id="active" *ngIf="page.status == 'true'">Active</p>
			<p id="inActive" *ngIf="page.status == 'false'">Inactive</p>
		</div>
	</div>
</section>