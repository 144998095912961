
<section class="sect1_wrapper">
	<div class="sect1_content">
		<div class="info">
			<h1>BUSINESS ADMINISTRATION</h1>
			<p>
				LCCI Business diplomas are made to provide more flexibility for students in their offerings for Business qualifications. Cadres are aligned with Pearson to ensure the maximum benefit received by the students in terms of their work-ready skills development, provide emphasis on practical learning to showcase specific skills required to be fully ready for the market, and 100% externally assessed by Pearson to ensure the standard of each of the qualification.
			</p>
		</div>
		<div class="img desktop">
			<img src="../../assets/imgs/business-administration/sect1.png" alt="">
		</div>
	</div>
</section>

<section class="sect2_wrapper">
	<div class="bg">
		<img src="../../assets/backgrounds/business-administration/sect2.svg">
	</div>
	<div class="sect2_content">
		<div class="left">
			<p>
				<span>Cadres Business School</span> provides a Pearson LCCI awarded qualifications for both seniors and executives; by offering level 2 and level 3 business qualifications. Both business levels certificates are acquired through the completion of business certification, as both levels provide a broad spectrum of the business context. As the practical development of the business focus ranges in international business and its qualifications to financial and risk management to understand the market business opportunities for further growth and success
			</p>
		</div>
		<div class="right">
			<p id="title">The LCCI Business Diplomas were 
developed in conjunction with</p>
			<div>
				<p id="title2">CIPD</p>
				<p>
					The Chartered Institute of Personnel and Development
				</p>
			</div>
			<div>
				<p id="title2">ISBE</p>
				<p>
					The Illinois State Board of Education
				</p>
			</div>
			<div>
				<p id="title2">IABC</p>
				<p>
					The International Association of 
					Business Communicators
				</p>
			</div>
			<div>
				<p id="title2">IABC</p>
				<p>
					The International Association of 
					Business Communicators
				</p>
			</div>
			<p>Peter Jones Foundation Enterprise</p>
			<p>Coventry University</p>
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	<div class="sect3_content">
		<p id="title">The available LCCI marketing diplomas include:</p>
		<div class="info left">
			<h1>Level 2</h1>
			<p>DIPLOMA IN BUSINESS</p>
			<p>ADVANCED DIPLOMA IN BUSINESS</p>
		</div>
		<div class="info right">
			<h1>Level 3</h1>
			<p>DIPLOMA IN BUSINESS</p>
			<p>ADVANCED DIPLOMA IN BUSINESS AND ENTERPRISE</p>
		</div>
	</div>
</section>