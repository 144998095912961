import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-cadres-support-startups',
  templateUrl: './cadres-support-startups.component.html',
  styleUrls: ['./cadres-support-startups.component.css']
})
export class CadresSupportStartupsComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void { 
  	window.scrollTo(0, 0);
  }


   show(hideORshow):void {
  	if(hideORshow=="hide") {
  		this.globals.bookAppointmentWrapper_display = "none";
  	}else if(hideORshow=="show") {
  		this.globals.bookAppointmentWrapper_display = "block";
  	}
  }


}
