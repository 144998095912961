
<lcci-sign-in></lcci-sign-in>
<payment-gateway></payment-gateway>
<product-plan></product-plan>

<section class="sect1_wrapper">
	<div class="bg"></div>
	<div class="sect1_content">
		<div class="top">
			<div class="info">
				<p id="title">General English Course</p>
				<p>
					Roadmap is a quite simplified English curriculum developed and designed by the world leading educational company Pearson. It consists of eight levels starting from beginner English level up to advanced English. Just like Cadres, Roadmap recognizes the need of every unique learner and different class. Our course has a dual-track approach, it focuses on the developing process of your English skills e.g. reading, writing, listening and speaking. 
				</p>
			</div>
			<div class="img">
				<img src="../../assets/imgs/general-english/sect1.svg" alt="">
			</div>
		</div>
		<div class="bottom bottom_desktop">
			<div class="img">
				<img src="../../assets/illustrations/general-english/sect1.svg" alt="">
			</div>
			<div class="info">
				<p>
					The Roadmap course could be used by students to learn and make a measurable progress in short time. Also, it would assist teachers organising their classes to develop the students’ sub-skills. Lessons are delivered successfully through easy and fun materials.
				</p>
			</div>
		</div>
		
		<div class="bottom bottom_mobile">
			<div class="info">
				<p>
					The Roadmap course could be used by students to learn and make a measurable progress in short time. Also, it would assist teachers organising their classes to develop the students’ sub-skills. Lessons are delivered successfully through easy and fun materials.
				</p>
			</div>
			<div class="img">
				<img src="../../assets/illustrations/general-english/sect1.svg" alt="">
			</div>
		</div>
	</div>
</section>
	<button (click)="lcciPlan('flex')">Enroll</button>

<section class="sect2_wrapper">
	<p id="title">Course Components</p>
	<div class="sect2_content">
		<div class="columns" id="comlumn1">
			<div class="cards" id="card1" onclick="showMore('studentBook', 'flex')">
				<div class="card_header">
					<p>Student Book</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
					</div>
				</div>
				<div class="card_extra" id="studentBook">
					<p>
						consists of 10 units, in each main lesson the Grammar, Vocabulary and Pronunciation lead you to a speaking task based on GSE learning objectives. The audio, video, scripts, word lists in 
						the book are available online.
					</p>
				</div>
			</div>
			<div class="cards" id="card7" onclick="showMore('presentationTool', 'flex')">
				<div class="card_header">
					<p>Presentation Tool</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
					</div>
				</div>
				<div class="card_extra" id="presentationTool">
					<p>
						with teachers access on Pearson English portal you can access this tool which offers a fully interactive version of the Students’ Book, a planner (includes teacher’s notes) and teaching mode, it also makes it easier to navigate via additional game activities
					</p>
				</div>
			</div>
		</div>
		<div class="columns" id="culomn2">
			<div class="cards" id="card2" onclick="showMore('mobilePractice', 'flex')">
				<div class="card_header">
					<p>Mobile Practice App</p>
					<div class="img">
							<img src="../../assets/icons/chevronDown_black.svg" alt="">
						</div>
					</div>
				<div class="card_extra" id="mobilePractice">
					<p>
						Extra English grammar and vocabulary exercises for students to enhance their English skills
					</p>
				</div>
			</div>
			<div class="cards" id="card6" onclick="showMore('teachersDigitalBook', 'flex')">
				<div class="card_header" id="h">
					<p>Teacher's digital Resources</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
					</div>
				</div>
				<div class="card_extra" id="teachersDigitalBook">
					<p>
						an online portal for teachers that they can access with a code. In addition to what Teacher’s book offers the digital resource includes an assessment test with diagnostic test, unit tests, mid-course test and end-of-course test.
					</p>
				</div>
			</div>
		</div>
		<div class="columns" id="column3">
			<div class="cards" id="card3" onclick="showMore('workBook', 'flex')">
				<div class="card_header">
					<p>WorkBook with Key</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
						</div>
					</div>
				<div class="card_extra" id="workBook">
					<p>
						Additional grammar, vocabulary, functional language, reading, writing and listening practice activities followed by an answer key to help students check their answers
					</p>
				</div>
			</div>
			<div class="cards" id="card5" onclick="showMore('teachersBook', 'flex')">
				<div class="card_header">
					<p>Teacher's Book</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
					</div>
				</div>
				<div class="card_extra" id="teachersBook">
					<p>
						consists of teachers additional notes and some teaching tips such as dealing with mixed abilities, teaching grammar, vocabulary and pronunciation, using video etc. It also has photocopiable worksheets and GSE Portfolios for learners to complete and illustrate
					</p>
				</div>
			</div>
		</div>
		<div class="columns" id="column4">
			<div class="cards" id="card4" onclick="showMore('myEnglishLab', 'flex')">
				<div class="card_header">
					<p>MyEnglishLab</p>
					<div class="img">
						<img src="../../assets/icons/chevronDown_black.svg" alt="">
					</div>
				</div>
				<div class="card_extra" id="myEnglishLab">
					<p>
						A digital version of the workbook 
						that also includes tools for 
						managing and assigning self-
						study
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="sect3_wrapper">
	
	<div class="sect3_content">
		<h1>To make it easier for you to understand what fits you best in these tools</h1>
		<div class="cards" id="card1">
			<p id="title">As Student</p>
			<div id="bulletPoint"></div>
			<p>
				The mobile app allows you to practice 
				your skills anytime, anywhere
			</p>
			<div id="bulletPoint"></div>
			<p>
				MyEnglishLab gives you an 
				opportunity to practice online and 
				review your performance, or even 
				check their student book online to 
				facilitate the distance learning 
				experience
			</p>
		</div>
		<div class="cards" id="card2">
			<p id="title">As an Instructor</p>
			<div id="bulletPoint"></div>
			<p>
				The presentation tools gives you fully 
				interactive version of students’ book 
				and workbook.
			</p>
			<div id="bulletPoint"></div>
			<p>
				You can also access teaching notes, photocopies, audio and video and an assessment package. 
			</p>
		</div>
	</div>
</section>
<button (click)="lcciPlan('flex')" id="btn2">Enroll</button>